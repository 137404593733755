<template>
  <div class="inf-content">
    <Flicking
      v-if="influencers.length > 0"
      :plugins="$mq !== 'sm' ? plugins : []"
      :options="{ align: 'prev', circular: true }"
    >
      <div
        v-for="(influencer, index) of influencers"
        :key="`influencer-${index}`"
        class="influencer-content"
      >
        <div class="inf-box" @click="handlerSelect(influencer)">
          <b-tooltip
            :position="handlePositionTooltip(index)"
            type="is-pigeon"
            :label="influencer.nome_midia"
          >
            <div
              class="influencer"
              :class="{
                active:
                  influencerSelecteds.includes(influencer.id) ||
                  (influencerSelecteds.length === 0 &&
                    influencer.id === 'todos'),
                'inf-destaques': influencer.id === 'todos',
              }"
              :style="{
                backgroundImage: influencer.url_foto
                  ? `url(${influencer.url_foto})`
                  : '',
              }"
            >
              <div
                v-if="influencer.id === 'todos'"
                :style="{ fontSize: $mq === 'sm' ? '9px' : '10px' }"
              >
                Todos
              </div>
            </div>
          </b-tooltip>
        </div>
      </div>
    </Flicking>
    <span v-if="$mq !== 'sm'" class="flicking-arrow-prev is-outside"></span>
    <span v-if="$mq !== 'sm'" class="flicking-arrow-next is-outside"></span>
  </div>
</template>

<script>
import { Arrow } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";

export default {
  name: "InfluencerList",
  props: {
    filters: {
      type: Object,
      required: true,
    },
    influencers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      influencerSelecteds: [],
      plugins: [new Arrow({ parentEl: document.body })],
    };
  },
  created() {
    if (this.filters?.influencer) {
      this.influencerSelecteds = this.filters.influencer
        .split(",")
        .map((i) => parseInt(i));
    }
  },
  methods: {
    handlerSelect(influencer) {
      if (influencer.id == "todos") this.influencerSelecteds = [];
      else {
        if (this.influencerSelecteds.includes(influencer.id))
          this.influencerSelecteds = this.influencerSelecteds.filter(
            (el) => el !== influencer.id
          );
        else this.influencerSelecteds.push(influencer.id);
      }

      const influencersToString = [...this.influencerSelecteds].join(",");
      this.$emit("change", { influencer: influencersToString });
    },
    reset() {
      this.$emit("change", {});
    },
    handlePositionTooltip(index) {
      if (index >= this.influencers.length - 3) return "is-left";
      return "is-right";
    },
  },
};
</script>

<style lang="scss">
.inf-content {
  position: relative;
  width: 90%;
  margin: 0 auto;

  .flicking-arrow-prev,
  .flicking-arrow-next {
    width: 40px;
    height: 40px;

    &::before {
      left: 12px;
      width: 15px;
      height: 4px;
      background-color: #7285a5;
    }
    &::after {
      top: calc(50% - 2px);
      left: 13px;
      width: 15px;
      height: 4px;
      background-color: #7285a5;
    }
  }

  @media screen and (max-width: 768px) {
    .inf-box {
      padding: 0.3em !important;

      .check-active {
        height: 15px !important;
        width: 15px !important;

        i {
          font-size: 12px;
        }
      }
      .influencer {
        height: 35px !important;
        width: 35px !important;
      }
    }
  }

  .inf-box {
    position: relative;
    padding: 0 0.75em;
    cursor: pointer;

    &:hover > .influencer {
      border: 2px solid #00f0f8;
      -webkit-box-shadow: 0px 0px 7px -1px #00f0f8bd;
      -moz-box-shadow: 0px 0px 7px -1px#00f0f8bd;
      box-shadow: 0px 0px 7px -1px#00f0f8bd;
    }

    .check-active {
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      bottom: 10px;
      right: 7px;
      background-color: #00f0f8;
      color: black;
    }

    .influencer {
      border-radius: 50%;
      border: 2px solid #7285a5;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #596a88;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;

      &:hover {
        border: 2px solid #00f0f8;
        -webkit-box-shadow: 0px 0px 7px -1px #00f0f8bd;
        -moz-box-shadow: 0px 0px 7px -1px#00f0f8bd;
        box-shadow: 0px 0px 7px -1px#00f0f8bd;
      }
    }

    .influencer.active {
      border: 2px solid #00f0f8;
      -webkit-box-shadow: 0px 0px 7px -1px #00f0f8bd;
      -moz-box-shadow: 0px 0px 7px -1px#00f0f8bd;
      box-shadow: 0px 0px 7px -1px#00f0f8bd;
    }

    .inf-destaques {
      background: -moz-linear-gradient(
        left,
        rgba(108, 40, 148, 1) 0%,
        rgba(102, 40, 248, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(108, 40, 148, 1)),
        color-stop(100%, rgba(102, 40, 248, 1))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(108, 40, 148, 1) 0%,
        rgba(102, 40, 248, 1) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(108, 40, 148, 1) 0%,
        rgba(102, 40, 248, 1) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(108, 40, 148, 1) 0%,
        rgba(102, 40, 248, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(108, 40, 148, 1) 0%,
        rgba(102, 40, 248, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6c2894', endColorstr='#6628f8', GradientType=1 );
    }
  }
}
</style>
