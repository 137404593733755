var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inf-content"},[(_vm.influencers.length > 0)?_c('Flicking',{attrs:{"plugins":_vm.$mq !== 'sm' ? _vm.plugins : [],"options":{ align: 'prev', circular: true }}},_vm._l((_vm.influencers),function(influencer,index){return _c('div',{key:("influencer-" + index),staticClass:"influencer-content"},[_c('div',{staticClass:"inf-box",on:{"click":function($event){return _vm.handlerSelect(influencer)}}},[_c('b-tooltip',{attrs:{"position":_vm.handlePositionTooltip(index),"type":"is-pigeon","label":influencer.nome_midia}},[_c('div',{staticClass:"influencer",class:{
              active:
                _vm.influencerSelecteds.includes(influencer.id) ||
                (_vm.influencerSelecteds.length === 0 &&
                  influencer.id === 'todos'),
              'inf-destaques': influencer.id === 'todos',
            },style:({
              backgroundImage: influencer.url_foto
                ? ("url(" + (influencer.url_foto) + ")")
                : '',
            })},[(influencer.id === 'todos')?_c('div',{style:({ fontSize: _vm.$mq === 'sm' ? '9px' : '10px' })},[_vm._v(" Todos ")]):_vm._e()])])],1)])}),0):_vm._e(),(_vm.$mq !== 'sm')?_c('span',{staticClass:"flicking-arrow-prev is-outside"}):_vm._e(),(_vm.$mq !== 'sm')?_c('span',{staticClass:"flicking-arrow-next is-outside"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }