<template>
  <section id="Payment">
    <div v-if="paymentRender">
      <div
        :class="{
          'no-margin': $mq == 'sm',
          'modal-card': product !== 'Planos',
        }"
      >
        <header
          v-if="product !== 'Planos'"
          :class="{ 'modal-card-head': product !== 'Planos' }"
        >
          <div
            v-if="result == 'success'"
            :class="{ 'modal-box-title': product !== 'Planos' }"
          >
            <div class="">
              <b-icon
                type="is-info"
                icon="info-circle"
                pack="fas"
                size="is-large"
              ></b-icon>
            </div>
            <div class="">
              <div class="is-vcentered">
                <p :class="{ 'modal-card-title': product !== 'Planos' }">
                  VOCÊ ESTÁ QUASE LÁ!
                </p>
                <span>Siga as intruções para realizar o pagamento</span>
              </div>
            </div>
          </div>
          <div
            v-if="result == 'error'"
            :class="{ 'modal-box-title': product !== 'Planos' }"
          >
            <div class="">
              <b-icon
                type="is-danger"
                icon="times-circle"
                pack="fas"
                size="is-large"
              ></b-icon>
            </div>
            <div class="">
              <div class="is-vcentered">
                <p :class="{ 'modal-card-title': product !== 'Planos' }">
                  OCORREU UM PROBLEMA!
                </p>
                <span>Verifique o erro e solicite o suporte se necessário</span>
              </div>
            </div>
          </div>
          <div
            v-if="result == 'url'"
            :class="{ 'modal-box-title': product !== 'Planos' }"
          >
            <div class="">
              <b-icon
                type="is-warning"
                icon="exclamation-circle"
                pack="fas"
                size="is-large"
              ></b-icon>
            </div>
            <div class="">
              <div class="is-vcentered">
                <p :class="{ 'modal-card-title': product !== 'Planos' }">
                  ATENÇÃO!
                </p>
                <span>Redirecionamento de página para integração</span>
              </div>
            </div>
          </div>
          <div
            v-if="result == '' && product !== 'Planos'"
            :class="{ 'modal-box-title': product !== 'Planos' }"
          >
            <div class="">
              <div class="is-vcentered">
                <p :class="{ 'modal-card-title': product !== 'Planos' }">
                  Processo de Pagamento
                </p>
              </div>
            </div>
          </div>
        </header>
        <section
          :class="{
            'no-padding': $mq == 'sm',
            'modal-card-body': product !== 'Planos',
          }"
        >
          <div
            v-if="result !== ''"
            class="column is-full is-multiline is-vcentered"
          >
            <div v-if="result == 'success'">
              <div
                v-if="starttime !== '' && endtime !== ''"
                id="timer"
                class="timer"
              >
                <!--  Timer Component  -->
                <timer
                  :starttime="startTime"
                  :endtime="endTime"
                  :trans="{
                    hours: 'Hora(s)',
                    minutes: 'Minuto(s)',
                    seconds: 'Segundo(s)',
                    running: 'Tempo estimado para efetuar o pagamento.',
                    expired:
                      'Realize uma nova transação para efetuar o pagamento.',
                    status: {
                      expired: 'Transação Expirada!',
                    },
                  }"
                ></timer>
                <!--  End! Timer Component  -->
              </div>

              <div class="centralize" v-if="resultMessage.qrcode">
                <b-button
                  icon-pack="fas"
                  icon-left="copy"
                  type="is-primary"
                  @click="copyQrCode()"
                  >Copiar o código do QR code
                </b-button>
                <b-input
                  type="hidden"
                  id="export_csv_file"
                  :value="export_file"
                ></b-input>
              </div>
              <div v-html="resultMessage.success"></div>
            </div>
            <div v-if="result == 'error'">
              <div v-html="resultMessage.error"></div>
            </div>
            <div v-if="result == 'url'" style="text-align: center">
              <h1 style="color: #fff">REDIRECIONANDO PARA...</h1>
              <img
                v-if="resultMessage.integration == 'paymee'"
                class="intLogo"
                src="@/assets/integradora/paymee.png"
                alt="PayMee"
              />
              <img
                v-if="resultMessage.integration == 'mercadopago'"
                class="intLogo"
                src="@/assets/integradora/mercadopago.png"
                alt="Mercado Pago"
              />
              <img
                v-if="resultMessage.integration == 'picpay'"
                class="intLogo"
                src="@/assets/integradora/picpay.png"
                alt="PicPay"
              />
              <img
                v-if="resultMessage.integration == 'escala10'"
                class="intLogo"
                src="@/assets/logotipo_green.png"
                alt="Escala10"
              />
              <progress class="progress is-small is-primary" max="100">
                15%
              </progress>
            </div>
          </div>

          <div v-if="config" class="columns is-multiline is-gapless">
            <div class="column is-full is-multiline is-vcentered">
              <b-steps
                v-model="activeStep"
                @change="changeStep"
                :has-navigation="false"
                size="is-small"
              >
                <b-step-item
                  v-if="product == 'Planos'"
                  label="Planos"
                  icon-pack="fas"
                  icon="coins"
                  type="is-success"
                >
                  <plans
                    v-on:select="selectOption('product', $event)"
                    :hasCupom="hasCupom"
                    :cpf="cpf"
                    :email="email"
                    :defaultInfluencer="defaultInfluencer"
                    :defaultCupom="defaultCupom"
                  ></plans>
                </b-step-item>

                <b-step-item
                  label="Forma Pagamento"
                  icon-pack="fas"
                  icon="money-bill-wave"
                  type="is-success"
                >
                  <section :style="{marginTop: '1em'}">
                    <div class="columns is-multiline is-centered">

                      <div
                        v-for="(integra, i) of paymentIntegration"
                        :key="integra.id"
                        v-show="showIntegration(integra)"
                        class="column is-4 is-vcentered integration-card has-background-bluedark"
                        :class="{
                          'integration-selected': paymentData.integration == integra.slug,
                          'integration-not-selected': paymentData.integration !== '' && paymentData.integration !== integra.slug
                        }"
                      >
                        <div class="columns is-multiline is-vcentered is-mobile">
                          <div class="column is-full" :style="{height: $mq == 'sm' ? 'auto' : '100px'}">
                            <img
                              class="intLogo"
                              :src="integra.image"
                              :alt="integra.name"
                            />
                          </div>
                          <div class="column is-full" :style="{height: '100%'}">

                            <b-field>
                              <div>
                                <b-radio-button 
                                  v-for="(typeInt, it) of integra.types"
                                  :key="integra.id + typeInt.id"
                                  v-model="paymentData.type"
                                  :native-value="typeInt.slug"
                                  type="is-primary"
                                  expanded
                                  @input="selectOption('type',{integra, typeInt})"
                                  @click.native="
                                    paymentData.type !== ''
                                      ? selectOption('type',{integra, typeInt})
                                      : ''
                                  "
                                >
                                  <b-icon v-if="typeInt.icon" pack="fas" :icon="typeInt.icon"></b-icon>
                                  <span><b>{{typeInt.name}}</b></span>
                                  <small v-if="typeInt.slug == 'carteira'" class="is-size-7" style="margin-left: 5px;">
                                    R${{ wallet.saldo_wallet.toFixed(2) }}
                                  </small>
                                </b-radio-button>
                              </div>
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </b-step-item>

                <b-step-item
                  v-if="product == 'Ligas'"
                  label="Créditos"
                  icon-pack="fas"
                  icon="coins"
                  type="is-success"
                >
                  <pack 
                    v-on:select="selectOption('product', $event)"
                  ></pack>
                </b-step-item>

                <b-step-item
                  v-if="paymentBankShow"
                  label="Dados Bancários"
                  icon="bank"
                  type="is-success"
                >
                  <div class="columns is-multiline">
                    <div class="column is-full">
                      <b-field label="Banco">
                        <template slot="label">
                          <p>Banco</p>
                          <small>Selecione o banco de sua conta</small>
                        </template>
                        <banks
                          default=""
                          :banks="banks"
                          v-on:select="selectOption('bank', $event)"
                        ></banks>
                      </b-field>
                    </div>

                    <div v-if="paymentAccountShow" class="column is-half">
                      <b-field label="Agência de Origem">
                        <template slot="label">
                          <p>Agência de Origem</p>
                          <small>Informe sua agência bancária</small>
                        </template>
                        <b-input
                          ref="branch"
                          v-model="paymentData.branch"
                          maxlength="8"
                        ></b-input>
                      </b-field>
                    </div>
                    <div v-if="paymentAccountShow" class="column is-half">
                      <b-field label="Conta de Origem">
                        <template slot="label">
                          <p>Conta de Origem</p>
                          <small>Informe sua conta bancária</small>
                        </template>
                        <b-input
                          ref="account"
                          v-model="paymentData.account"
                          maxlength="25"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </b-step-item>

                <b-step-item
                  label="Confirmação"
                  icon-pack="fas"
                  icon="check"
                  type="is-success"
                >
                  <div class="columns is-multiline" v-if="confirmData">
                    <div class="column is-full">
                      <p class="confirm-title">
                        Confirme os dados informados e clique em processar para
                        iniciar a transação
                      </p>
                    </div>
                    <div class="column">
                      <div class="columns is-multiline">
                        <div class="column is-full" v-if="paymentValue > 0">
                          <label>Forma de Pagamento</label>
                          <p class="confirm-data has-text-primary">
                            {{ paymentIntegrationName }} {{ paymentIntegrationType }}
                          </p>
                        </div>
                        <div class="column is-full" v-if="paymentValue == 0">
                          <p class="confirm-data has-text-primary">
                            {{
                              (product == "Ligas" ? "CRÉDITO" : "PLANO") +
                                " GRATUITO"
                            }}
                          </p>
                        </div>
                        <div class="column is-full">
                          <label>{{product == 'Ligas' ? 'Crédito' : product}}</label>
                          <p
                            class="confirm-data has-text-primary"
                            v-html="paymentProduct"
                          ></p>
                        </div>
                        <div v-if="paymentBonus" class="column is-full">
                          <label>Bônus</label>
                          <p class="confirm-data has-text-primary">
                            R$ {{ paymentBonus.toFixed(2) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="column is-narrow">
                      <div class="columns is-multiline">
                        <div class="column is-full">
                          <label>Valor a Pagar</label>
                          <p class="confirm-data has-text-primary">
                            R$ {{ paymentValue.toFixed(2) }}
                          </p>
                        </div>
                        <div class="column is-full" v-if="paymentBankShow">
                          <label>Dados Bancários</label>
                          <p class="confirm-data has-text-primary">
                            Banco: {{ paymentData.bank.id }} -
                            {{ paymentData.bank.name }}
                          </p>
                          <p
                            class="confirm-data has-text-primary"
                            v-if="paymentAccountShow"
                          >
                            Agência: {{ paymentData.branch }} / Conta:
                            {{ paymentData.account }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-step-item>
              </b-steps>
            </div>
          </div>
        </section>
        <footer :class="{ 'modal-card-foot': product !== 'Planos' }">
          <div
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <b-button
              v-if="back"
              type="is-dark"
              @click="paymentReturn()"
              icon-pack="fas"
              icon-left="backward"
              >Voltar</b-button
            >
            <b-button
              v-if="config && product !== 'Planos'"
              type="is-danger"
              @click="$parent.close()"
              outlined
              >Cancelar</b-button
            >
            <b-button v-if="confirm" type="is-success" @click="paymentConfirm()"
              >Confirmar</b-button
            >
            <b-button
              v-if="process"
              type="is-success"
              id="botao-pagamento"
              @click="paymentProcess()"
              >Processar</b-button
            >
            <b-button
              v-if="result !== ''"
              type="is-danger"
              @click="close()"
              outlined
              expanded
              >Ok</b-button
            >
          </div>
        </footer>
      </div>
    </div>

    <div v-if="paymentNotRender">
      <b-notification type="is-danger" has-icon>
        Produto não identificado pelo processo de pagamento! Favor informar ao
        suporte.
      </b-notification>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Package from "@/components/payment/Package";
import Banks from "@/components/payment/Banks";
import DownTimer from "@/components/helpers/DownTimer";
import Planos from "@/components/payment/Plans";

export default {
  name: "Payment",
  components: {
    pack: Package,
    banks: Banks,
    timer: DownTimer,
    plans: Planos,
  },
  props: {
    product: {
      type: String,
      default: () => "",
    },
    hasCupom: {
      type: Boolean,
      default: () => true,
    },
    cpf: {
      type: String,
      default: () => "",
    },
    email: {
      type: String,
      default: () => "",
    },
    defaultInfluencer: {
      type: String,
      default: () => null,
    },
    defaultCupom: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      paymentNotRender: false,
      paymentRender: false,
      paymentData: {
        integration: "",
        type: "",
        bank: "",
        branch: "",
        account: "",
        product: "",
        details: {},
      },
      paymentProduct: "",
      paymentValue: 0,
      paymentBonus: 0,
      paymentIntegration: [],
      paymentBankShow: false,
      paymentAccountShow: false,
      validationMessage: "",
      activeStep: 0,
      banks: [],
      config: true,
      back: false,
      confirm: false,
      confirmData: false,
      process: false,
      result: "",
      resultMessage: { error: null, success: null, url: null, qrcode: null },
      startTime: "",
      endTime: "",
      export_file: "",
    };
  },
  computed: {
    ...mapGetters(["userId", "userInfo", "isAdmin", "wallet", "paymentType"]),
    paymentIntegrationName(){
      let integration = this.paymentIntegration.find(i => i.slug == this.paymentData.integration)
      return integration ? integration.name : ''
    },
    paymentIntegrationType(){
      let integration = this.paymentIntegration.find(i => i.slug == this.paymentData.integration)
      if (integration){
        let type = integration.types.find(t => t.slug == this.paymentData.type)
        if (type){
          return (type.name !== integration.name) ? (" - " + type.name) : ''
        }
      }
    },
  },
  async created() {

    let loader = this.$loading.show();

    if (this.product) {
      
      await this.getUser(this.userId);
      await this.getWallet(this.userId);
      
      this.paymentIntegration = await this.getPaymentIntegration({
        operation: 'compra',
        product: this.product
      })

      this.paymentData.user = JSON.parse(JSON.stringify(this.userInfo));
      this.paymentData.integration = "";
      this.paymentData.type = "";
      this.paymentData.bank = ""; //this.paymentData.user.bank
      this.paymentData.branch = ""; //this.paymentData.user.bank_branch
      this.paymentData.account = ""; //this.paymentData.user.account
      this.paymentData.product = this.product;
      this.paymentData.details = {};
      this.paymentRender = true;
      this.paymentNotRender = false;
    } else {
      this.paymentRender = false;
      this.paymentNotRender = true;
    }

    loader.hide()
  },
  methods: {
    ...mapActions(["getUser", "getWallet", "getPaymentIntegration", "setPaymentType"]),
    showIntegration(integra){
      return (integra.slug == 'escala10') ? 
        (this.wallet.saldo_wallet >= this.paymentValue) : 
        true;
    },
    async changeStep(index) {
      if (index == 0) {
        this.confirm = false;
        this.confirmData = false;
        this.back = false;
        this.process = false;
        this.paymentBankShow = false;
        this.paymentAccountShow = false;
      } else {
        this.back = true;
      }

      this.validationMessage = "";

      switch (index) {
        case 1:
          if (this.product == "Planos") {
            if (!this.paymentData.details) {
              this.validationMessage = "Selecione um Produto";
              this.activeStep = 0;
            } else if (this.paymentBankShow) {
              this.confirm = true;
              this.confirmData = false;
              this.process = false;
            }
          } else {
            if (this.paymentData.type == "") {
              this.validationMessage = "Selecione uma Forma de Pagamento";
              this.activeStep = 0;
            } else if (!this.paymentBankShow) {
              this.confirm = true;
              this.confirmData = false;
              this.process = false;
            }
          }
          break;

        case 2:
          if (this.product == "Planos") {
            if (this.paymentData.type == "" && this.paymentValue > 0) {
              this.validationMessage = "Selecione uma Forma de Pagamento";
              this.activeStep = 1;
            } else {
              this.confirm = this.paymentValue > 0 && this.paymentBankShow;
              this.confirmData = !this.paymentBankShow;
              this.process = this.paymentValue == 0 || !this.paymentBankShow;
            }
          } else {
            if (!this.paymentData.details) {
              this.validationMessage = "Selecione um Produto";
              this.activeStep = 1;
            } else if (this.paymentBankShow) {
              this.confirm = true;
              this.confirmData = false;
              this.process = false;
            }
          }
          break;
        case 3:
          if (this.paymentBankShow) {
            if (!this.paymentData.bank) {
              this.validationMessage = "Banco";
            }
            if (this.paymentAccountShow) {
              if (this.paymentData.branch == "") {
                this.validationMessage +=
                  (this.validationMessage !== "" ? ", " : "") + "Agência";
              }
              if (this.paymentData.account == "") {
                this.validationMessage +=
                  (this.validationMessage !== "" ? ", " : "") + "Conta";
              }
            }
            if (this.validationMessage !== "") {
              this.validationMessage = "Informe " + this.validationMessage;
              this.activeStep = 2;
            }
          }

          this.confirmData = this.validationMessage == "";
          break;
      }

      if (this.validationMessage !== "") {
        this.confirm = false;
        this.confirmData = false;
        this.process = false;
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: this.validationMessage,
          type: "is-warning",
          hasIcon: true,
        });
      }
    },
    async selectOption(opt, details) {
      switch (opt) {
        
        case "type":

          await this.setPaymentType({
            integra: details.integra,
            type: details.typeInt
          })

          this.paymentData.integration = details.integra.slug
          this.paymentData.type = details.typeInt.slug
          
          if (this.paymentData.integration == "paymee") {
            if (this.paymentData.type == "transferencia") {
              this.paymentBankShow = true;
              this.paymentAccountShow = true;
              this.banks = [
                "001",
                "033",
                "077",
                "104",
                "212",
                "218",
                "237",
                "341",
              ];
            } else if (this.paymentData.type == "deposito") {
              this.paymentBankShow = true;
              this.paymentAccountShow = false;
              this.banks = ["033", "341"];
            }
          } else if (
            this.paymentData.integration == "mercadopago" ||
            this.paymentData.integration == "picpay"
          ) {
            this.paymentBankShow = false;
            this.paymentAccountShow = false;
          }

          if (this.product == "Planos") {
            this.activeStep = 2;
          } else {
            this.activeStep = 1;
          }
          break;

        case "product":
          this.paymentData.details = JSON.parse(JSON.stringify(details));

          if (this.product == "Ligas") {
            this.paymentProduct = this.paymentData.details.name;
            this.paymentValue = this.paymentData.details.value;
            this.paymentBonus = this.paymentData.details.valor_bonus;
          } else if (this.product == "Planos") {
            this.paymentProduct = this.paymentData.details.descricao;
            if (this.paymentData.details.cupom.valor) {
              this.paymentValue = this.paymentData.details.cupom.valor;
            } else {
              this.paymentValue = this.paymentData.details.valor;
            }
          }

          if (this.paymentValue == 0) {
            this.paymentBankShow = false;
            this.paymentAccountShow = false;
          }

          if (this.product == "Planos") {
            this.activeStep = this.paymentValue == 0 ? 2 : 1;
          } else {
            if (this.paymentBankShow) {
              this.activeStep = 2;
            } else {
              this.confirm = true;
              this.confirmData = false;
              this.process = false;
            }
          }
          break;

        case "bank":
          this.paymentData.bank = JSON.parse(JSON.stringify(details));
          break;
      }
    },
    async paymentReturn() {
      if (this.product == "Planos" && this.paymentValue == 0) {
        this.activeStep = 0;
      } else {
        this.activeStep--;
      }
    },
    async paymentConfirm() {
      let step = 0;
      this.validationMessage = "";

      if (this.paymentData.type == "" && this.paymentValue > 0) {
        step = this.product == "Planos" ? 1 : 0;
        this.validationMessage = "Selecione uma Forma de Pagamento";
      }
      if (!this.paymentData.details) {
        step = this.product == "Planos" ? 0 : 1;
        this.validationMessage = "Selecione um Produto";
      }
      if (this.paymentBankShow) {
        if (!this.paymentData.bank) {
          this.validationMessage = "Banco";
        }
        if (this.paymentAccountShow) {
          if (this.paymentData.branch == "") {
            this.validationMessage +=
              (this.validationMessage !== "" ? ", " : "") + "Agência";
          }
          if (this.paymentData.account == "") {
            this.validationMessage +=
              (this.validationMessage !== "" ? ", " : "") + "Conta";
          }
        }
        if (this.validationMessage !== "") {
          step = 2;
          this.validationMessage = "Informe " + this.validationMessage;
        }
      }

      let valid = this.validationMessage == "";

      if (!valid) {
        this.process = false;
        this.confirm = true;
        this.activeStep = step;
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: this.validationMessage,
          type: "is-warning",
          hasIcon: true,
        });
      } else {
        this.process = true;
        this.confirm = false;
        this.confirmData = true;
        this.activeStep = this.paymentBankShow ? 3 : 2;
      }

      return valid;
    },
    async paymentProcess() {
      let loader = this.$loading.show();
      let referenceId = new Date().getTime();
      this.paymentData.reference = referenceId;

      this.$http
        .post(url + "/payment/insert", {
          data: this.paymentData,
        })
        .catch((err) => {
          loader.hide();
          console.log(err);
        })
        .then(async (response) => {
          this.back = false;
          this.config = false;
          this.confirm = false;
          this.process = false;

          if (response.ok) {
            if (response.body.data) {
              let responseData = response.body.data;

              if (responseData) {
                this.resultMessage = responseData;

                if (this.resultMessage.url) {
                  window.location.href = this.resultMessage.url;
                } else if (this.resultMessage.success) {
                  if (this.paymentData.type == "carteira") {
                    this.startTime = "";
                    this.endTime = "";
                  } else {
                    this.startTime = new Date();
                    this.endTime = new Date();

                    this.endTime.setHours(this.startTime.getHours() + 24);

                    this.startTime = this.startTime.toString();
                    this.endTime = this.endTime.toString();
                  }
                }
              } else {
                this.resultMessage["error"] =
                  "Ocorreu um problema ao processar o pagamento. Favor informar ao suporte a seguinte mensagem: responseData não retornado.";
              }
            } else {
              console.error("ERROR_PAYMENT_REGISTER");
              this.resultMessage["error"] =
                "Ocorreu um problema ao processar o pagamento. Favor informar ao suporte a seguinte mensagem: ERROR_PAYMENT_REGISTER";
            }
          } else {
            console.error("ERROR_PAYMENT");
            this.resultMessage["error"] =
              "Ocorreu um problema ao processar o pagamento. Favor informar ao suporte a seguinte mensagem: ERROR_PAYMENT";
          }

          this.result = this.resultMessage.error
            ? "error"
            : this.resultMessage.url
            ? "url"
            : "success";
          loader.hide();
        });
    },
    async close() {
      // $parent.close()
      if (this.product == "Ligas") {
        if (this.result == "") {
          this.$parent.close();
        } else {
          window.location.reload();
        }
      } else if (this.product == "Planos") {
        if (this.result == "" || this.result == "error") {
          // this.$router.push({ path: "/renewSignature/" + this.userId });
          window.location.reload();
        } else if (
          this.paymentData.type == "carteira" ||
          this.paymentValue == 0
        ) {
          this.$router.push({ path: "/login" });
        } else {
          this.$router.push({ path: "/signature/" + this.userId });
        }
      }
    },
    async copyQrCode() {
      this.export_file = this.resultMessage.qrcode;

      let testingCodeToCopy = document.querySelector("#export_csv_file");
      testingCodeToCopy.value = this.export_file;
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$toasted.show("Código Copiado!", {
            position: "top-center",
            duration: 2000,
            type: "success",
            className: "toasted-class",
          });
        } else {
          this.$toasted.show("Não foi possível copiar, tente novamente!", {
            position: "top-center",
            duration: 2000,
            type: "error",
            className: "toasted-class",
          });
        }
      } catch (err) {
        this.$toasted.show("Não foi possível copiar, tente novamente!", {
          position: "top-center",
          duration: 2000,
          type: "error",
          className: "toasted-class",
        });
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped>
#Payment .no-margin {
  margin: 0 !important;
}

#Payment .no-padding {
  padding: 0 !important;
}
.modal-box-title {
  padding: 0.5em;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
.modal-box-title .icon {
  margin-right: 0.75em;
}

.integration {
  padding: 1.5em;
  text-align: left;
}
.integration-content {
  padding: 0.5em;
}
.integration-logo {
  text-align: right;
  border-right: 1px solid #00f0f8;
}
.integration-logo img {
  width: 150px;
}

.confirm-title {
  font-size: 1.2em;
  font-weight: bold;
}
.confirm-data {
  font-size: 1.5em;
  font-weight: bolder;
}
.btn-copy-qrcode {
  /* position: absolute; */
  position: relative;
  transform: translate(27%, -1080%);
}
.intLogo {
  max-height: 150px;
}

.integration-card {
  border-radius: 7px;
  background-color: #272727;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
  margin: 5px;
}
.integration-card:hover {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  border: 1px solid rgba(0,255,204,1);
  filter: unset !important;
}
.integration-selected {
  -webkit-box-shadow: 0px 0px 20px -2px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 20px -2px rgba(0,255,204,0.75);
  box-shadow: 0px 0px 20px -2px rgba(0,255,204,0.75);
  border: 3px solid rgba(0,255,204,1);
}
.integration-not-selected {
  filter: blur(1.0px);
}

</style>
