<template>
  <b-dropdown class="ft-dropdown" ref="ftInfosMobile" mobile-modal :expanded="$mq=='sm'">
    <div class="lg-ft-content" slot="trigger">
      <b-button
        type="is-info"
        size="is-small"
        icon-pack="fas"
        icon-left="info"
        style="width: 100%;"
        can-close
        outlined
        expanded
      >Informações e Regras
      </b-button>
    </div>

    <div class="ft-container">
      <div class="ft-close" @click="handlerClose()">
        <b-icon icon="times-circle" pack="fas"></b-icon>
      </div>

      <div class="ft-content centralize">

        <div class="columns is-multiline is-mobile is-centered game-modal-info ">

          <div class="column is-narrow">
            <h5>Rodada</h5>
            <p>{{ sala.rodada_inicio + ((sala.rodada_fim !== sala.rodada_inicio) ? ' - ' + sala.rodada_fim : '') }}</p>
          </div>

          <div class="column is-narrow">
            <h5>Valor por Time</h5>
            <p>
              {{
                sala.valor > 0
                  ? `R$ ${sala.valor.toFixed(2).replace(".", ",")}`
                  : "Gratuito"
              }}
            </p>
          </div>

          <div class="column is-narrow">
            <h5>Meu Saldo</h5>
            <Wallet dorpPosition="bottom-left" tipPosition="bottom" :hideIconMobile="false" :hideIcon="false"></Wallet>
          </div>

        </div>

        <div class="columns is-multiline is-mobile">

          <div class="column is-full has-text-centered">
            <p class="minigame-label-2 has-text-primary">JOGUE A CAMISA<br/>E MONTE SUA PANELA!</p>
            <!-- <p class="minigame-label-3">Escolha 
              <span class="has-text-primary">{{ sala.game.qtd_atletas }} </span>
              <span v-if="sala.game.qtd_atletas > 1">craques </span>
              <span v-else>craque </span>
              <span>pra jogar </span>
              <span v-if="(sala.game.posicoes) ? sala.game.posicoes.split(',').length > 1 : false">nas posições: </span>
              <span v-else>na posição: </span>
            </p>
            <p class="minigame-label-3">
              <span class="has-text-primary">{{ sala.game.posicoes.replace(/,/g, ", ").toUpperCase() }}</span>
            </p>
            <p class="minigame-label-3">Você poderá escolher no máximo UM jogador por posição de um mesmo clube.</p> -->
          </div>

          <!-- <div class="column is-full has-text-centered">
            <p class="minigame-label-2 has-text-primary">SCOUTS ANALISADOS</p>
            <p class="minigame-label-3">
              Desafio: <span class="has-text-primary" v-if="sala.game.scouts">{{ sala.game.scouts }}</span><span class="has-text-primary" v-else>-</span>
              Desempate: <span class="has-text-primary" v-if="sala.game.scouts_desempate">{{ sala.game.scouts_desempate }}</span><span class="has-text-primary" v-else>-</span>
            </p>
          </div> -->

          <div class="column is-full has-text-centered">
            <p class="minigame-label-3">Escolha 
              <span class="has-text-primary">{{ infoGame("qtd_atletas") }} </span>
              <span>atleta(s) entre </span>
              <span class="has-text-primary">{{ infoGame("posicoes") }}</span>
            </p>
            <p class="minigame-label-3">
              O time que tiver a 
              <span class="has-text-primary">{{infoGame("rank")}} {{infoGame("apuracao")}}</span>
                de 
              <span class="has-text-primary">{{infoGame("scouts")}}</span>
              , ganha a disputa!
            </p>
            <p class="minigame-label-3">
              <span class="has-text-danger">O desempate será a {{infoGame("rank_desempate")}} {{infoGame("apuracao_desempate")}} de {{infoGame("scouts_desempate")}}</span>
            </p>
            <p class="minigame-label-3">Você poderá escolher no máximo UM jogador por posição de um mesmo clube.</p>
          </div>
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Wallet from "@/apps/Ligas/components/Wallet";

export default {
  name: "InfosMobile",
  components: {
    Wallet,
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    sala: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapGetters(['posicoes','scouts']),
    gamePositions() {
      return this.posicoes.filter(el => (this.game.posicoes) ? this.game.posicoes.split(',').indexOf(el.sigla) !== -1 : false)
    },
    posicoesScouts() {
      
      let scouts = this.scouts.map(s => s.cd_scout)
      scouts.sort()

      // let scouts = []
      // // let filPosition = this.posicoes.filter(
      // //   p => this.form.posicoes.indexOf(p.sigla) !== -1
      // // );
      
      // this.posicoes.map(p => {
      //   p.scouts.map(s => {
      //     let pScouts = s.scouts.split(',')
      //     for (const scout of pScouts) {
      //       if (scouts.indexOf(scout) == -1){
      //         scouts.push(scout)
      //       }
      //     }
      //   }) 
      // });

      // scouts.sort()

      return scouts
    },
  },
  methods: {
    infoGame(campo){

      let info = ""

      switch(campo){
        case "qtd_atletas":
          info = this.sala.game.qtd_atletas
        break;
        case "posicoes":
          // info = this.sala.game.posicoes.toUpperCase().replace(/,/g, ", ")
          info = (this.posicoes.length-1) == this.sala.game.posicoes.split(',').length ? 'TODAS AS POSIÇÕES' : this.gamePositions.map(p => p.nome).join(', ');
          info = info.replace(/,/g, ", ");
        break;
        case "rank":
          info = this.sala.game.rank.name.toUpperCase()
        break;
        case "rank_desempate":
          info = this.sala.game.rank_desempate.name.toUpperCase()
        break;
        case "scouts":
          info = this.posicoesScouts.length == this.sala.game.scouts.split(',').length ? 'TODOS OS SCOUTS' : this.sala.game.scouts.toUpperCase().replace(/,([^,]*)$/, ' e $1')
          info = info.replace(/,/g, ", ")
        break;
        case "scouts_desempate":
          info = this.posicoesScouts.length == this.sala.game.scouts_desempate.split(',').length ? 'TODOS OS SCOUTS' : this.sala.game.scouts_desempate.toUpperCase().replace(/,([^,]*)$/, ' e $1')
          info = info.replace(/,/g, ", ")
        break;
        case "apuracao":
          info = this.sala.game.id_apuracao == 1 ? ' pontuação nos scouts ' : ' soma '
        break;
        case "apuracao_desempate":
          info = this.sala.game.id_apuracao_desempate == 1 ? ' pontuação nos scouts ' : ' soma '
        break;
      }

      return info
    },
    handlerClose() {
      this.$refs.ftInfosMobile.toggle();
    },
  }
};
</script>
