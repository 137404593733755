import Vue from "vue";

export default {
  getFiltersFields() {
    const query = `${url}/ligas/filtros`;
    return Vue.http.get(query);
  },

  getInfluencersWithLeagues(queryParams) {
    const query = `${url}/v3/ligas/influenciadores?${queryParams}`;
    return Vue.http.get(query);
  },

  getCategories() {
    const query = `${url}/v3/ligas/categorias`;
    return Vue.http.get(query);
  },

  getCategoryBySlug(slug) {
    const query = `${url}/v3/ligas/categorias/${slug}`;
    return Vue.http.get(query);
  },

  getLeaguesByCategory(queryParams) {
    const query = `${url}/v3/ligas?${queryParams}`;
    return Vue.http.get(query);
  },

  getLeaguesByUser(userId, queryParams) {
    const query = `${url}/v3/ligas/usuarios/${userId}?${queryParams}`;
    return Vue.http.get(query);
  },

  getInfluencersBySlug(slug) {
    const query = `${url}/influenciadores/slug/${slug}`;
    return Vue.http.get(query);
  },

  getUserLeaguesRegistrations(userId, params) {
    const query = `${url}/v2/ligas/my/${userId}`;
    return Vue.http.post(query, params);
  },

  getUserGamesRegistrations(userId, params) {
    const query = `${url}/games/my/${userId}`;
    return Vue.http.post(query, params);
  },
};
