<template>
  <div
    class="sala-container"
    :destaque="isDestaque"
    :reserva="isReserved"
    :subscribed="possuiTimesInscritos"
    :canceled="sala.ativo === 5"
    :game="isGame"
    :cart="salaInCart"
  >
    <div class="sl-row" :style="{ marginBottom: '0.5em' }" :game="isGame">
      <div class="sl-item">
        <div v-if="!isGame" class="sl-inf">
          <img
            class="sl-foto-influencer"
            :src="sala.influencer.url_foto"
            :alt="sala.influencer.nome_midia"
          />
          <span class="sl-dp-title">{{ sala.influencer.nome_midia }}</span>
        </div>
        <div v-else class="sl-inf" :game="isGame">
          <img class="sl-img-game" src="@/assets/joystick.svg" alt="MNIGAME" />
          <span class="sl-dp-title">Minigame</span>
        </div>
      </div>
      <div class="sl-item">
        <Report :sala="sala" :game="isGame"></Report>
        <Share
          :id="`sala-${sala.id}`"
          :url="getShare.url"
          :title="getShare.title"
          description="Venha participar desta Liga no Escala10!"
          :quote="getShare.quote"
          :hashtags="getShare.hashtags"
          :game="isGame"
        ></Share>
        <div
          v-if="salaInCart"
          class="cart-icon small"
          @click="openCart() && expandCart()"
          style="margin-left: 0.5em;"
        >
          <b-icon
            type="is-primary"
            size="is-small"
            pack="fa"
            icon="shopping-cart"
          ></b-icon>
        </div>
      </div>
    </div>
    <div class="sl-row">
      <div class="sl-item">
        <span class="sl-dp-info">{{ salaLiga.tipo }}</span>
      </div>
      <div v-if="isGame" class="sl-item">
        <span class="sl-dp-info">
          {{ gamePosicoes }}
        </span>
      </div>
      <div v-else class="sl-item">
        <span class="sl-dp-capitao" :class="{ active: sala.capitao }">
          <b-icon
            :icon="sala.capitao ? 'copyright' : 'creative-commons-pd'"
            :pack="sala.capitao ? 'far' : 'fab'"
            :style="{ fontSize: $mq === 'sm' ? '10px' : '13px' }"
          ></b-icon>
          {{ sala.capitao ? "COM" : "SEM" }} capitão</span
        >
      </div>
    </div>
    <div class="sl-row">
      <div class="sl-item">
        <span class="sl-dp-info">{{ salaLiga.apuracao }}</span>
      </div>
      <div v-if="isGame" class="sl-item">
        <span class="sl-dp-info">
          {{ gameScouts }}
        </span>
      </div>
      <div v-else class="sl-item">
        <span class="sl-dp-info">{{ handlerRodada() }}</span>
      </div>
    </div>

    <div class="sl-content" :game="isGame">
      <div class="columns is-mobile is-multiline is-variable is-2">
        <div class="column is-full" :style="{ paddingBottom: 0 }">
          <!-- <div v-if="isGame" class="sl-game">
            GAME
          </div> -->
          <div v-if="sala.titulo" class="sl-ct-title">
            <b-tooltip label="Titulo" position="is-top" type="is-pigeon">
              <span v-if="isGame" class="sl-dp-title-2" title game>
                {{ sala.titulo }}
              </span>
              <span v-else class="sl-dp-title-2" title>
                {{ sala.titulo }}
              </span>
            </b-tooltip>
          </div>
          <div v-else class="sl-ct-title">
            <b-tooltip
              v-if="
                sala.premio_previsto &&
                  sala.inscricao &&
                  sala.times_inscritos_previsto > sala.times_inscritos
              "
              :label="
                `Premiação (baseado em ${sala.times_inscritos_previsto} times)`
              "
              position="is-top"
              type="is-pigeon"
            >
              <div class="sl-ct-align-title">
                <span class="sl-dp-title-2"
                  >R$
                  {{
                    sala.premio_previsto
                      ? sala.premio_previsto.toLocaleString()
                      : 0
                  }}
                </span>
                <span class="sl-dp-title-3">
                  Baseado em {{ sala.times_inscritos_previsto }} times
                </span>
              </div>
            </b-tooltip>
            <b-tooltip
              v-else
              label="Premiação"
              position="is-top"
              type="is-pigeon"
            >
              <span class="sl-dp-title-2"
                >R$ {{ sala.premio ? sala.premio.toLocaleString() : 0 }}
              </span>
            </b-tooltip>
          </div>
        </div>

        <div class="column is-full" :style="{ paddingBottom: 0 }">
          <div class="columns is-mobile is-gapless is-2">
            <div
              v-if="revisarTime"
              class="column is-narrow"
              :style="{ 'margin-right': '0.5em' }"
            >
              <b-button
                class="tag sl-bt-inscricao"
                type="is-danger"
                size="is-small"
                expanded
                analytics
                @click="escalarGame()"
                :style="{
                  height: '100%',
                  'line-height': '0.9rem',
                  padding: '0 5px !important',
                }"
              >
                Revisar<br />Time
              </b-button>
            </div>
            <div class="column">
              <!-- :style="{ 'margin-left': '0.5em', 'padding-top': '0.5em' }" -->
              <b-button
                class="tag sl-bt-inscricao"
                :type="
                  isReserved
                    ? 'is-warning'
                    : salaInCart
                    ? 'is-pigeon'
                    : 'is-primary'
                "
                :size="isMyGame && $mq == 'sm' ? 'is-small' : 'is-medium'"
                v-if="sala.inscricao || isReserved"
                expanded
                analytics
                @click="handlerRegistration()"
                :style="
                  revisarTime && $mq == 'sm'
                    ? 'height: 100%; line-height: 0.9rem; padding: 0 5px !important;'
                    : ''
                "
                :disabled="salaInCart"
              >
                <!-- <template v-if="salaInCart">
                  Adicionado ao Carrinho!
                </template>
                <template v-else>
                  <b-icon
                    v-if="!isGame"
                    pack="fas"
                    icon="cart-plus"
                    size="is-small"
                  ></b-icon>
                  {{ labelBtnInsc }}
                  {{ isReserved ? (isGame ? 'Escalar' : 'Substituir Reserva') : 'Inscrição' }}
                  <br v-if="isGame && isMyGame && $mq=='sm'"/>
                  <span v-if="!isReserved">{{ sala.valor == 0 ? "Gratuita" : "R$ " + sala.valor }}</span>
                  <span v-if="isReserved">({{ isReserved.reservas }} time{{ (isReserved.reservas > 1) ? 's' : '' }})</span>
                </template> -->

                <template>
                  <b-icon
                    v-if="!isGame"
                    pack="fas"
                    icon="cart-plus"
                    size="is-small"
                  ></b-icon>
                  <span v-html="labelInscricao"></span>
                </template>
              </b-button>
              <b-tag
                class="sl-tag-title"
                type="is-yellowdark"
                :size="'is-' + (revisarTime ? 'small' : 'medium')"
                analytics
                v-if="inscricaoEncerrada"
                :style="{
                  'font-size':
                    revisarTime && $mq == 'sm'
                      ? '0.8rem !important'
                      : revisarTime
                      ? '0.9rem !important'
                      : '',
                  height: revisarTime ? '100% !important' : '',
                  'line-height':
                    revisarTime && $mq == 'sm' ? '0.9rem !important' : '',
                  padding: revisarTime && $mq == 'sm' ? '5px !important' : '',
                }"
                >Inscrições <br v-if="revisarTime && $mq == 'sm'" />
                Encerradas</b-tag
              >
              <b-tag
                class="sl-tag-title"
                type="is-magentadark"
                size="is-medium"
                analytics
                v-if="sala.ativo === 5"
                >{{ modLiga }} Cancelada</b-tag
              >
              <b-tag
                class="sl-tag-title"
                type="is-pigeon"
                size="is-medium"
                style="color: #25273c"
                analytics
                v-else-if="salaLiga.status === 'em_andamento'"
                >{{ modLiga }} Em Andamento</b-tag
              >
              <b-tag
                class="sl-tag-title"
                type="is-magentadark"
                size="is-medium"
                analytics
                v-else-if="salaLiga.status == 'encerrada'"
                >{{ modLiga }} Finalizada</b-tag
              >
            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="columns is-mobile is-gapless is-2">
            <div
              class="column is-narrow"
              :style="{ padding: '0.5em 0.5rem 0 0.5rem' }"
            >
              <b-button
                @click="handlerInfo()"
                size="is-small"
                type="is-bluelighten"
                class="tag"
                expanded
                analytics
              >
                <b-icon
                  v-if="$mq === 'sm'"
                  pack="fas"
                  icon="info"
                  size="is-small"
                ></b-icon>
                <span v-if="$mq !== 'sm'" :style="{ fontWeight: 'bold' }"
                  >Informações</span
                >
              </b-button>
            </div>

            <div
              class="column"
              :style="{ 'margin-left': '0.5em', 'padding-top': '0.5em' }"
            >
              <b-button
                size="is-small"
                type="is-purplelighten2"
                class="tag sl-bt-times"
                expanded
                analytics
                @click="handlerInscritos()"
              >
                <span v-if="salaLiga.status == 'em_andamento'"
                  ><b>Parciais</b></span
                >
                <span v-else
                  ><b
                    >{{ sala.times_inscritos ? sala.times_inscritos : 0 }}
                    Time(s)
                    {{
                      sala.max_qtd_time > 0 ? " / " + sala.max_qtd_time : ""
                    }}</b
                  ></span
                >
              </b-button>
            </div>
          </div>
        </div>

        <div
          class="column is-full has-text-centered"
          :style="{ padding: '0.25em 0.15em 0.5em 0.15em' }"
        >
          <span
            v-if="isAdmin"
            class="sl-dp-rg"
            :style="{ marginRight: '0.5em' }"
            >{{ modLiga }} {{ idLiga }} /</span
          >
          <span class="sl-dp-rg">ID {{ sala.id }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { mapActions, mapGetters } from "vuex";

import Registration from "./Salas/Enroll";
import ListaTimes from "./Salas/ListaTimes";
import Info from "./Salas/Info";
import Report from "./Salas/Report";
import Share from "./Salas/Share";
import ModalEscalarTime from "./Salas/Games/EscalarTime";
import ModuloLogin from "@/apps/Auth/pages/Module";

export default {
  name: "SalaCard",
  components: {
    Registration,
    ListaTimes,
    Report,
    Share,
    ModuloLogin,
    ModalEscalarTime,
  },
  props: {
    sala: {
      type: Object,
      required: true,
    },
    reservedSalas: {
      type: Array,
      required: true,
    },
    isInfluencerPage: {
      type: Boolean,
      default: false,
    },
    enrolled: {
      type: Boolean,
      default: false,
    },
    isUserLeagues: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isEnrolled: false,
    };
  },
  computed: {
    ...mapGetters([
      "isPublic",
      "isAdmin",
      "influencer",
      "cart",
      "posicoes",
      "scouts",
    ]),
    isDestaque() {
      return this.isInfluencerPage
        ? this.sala.destaque_influencer
        : this.sala.destaque;
    },
    posicoesScouts() {
      let scouts = this.scouts.map((s) => s.cd_scout);
      scouts.sort();

      return scouts;
    },
    salaLiga() {
      return this.sala.liga ? this.sala.liga : this.sala.game;
    },
    gameScouts() {
      return this.sala.game.scouts.split(",").length ==
        this.posicoesScouts.length
        ? "Todos os Scouts"
        : "Todos os Scouts"; // this.sala.game.scouts.replace(/,/g, ", ");
    },
    gamePosicoes() {
      return this.sala.game.posicoes.split(",").length ==
        this.posicoes.length - 1
        ? "Todas as Posições"
        : "Todas as Posições"; // this.sala.game.posicoes.replace(/,/g, ", ");
    },
    modLiga() {
      return this.sala.liga ? "LIGA" : "MINIGAME";
    },
    idLiga() {
      return this.sala.liga ? this.sala.liga.id_liga : this.sala.game.id_game;
    },
    isGame() {
      return this.sala.game;
    },
    isReserved() {
      if (this.isGame) {
        return this.reservedSalas.find(
          (sala) =>
            sala.id_game &&
            sala.id_game == this.idLiga &&
            sala.id_sala == this.sala.id &&
            sala.reservas > 0
        );
      } else {
        return this.reservedSalas.find(
          (sala) =>
            sala.id_liga &&
            sala.id_liga == this.idLiga &&
            sala.id_sala == this.sala.id &&
            sala.reservas > 0
        );
      }
    },
    isMyGame() {
      if (this.isGame) {
        return this.reservedSalas.find(
          (sala) =>
            sala.id_game &&
            sala.id_game == this.idLiga &&
            sala.id_sala == this.sala.id &&
            sala.inscricoes > 0
        );
      }
    },
    getShare() {
      const shareParams = {
        url: this.mountUrlForShare(),
        title: `ESCALA 10 - Liga ${this.sala.influencer.nome_midia} - ${this.salaLiga.tipo}`,
        quotes: `ESCALA 10 - Liga ${this.sala.influencer.nome_midia} - ${this.salaLiga.tipo}`,
        hashtags: `escala10,ligas,liga,${this.salaLiga.tipo.replace(" ", "")},${
          this.sala.influencer.nome_midia
        }`,
      };

      return shareParams;
    },
    urlSite() {
      return window.location.origin;
    },
    salaInCart() {
      return this.cart.findIndex((sala) => sala.id == this.sala.id) !== -1;
    },
    labelInscricao() {
      let labelBtnInsc = "";

      if (this.salaInCart) {
        labelBtnInsc += "Adicionado ao Carrinho!";
      } else {
        let convidados = this.sala.valor == 0 && this.sala.senha !== null;
        labelBtnInsc += this.isReserved
          ? this.isGame
            ? "Escalar"
            : "Substituir Reserva"
          : convidados
          ? "Para"
          : "Inscrição";
        labelBtnInsc +=
          this.isGame && this.isMyGame && this.$mq == "sm" ? "<br/>" : "";
        labelBtnInsc += !this.isReserved
          ? this.sala.valor == 0
            ? convidados
              ? " Convidados! "
              : " Gratuita! "
            : " R$ " + this.sala.valor
          : "";
        labelBtnInsc += this.isReserved
          ? this.isReserved.reservas +
            " time" +
            (this.isReserved.reservas > 1 ? "s" : "")
          : "";
      }
      return labelBtnInsc;
    },
    revisarTime() {
      return this.isMyGame && (this.sala.inscricao || this.inscricaoEncerrada);
    },
    inscricaoEncerrada() {
      return (
        !this.isReserved &&
        this.salaLiga.status === "aberta" &&
        (this.sala.max_qtd_time && this.sala.max_qtd_time > 0
          ? this.sala.max_qtd_time <= this.sala.times_inscritos
          : false) &&
        this.salaLiga.status != "encerrada"
      );
    },
    possuiTimesInscritos() {
      if (this.isUserLeagues) return true;
      return (
        this.sala.usuario?.times_inscritos ||
        this.sala.usuario?.times_reservados
      );
    },
  },
  async created() {
    if (this.isGame) {
      await this.getPosicoes();
      await this.getScouts();
    }

    this.handleEnrolled();

    if ("inscricao" in this.$route.query && "salas" in this.$route.query) {
      const sala = this.$route.query.salas.includes(this.sala.id.toString());
      if (sala) {
        this.handlerRegistration();

        let query = Object.assign({}, this.$route.query);
        delete query.inscricao;
        delete query.salas;
        this.$router.replace({ query });
      }
    }
  },
  methods: {
    ...mapActions([
      "getPosicoes",
      "getScouts",
      "setSala",
      "addSalaInCart",
      "removeSalaFromCart",
      "openCart",
      "expandCart",
    ]),
    handleEnrolled() {
      const enrolled = this.reservedSalas.filter(
        (s) => s.id_sala == this.sala.id && s.incricoes > 0
      );
      this.isEnrolled = enrolled.length > 0 ? true : false;
    },
    mountUrlForShare() {
      const params = {
        ligas: this.sala.liga.id_liga,
      };
      const url = `${this.urlSite}/ligas/${this.sala.influencer.slug}`;
      const encodeParams = qs.stringify(params);
      return `${url}?${encodeParams}`;
    },
    handlerRodada() {
      if (this.sala.rodada_inicio === this.sala.rodada_fim)
        return `RODADA ${this.sala.rodada_inicio}`;
      else return `RODADAS ${this.sala.rodada_inicio}-${this.sala.rodada_fim}`;
    },
    async handlerRegistration() {
      // se estiver publico, ira abrir o login,
      // caso contrario ira abrir o modal de inscricao

      if (!this.isPublic) {
        await this.setSala({ ...this.sala });

        if (this.sala.senha !== null) {
          // abrindo modal para validacao de senha
          this.$buefy.dialog.prompt({
            title: "Sala Privada",
            message: "Informe a senha para realizar a inscrição",
            type: "is-warning",
            hasIcon: true,
            inputAttrs: {
              type: "text",
              placeholder: "Senha",
            },
            trapFocus: true,
            confirmText: "Ok",
            cancelText: "Cancelar",
            onConfirm: (value) => {
              if (value !== this.sala.senha) {
                this.$toasted.error("Senha inválida", {
                  duration: 3000,
                  position: "top-center",
                });
              } else {
                if (!this.isGame) {
                  this.addSalaInCart(this.sala);
                } else {
                  this.openModalEnroll();
                }
              }
            },
          });
        } else {
          if (!this.isGame) {
            this.addSalaInCart(this.sala);
          } else {
            this.openModalEnroll();
          }
        }
      } else {
        const isMobile = this.$mq === "sm";

        let props = {};
        if (this.isInfluencerPage) {
          props = {
            displayDefaultLogo: false,
            urlLogo: this.influencer.url_logo,
            hasLogo: true,
            logoHref: this.$route.path,
          };
        }

        this.$buefy.modal.open({
          parent: this,
          component: ModuloLogin,
          hasModalCard: false,
          fullScreen: isMobile,
          canCancel: false,
          props: {
            fluid: isMobile,
            hasClose: true,
            autoSignature: true,
            ...props,
          },
          events: {
            login: () => {
              this.$emit("login");
              this.handlerRegistration();
            },
            register: () => {
              this.$emit("register");
              this.handlerRegistration();
            },
            close: () => {
              // this.$parent.close();
            },
          },
        });
      }
    },
    openModalEnroll() {
      this.$buefy.modal.open({
        parent: this,
        component: Registration,
        hasModalCard: true,
        trapFocus: true,
        canCancel: [false, false, false],
        fullScreen: this.$mq === "sm",
        props: {
          sala: this.sala,
        },
        events: {
          done: () => {
            this.$emit("refresh");
          },
        },
      });
    },
    escalarGame() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalEscalarTime,
        hasModalCard: true,
        trapFocus: true,
        fullScreen: true,
        canCancel: false,
        props: {
          sala: this.sala,
          action: "update",
        },
      });
    },
    handlerInfo() {
      this.$buefy.modal.open({
        parent: this,
        component: Info,
        hasModalCard: true,
        trapFocus: true,
        canCancel: [false, false, false],
        fullScreen: this.$mq === "sm",
        props: {
          sala: this.sala,
        },
      });
    },
    handlerInscritos() {
      this.$buefy.modal.open({
        parent: this,
        component: ListaTimes,
        hasModalCard: true,
        trapFocus: true,
        canCancel: [false, false, false],
        fullScreen: this.$mq === "sm",
        props: {
          sala: this.sala,
        },
      });
    },
  },
};
</script>
