import LigasService from "@/apps/Ligas/services/api";

export default {
  data() {
    return {
      influencerPage: {},
    };
  },
  computed: {
    isInfluencerPage() {
      return !!this.$route.params.influencer;
    },
    layoutLeagueProperties() {
      if (!this.isInfluencerPage) return {};
      if (!this.influencerPage.configuracao) return {};

      const { configuracao, ...influencer } = this.influencerPage;
      return {
        influencer,
        isInfluencerPage: this.isInfluencerPage,
        ...configuracao,
      };
    },
  },
  methods: {
    loadInfluencerData() {
      const influencerSlug = this.$route.params.influencer;
      return LigasService.getInfluencersBySlug(influencerSlug);
    },
    handleErrorInInfluencersPage(status) {
      if (status === "error") {
        this.$router.push("/404");
      }
    },
    setFilterForInfluencerPage() {
      this.filters = {
        ...this.filters,
        influencer: this.influencerPage.id,
        influencerPage: 1,
      };
    },
    handleWhenIsInfluencerPage() {
      this.loadInfluencerData().then((response) => {
        this.handleErrorInInfluencersPage(response.data.status);
        this.setInfluencerPage(response.data.data);
        this.setFilterForInfluencerPage();
      });
    },
    setInfluencerPage(influencer) {
      this.influencerPage = { ...influencer };
    },
  },
};
