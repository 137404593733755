<template>
  <BasePublicLayout
    :background-color="backgroundColor"
    :has-banner="!!hasBanner"
    :background-image="backgroundImage"
    :is-influencer-page="isInfluencerPage"
    @click="$emit('click')"
  >
    <template slot="header">
      <NavBar
        ref="navbar"
        :social-medias-list="mediaList"
        :routerCaixa="routeToCaixa"
        :is-influencer-page="isInfluencerPage"
        :is-loading="isLoading"
        @signout="signout()"
      >
        <template slot="brand">
          <div
            v-if="!isInfluencerPage && !isLoading"
            class="template-brand-ajust"
            @click="handlerRoute(`/ligas`)"
          >
            <img
              src="../../../assets/imagens/logotipo_green_10.png"
              alt="Escala 10"
              :style="$mq == 'sm' ? 'width: 75px;' : 'width: 170px'"
            />
          </div>
          <div v-else-if="influencer">
            <div
              class="template-brand-ajust"
              @click="handlerRoute(`/ligas/${this.$route.params.influencer}`)"
            >
              <img :src="influencer.url_logo" class="ajust-image" />
            </div>
          </div>
        </template>
      </NavBar>
    </template>

    <slot />
  </BasePublicLayout>
</template>

<script>
import NavBar from "@/apps/Ligas/components/Navbar";
import SocialMediasMixin from "@/shared/SocialMedia";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LayoutLeagues",
  mixins: [SocialMediasMixin],
  props: {
    backgroundColor: {
      type: String,
      default: "",
    },
    backgroundImage: {
      type: String,
      default: "",
    },
    hasBanner: {
      type: [Number, String],
      default: 0,
    },
    isInfluencerPage: {
      type: Boolean,
      default: false,
    },
    influencer: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NavBar,
  },
  data() {
    return {
      hasBannerPromotion: true,
      bannerLink: "",
      bannerType: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    mediaList() {
      if (this.isInfluencerPage) {
        return this.influencer.contatos;
      } else {
        return this.socialMediasList;
      }
    },
    routeToCaixa() {
      if (this.isInfluencerPage) {
        return `/ligas/${this.$route.params.influencer}/caixa`;
      } else {
        return "/ligas/caixa";
      }
    },
  },
  created() {
    this.handlePromotionUserPlan();
  },
  watch: {
    userInfo() {
      this.handlePromotionUserPlan();
    },
  },
  methods: {
    ...mapActions(["setPublic"]),
    async signout() {
      await this.setPublic(true);

      if (this.isInfluencerPage) {
        if (this.$route.path !== `/ligas/${this.$route.params.influencer}`)
          this.$router.push(`/ligas/${this.$route.params.influencer}`);
      } else {
        this.$router.push("/ligas");
      }
    },
    handlerRoute(route) {
      if (this.$route.path !== route) this.$router.push(route);
    },
    handleBannerClick() {
      // medida momentanea para black friday 2021
      const id = this.bannerType === "promotional" ? 99 : 100;
      const influencerId = this.bannerType === "promotional" ? 132 : 10;

      this.setActions({
        id_notification: id,
        description: "Abriu o Link",
        area: "link",
        status_action: "complete",
      });

      const routeData = this.$router.resolve(`/black-friday/${influencerId}`);
      window.open(routeData.href, "_blank");
    },
    handlePromotionUserPlan() {
      const availablePlansFree = ["21"];
      const unavailablePlansRenew = ["12", "22"];

      if (!this.userInfo.id) {
        this.bannerType = "promotional";
        this.bannerLink = "/images/banner_pardal_escala10.gif";
      } else if (!unavailablePlansRenew.includes(this.userInfo.plano)) {
        if (availablePlansFree.includes(this.userInfo.plano)) {
          this.bannerType = "promotional";
          this.bannerLink = "/images/banner_pardal_escala10.gif";
        } else {
          this.bannerType = "escala10";
          this.bannerLink = "/images/banner_escala10.gif";
        }
      } else {
        this.hasBannerPromotion = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.template-brand-ajust {
  cursor: pointer;

  .ajust-image {
    height: 40px !important;
  }
}

.app-banner-promotion {
  width: 100%;
  border-radius: 7px;
  background-color: white;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-size: cover;
  background-position: center;

  @media (min-width: 769px) {
    min-height: 40px;
    img {
      height: 50px;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 1em;

    img {
      width: 100%;
    }
  }
}
.app-banner-dar-background {
  background-color: rgb(7, 19, 9) !important;
}
</style>
