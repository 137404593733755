<template>
  <div id="shield">
    <!-- 'ajust-width-campo': !perspectiveview -->
    <div
      v-if="render"
      class="campo-squad"
      :class="{ centralize: perspectiveview }"
    >
      <!-- <transition name="fade">
        <div v-if="perspectiveview">
          <div class="cube">
            <div class="topFace">
              <div id="campinho">
                <div class="zone">
                  <div
                    v-for="(kpi, i) of kpis"
                    :key="'kpi-' + i"
                    :class="`position-${kpi.pos} zone-spc ${!hasBackground ? 'no-bg-pos': ''}`"
                    @click="showDetails(kpi)"
                    :style="{ 'z-index': kpi.pos == 2 || kpi.pos == 20 ? 3 : 2, 'cursor': 'pointer' }"
                  >
                    <div :class="`kpi kpi-3d k-${getSize(kpi.value)} effect-kpi`">
                      <span :class="{ 'kpi-span-rt': perspectiveview }">{{ kpi.value }}</span>
                    </div>
                  </div>

                  <div class="position-1 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-2 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-3 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-4 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-5 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-6 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-7 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-8 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-9 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-10 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-11 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-12 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-13 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-14 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-15 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-16 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-17 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-18 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-19 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-20 zone-spc z-1" v-if="overlap"></div>
                </div>
              </div>
            </div>
            <div class="leftFace"></div>
            <div class="rightFace"></div>
          </div>
          
        </div>
      </transition> -->

      <transition name="fade">
        <div :class="{ cube: perspectiveview }" class="centralize">
          <div :class="`${!perspectiveview ? 'topFace-2' : 'topFace'}`">
            <div
              id="campinho"
              :class="{
                'rt-tp2': rotationView,
                'shadow-hor': rotationView,
                'shadow-ver': !rotationView,
              }"
            >
              <div class="zone">
                <!-- Sombras no campo p/ sobreposição de cores (delimitar os campos) -->
                <template v-if="kpisPosition == 'area'">
                  <div
                    v-for="(kpi, i) of kpis"
                    :key="'kpi-' + i"
                    :class="
                      `position-${kpi.pos} zone-spc ${
                        !hasBackground ? 'no-bg-pos' : ''
                      }`
                    "
                    @click="showDetails(kpi)"
                    :style="{
                      'z-index': kpi.pos == 2 || kpi.pos == 20 ? 3 : 2,
                      cursor: 'pointer',
                    }"
                  >
                    <div
                      v-if="!kpi.confronto"
                      :class="
                        `kpi ${
                          perspectiveview ? 'kpi-3d' : 'kpi-2d'
                        } k-${getSize(kpi.value)} effect-kpi`
                      "
                    >
                      <span
                        :class="{
                          'kpi-span-rt': perspectiveview,
                          'kpi-span-rt-2': !perspectiveview && rotationView,
                          'kpi-span-rt-3': !perspectiveview && !rotationView,
                        }"
                        >{{ kpi.value }}</span
                      >
                    </div>
                    <div v-else class="kpi kpi-2d k-sm effect-kpi-conf">
                      <div
                        class="has-background-primary"
                        style="margin-right: -13px; margin-top: -5px;"
                        :class="{
                          'kpi-span-rt': perspectiveview,
                          'kpi-span-rt-2': !perspectiveview && rotationView,
                          'kpi-span-rt-3': !perspectiveview && !rotationView,
                        }"
                      >
                        {{
                          kpi.data.filter((e) => e.clube.sigla == kpi.clubes[1])
                            .length
                        }}
                      </div>
                      <div
                        class="has-background-purple"
                        style="margin-right: 15px; margin-top: -10px;"
                        :class="{
                          'kpi-span-rt': perspectiveview,
                          'kpi-span-rt-2': !perspectiveview && rotationView,
                          'kpi-span-rt-3': !perspectiveview && !rotationView,
                        }"
                      >
                        {{
                          kpi.data.filter((e) => e.clube.sigla == kpi.clubes[0])
                            .length
                        }}
                      </div>
                    </div>
                    <!-- <RadialBars v-else class="has-background-light centralize graph-radial"></RadialBars> -->
                  </div>

                  <div class="position-1 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-2 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-3 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-4 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-5 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-6 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-7 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-8 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-9 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-10 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-11 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-12 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-13 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-14 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-15 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-16 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-17 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-18 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-19 zone-spc z-1" v-if="overlap"></div>
                  <div class="position-20 zone-spc z-1" v-if="overlap"></div>
                </template>
                <template v-if="kpisPosition == 'posicao'">
                  <div
                    v-for="(kpi, i) of kpis"
                    :key="'kpi-' + i"
                    :class="
                      `position-${kpi.pos} zone-spc ${
                        !hasBackground ? 'no-bg-pos' : ''
                      }`
                    "
                    @click="showDetails(kpi)"
                    :style="{ cursor: 'pointer' }"
                  >
                    <div class="banco-de-reserva" v-if="kpi.pos == 'reserva'">
                      RESERVAS
                    </div>
                    <div class="columns is-mobile is-gapless is-centered" :class="{'is-multiline': kpi.pos == 'reserva'}">
                      <div
                        v-for="(atleta, i) of kpi.atletas"
                        :key="'atleta-' + i"
                        class="column is-narrow"
                        :style="{
                          margin: kpi.pos == 'reserva' ? '-10px 2px' : '0 2px',
                          transform: kpi.pos == 'reserva' ?
                            `translate(0%,-${(i*20)}%)` : 
                            'translate(0%,' +
                                (i < (kpi.atletas.length - 1) / 2
                                  ? ((kpi.atletas.length - 1) / 2 - i) * -20
                                  : (i - (kpi.atletas.length - 1) / 2) * -20) +
                                '%)',
                        }"
                      >
                        <b-tooltip
                          v-if="atleta.id"
                          :label="atleta.apelido"
                          type="is-black"
                          size="is-small"
                          position="is-top"
                          animated
                        >
                          <div
                            @click="delAtleta(kpi.pos, atleta)"
                            style="padding: 5px 0px;"
                            :class="kpi.pos == 'reserva' ? 'banco-reserva' : ''"
                          >
                            <div class="foto-atleta">
                              <img
                                v-if="
                                  atleta.foto &&
                                    atleta.foto.indexOf('emptystate') == -1
                                "
                                :src="atleta.foto"
                                :alt="atleta.apelido"
                                style="cursor: pointer;"
                              />
                              <b-icon
                                v-else
                                icon="user"
                                pack="fas"
                                size="is-medium"
                                type="is-grey"
                              ></b-icon>
                            </div>
                            <div
                              v-if="atleta.clube_escudo"
                              class="escudo-clube"
                            >
                              <img
                                :src="atleta.clube_escudo"
                                :alt="atleta.clube"
                              />
                            </div>
                            <b-button
                              class="button"
                              type="is-danger"
                              icon-pack="fas"
                              icon-right="times"
                              size="is-small"
                              rounded
                              @click="delAtleta(kpi.pos, atleta)"
                              style="opacity: 0.8;"
                            />
                          </div>
                        </b-tooltip>
                        <template v-else>
                          <div
                            @click="addAtleta(kpi.pos)"
                            style="padding: 5px 0px;"
                            :class="kpi.pos == 'reserva' ? 'banco-reserva' : ''"
                          >
                            <div class="foto-atleta" style="opacity: 0.5;">
                              <b-icon
                                icon="user"
                                pack="fas"
                                type="is-grey"
                                size="is-large"
                              ></b-icon>
                            </div>
                            <div class="escudo-clube" style="opacity: 0.5;">
                              <b-icon
                                icon="shield-alt"
                                pack="fas"
                                type="is-grey"
                                size="is-small"
                              ></b-icon>
                            </div>
                            <b-button
                              class="button"
                              type="is-primary"
                              icon-pack="fas"
                              icon-right="plus"
                              size="is-small"
                              rounded
                              @click="addAtleta(kpi.pos)"
                              style="opacity: 0.8;"
                            />
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>

                  <div class="position-tec zone-spc z-1" v-if="overlap"></div>
                  <div class="position-gol zone-spc z-1" v-if="overlap"></div>
                  <div
                    class="position-zag-le-ld zone-spc z-1"
                    v-if="overlap"
                  ></div>
                  <div
                    class="position-mei-vol zone-spc z-1"
                    v-if="overlap"
                  ></div>
                  <div class="position-ata zone-spc z-1" v-if="overlap"></div>
                </template>
              </div>
              <!-- <h2>Parte de cima do cubo</h2>
              <p>A parte de cima está dentro de uma tag div extra para poder da à rotação correta do retângulo distorcida.</p>.
              <p>Esse lado do cubo também é dimensionado, de modo que o tamanho da fonte foi reduzido para acomodar.</p>. -->
            </div>
          </div>
          <div v-if="perspectiveview" class="leftFace"></div>
          <div v-if="perspectiveview" class="rightFace"></div>
        </div>
      </transition>

      <slot name="field-legend"></slot>

      <div
        class="centralize-row"
        style="margin-top: 0.5em;"
        :class="{ 'align-slide-per': perspectiveview }"
      >
        <!-- <div class="centralize" >
          <small style="margin-bottom: 0.5em">Perspectiva</small>
          <div class="field">
            <b>2D</b>
            <b-switch style="margin-left: 0.5em;"
              v-model="perspectiveview"
              type="is-lowgrey"
              :true-value="true"
              :false-value="false"></b-switch>
            <b>3D</b>
          </div>
        </div> -->
        <div class="centralize" v-if="rotation == '' && !perspectiveview">
          <small style="margin-bottom: 0.5em">Rotação</small>
          <div class="field">
            <b>Vertical</b>
            <b-switch
              style="margin-left: 0.5em;"
              v-model="rotationView"
              type="is-lowgrey"
              :true-value="true"
              :false-value="false"
            ></b-switch>
            <b>Horizontal</b>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { roundToNearestMinutesWithOptions } from 'date-fns/fp';
// import RadialBars from '@/components/helpers/ApexCharts/RadialBars'

export default {
  name: "Campo",
  props: {
    flat: {
      type: Boolean,
      default: true,
    },
    rotation: {
      type: String,
      default: "",
    },
    perspectiveview: {
      type: Boolean,
      default: false,
    },
    overlap: {
      type: Boolean,
      default: true,
    },
    hasBackground: {
      type: Boolean,
      default: true,
    },
    kpis: {
      type: Array,
      default: () => [],
    },
    kpisPosition: {
      type: String,
      default: "area",
    },
  },
  data() {
    return {
      render: false,
      rotationView: true,
      // kpis: [
      //   { pos: 1, value: 20 },
      //   { pos: 2, value: 1 },
      //   { pos: 19, value: 3 },
      //   { pos: 4, value: 3 },
      //   { pos: 9, value: 9 },
      //   { pos: 7, value: 6 }
      // ]
    };
  },
  watch: {
    perspectiveview: function(n, o) {
      localStorage.config_e10_perspective = n;
    },
    rotationView: function(n, o) {
      localStorage.config_e10_rotation = n;
    },
  },
  created() {
    // if (localStorage.config_e10_perspective || localStorage.config_e10_rotation) {
    //   this.perspectiveview = localStorage.config_e10_perspective
    //   this.rotationView = localStorage.config_e10_rotation
    // } else {
    //   localStorage.config_e10_perspective = this.perspectiveview
    //   localStorage.config_e10_rotation = this.rotationView
    // }
    this.rotationView = this.rotation == "" || this.rotation == "horizontal";
    this.render = true;
  },
  methods: {
    getKpiSVG(value) {
      if (value > 10 && value < 20) return "../../../assets/svg/kpi-md.svg";
      else if (value > 20) return "../../../assets/svg/kpi-big.svg";
      else return "../../../assets/svg/kpi-sm.svg";
    },
    getPositionInClass(kpi) {
      let a = {};
      a["position-" + kpi.pos] = true;

      if (kpi.value >= 10 && kpi.value < 20) a["kpi-md"] = true;
      else if (kpi.value >= 20) a["kpi-xl"] = true;
      else a["kpi-sm"] = true;

      return a;
    },
    getSize(val) {
      if (val >= 10 && val < 20) return "md";
      else if (val >= 20) return "xl";
      else return "sm";
    },
    showDetails(kpi) {
      this.$emit("details", JSON.parse(JSON.stringify(kpi)));
    },
    delAtleta(posicao, atleta) {
      this.$emit("del", {
        posicao: posicao,
        atleta: JSON.parse(JSON.stringify(atleta)),
      });
    },
    addAtleta(posicao) {
      this.$emit("add", posicao);
    },
  },
};
</script>

<style scoped>
.graph-radial {
  /* border-radius: 50%; 
  overflow: hidden; 
  width: 120px; 
  height: 120px;  */
  /* padding-top: 5px; */
  -webkit-transform: scale(0.2, 0.2);
  -moz-transform: scale(0.2, 0.2);
  -o-transform: scale(0.2, 0.2);
  -ms-transform: scale(0.2, 0.2);
  transform: scale(0.2, 0.2);
}
.zone {
  margin: 12px auto auto auto;
  width: 93%;
  position: relative;
  height: calc(100% - 24px);
}
.zone-spc {
  position: absolute;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.no-bg-pos {
  background-color: transparent !important;
}
.lg-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  float: left;
  margin-top: 0.2em;
}
.lg-small {
  font-size: 0.8em;
  margin: 0.5em 0.5em 0 0.5em;
}
.def {
  background-color: #2196f3;
}
.atk {
  background-color: #ff5252;
}
.shadows {
  -webkit-box-shadow: 0px 13px 34px -15px rgba(0, 0, 0, 0.664);
  -moz-box-shadow: 0px 13px 34px -15 rgba(0, 0, 0, 0.664);
  box-shadow: 0px 13px 34px -15px rgba(0, 0, 0, 0.664);
}
.align-slide-per {
  top: 45%;
  position: relative;
}

.flat-pos {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.kpi-3d {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.kpi-2d {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .kpi:hover {
  cursor: pointer;
  transition: 0.8s ease-in-out;
  transform: translate(-80%, -80%);
  -webkit-box-shadow: 10px 30px 31px -8px rgba(0, 0, 0, 0.91);
  -moz-box-shadow: 10px 30px 31px -8px rgba(0, 0, 0, 0.91);
  box-shadow: 10px 30px 31px -8px rgba(0, 0, 0, 0.91);
} */
.kpi-3d:hover {
  cursor: pointer;
  transition: 0.8s ease-in-out;
  transform: translate(-80%, -80%);
  -webkit-box-shadow: 10px 30px 31px -8px rgba(0, 0, 0, 0.91);
  -moz-box-shadow: 10px 30px 31px -8px rgba(0, 0, 0, 0.91);
  box-shadow: 10px 30px 31px -8px rgba(0, 0, 0, 0.91);
}
.kpi-2d:hover {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transform: translate(-60%, -50%);
}
.kpi-span-rt {
  /* transform: rotate3d(1, 2, -1, -50deg); */
  transform: rotate(-50deg);
}
.kpi-span-rt-2 {
  /* transform: rotate3d(1, 2, -1, -50deg); */
  transform: rotate(-90deg);
}
.kpi-span-rt-3 {
  /* transform: rotate3d(1, 2, -1, -50deg); */
  transform: rotate(0deg);
}
.k-sm {
  height: 30px;
  width: 30px;
}
.k-md {
  height: 40px;
  width: 40px;
}
.k-xl {
  height: 50px;
  width: 50px;
}

.cube {
  position: absolute;
  left: 15%;
  top: 300px;
}

.leftFace {
  padding: 10px;
  width: 337.1px;
  height: 30px;
}
.rightFace {
  padding: 10px;
  width: 435px;
  height: 30px;
}
.topFace #campinho {
  width: 280px;
  height: 370px;
}
.topFace-2 #campinho {
  width: 280px;
  height: 370px;
  /* width: 388px;
  height: 500px; */
  /* width: 450px;
  height: 580px; */
}

.rightFace,
.leftFace,
.topFace,
.top-face-2 {
  position: absolute;
}

.leftFace {
  -webkit-transform: skew(0deg, 30deg);
  -moz-transform: skew(0deg, 30deg);
  -o-transform: skew(0deg, 30deg);
  -ms-transform: skew(0deg, 30deg);
  transform: skew(0deg, 30deg);
  background-color: #11741b;
  left: 0.752px;
  top: -1px;
  -webkit-box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
  box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
}

.rightFace {
  -webkit-transform: skew(0deg, -30deg);
  -moz-transform: skew(0deg, -30deg);
  -o-transform: skew(0deg, -30deg);
  -ms-transform: skew(0deg, -30deg);
  transform: skew(0deg, -30deg);
  background-color: #11971c;
  left: 338px;
  top: -29px;
  -webkit-box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
  box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
}

.topFace #campinho {
  -webkit-transform: skew(0deg, -30deg) scale(1, 1.16);
  -moz-transform: skew(0deg, -30deg) scale(1, 1.16);
  -o-transform: skew(0deg, -30deg) scale(1, 1.16);
  -ms-transform: skew(0deg, -30deg) scale(1, 1.16);
  transform: skew(0deg, -30deg) scale(1, 1.16);
  /* background-color: red; */
  border: 3px solid #11971c;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../assets/campinho-2.png");
  font-size: 0.862em;
}
@media screen and (max-width:768px) {
  .topFace-2 #campinho {
    margin: 1em !important;
  }
}
.topFace-2 #campinho {
  transition: all 0.3s ease;
  /* margin-left: 10%; */
  margin: 2.2em 1.5em 1.5em 1.5em;
  -webkit-transform: scale(1.16, 1.16);
  -moz-transform: scale(1.16, 1.16);
  -o-transform: scale(1.16, 1.16);
  -ms-transform: scale(1.16, 1.16);
  transform: scale(1.16, 1.16);
  border: 3px solid #11971c;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../assets/campinho-2.png");
  border-radius: 4px;
}

.shadow-hor {
  -webkit-box-shadow: 20px 0px 30px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 20px 0px 30px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 20px 0px 30px -10px rgba(0, 0, 0, 0.4);
}
.shadow-ver {
  -webkit-box-shadow: 0px 20px 30px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 20px 30px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 20px 30px -10px rgba(0, 0, 0, 0.4);
}

.topFace {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
  top: -344px;
  left: 218px;
}
.top-face-2 {
}
.rt-tp2 {
  -webkit-transform: rotate(90deg) scale(1.16, 1.16) !important;
  -moz-transform: rotate(90deg) scale(1.16, 1.16) !important;
  -o-transform: rotate(90deg) scale(1.16, 1.16) !important;
  -ms-transform: rotate(90deg) scale(1.16, 1.16) !important;
  transform: rotate(90deg) scale(1.16, 1.16) !important;
  /* margin-left: 20% !important; */
}
.campo-squad {
  height: auto;
  padding-bottom: 1em;
  width: 100%;
  position: relative;
}
.ajust-width-campo {
  width: 50%;
}

.positioning {
  position: relative;
  width: 100%;
  max-width: 848.7px;
  min-width: 848.7px;
  height: 600px;
  top: 20px;
  display: inline-block;
}
.pos-kpi {
  width: 80px;
  height: 110px;
  position: absolute;
  z-index: 2;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.effect-kpi {
  color: #333;
  font-weight: bold;
  background: rgba(0, 247, 185, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(0, 247, 185, 1) 0%,
    rgba(0, 240, 248, 1) 48%,
    rgba(0, 131, 245, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba(0, 247, 185, 1)),
    color-stop(48%, rgba(0, 240, 248, 1)),
    color-stop(100%, rgba(0, 131, 245, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(0, 247, 185, 1) 0%,
    rgba(0, 240, 248, 1) 48%,
    rgba(0, 131, 245, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(0, 247, 185, 1) 0%,
    rgba(0, 240, 248, 1) 48%,
    rgba(0, 131, 245, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(0, 247, 185, 1) 0%,
    rgba(0, 240, 248, 1) 48%,
    rgba(0, 131, 245, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(0, 247, 185, 1) 0%,
    rgba(0, 240, 248, 1) 48%,
    rgba(0, 131, 245, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f7b9', endColorstr='#0083f5', GradientType=1 );
  -webkit-box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
  box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
}
.effect-kpi-conf {
  color: #333;
  font-weight: bold;
  /* -webkit-box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1);
  box-shadow: 10px 13px 21px -8px rgba(0, 0, 0, 1); */
  /* background-color: cornsilk; */
  /* padding: 0.25em; */
  border: 1px solid white;
  position: relative;
}
.effect-kpi-conf div {
  width: 2em;
  height: 2.2em;
  padding: 0.2em;
  font-size: 0.95em;
  text-align: center;
  border-radius: 50%;
  background: crimson;
}
.position-1 {
  width: 27%;
  height: 67px;
  background-color: rgba(211, 47, 47, 0.5);
}
.position-2 {
  width: 46%;
  left: 27%;
  top: 36px;
  height: 32px;
  background-color: rgba(229, 56, 53, 0.61);
}
.position-19 {
  width: 46%;
  left: 27%;
  height: 36px;
  background-color: rgba(198, 40, 40, 0.514);
}
.position-3 {
  width: 27%;
  right: 0;
  height: 67px;
  background-color: rgba(211, 47, 47, 0.5);
}
.position-4 {
  width: 27%;
  top: 67px;
  left: 0;
  height: 135px;
  background-color: rgba(239, 83, 80, 0.5);
}
.position-5 {
  width: 23%;
  left: 27%;
  top: 67px;
  height: 67.5px;
  background-color: rgba(239, 154, 154, 0.719);
}
.position-6 {
  width: 23%;
  left: 27%;
  height: 67.5px;
  top: 134.5px;
  background-color: rgba(239, 154, 154, 0.575);
}
.position-15 {
  width: 23%;
  right: 27%;
  top: 67px;
  height: 67.5px;
  background-color: rgba(229, 115, 115, 0.705);
}
.position-16 {
  width: 23%;
  right: 27%;
  height: 67.5px;
  top: 134.5px;
  background-color: rgba(229, 115, 115, 0.555);
}
.position-7 {
  width: 27%;
  top: 67px;
  right: 0;
  height: 135px;
  background-color: rgba(239, 83, 80, 0.5);
}
.position-8 {
  width: 27%;
  bottom: 67px;
  left: 0;
  height: 135px;
  background-color: rgba(21, 101, 192, 0.521);
}
.position-9 {
  width: 23%;
  left: 27%;
  bottom: 67px;
  height: 67.5px;
  background-color: rgba(144, 202, 249, 0.767);
}
.position-10 {
  width: 23%;
  left: 27%;
  height: 67.5px;
  bottom: 134.5px;
  background-color: rgba(144, 202, 249, 0.514);
}
.position-17 {
  width: 23%;
  right: 27%;
  bottom: 67px;
  height: 67.5px;
  background-color: rgba(100, 180, 246, 0.719);
}
.position-18 {
  width: 23%;
  right: 27%;
  height: 67.5px;
  bottom: 134.5px;
  background-color: rgba(100, 180, 246, 0.5);
}
.position-11 {
  width: 27%;
  bottom: 67px;
  right: 0;
  height: 135px;
  background-color: rgba(21, 101, 192, 0.521);
}
.position-12 {
  width: 27%;
  bottom: 0;
  height: 67px;
  background-color: rgba(25, 118, 210, 0.514);
}
.position-13 {
  width: 46%;
  left: 27%;
  bottom: 36px;
  height: 32px;
  background-color: rgba(66, 164, 245, 0.616);
}
.position-20 {
  width: 46%;
  left: 27%;
  bottom: 0;
  height: 36px;
  background-color: rgba(30, 136, 229, 0.596);
}
.position-14 {
  width: 27%;
  right: 0;
  bottom: 0;
  height: 67px;
  background-color: rgba(25, 118, 210, 0.514);
}

.position-reserva {
  top: 0px;
  left: -40%;
  width: 30%;
  height: 340px;
  text-align: center;
  border: 3px solid #11971c;
  background-color: #2fad1d;
  border-radius: 4px;
}
.position-tec {
  width: fit-content;
  bottom: -20px;
  left: -20px;
  text-align: center;
}
.position-gol {
  bottom: -20px;
  width: -webkit-fill-available;
  text-align: center;
  transform: scale(0.8, 0.8);
  z-index: 4;
}
.position-zag-le-ld {
  bottom: 50px;
  width: -webkit-fill-available;
  text-align: center;
  transform: scale(0.8, 0.8);
  z-index: 3;
}
.position-mei-vol {
  bottom: 130px;
  width: -webkit-fill-available;
  text-align: center;
  transform: scale(0.8, 0.8);
  z-index: 2;
}
.position-ata {
  bottom: 220px;
  width: -webkit-fill-available;
  text-align: center;
  transform: scale(0.8, 0.8);
  z-index: 1;
}

.banco-de-reserva {
  font-size: 0.8em;
  font-weight: bolder;
}

#shield .button {
  height: 20px;
  width: 20px;
  margin: 0px;
  padding: 0px;
  right: 0px;
  top: 0px;
  transform: translate(90%, -250%);
}
/* 
#shield .escudo-clube {
  height: 23px;
  width: 23px;
  left: 31px;
  top: 15px;
  transform: translate(-10%, 100%);
}
#shield .foto-atleta {
  height: 45px !important;
  width: 45px !important;
  top: 10px;
}
#shield .escudo-clube img {
  width: -webkit-fill-available;
  left: 52%;
  padding: 3px;
  transform: translate(-50%, -205%);
}
#shield .foto-atleta img {
  width: 125%;
  top: 50%;
  left: 50%;
  padding: 3px;
  transform: translate(-50%,-40%);
}
 */

#shield .foto-atleta img,
#shield .foto-atleta span {
  transform: translate(-50%, -38%);
}
#shield .escudo-clube span {
  width: -webkit-fill-available;
  left: 52%;
  padding: 3px;
  transform: translate(0%, -325%);
}
#shield .banco-reserva {
  transform: scale(0.8, 0.8);
}
</style>
