import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions([
      "getUser",
      "getLigasTimesCartola",
      "getGamesTimes",
      "getWallet",
      "setPublic",
    ]),
    async getUserData() {
      const token = this.$cookies.get("_nekot");
      const userId = localStorage.userId;

      if (token && userId) {
        await this.getUser(userId);
        this.getLigasTimesCartola(userId);
        this.getGamesTimes({ userId });
        this.getWallet(userId);
        await this.setPublic(false);
      } else {
        await this.setPublic(true);
      }
    },
  },
};
