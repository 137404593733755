var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"module-meusdados","fluid":_vm.fluid}},[_c('div',{staticClass:"container-login"},[_c('div',{staticClass:"columns is-gapless"},[_c('div',{staticClass:"column is-full"},[_c('WizardLayout',{attrs:{"title":"Meus Dados","has-close":"","display-default-logo":_vm.displayDefaultLogo,"has-logo":_vm.hasLogo,"url-logo":_vm.urlLogo,"logo-href":_vm.logoHref,"width-logo":_vm.widthLogo},on:{"close":function($event){return _vm.$emit('close')}}},[_c('ValidationObserver',{staticClass:"form-auth",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"columns is-multiline is-variable is-2"},[_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"text","name":"cad-nome","rules":{
                    required: true,
                  },"label":"Nome"},model:{value:(_vm.dados.nome),callback:function ($$v) {_vm.$set(_vm.dados, "nome", $$v)},expression:"dados.nome"}})],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"text","name":"cad-nome","rules":{
                    required: true,
                  },"label":"Sobrenome"},model:{value:(_vm.dados.sobrenome),callback:function ($$v) {_vm.$set(_vm.dados, "sobrenome", $$v)},expression:"dados.sobrenome"}})],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"text","name":"cad-nome","rules":{
                    required: true,
                    cpf: true,
                  }},model:{value:(_vm.dados.cpf),callback:function ($$v) {_vm.$set(_vm.dados, "cpf", $$v)},expression:"dados.cpf"}},[_c('template',{slot:"label"},[_c('p',[_vm._v("CPF")]),_c('span',{staticStyle:{"font-size":"0.7rem"}},[_vm._v("(necessário para transações bancárias em Ligas)")])])],2)],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"text","name":"cad-telefone","icon":"whatsapp"},model:{value:(_vm.dados.telefone),callback:function ($$v) {_vm.$set(_vm.dados, "telefone", $$v)},expression:"dados.telefone"}},[_c('template',{slot:"label"},[_c('p',[_vm._v("Telefone")]),_c('span',{staticStyle:{"font-size":"0.7rem"}},[_vm._v("(DDD + Número)")])])],2)],1),_c('div',{staticClass:"column is-one-third"},[_c('Input',{attrs:{"type":"text","name":"cad-cidade","rules":{
                    required: true,
                  },"label":"Cidade"},model:{value:(_vm.dados.endereco.cidade),callback:function ($$v) {_vm.$set(_vm.dados.endereco, "cidade", $$v)},expression:"dados.endereco.cidade"}})],1),_c('div',{staticClass:"column is-one-third"},[_c('Select',{attrs:{"name":"cad-cidade","rules":"required","label":"Estado"},model:{value:(_vm.dados.endereco.uf),callback:function ($$v) {_vm.$set(_vm.dados.endereco, "uf", $$v)},expression:"dados.endereco.uf"}},_vm._l((_vm.states),function(estado,i){return _c('option',{key:i,domProps:{"value":estado}},[_vm._v(" "+_vm._s(estado)+" ")])}),0)],1),_c('div',{staticClass:"column is-one-third"},[_c('Input',{attrs:{"type":"text","name":"cad-pais","rules":{
                    required: true,
                  },"label":"País"},model:{value:(_vm.dados.endereco.pais),callback:function ($$v) {_vm.$set(_vm.dados.endereco, "pais", $$v)},expression:"dados.endereco.pais"}})],1),_c('div',{staticClass:"column is-full"},[_c('Input',{attrs:{"type":"text","name":"cad-email","rules":{
                    required: true,
                    email: true,
                  },"label":"Email"},model:{value:(_vm.dados.email),callback:function ($$v) {_vm.$set(_vm.dados, "email", $$v)},expression:"dados.email"}})],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"password","name":"cad-password","label":"Senha","password-reveal":""},model:{value:(_vm.dados.pass),callback:function ($$v) {_vm.$set(_vm.dados, "pass", $$v)},expression:"dados.pass"}})],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"password","name":"confirm-password","label":"Confirmar Senha","password-reveal":""},model:{value:(_vm.confirmarPass),callback:function ($$v) {_vm.confirmarPass=$$v},expression:"confirmarPass"}})],1)]),_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('b-button',{attrs:{"type":"is-secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancelar")])],1),_c('div',{staticClass:"level-right"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return handleSubmit(_vm.update)}}},[_vm._v(" Confirmar ")])],1)])]}}])})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }