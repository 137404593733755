<template>
  <div id="Auth">
    <WizardLayout
      title="Autenticação"
      subtitle="Use suas credenciais para entrar no site"
      :has-close="hasClose"
      :display-default-logo="displayDefaultLogo"
      :has-logo="hasLogo"
      :url-logo="urlLogo"
      :logo-href="logoHref"
      :width-logo="widthLogo"
      @close="$emit('close')"
    >
      <ValidationObserver
        v-slot="{ handleSubmit }"
        v-if="!showHelp"
        tag="form"
        class="form-auth"
      >
        <Input
          v-model="auth.email"
          type="text"
          name="auth-email"
          rules="required|email"
          label="Email"
          @onkeyupenter="handleSubmit(authentication)"
        />
        <Input
          v-model="auth.pwd"
          type="password"
          name="auth-password"
          rules="required"
          label="Senha"
          password-reveal
          @onkeyupenter="handleSubmit(authentication)"
        />
        <b-button
          style="margin-top: 0.5em"
          size="is-small"
          type="is-text"
          icon-pack="far"
          icon-right="question-circle"
          @click="openForgotModal()"
          >Esqueceu a Senha?</b-button
        >

        <div class="form-auth-group">
          <div class="form-auth-align">
            <div class="form-auth-item">
              <b-button
                type="is-secondary"
                :size="$mq == 'sm' ? 'is-small' : ''"
                @click="$emit('showRegister')"
                >Criar Nova Conta</b-button
              >
            </div>
            <div class="form-auth-item">
              <b-button
                name="bt-submit"
                type="is-primary"
                :size="$mq == 'sm' ? 'is-small' : ''"
                style="width: 150px; font-weight: 600"
                @keyup.native.enter="handleSubmit(authentication)"
                @click="handleSubmit(authentication)"
                >Entrar</b-button
              >
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div v-if="showHelp" class="box-help-auth">
        <p>Você possui um cadastro no <b>Escala 10</b>?</p>
        <b-button
          type="is-secondary"
          name="bt-help-no"
          :size="$mq == 'sm' ? 'is-small' : ''"
          @click="$emit('showRegister')"
          style="margin-right: 15px"
          >Não, quero me cadastrar</b-button
        >
        <b-button
          type="is-primary"
          name="bt-help-yes"
          :size="$mq == 'sm' ? 'is-small' : ''"
          @click="showHelp = false"
          >Sim, possuo cadastro</b-button
        >
      </div>
      <div class="has-text-right" style="clear: both">
        <p class="subtitle">
          Em caso de dúvidas, favor entrar em contato com o suporte
        </p>
        <b-button
          @click="handlerAjuda()"
          icon-right="whatsapp"
          icon-pack="fab"
          type="is-secondary"
          :size="$mq == 'sm' ? 'is-small' : ''"
          >Pedir Ajuda
        </b-button>
      </div>
    </WizardLayout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import WizardLayout from "./WizardLayout";
import WhatsMixin from "@/_mixins/whatsapp";
import UsuarioService from "../services/usuarioService";

export default {
  name: "Auth",
  mixins: [WhatsMixin, UsuarioService],
  components: {
    ValidationObserver,
    WizardLayout,
  },
  props: {
    action: {
      type: String,
      default: "",
    },
    cupom: {
      type: String,
      default: "",
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    displayDefaultLogo: {
      type: Boolean,
      default: true,
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
    urlLogo: {
      type: String,
      default: "",
    },
    logoHref: {
      type: String,
      default: "",
    },
    widthLogo: {
      type: Number,
      default: 140,
    },
    isInfluencerPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      auth: {
        email: "",
        pwd: "",
      },
      cdCupom: "",
      idInfluencer: "",
      showHelp: false,
    };
  },
  computed: {
    ...mapGetters(["loggedIn", "userInfo", "userId", "influencer"]),
  },
  created() {
    if (this.isInfluencerPage && Object.keys(this.influencer).length > 0) this.showHelp = true;
    this.cdCupom = this.$route.query.cupom ? this.$route.query.cupom : this.cupom;
    this.idInfluencer = this.$route.query.influencer ? 
      this.$route.query.influencer : 
      (this.influencer && this.influencer.id ? this.influencer.id : "");
  },
  methods: {
    ...mapActions(["authLogin"]),
    async authentication() {
      let loader = this.$loading.show();

      try {
        const response = await this.login(this.auth);

        if (response.login) {
          if (this.action.indexOf("promotion") !== -1) {
            this.$router.push({
              path: "/renewSignature/" + this.userId + "?action=" + this.action,
            });
          } else if (this.action.indexOf("renovacao") !== -1) {
            this.$router.push({
              path:
                "/renewSignature/" +
                this.userId +
                "?action=" +
                this.action +
                "&influencer=" +
                this.idInfluencer +
                "&cupom=" +
                this.cdCupom,
            });
          } else if (
            this.action.indexOf("influencer") !== -1 ||
            this.$route.path.includes("/painel")
          ) {
            this.validateFreePlan();
          } else {
            this.$emit("success");
          }
        }
      } catch (err) {
        this.$buefy.dialog.confirm({
          title: "Atenção!",
          message: `Não foi possível realizar seu login, tente novamente ou clique em "Esqueci a Senha" abaixo. Caso o problema persista favor entrar em contato com o suporte, ao clicar no botão abaixo`,
          type: "is-danger",
          hasIcon: true,
          confirmText: "Esqueci a Senha",
          cancelText: "Fechar",
          onConfirm: () => {
            this.openForgotModal();
          },
        });

        // resetando campos do login
        this.auth = {
          email: "",
          pwd: "",
        };
      }
      loader.hide();
    },
    openForgotModal() {
      this.$buefy.dialog.prompt({
        type: "is-primary",
        message: `Insira seu e-mail abaixo para enviar as instruções.`,
        title: "Instruções",
        confirmText: "Enviar",
        onConfirm: (value) => {
          this.$http
            .post(url + "/usuarios/forgotPass", { email: value })
            .then((res) => {
              if (res.body.status == "error") {
                this.$buefy.dialog.alert({
                  title: "Erro ao enviar e-mail",
                  message:
                    "Não foi possível enviar as instruções. Verifique seu e-mail e tente novamente!",
                  type: "is-danger",
                  hasIcon: true,
                  icon: "times-circle",
                  iconPack: "fa",
                });
              } else {
                this.$toasted.show("Email enviado!", {
                  position: "top-center",
                  duration: 3000,
                  type: "success",
                  className: "toasted-class",
                });
              }
            })
            .catch(() => {
              this.$buefy.dialog.alert({
                title: "Erro ao enviar e-mail",
                message:
                  "E-mail não encontrado. Verifique seu e-mail e tente novamente!",
                type: "is-danger",
                hasIcon: true,
                icon: "times-circle",
                iconPack: "fa",
              });
            });
        },
      });
    },
    validateFreePlan() {
      if (!this.userInfo.acesso || this.userInfo.limitAccess) {
        this.$buefy.dialog.confirm({
          title: "Atenção!",
          message: `<p>Identificamos que está cadastrado em um plano limitado!</p><p><b>Deseja realizar um upgrade para ter acesso ilimitado e aumentar suas chances de mitar?</b></p>`,
          confirmText: "Fazer Upgrade",
          cancelText: "Manter Plano",
          type: "is-info",
          hasIcon: true,
          onConfirm: async () => {
            this.$router.push({
              path:
                "/renewSignature/" +
                this.userId +
                "?action=" +
                this.action +
                "&influencer=" +
                this.idInfluencer +
                "&cupom=" +
                this.cdCupom,
            });
          },
          onCancel: async () => {
            this.$emit("success");
          },
        });
      } else {
        this.$emit("success");
      }
    },
    handlerAjuda() {
      this.sendMessageToZap({ text: "" });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-help-auth {
  margin: 1em 0 1.5em 0;

  p {
    margin-bottom: 1em;
  }
}
.form-auth {
  margin-bottom: 2em;
}
.form-auth-group {
  margin-top: 1.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.form-auth-align {
  /* float: right; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.form-auth-forgot-pass {
  margin: 0.25em;
}
.form-auth-item:first-child {
  margin-right: 0.5em;
}

@media (max-width: 768px) {
  .form-auth {
    padding: 0.25em !important;
  }
}
</style>
