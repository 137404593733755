<template>
  <div class="campo-squad">
    <transition name="fade">
      <div class="centralize">
        <div :class="`campo-2d centralize ${align == 'horizontal' ? 'em-back-2' : 'em-back'} ${align}`">
          <div style="opacity: 0.2" class="circle"></div>
        </div>
        <div class="centralize-row" :style="{'margin-top': align == 'vertical' ? '2em' : '1em'}">
          <div class="legenda-cp em-back" style="margin-right: 20px"></div>
          <div class="legenda-cp em-back" v-if="!perspective"></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'EmptyCampo',
  props: {
    align: {
      type: String,
      default: 'horizontal'
    },
    perspective: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.campo-squad {
  height: 550px;
  padding-bottom: 1em;
  width: 100%;
  position: relative;
}
.campo-2d {
  width: 337px;
  height: 434px;
  border-radius: 7px;
  position: relative;
  /* top: 50px; */
}
.vertical {
  left: 2em;
}
.horizontal {
  -webkit-transform: rotate(90deg) scale(1.16, 1.16);
  -moz-transform: rotate(90deg) scale(1.16, 1.16);
  -o-transform: rotate(90deg) scale(1.16, 1.16);
  -ms-transform: rotate(90deg) scale(1.16, 1.16);
  transform: rotate(90deg) scale(1.16, 1.16);
  /* margin-left: 20% !important; */
}
.legenda-cp {
  width: 100px;
  height: 60px;
  position: relative;
  bottom: -40px;
  left: 0px;
  border-radius: 4px;
}
.em-back {
  background: linear-gradient(-45deg, #747474, #494949);
	background-size: 200% 200%;
	animation: gradient 5s ease-in-out infinite;
}
.em-back-2 {
  background: linear-gradient(225deg, #747474, #494949);
	background-size: 200% 200%;
	animation: gradient 5s ease-in-out infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
</style>