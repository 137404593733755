import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["authLogin"]),
    async login(form) {
      const response = (
        await this.$http.post(`${url}/usuarios/login`, {
          login: form,
        })
      ).data.data;

      if (response.login) {
        localStorage.loggedIn = response.login;
        localStorage.userId = response.id;
        this.$cookies.set("_nekot", response.token);

        await this.authLogin(response);
        return response;
      }
      return null;
    },
  },
};
