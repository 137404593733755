import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["userId"]),
  },
  methods: {
    async getTeamsEnrollBySala(salaId) {
      let teams = this.$http.get(
        `${url}/v3/ligas/salas/${salaId}/usuario/${this.userId}`
      );
      return teams;
    },
    async getGameTeamsEnrollBySala(gameId, salaId) {
      let teams = this.$http.get(
        `${url}/games/${gameId}/salas/${salaId}/usuario/${this.userId}`
      );
      return teams;
    },
  },
};
