<template>
  <b-dropdown
    class="ft-dropdown"
    ref="ftdropdown"
    position="is-bottom-right"
    :expanded="$mq == 'sm'"
    mobile-modal
  >
    <div class="lg-ft-content" slot="trigger">
      <b-tooltip
        label="Clique para adicionar Filtros"
        position="is-right"
        type="is-pigeon"
      >
        <div class="lg-box-filter">
          <b-icon icon="filter" pack="fas"></b-icon>
          <span v-if="$mq !== 'sm'">Filtros</span>
          <b-tag
            v-if="sumFilters > 0"
            class="ft-tag"
            rounded
            type="is-darkblue"
            >{{ sumFilters }}</b-tag
          >
        </div>
      </b-tooltip>
    </div>

    <div
      class="ft-container"
      :style="{ 'max-height': heightFilter, 'max-width': widthFilter }"
    >
      <div v-if="$mq === 'sm'" class="ft-header">
        <div class="ft-close" @click="close">
          <b-icon icon="times-circle" pack="fas"></b-icon>
        </div>
        <h3 class="ft-title">Filtrar Ligas</h3>
      </div>

      <div class="ft-content">
        <div v-if="filterFields" class="columns">
          <div class="column">
            <h3 class="ft-title">MODALIDADE</h3>
            <div
              v-for="mod of filterFields.modalidade"
              :key="`mod-${mod.slug}`"
              class="field"
            >
              <b-checkbox
                v-model="params.modalidade"
                type="is-pigeon"
                :native-value="mod.id"
              >
                {{ mod.label }}
              </b-checkbox>
            </div>
          </div>

          <div v-if="filterFields.status" class="column">
            <h3 class="ft-title">STATUS</h3>
            <div
              v-for="st of filterFields.status"
              :key="`st-${st.slug}`"
              class="field"
            >
              <b-checkbox
                v-model="params.status"
                type="is-pigeon"
                :native-value="st.id"
              >
                {{ st.label }}
              </b-checkbox>
            </div>
          </div>

          <div v-if="filterFields.tipo" class="column">
            <h3 class="ft-title">TIPO</h3>
            <div
              v-for="tp of filterFields.tipo"
              :key="`tp-${tp.slug}`"
              class="field"
            >
              <b-checkbox
                v-model="params.tipo"
                type="is-pigeon"
                :native-value="tp.id"
              >
                {{ tp.label }}
              </b-checkbox>
            </div>
          </div>

          <div v-if="filterFields.apuracao" class="column">
            <h3 class="ft-title">APURAÇÃO</h3>
            <div
              v-for="ap of filterFields.apuracao"
              :key="`ap-${ap.slug}`"
              class="field"
            >
              <b-checkbox
                v-model="params.apuracao"
                type="is-pigeon"
                :native-value="ap.id"
              >
                {{ ap.label }}
              </b-checkbox>
            </div>
          </div>

          <div v-if="filterFields.capitao" class="column">
            <h3 class="ft-title">CAPITÃO</h3>
            <div
              v-for="cap of filterFields.capitao"
              :key="`cap-${cap.slug}`"
              class="field"
            >
              <b-checkbox
                v-model="params.capitao"
                type="is-pigeon"
                :native-value="cap.id"
              >
                {{ cap.label }}
              </b-checkbox>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-full">
            <b-field label="Preços R$">
              <b-slider
                type="is-primary"
                v-model="params.valor"
                :min="minPrice"
                :max="maxPrice"
                :step="1"
                :custom-formatter="customFormatter"
              >
                <b-slider-tick :value="0"></b-slider-tick>
                <b-slider-tick :value="5"></b-slider-tick>
                <b-slider-tick :value="10"></b-slider-tick>
                <b-slider-tick :value="50"></b-slider-tick>
                <b-slider-tick :value="100"></b-slider-tick>
                <b-slider-tick :value="250"></b-slider-tick>
                <b-slider-tick :value="500"></b-slider-tick>
                <b-slider-tick :value="750"></b-slider-tick>
                <b-slider-tick :value="1000"></b-slider-tick>
              </b-slider>
            </b-field>
          </div>
        </div>
      </div>

      <div class="group-bt-filter">
        <b-button class="fa-bt" type="is-secondary" @click="cleanFilters()"
          >Limpar Filtros</b-button
        ><b-button class="fa-bt" type="is-success" @click="submit()"
          >Filtrar</b-button
        >
      </div>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: "FiltersLeagues",
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterFields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      params: {
        modalidade: [],
        status: [],
        tipo: [],
        apuracao: [],
        capitao: [],
        valor: [0, 1000],
      },
      minPrice: 0,
      maxPrice: 1000,
    };
  },
  computed: {
    heightFilter() {
      const screenHeight =
        this.$mq == "sm" ? window.screen.height : window.screen.height - 170;
      const screenAvailable = screenHeight - (this.$mq == "sm" ? 0 : 170);
      return `${screenAvailable}px`;
    },
    widthFilter() {
      const screenWidth =
        this.$mq == "sm" ? window.screen.width : window.screen.width - 50;
      const screenAvailable = screenWidth - (this.$mq == "sm" ? 0 : 50);
      return `${screenAvailable}px`;
    },
    sumFilters() {
      return (
        this.params.modalidade.length +
        this.params.status.length +
        this.params.tipo.length +
        this.params.apuracao.length +
        this.params.capitao.length +
        (this.params.valor.length - 1)
      );
    },
  },
  watch: {
    filters: {
      handler(newValue) {
        this.loadFilters(newValue);
      },
      deep: true,
    },
  },
  created() {
    this.loadFilters(this.filters);
  },
  methods: {
    close() {
      this.$refs.ftdropdown.toggle();
    },
    customFormatter(value) {
      if (value == 1000) {
        return `R$ ${value}+`;
      }
      return `R$ ${value}`;
    },
    submit() {
      const filters = { ...this.params };
      // const filters = {
      //   modalidade: [...this.modalidade],
      //   status: [...this.status],
      //   tipo: [...this.tipo],
      //   apuracao: [...this.apuracao],
      //   capitao: [...this.capitao],
      //   valor: [...this.valor],
      // };
      this.$emit("change", filters);
      this.$refs.ftdropdown.toggle();
    },
    cleanFilters() {
      const filters = {
        modalidade: [],
        status: [],
        tipo: [],
        apuracao: [],
        capitao: [],
        valor: [0, 1000],
      };
      this.params = { ...filters };
      this.$emit("change", filters);
    },
    loadFilters(filters) {
      this.params = { ...filters };
      // Object.entries(filters).forEach(([key, value]) => {
      //   console.log(key, value);
      //   if (this[key]) this[key] = [...value];
      // });
    },
  },
};
</script>
