<template>
  <section id="DownTimer">
    <div v-if="clock && (statusText !== '' || message !== '')" id='timer' class='timer'>
      <!-- <div>TEMPO ESTIMADO</div> -->
      <div v-if="statusType !== 'expired' && wordString.days" class="day">
        <b-tag class="is-primary" size="is-large" style="width: 50px;">{{ days.toString().padStart(2,'0') }}</b-tag>
        <div class="format">{{ wordString.days }}</div>
      </div>
      <div v-if="statusType !== 'expired' && wordString.hours" class="hour">
        <b-tag class="is-primary" size="is-large" style="width: 50px;">{{ hours.toString().padStart(2,'0') }}</b-tag>
        <div class="format">{{ wordString.hours }}</div>
      </div>
      <div v-if="statusType !== 'expired' && wordString.minutes" class="min">
        <b-tag class="is-primary" size="is-large" style="width: 50px;">{{ minutes.toString().padStart(2,'0') }}</b-tag>
        <div class="format">{{ wordString.minutes }}</div>
      </div>
      <div v-if="statusType !== 'expired' && wordString.seconds" class="sec">
        <b-tag class="is-primary" size="is-large" style="width: 50px;">{{ seconds.toString().padStart(2,'0') }}</b-tag>
        <div class="format">{{ wordString.seconds }}</div>
      </div>
      <div v-if="statusText !== ''" >
        <b-tag :class="{'is-danger': statusType == 'expired', 'is-warning': statusType == 'running', 'is-success': statusType == 'upcoming'}" size="is-medium"><b>{{ statusText }}</b></b-tag>
      </div>
      <div v-if="message !== ''" >
        <b-tag class="is-primary" size="is-small"><b>{{ message }}</b></b-tag>
      </div>
    </div>
    <div v-else style="text-align: center;">
      <div v-if="statusText !== ''" style="text-align: center; font-size: 0.95rem;">
        <b :class="{'is-danger': statusType == 'expired', 'is-success': statusType == 'running', 'is-warning': statusType == 'upcoming'}">{{ statusText }}</b>
      </div>
      <div v-if="message !== ''" style="text-align: center; font-size: 0.8rem;">
        {{ message }}
        <span v-if="statusType !== 'expired' && wordString.days && days > 0">
          {{ days.toString().padStart(2,'0') }} {{ wordString.days }}
        </span>
        <span v-if="statusType !== 'expired' && wordString.hours && days == 0 && hours > 0">
          {{ hours.toString().padStart(2,'0') }}{{ wordString.hours }} {{ minutes.toString().padStart(2,'0') }}{{ wordString.minutes }}
        </span>
        <span v-if="statusType !== 'expired' && wordString.minutes && days == 0 && hours == 0">
          {{ minutes.toString().padStart(2,'0') }}{{ wordString.minutes }} {{ seconds.toString().padStart(2,'0') }}{{ wordString.seconds }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DownTimer',
  props: {
    clock: {
      type: Boolean,
      default: () => true,
    },
    starttime: {
      type: String,
      default: () => "",
    },
    endtime: {
      type: String,
      default: () => "",
    },
    trans: {
      type: Object,
      default: () => {},
    }
  },
  data: function(){
  	return{
    	timer:"",
      wordString: {},
      start: "",
      end: "",
      interval: "",
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0,
      message: "",
      statusType: "",
      statusText: ""
    };
  },
  created: function () {
      if (typeof this.trans == 'string'){
        this.wordString = JSON.parse(this.trans);
      } else {
        this.wordString = this.trans;
      }
    },
  mounted(){

    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();

    // Update the count down every 1 second
    this.timerCount(this.start,this.end);
    this.interval = setInterval(() => {
        this.timerCount(this.start,this.end);
    }, 1000);
  },
  methods: {
    timerCount: function(start,end){
        // Get todays date and time
        var now = new Date().getTime();

        // Find the distance between now an the count down date

        var distance = start - now;
        var passTime =  end - now;

        if(distance < 0 && passTime < 0){

          this.message = (this.wordString.expired) ? this.wordString.expired : '';
          this.statusType = "expired";
          this.statusText = (this.wordString.status) ? ((this.wordString.status.expired) ? this.wordString.status.expired : '') : '';
          this.$emit('expired')
          clearInterval(this.interval);
          return;
        
        }else if(distance < 0 && passTime > 0){
          
          this.calcTime(passTime);
          this.message = (this.wordString.running) ? this.wordString.running : '';
          this.statusType = "running";
          this.statusText = (this.wordString.status) ? ((this.wordString.status.running) ? this.wordString.status.running : '') : '';
        
        } else if( distance > 0 && passTime > 0 ){
          
          this.calcTime(distance); 
          this.message = (this.wordString.upcoming) ? this.wordString.upcoming : '';
          this.statusType = "upcoming";
          this.statusText = (this.wordString.status) ? ((this.wordString.status.upcoming) ? this.wordString.status.upcoming : '') : '';
        
        }
    },
    calcTime: function(dist){
      // Time calculations for days, hours, minutes and seconds
        this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    }
    
  }
}
</script>

<style scoped>
#DownTimer .timer {
  font-size: 20px;
  color: #fff;
  text-align:center;
  padding: 10px;
  /* margin: 10px 0; */
}
#DownTimer .day, 
#DownTimer .hour, 
#DownTimer .min, 
#DownTimer .sec {
  font-size: 30px;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  margin: 0 5px;
}
#DownTimer .format {
  font-weight: 300;
  font-size: 14px;
  opacity: 0.8;
  /* width: 60px; */
}
#DownTimer .number{
  /* color: #000; */
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  /* width: 60px; */
  text-align: center;
  /* background: #00d1b2; */
}
#DownTimer .message {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  /* color: #000; */
  /* background: #00d1b2; */
}
#DownTimer .status-tag{
  width: 270px;
  margin: 10px auto;
  padding: 8px 0;
  font-weight: 500;
  /* color: #000; */
  text-align: center;
  border-radius: 15px;
}
/* #DownTimer .upcoming{
  background-color: lightGreen;
}
#DownTimer .running{
  background-color: gold;
}
#DownTimer .expired{
  background-color: silver;
} */
</style>
