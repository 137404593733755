<template>
  <div id="module-meusdados" :fluid="fluid">
    <div class="container-login">
      <div class="columns is-gapless">
        <div class="column is-full">
          <WizardLayout
            title="Meus Dados"
            has-close
            :display-default-logo="displayDefaultLogo"
            :has-logo="hasLogo"
            :url-logo="urlLogo"
            :logo-href="logoHref"
            :width-logo="widthLogo"
            @close="$emit('close')"
          >
            <ValidationObserver
              v-slot="{ handleSubmit }"
              tag="form"
              class="form-auth"
            >
              <div class="columns is-multiline is-variable is-2">
                <div class="column is-half">
                  <Input
                    v-model="dados.nome"
                    type="text"
                    name="cad-nome"
                    :rules="{
                      required: true,
                    }"
                    label="Nome"
                  />
                </div>
                <div class="column is-half">
                  <Input
                    v-model="dados.sobrenome"
                    type="text"
                    name="cad-nome"
                    :rules="{
                      required: true,
                    }"
                    label="Sobrenome"
                  />
                </div>
                <div class="column is-half">
                  <Input
                    v-model="dados.cpf"
                    type="text"
                    name="cad-nome"
                    :rules="{
                      required: true,
                      cpf: true,
                    }"
                  >
                    <template slot="label">
                      <p>CPF</p>
                      <span style="font-size: 0.7rem"
                        >(necessário para transações bancárias em Ligas)</span
                      >
                    </template>
                  </Input>
                </div>
                <div class="column is-half">
                  <Input
                    v-model="dados.telefone"
                    type="text"
                    name="cad-telefone"
                    icon="whatsapp"
                  >
                    <template slot="label">
                      <p>Telefone</p>
                      <span style="font-size: 0.7rem">(DDD + Número)</span>
                    </template>
                  </Input>
                </div>
                <div class="column is-one-third">
                  <Input
                    v-model="dados.endereco.cidade"
                    type="text"
                    name="cad-cidade"
                    :rules="{
                      required: true,
                    }"
                    label="Cidade"
                  />
                </div>
                <div class="column is-one-third">
                  <Select
                    v-model="dados.endereco.uf"
                    name="cad-cidade"
                    rules="required"
                    label="Estado"
                  >
                    <option
                      v-for="(estado, i) of states"
                      :key="i"
                      :value="estado"
                    >
                      {{ estado }}
                    </option>
                  </Select>
                </div>
                <div class="column is-one-third">
                  <Input
                    v-model="dados.endereco.pais"
                    type="text"
                    name="cad-pais"
                    :rules="{
                      required: true,
                    }"
                    label="País"
                  />
                </div>
                <div class="column is-full">
                  <Input
                    v-model="dados.email"
                    type="text"
                    name="cad-email"
                    :rules="{
                      required: true,
                      email: true,
                    }"
                    label="Email"
                  />
                </div>
                <div class="column is-half">
                  <Input
                    v-model="dados.pass"
                    type="password"
                    name="cad-password"
                    label="Senha"
                    password-reveal
                  />
                </div>
                <div class="column is-half">
                  <Input
                    v-model="confirmarPass"
                    type="password"
                    name="confirm-password"
                    label="Confirmar Senha"
                    password-reveal
                  />
                </div>
              </div>
              <div class="level is-mobile">
                <div class="level-left">
                  <b-button type="is-secondary" v-on:click="$emit('close')"
                    >Cancelar</b-button
                  >
                </div>
                <div class="level-right">
                  <b-button type="is-primary" @click="handleSubmit(update)">
                    Confirmar
                  </b-button>
                </div>
              </div>
            </ValidationObserver>
          </WizardLayout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import WizardLayout from "../components/WizardLayout";

export default {
  name: "MeusDados",
  components: {
    WizardLayout,
    ValidationObserver,
  },
  props: {
    displayDefaultLogo: {
      type: Boolean,
      default: true,
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
    urlLogo: {
      type: String,
      default: "",
    },
    logoHref: {
      type: String,
      default: "",
    },
    widthLogo: {
      type: Number,
      default: 140,
    },
    fluid: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dados: {},
      confirmarPass: "",
      states: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RO",
        "RS",
        "RR",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  async created() {
    this.dados = { ...this.userInfo };
  },
  methods: {
    ...mapActions(["updateUser"]),
    update() {
      if (this.dados.pass && this.dados.pass !== "") {
        if (this.dados.pass !== this.confirmarPass) {
          this.$buefy.notification.open({
            message: "As senhas estão diferentes",
            type: "is-danger",
            position: "is-top",
            hasIcon: true,
            duration: 2500,
          });
          return;
        }
        if (this.dados.pass === "") delete this.dados.pass;
      }

      Promise.all([this.updateUser(this.dados)])
        .then((response) => {
          if (response[0] && response[0].length > 0) {
            this.$buefy.notification.open({
              message: response[0].join("<br>"),
              type: "is-danger",
              position: "is-top",
              hasIcon: true,
              duration: 3500,
            });
            this.$emit("error");
          } else {
            this.$buefy.notification.open({
              message: "Dados atualizados!",
              type: "is-success",
              position: "is-top",
              hasIcon: true,
              duration: 3500,
            });
            this.$emit("updated");
          }
        })
        .catch(() => {
          this.$emit("error");
        });
    },
  },
};
</script>

<style></style>
