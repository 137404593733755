<template>
  <section id="Register">
    <WizardLayout
      ref="wizard"
      title="Cadastro"
      subtitle="Insira suas informações para se cadastrar no Escala10"
      has-close
      :display-default-logo="displayDefaultLogo"
      :has-logo="hasLogo"
      :url-logo="urlLogo"
      :logo-href="logoHref"
      :width-logo="widthLogo"
      @close="$emit('showLogin')"
    >
      <ValidationObserver
        v-slot="{ handleSubmit }"
        tag="form"
        class="form-auth"
      >
        <div class="columns is-multiline is-variable is-2">
          <div class="column is-half">
            <Input
              v-model="cadUser.nome"
              type="text"
              name="cad-nome"
              :rules="{
                required: true,
              }"
              label="Nome"
            />
          </div>
          <div class="column is-half">
            <Input
              v-model="cadUser.sobrenome"
              type="text"
              name="cad-nome"
              :rules="{
                required: true,
              }"
              label="Sobrenome"
            />
          </div>
          <div class="column is-half">
            <Input
              v-model="cadUser.cpf"
              type="text"
              name="cad-nome"
              :rules="{
                required: true,
                cpf: true,
              }"
            >
              <template slot="label">
                <p>CPF</p>
                <span style="font-size: 0.7rem"
                  >(necessário para transações bancárias em Ligas)</span
                >
              </template>
            </Input>
          </div>
          <div class="column is-half">
            <Input
              v-model="cadUser.telefone"
              type="text"
              name="cad-telefone"
              icon="whatsapp"
            >
              <template slot="label">
                <p>Telefone</p>
                <span style="font-size: 0.7rem">(DDD + Número)</span>
              </template>
            </Input>
          </div>
          <div class="column is-one-third">
            <Input
              v-model="cadUser.endereco.cidade"
              type="text"
              name="cad-cidade"
              :rules="{
                required: true,
              }"
              label="Cidade"
            />
          </div>
          <div class="column is-one-third">
            <Select
              v-model="cadUser.endereco.uf"
              name="cad-cidade"
              rules="required"
              label="Estado"
            >
              <option v-for="(estado, i) of states" :key="i" :value="estado">
                {{ estado }}
              </option>
            </Select>
          </div>
          <div class="column is-one-third">
            <Input
              v-model="cadUser.endereco.pais"
              type="text"
              name="cad-pais"
              :rules="{
                required: true,
              }"
              label="País"
            />
          </div>
          <div class="column is-half">
            <Input
              v-model="cadUser.email"
              type="text"
              name="cad-email"
              :rules="{
                required: true,
                email: true,
              }"
              label="Email"
            />
          </div>
          <div class="column is-half">
            <Input
              v-model="cadUser.pass"
              type="password"
              name="cad-password"
              rules="required"
              label="Senha"
              password-reveal
            />
          </div>
          <div class="column is-full">
            <b-field>
              <b-checkbox
                v-model="cadUser.termos_uso"
                :true-value="1"
                :false-value="0"
                type="is-primary"
                >Concorda com os nossos
                <a href="/termos-de-uso" target="_blank">termos de usos</a
                >.</b-checkbox
              >
            </b-field>
            <b-field>
              <b-checkbox
                v-model="cadUser.mensagens_externas"
                :true-value="1"
                :false-value="0"
                type="is-primary"
                >Aceita receber informações do Escala10, como novidades,
                promoções e informações, por meio de seus contatos
                cadastrados.</b-checkbox
              >
            </b-field>
          </div>
        </div>
        <div class="level is-mobile">
          <div class="level-left">
            <b-button type="is-secondary" v-on:click="$emit('showLogin')"
              >Voltar</b-button
            >
          </div>
          <div class="level-right">
            <b-button type="is-primary" @click="handleSubmit(validForm)">
              Confirmar
            </b-button>
          </div>
        </div>
      </ValidationObserver>
    </WizardLayout>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import WizardLayout from "./WizardLayout";

export default {
  name: "Register",
  components: {
    ValidationObserver,
    WizardLayout,
  },
  props: {
    isInfluencerPage: {
      type: Boolean,
      default: false,
    },
    displayDefaultLogo: {
      type: Boolean,
      default: true,
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
    urlLogo: {
      type: String,
      default: "",
    },
    logoHref: {
      type: String,
      default: "",
    },
    widthLogo: {
      type: Number,
      default: 140,
    },
  },
  data() {
    return {
      loading: null,
      termosUso: false,
      cadUser: {
        id: 0,
        nome: "",
        sobrenome: "",
        nome_completo: "",
        email: "",
        pass: "",
        cpf: "",
        token: "",
        endereco: {
          logradouro: "",
          numero: "",
          comp: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
        },
        plano: "",
        planoDescricao: "",
        planoValor: 0,
        referencia: "",
        cupom: "",
      },
      states: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RO",
        "RS",
        "RR",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
    };
  },
  computed: {
    ...mapGetters([
      "loggedIn",
      "userInfo",
      "userId",
      "userValid",
      "token",
      "influencer",
    ]),
  },
  methods: {
    ...mapActions([
      "setLogin",
      "addUser",
      "updateUser",
      "getValidUser",
      "updateSignature",
    ]),
    async validForm() {
      if (this.cadUser.termos_uso === 0) {
        this.$buefy.dialog.alert({
          title: "Termos de Uso",
          message:
            "Para continuar com o cadastro, deverá aceitar os termos de uso. Assim estará de acordo com as diretrizes do Escala10",
          type: "is-warning",
          hasIcon: true,
        });
        return;
      }

      this.loading = this.$buefy.loading.open();

      this.cadUser.nome = this.cadUser.nome ? this.cadUser.nome.trim() : "";
      this.cadUser.sobrenome = this.cadUser.sobrenome
        ? this.cadUser.sobrenome.trim()
        : "";
      this.cadUser.cpf = this.cadUser.cpf ? this.cadUser.cpf.trim() : "";
      this.cadUser.telefone = this.cadUser.telefone
        ? this.cadUser.telefone.trim()
        : "";
      this.cadUser.email = this.cadUser.email ? this.cadUser.email.trim() : "";
      this.cadUser.endereco.cidade = this.cadUser.endereco
        ? this.cadUser.endereco.cidade
          ? this.cadUser.endereco.cidade.trim()
          : ""
        : "";
      this.cadUser.endereco.uf = this.cadUser.endereco
        ? this.cadUser.endereco.uf
          ? this.cadUser.endereco.uf.trim()
          : ""
        : "";
      this.cadUser.endereco.pais = this.cadUser.endereco
        ? this.cadUser.endereco.pais
          ? this.cadUser.endereco.pais.trim()
          : ""
        : "";

      await this.getValidUser(this.cadUser);

      if (this.userValid) {
        if (
          !this.userValid.available &&
          this.userValid.id !== this.cadUser.id
        ) {
          this.$buefy.dialog.alert({
            title: "Cadastro com dados inválidos",
            message:
              "O seu CPF / E-Mail já estão cadastrados em nosso site. Realize a autenticação com seus dados, caso esqueceu sua senha, clique no botão 'esqueceu a senha'.",
            type: "is-warning",
            hasIcon: true,
          });
        } else {
          await this.register();
        }
      }
    },
    async register() {
      try {
        if (this.loggedIn) {
          const error = await this.updateUser(this.cadUser);

          let msgError = "";
          if (error && error.length > 0) {
            msgError = response.join("<br>");
          }

          if (msgError !== "") {
            this.$toasted.show(msgError, {
              position: "top-center",
              duration: 3000,
              type: "is-danger",
              className: "toasted-class",
            });
          }
        } else {
          if (this.isInfluencerPage) {
            this.cadUser.id_influencer = this.influencer.id;
          }
          await this.addUser(this.cadUser);
        }

        if (this.loggedIn) {
          this.cadUser.id = this.userInfo.id;
          this.cadUser.token = this.userInfo.token;

          this.$emit("success", { ...this.userInfo });
        } else {
          this.$buefy.dialog.alert({
            title: "Atenção",
            message: "Não foi possível realizar o cadastro. Tente novamente!",
            type: "is-danger",
            hasIcon: true,
          });
        }
      } catch (err) {
        this.$buefy.dialog.alert({
          title: "Atenção",
          message: "Não foi possível realizar o cadastro. Tente novamente!",
          type: "is-danger",
          hasIcon: true,
        });
      }
      this.loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
#Register {
  width: 750px;
}
@media (max-width: 768px) {
  #Register {
    width: 100% !important;
    height: 100%;
  }
}
</style>
