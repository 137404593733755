<template>
  <section ref="shoppingCart">
    <b-navbar 
        v-if="reduce"
        type="is-bluedark-2" 
        :close-on-click="false"
        fixed-bottom
        shadow
        style="padding: 0.25em 2em;"
    >
        <template v-if="$mq == 'sm'" slot="burger">
            <b-navbar-item
              tag="div"
              class="centralize"
            >
                <b-taglist attached>
                    <b-tag type="is-bluedark2" size="is-small">{{ labelLiga }}</b-tag>
                    <b-tag type="is-primary" size="is-small">{{ qtdLiga }}</b-tag>
                </b-taglist>
            </b-navbar-item>
            <b-navbar-item
              tag="div"
              class="centralize"
            >
                <b-taglist attached>
                    <b-tag type="is-bluedark2" size="is-small">Total por Time</b-tag>
                    <b-tag type="is-primary" size="is-small">{{ valorTimeDesc }}</b-tag>
                </b-taglist>
            </b-navbar-item>
            <b-navbar-item
                tag="div"
                class="cart-icon medium"
                @click="viewCart()"
            >
                <b-icon type="is-primary" size="is-medium" pack="fa" icon="shopping-cart"></b-icon>
            </b-navbar-item>
        </template>

        <template v-if="$mq !== 'sm'" slot="end">
            <b-navbar-item
              tag="div"
              class="centralize"
            >
                <b-taglist attached>
                    <b-tag type="is-bluedark2" size="is-small">{{ labelLiga }}</b-tag>
                    <b-tag type="is-primary" size="is-small">{{ qtdLiga }}</b-tag>
                </b-taglist>
            </b-navbar-item>
            <b-navbar-item
              tag="div"
              class="centralize"
            >
                <b-taglist attached>
                    <b-tag type="is-bluedark2" size="is-small">Total por Time</b-tag>
                    <b-tag type="is-primary" size="is-small">{{ valorTimeDesc }}</b-tag>
                </b-taglist>
            </b-navbar-item>
            <b-navbar-item
                tag="div"
                class="cart-icon medium"
                @click="viewCart()"
            >
                <b-icon type="is-primary" size="is-medium" pack="fa" icon="shopping-cart"></b-icon>
            </b-navbar-item>
        </template>
    </b-navbar>
    
    <b-sidebar
        v-show="!reduce"
        id="Shopping-Cart"
        type="is-bluedark-2"
        :fullheight="fullheight"
        :fullwidth="fullwidth"
        :overlay="overlay"
        :right="right"
        v-model="open"
        :open.sync="open"
        position="fixed"
        :reduce="reduce"
        :mobile="'fullwidth'"
        :can-cancel="canCancel"
    >
        <div class="cart-header">
            <div class="columns is-gapless is-mobile">
                <div 
                    class="column is-narrow"
                >
                    <div 
                        class="cart-icon"
                        :class="{'small': $mq == 'sm'}"
                        @click="viewCart()"
                    >
                        <b-icon type="is-primary" :size="'is-'+($mq == 'sm' ? 'small' : 'medium')" pack="fa" icon="shopping-cart"></b-icon>
                    </div>
                </div>
                <div class="column centralize">
                    <span v-if="cartStep == 1">Ligas para inscrição</span>
                    <span v-if="cartStep == 2">Seleção dos Times</span>
                    <span v-if="cartStep == 3">Confirmação para Inscrição</span>
                    <span v-if="cartStep == 4">Log da Inscrição</span>
                </div>
                <div 
                    class="column is-narrow centralize"
                    @click="cartStep == 4 ? endCard() : closeCart()"
                >
                    <div class="cart-passos">Passo {{ cartStep }}/4
                        <b-icon type="is-danger" icon="times-circle" pack="fas"></b-icon>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile">
                <div class="column">
                    <div
                        style="display: flex; justify-content: space-between; width: 100%"
                    >
                        <b-button
                            v-if="cartStep > 1 && cartStep < 4"
                            type="is-pigeon"
                            size="is-small"
                            @click="backStep()"
                        >Voltar
                        </b-button>

                        <b-button
                            v-if="cartStep == 1"
                            type="is-pigeon"
                            size="is-small"
                            @click="reduceCart()"
                        >Selecionar +Ligas
                        </b-button>
                        <b-button 
                            v-if="cartStep == 2" 
                            type="is-pigeon" 
                            @click="addTeam()"
                            size="is-small"
                        >Adicionar Time(s)
                        </b-button>
                        <b-button
                            v-if="cartStep == 1"
                            type="is-success"
                            size="is-small"
                            @click="selectTeams()"
                        >Selecionar Times
                        </b-button>
                        <b-button
                            v-if="cartStep == 4 && needSuporte"
                            type="is-danger"
                            size="is-small"
                            @click="callSuport()"
                        >Falar com Suporte
                        </b-button>
                        
                        <div v-if="cartStep == 3 && (wallet ? wallet.saldo_bonus : 0) > 0 && valorPagar > 0"
                            class="block has-background-bluedark"
                            style="margin: 0px !important;"
                        >
                            <span class="subtitle">
                                Utilizar bônus?
                            </span>
                            <b-radio
                                type="is-primary"
                                ref="saldo_bonus_sim"
                                name="saldo_bonus"
                                v-model="saldo_bonus"
                                size="is-small"
                                :native-value="wallet.saldo_bonus"
                            >Sim
                            </b-radio>
                            <b-radio
                                type="is-primary"
                                ref="saldo_bonus_nao"
                                name="saldo_bonus"
                                v-model="saldo_bonus"
                                size="is-small"
                                :native-value="0"
                            >Não
                            </b-radio>
                        </div>

                        <b-button
                            v-if="cartStep == 2"
                            type="is-success"
                            size="is-small"
                            @click="nextStep()"
                        >Avançar
                        </b-button>
                        <b-button
                            v-if="cartStep == 3"
                            type="is-success"
                            size="is-small"
                            @click="submit()"
                        >Confirmar
                        </b-button>
                        <b-button
                            v-if="cartStep == 4"
                            type="is-success"
                            size="is-small"
                            @click="endCart()"
                        >Finalizar
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="columns is-gapless is-multiline is-mobile"
        >
            <div 
                class="column is-full"
                v-show="cartStep == 1"
            >
                <div 
                    class="columns is-gapless is-mobile has-background-bluedark2 cart-sala"
                    v-for="sala of cart"
                    :key="sala.id"
                >
                    <div class="column is-narrow">
                        <img
                            class="cart-foto-influencer"
                            :src="sala.influencer.url_foto"
                            :alt="sala.influencer.nome_midia"
                        />
                    </div>
                    <div class="column">
                        <p><span class="sala-info">{{ sala.influencer.nome_midia }}</span></p>
                        <p><span class="sala-info">{{ sala.liga.tipo }}</span></p>
                        <p><span class="sala-info">{{ sala.liga.apuracao }}</span></p>
                    </div>
                    <div class="column">
                        <p><span class="sala-info">ID {{ sala.id }}</span></p>
                        <p>
                            <span class="sala-info">
                                <b-icon
                                    :icon="sala.capitao ? 'copyright' : 'creative-commons-pd'"
                                    :pack="sala.capitao ? 'far' : 'fab'"
                                    size="is-small"
                                ></b-icon>
                                {{ sala.capitao ? "COM" : "SEM" }} capitão
                            </span>
                        </p>
                        <p><span class="sala-info">{{ handlerRodada(sala) }}</span></p>
                    </div>
                    <div v-if="$mq !== 'sm'" class="column centralize">
                        <p>
                            <span class="sala-sub-info has-text-primary">Inscrição</span><br/>
                        </p>
                        <p>
                            <span class="sala-info has-text-primary">R$ {{ sala.valor }}</span>
                        </p>
                    </div>
                    <div class="column is-narrow">
                        <div @click="removeSalaFromCart(sala)" style="text-align: right !important;">
                            <b-tooltip
                                label="Cancelar Inscrição"
                                position="is-left"
                                type="is-danger"
                            >
                                <b-icon
                                    type="is-danger"
                                    icon="times"
                                    pack="fas"
                                    size="is-small"
                                ></b-icon>
                            </b-tooltip>
                        </div>
                        <div v-if="$mq == 'sm'" class="centralize">
                            <p>
                                <span class="sala-sub-info has-text-primary">Inscrição</span><br/>
                            </p>
                            <p>
                                <span class="sala-info has-text-primary">R$ {{ sala.valor }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div 
                class="column is-full"
                v-if="cartStep == 2"
            >
                <div 
                    class="columns is-mobile has-background-bluedark2 cart-teams"
                >
                    <TeamsEnroll
                        ref="teams"
                        :reservedEnrols="reservedEnrols"
                        :cart="cart"
                        @change="handlerTeams"
                    ></TeamsEnroll>
                </div>
            </div>

            <div 
                class="column is-full"
                v-if="cartStep == 3"
            >
                <b-tabs type="is-boxed" expanded>
                    <b-tab-item label="Ligas" icon="trophy">
                        <div 
                            class="columns is-gapless is-mobile has-background-bluedark2 cart-sala"
                            v-for="sala of cart"
                            :key="sala.id"
                        >
                            <div class="column is-narrow">
                                <img
                                    class="cart-foto-influencer"
                                    :src="sala.influencer.url_foto"
                                    :alt="sala.influencer.nome_midia"
                                />
                            </div>
                            <div class="column">
                                <p><span class="sala-info">{{ sala.influencer.nome_midia }}</span></p>
                                <p><span class="sala-info">{{ sala.liga.tipo }}</span></p>
                                <p><span class="sala-info">{{ sala.liga.apuracao }}</span></p>
                            </div>
                            <div class="column">
                                <p><span class="sala-info">ID {{ sala.id }}</span></p>
                                <p>
                                    <span class="sala-info">
                                        <b-icon
                                            :icon="sala.capitao ? 'copyright' : 'creative-commons-pd'"
                                            :pack="sala.capitao ? 'far' : 'fab'"
                                            size="is-small"
                                        ></b-icon>
                                        {{ sala.capitao ? "COM" : "SEM" }} capitão
                                    </span>
                                </p>
                                <p><span class="sala-info">{{ handlerRodada(sala) }}</span></p>
                            </div>
                            <div class="column is-narrow centralize">
                                <p>
                                    <span class="sala-sub-info has-text-primary">Total Inscrição</span><br/>
                                </p>
                                <p>
                                    <span class="sala-info has-text-primary">R$ {{ teamsToEnroll.length * sala.valor }}</span>
                                </p>
                            </div>
                        </div>
                    </b-tab-item>
                    <b-tab-item label="Times" icon-pack="fas" icon="shield-alt">
                        <div 
                            class="columns is-mobile has-background-bluedark2 cart-teams"
                        >
                            <TeamsEnroll
                                onlyList
                                :list="teamsToEnroll"
                                :reservedEnrols="reservedEnrols"
                                :cart="cart"
                                @change="handlerTeams"
                            ></TeamsEnroll>
                        </div>
                    </b-tab-item>
                </b-tabs>
            </div>

            <div 
                class="column is-full"
                v-if="cartStep == 4"
            >
                <div 
                    class="columns is-multiline is-gapless is-mobile has-background-bluedark2 cart-sala"
                    v-for="sala of cart"
                    :key="sala.id"
                >
                    <div class="column is-full">
                        <div class="columns is-gapless is-mobile">
                            <div class="column is-narrow">
                                <img
                                    class="cart-foto-influencer"
                                    :src="sala.influencer.url_foto"
                                    :alt="sala.influencer.nome_midia"
                                />
                            </div>
                            <div class="column">
                                <p><span class="sala-info">{{ sala.influencer.nome_midia }}</span></p>
                                <p><span class="sala-info">{{ sala.liga.tipo }}</span></p>
                                <p><span class="sala-info">{{ sala.liga.apuracao }}</span></p>
                            </div>
                            <div class="column">
                                <p><span class="sala-info">ID {{ sala.id }}</span></p>
                                <p>
                                    <span class="sala-info">
                                        <b-icon
                                            :icon="sala.capitao ? 'copyright' : 'creative-commons-pd'"
                                            :pack="sala.capitao ? 'far' : 'fab'"
                                            size="is-small"
                                        ></b-icon>
                                        {{ sala.capitao ? "COM" : "SEM" }} capitão
                                    </span>
                                </p>
                                <p><span class="sala-info">{{ handlerRodada(sala) }}</span></p>
                            </div>
                            <div v-if="$mq !== 'sm'" class="column is-narrow centralize">
                                <p>
                                    <span class="sala-sub-info has-text-primary">Inscrições Confirmadas</span><br/>
                                </p>
                                <p>
                                    <span class="sala-info has-text-primary">
                                        {{ sala.enroll.success.length }} Time(s)
                                    </span>
                                </p>
                                <p>
                                    <span class="sala-info has-text-primary">
                                        R$ {{ sala.enroll.success.length * sala.valor }}
                                    </span>
                                </p>
                            </div>
                            <div class="column is-narrow centralize">
                                <b-tooltip
                                    :label="sala.enroll.msg"
                                    :type="`is-${sala.enroll.type}`"
                                    position="is-left"
                                    multilined
                                >
                                    <b-icon
                                        :type="`is-${sala.enroll.type}`"
                                        :icon="sala.enroll.icon"
                                        pack="fas"
                                    ></b-icon>
                                </b-tooltip>
                                <div 
                                    v-if="sala.enroll.suport"
                                    @click="callSuport(sala)"
                                >
                                    <b-tooltip
                                        label="Falar com Suporte"
                                        type="is-pigeon"
                                        position="is-left"
                                    >
                                        <b-icon pack="mdi" icon="face-agent" class="rp-icon" danger></b-icon>
                                    </b-tooltip>
                                </div>
                                <div v-if="$mq == 'sm'" class="centralize">
                                    <p>
                                        <span class="sala-sub-info has-text-primary">Inscrições Confirmadas</span><br/>
                                    </p>
                                    <p>
                                        <span class="sala-info has-text-primary">
                                            {{ sala.enroll.success.length }} Time(s)
                                        </span>
                                    </p>
                                    <p>
                                        <span class="sala-info has-text-primary">
                                            R$ {{ sala.enroll.success.length * sala.valor }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-full">
                        <div class="columns is-gapless is-mobile has-background-bluedark">
                            <div 
                                v-if="sala.enroll.success.length"
                                class="column is-half"
                            >
                                <div class="columns is-gapless is-multiline is-mobile">
                                    <div class="column is-full">
                                        <b-tag type="is-success" :aria-expanded="true">Times inscritos com sucesso!</b-tag>
                                    </div>
                                    <div 
                                        v-for="success of sala.enroll.success"
                                        :key="success.time.id_cartola"
                                        class="column is-full"
                                    >
                                        <img
                                        :src="success.time.url_escudo_svg"
                                        :alt="`${success.time.id_cartola} - ${success.time.nm_time_cartola}`"
                                        class="img-team"
                                        />
                                        {{ success.time.nm_time_cartola }}<br/>
                                        <small class="has-text-success">{{ success.msg.replace("OK - ", "") }}</small>
                                    </div>
                                </div>
                            </div>
                            <div 
                                v-if="sala.enroll.error.length"
                                class="column is-half"
                            >
                                <div class="columns is-gapless is-multiline is-mobile">
                                    <div class="column is-full">
                                        <b-tag type="is-danger" :aria-expanded="true">Times Não inscritos!</b-tag>
                                    </div>
                                    <div 
                                        v-for="error of sala.enroll.error"
                                        :key="error.time.id_cartola"
                                        class="column is-full"
                                    >
                                        <img
                                        :src="error.time.url_escudo_svg"
                                        :alt="`${error.time.id_cartola} - ${error.time.nm_time_cartola}`"
                                        class="img-team"
                                        />
                                        {{ error.time.nm_time_cartola }}<br/>
                                        <small class="has-text-danger">{{ error.msg.replace("ERROR - ", "") }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="cart-footer">
            <div v-if="cartStep < 4" class="columns is-gapless is-mobile">
                <div class="column is-vcentered">
                    <span class="title">TOTAL</span>
                </div>

                <div class="column is-narrow centralize">
                    <b-taglist attached style="align-self: flex-end;">
                        <b-tag type="is-bluedark2" size="is-small">{{ labelLiga }}</b-tag>
                        <b-tag type="is-primary" size="is-small">{{ qtdLiga }}</b-tag>
                    </b-taglist>
                    <b-taglist attached style="align-self: flex-end;">
                        <b-tag type="is-bluedark2" size="is-small">{{ labelTime }}</b-tag>
                        <b-tag type="is-primary" size="is-small">{{ qtdTime }}</b-tag>
                    </b-taglist>
                </div>
                <div class="column is-narrow centralize">
                    <b-taglist attached style="align-self: flex-end;">
                        <b-tag type="is-bluedark2" size="is-small">Total por Time</b-tag>
                        <b-tag type="is-primary" size="is-small">{{ valorTimeDesc }}</b-tag>
                    </b-taglist>
                    <b-taglist attached style="align-self: flex-end;">
                        <b-tag type="is-bluedark2" size="is-small">Valor Total</b-tag>
                        <b-tag type="is-primary" size="is-small">{{ valorTotalDesc }}</b-tag>
                    </b-taglist>
                </div>
            </div>
            <div v-else class="columns is-gapless is-mobile">
                <div class="column is-vcentered">
                    <span class="title">INSCRIÇÕES CONFIRMADAS</span>
                </div>

                <div class="column is-narrow centralize">
                    <b-taglist attached style="align-self: flex-end;">
                        <b-tag type="is-bluedark2" size="is-small">Qtd.</b-tag>
                        <b-tag type="is-primary" size="is-small">{{ qtdInsc }}</b-tag>
                    </b-taglist>
                </div>
                <div class="column is-narrow centralize">
                    <b-taglist attached style="align-self: flex-end;">
                        <b-tag type="is-bluedark2" size="is-small">Valor</b-tag>
                        <b-tag type="is-primary" size="is-small">{{ valorInscDesc }}</b-tag>
                    </b-taglist>
                </div>
            </div>
        </div>

    </b-sidebar>
  </section>
</template>

<script>
import * as Sentry from "@sentry/browser";
import { mapActions, mapGetters } from "vuex";
import TeamsEnroll from "@/apps/Ligas/components/Salas/TeamsEnroll";
import ModalAdicionarTime from "@/apps/Ligas/components/Times/ModalAdicionarTime";
import ModalMessageVue from "@/components/modals/ModalMessage.vue";
import WhatsMixin from "@/_mixins/whatsapp";

export default {
    name: "Cart",
    components: {
        TeamsEnroll,
        ModalAdicionarTime,
    },
    mixins: [WhatsMixin],
    data() {
        return {
            open: false,
            reduce: false,
            overlay: true,
            fullheight: true,
            fullwidth: false,
            right: true,
            canCancel: false,
            reservedEnrols: 0,
            teamsToEnroll: [],
            showMessage: {
                reservas: {show: false, msg: ""},
                times: {show: false, msg: ""},
            },
            saldo_bonus: null,
            reservation: {check: false, number: 0},
            reservedEnrols: 0,
            needSuporte: false
        };
    },
    watch: {
        cart: function(n, o) {
            let bkp = JSON.parse(JSON.stringify(n))
            if (bkp.length > 0){
                this.openCart()
            } else {
                this.closeCart()
            }
        },
        openedCart: function(n, o) {
            this.open = this.openedCart
            if (this.open){
                this.expandCart()
            } else if (this.cart.length > 0){
                this.reduceCart()
            }
        },
        reducedCart: function(n, o){
            this.reduce = this.reducedCart
            this.open = !this.reduce
            this.overlay = !this.reduce
            this.fullheight = !this.reduce
            this.updateStepCart(1)
        },
        cartStep: function(n, o){
            this.fullwidth = (this.$mq == 'sm' || this.cartStep >= 3);
        }
    },
    computed: {
        ...mapGetters([
            "wallet",
            "userId",
            "userInfo",
            "openedCart", 
            "reducedCart", 
            "cart",
            "cartStep"
        ]),
        labelLiga() {
            return this.cart.length > 1 ? 'Ligas' : 'Liga'
        },
        qtdLiga() {
            return this.cart.length
        },
        labelTime() {
            return this.teamsToEnroll.length > 1 ? 'Times' : 'Time'
        },
        qtdTime() {
            return this.teamsToEnroll.length
        },
        valorTime() {
            let total = 0
            this.cart.map(sala => {
                total += sala.valor
            })
            return total
        },
        valorTimeDesc() {
            return "R$ " + this.valorTime.toFixed(2).replace('.',',')
        },
        valorTotal() {
            let total = this.valorTime * this.teamsToEnroll.length
            return total
        },
        valorTotalDesc() {
            return "R$ " + this.valorTotal.toFixed(2).replace('.',',')
        },
        valorPagar() {
            return this.valorTotal 
        },
        qtdInsc() {
            let qtd = 0
            this.cart.map(sala => {
                qtd += sala.enroll.success.length
            })
            return qtd
        },
        valorInsc() {
            let valor = 0
            this.cart.map(sala => {
                valor += sala.enroll.success.length * sala.valor
            })
            return valor
        },
        valorInscDesc() {
            return "R$ " + this.valorInsc.toFixed(2).replace('.',',')
        }
    },
    created() {
        this.teamsToEnroll = []
    },
    methods: {
        ...mapActions([
            "openCart",
            "closeCart",
            "endCart",
            "reduceCart",
            "expandCart",
            "removeSalaFromCart",
            "updateStepCart",
            "getUser", 
            "getWallet", 
            "addTimeInRoom"
        ]),
        handlerRodada(sala) {
            if (sala.rodada_inicio === sala.rodada_fim)
                return `Rodada ${sala.rodada_inicio}`;
            else return `Rodadas ${sala.rodada_inicio}-${sala.rodada_fim}`;
        },
        viewCart() {
            this.openCart()
            this.expandCart()
        },
        selectTeams() {
            this.updateStepCart(2)
        },
        handlerTeams(teams) {
            this.teamsToEnroll = teams;
        },
        backStep() {
            this.updateStepCart(this.cartStep-1)
        },
        nextStep() {
            this.updateStepCart(this.cartStep+1)
        },
        addTeam() {
            this.$buefy.modal.open({
                parent: this,
                component: ModalAdicionarTime,
                hasModalCard: true,
                trapFocus: true,
            });
        },
        async submit() {

            let loader = this.$loading.show();
            this.loadProgress = true;

            const validation = await this.validationSubmit();
            if (!validation){
                loader.hide();
                return;
            }

            try {

                const teamsToEnrollIds = this.teamsToEnroll.map((e) => e.id_cartola);

                let responseEnroll = null
                let msgError = "";

                for (const sala of this.cart) {

                    responseEnroll = await this.addTimeInRoom({
                        ligaId: sala.liga.id_liga,
                        salaId: sala.id,
                        timeId: teamsToEnrollIds,
                        reservation: this.reservation,
                        userId: this.userId,
                        saldo_bonus: this.saldo_bonus,
                    });

                    sala.enroll = {
                        icon: '',
                        type: '',
                        msg: '',
                        error: [],
                        success: []
                    }

                    if (!responseEnroll) {
                        sala.enroll.icon = "times-circle"
                        sala.enroll.type = "danger"
                        sala.enroll.msg = "Inscrição não realizada!"
                        msgError += ((msgError !== "") ? "; " : "") + "Inscrição não realizada!";
                    }
                    if (responseEnroll.error) {
                        sala.enroll.icon = "times-circle"
                        sala.enroll.type = "danger"
                        sala.enroll.msg = responseEnroll.error
                        msgError += ((msgError !== "") ? "; " : "") + responseEnroll.error;
                    }
                    if (responseEnroll.result) {
                        
                        let error = responseEnroll.result.filter(r => r.cod == 0)
                        let success = responseEnroll.result.filter(r => r.cod == 1)

                        sala.enroll.icon = (success.length && error.length) ? "exclamation-triangle" : (success.length ? "check-square" : "times-circle")
                        sala.enroll.type = (success.length && error.length) ? "warning" : (success.length ? "success" : "danger")
                        sala.enroll.msg = (success.length && error.length) ? "Inscrição parcialmente realizada!" : (success.length ? "Inscrição realizada com sucesso!" : "Inscrição não realizada!")
                        sala.enroll.result = responseEnroll.result
                        sala.enroll.suport = (error.length)

                        if (success.length){
                            success.map(s => {
                                if (s.id_time){
                                    s.time = this.teamsToEnroll.find(t => t.id_cartola == s.id_time)
                                }
                            })
                        }
                        if (error.length){
                            error.map(e => {
                                if (e.id_time){
                                    e.time = this.teamsToEnroll.find(t => t.id_cartola == e.id_time)
                                }
                                if (msgError.indexOf(e.msg) == -1){
                                    msgError += (msgError !== "" ? "; " : "") + e.msg;
                                }
                            })
                        }
                        sala.enroll.error = error
                        sala.enroll.success = success
                    }
                }

                // Atualizando os saldos da carteira
                await this.getWallet(this.userId);

                this.needSuporte = (msgError !== "");
                this.updateStepCart(4)
                this.loadProgress = false;
                loader.hide();
                this.$emit("done");

            } catch (err) {
                console.log(err)
                this.$toasted.error(
                    "Houve um problema ao realizar sua inscrição, tente mais tarde!",
                    {
                        duration: 3000,
                        position: "top-center",
                    }
                );

                // Analytics de erro em ligas
                Sentry.captureException(err);

                this.loadProgress = false;
                this.closeCart();
                loader.hide();
            }
        },

        async validationSubmit() {
            try {
                if (this.reservation.check){
                    if (this.reservation.number === 0) {
                        this.$toasted.error("Quantidade de inscrições para reserva não informada!", {
                            duration: 3000,
                            position: "top-center",
                        });
                        return false;
                    }
                } else {
                    if (this.teamsToEnroll.length === 0) {
                        this.$toasted.error("Nenhum time selecionado!", {
                            duration: 3000,
                            position: "top-center",
                        });
                        return false;
                    }
                }
                // validando o ultimo valor da carteira
                await this.getWallet(this.userId);

                if (this.valorPagar == 0) {
                    this.saldo_bonus = 0
                } else if (this.wallet.saldo_bonus > 0 && this.saldo_bonus === null) {
                    this.$toasted.error(
                        "Responda se deseja ou não utilizar seu bônus nessa inscrição!",
                        {
                        duration: 5000,
                        position: "top-center",
                        }
                    );
                    return false;
                }

                const saldo = this.wallet.saldo_wallet;
                const saldo_bonus = this.wallet.saldo_bonus;
                const newSaldo = (saldo + saldo_bonus) - this.valorPagar

                if (newSaldo < 0) {
                    this.$buefy.modal.open({
                        parent: this,
                        component: ModalMessageVue,
                        hasModalCard: true,
                        trapFocus: true,
                        props: {
                        message: `<p>Fala, Cartoleiro!</p><p>Sua carteira virtual não possui saldo suficiente para efetuar a inscrição nesta liga.</p><p>Deseja incluir créditos para possibilitar sua inscrição?</p>`,
                        buttonConfirmText: "Sim",
                        title: "Saldo Insuficiente",
                        hasBody: false,
                        },
                        events: {
                            submit: () => {
                                this.$parent.close();
                                this.redirectToCheckout();
                            },
                        },
                    });
                    return false;
                }
                return true;
            } catch (err) {
                this.$toasted.error("Não foi possível realizar sua inscrição", {
                    position: "top-center",
                    duration: 4000,
                });
                return false;
            }
        },

        callSuport(sala){
            this.sendMessageToZap({
                text: sala ? `Olá! Eu sou ${this.userInfo.nome} (${
                    this.userInfo.email
                }). Tive problemas na inscrição da liga: ${
                    sala.liga.tipo
                } do ${sala.influencer.nome_midia} ${
                    sala.liga.apuracao
                } ${sala.capitao ? "SEM CAPITÃO" : "COM CAPITÃO"} (ID ${
                    sala.id
                })` : `Olá! Eu sou ${this.userInfo.nome} (${
                    this.userInfo.email
                }). Tive problemas na inscrição em ligas.`,
            });
        },

        notifyError({msgError, sala}) {

            this.$buefy.modal.open({
                parent: this,
                component: ModalMessageVue,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    message: msgError ? 
                    `<p>${msgError}</p>` : 
                    `<p>Ocorreu um problema na inscrição dos times na Liga.</p><p>Tente novamente ou entre em contato com o suporte.</p>`,
                    buttonConfirmText: "Falar com o suporte",
                    title: "Inscrição não realizada!",
                    hasBody: false,
                },
                events: {
                    submit: () => {
                        this.sendMessageToZap({
                            text: sala ? `Olá! Eu sou ${this.userInfo.nome} (${
                                this.userInfo.email
                            }). Tive problemas na inscrição da liga: ${
                                sala.liga.tipo
                            } do ${sala.influencer.nome_midia} ${
                                sala.liga.apuracao
                            } ${sala.capitao ? "SEM CAPITÃO" : "COM CAPITÃO"} (ID ${
                                sala.id
                            })` : `Olá! Eu sou ${this.userInfo.nome} (${
                                this.userInfo.email
                            }). Tive problemas na inscrição em ligas.`,
                        });
                    },
                },
            });
        },
    }
};
</script>

