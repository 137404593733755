<template>
  <section id="TeamsDetails" class="has-background-bluedark">
    <div
      v-if="renderFilter && rodadas.length > 0 && rodadaInicio !== rodadaFim"
    >
      <div class="columns is-multiline is-mobile" v-if="$mq == 'sm'">
        <div class="column is-narrow">
          <div class="atl-legend" @click="showFilters = !showFilters">
            Rodada {{ selectRodada }}
            <b-icon
              pack="fas"
              :icon="showFilters ? 'chevron-up' : 'chevron-down'"
              size="is-small"
            ></b-icon>
          </div>
          <div class="rodadas-mobile" v-if="showFilters">
            <div
              v-for="(el, index) of rodadas"
              :key="index"
              :class="{
                'rodada-mobile': true,
                'rodada-selected': el == selectRodada,
              }"
              @click="loadAgain(el)"
            >
              {{ el }}
            </div>
          </div>
        </div>
        <div class="column">
          <div style="float: right;">
            <b-tag rounded type="is-bluedark" class="tag-total-rodada">
              TOTAL
              {{
                (apuracaoId == 2 || apuracaoId == 4 ? "C$ " : "") +
                  (apuracaoId == 3
                    ? totalRodada.toFixed(0)
                    : totalRodada.toFixed(2).replace(".", ","))
              }}
              <small class="subtitle" v-if="isGame">
                <br />Desempate
                {{
                  apuracaoId == 3
                    ? desempateRodada.toFixed(0)
                    : desempateRodada.toFixed(2).replace(".", ",")
                }}
              </small>
            </b-tag>
          </div>
        </div>
      </div>
      <div class="columns is-multiline is-mobile" v-else>
        <div class="column is-auto">
          <div class="rodadas">
            <p class="rodada-title">Escolha uma Rodada</p>
            <div
              v-for="(el, index) of rodadas"
              :key="index"
              :class="{ rodada: true, 'rodada-selected': el == selectRodada }"
              @click="loadAgain(el)"
            >
              {{ el }}
            </div>
          </div>
        </div>
        <div class="column is-narrow">
          <div style="margin-top: 1em;">
            <b-tag
              rounded
              size="is-large"
              type="is-bluedark"
              class="tag-total-rodada"
            >
              TOTAL
              {{
                (apuracaoId == 2 || apuracaoId == 4 ? "C$ " : "") +
                  (apuracaoId == 3
                    ? totalRodada.toFixed(0)
                    : totalRodada.toFixed(2).replace(".", ","))
              }}
              <small class="subtitle" v-if="isGame">
                <br />Desempate
                {{
                  apuracaoId == 3
                    ? desempateRodada.toFixed(0)
                    : desempateRodada.toFixed(2).replace(".", ",")
                }}
              </small>
            </b-tag>
          </div>
        </div>
      </div>
    </div>
    <div v-if="renderFilter && rodadas.length > 0 && rodadaInicio == rodadaFim">
      <div class="columns is-multiline is-mobile" v-if="$mq == 'sm'">
        <div class="column is-auto">
          <div class="rodadas-mobile">
            <span class="rodada-title"> Rodada </span>
            <div
              class="rodada-mobile rodada-selected"
              @click="loadAgain(rodadaInicio)"
            >
              {{ rodadaInicio }}
            </div>
            <div style="float: right;">
              <b-tag
                rounded
                type="is-bluedark"
                size="is-small"
                class="tag-total-rodada"
              >
                TOTAL
                {{
                  (apuracaoId == 2 || apuracaoId == 4 ? "C$ " : "") +
                    (apuracaoId == 3
                      ? totalRodada.toFixed(0)
                      : totalRodada.toFixed(2).replace(".", ","))
                }}
                <small class="subtitle is-size-7" v-if="isGame">
                  <br />Desempate
                  {{
                    apuracaoId == 3
                      ? desempateRodada.toFixed(0)
                      : desempateRodada.toFixed(2).replace(".", ",")
                  }}
                </small>
              </b-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline is-mobile" v-else>
        <div class="column is-auto">
          <div class="rodadas">
            <span class="rodada-title"> Rodada </span>
            <div
              class="rodada rodada-selected"
              @click="loadAgain(rodadaInicio)"
            >
              {{ rodadaInicio }}
            </div>
          </div>
        </div>
        <div class="column is-narrow">
          <div>
            <b-tag
              rounded
              size="is-large"
              type="is-bluedark"
              class="tag-total-rodada"
            >
              TOTAL
              {{
                (apuracaoId == 2 || apuracaoId == 4 ? "C$ " : "") +
                  (apuracaoId == 3
                    ? totalRodada.toFixed(0)
                    : totalRodada.toFixed(2).replace(".", ","))
              }}
              <small class="subtitle" v-if="isGame">
                <br />Desempate
                {{
                  apuracaoId == 3
                    ? desempateRodada.toFixed(0)
                    : desempateRodada.toFixed(2).replace(".", ",")
                }}
              </small>
            </b-tag>
          </div>
        </div>
      </div>
    </div>
    <div v-if="render && rodadas.length > 0">
      <div
        v-if="!hasntTeam && time"
        class="columns is-multiline is-gapless is-mobile"
      >
        <div
          class="column is-full has-background-bluedark2"
          :style="'margin: 0.25em 0 !important; padding: 0.5em !important;'"
          v-for="jogador of time.atletas"
          :key="jogador.atleta_id"
        >
          <div
            class="columns is-gapless is-mobile"
            :class="{ 'nao-jogou': !jogador.jogou }"
          >
            <div
              class="column foto-jogador-lg is-vcentered"
              :class="{ 'is-1': $mq !== 'sm', 'is-2': $mq == 'sm' }"
            >
              <img
                v-if="jogador.foto != null"
                :src="jogador.foto"
                class="img-jogador-lg"
              />
              <i v-else class="fas fa-user-shield"></i>
            </div>

            <div class="column is-1">
              <div class="columns is-multiline is-gapless">
                <div
                  class="column is-full centralize"
                  v-if="
                    sala.capitao == 1 && time.capitao_id == jogador.atleta_id
                  "
                >
                  <i
                    v-tooltip.top="{
                      content: 'Capitão',
                      classes: 'home-tooltip',
                    }"
                    class="fas fa-copyright"
                    :style="{
                      'font-size':
                        $mq == 'sm' ? '15px !important' : '20px !important',
                    }"
                    style="padding: 3px; color: rgb(243, 247, 41)"
                  ></i>
                </div>
                <div class="column is-full centralize">
                  <img
                    v-if="jogador.clube.escudo != null"
                    :src="jogador.clube.escudo"
                    class="img-escudo-lg"
                  />
                  <i v-else class="fas fa-shield-alt"></i>
                  <!-- <p class="abreviacao-lg">{{ jogador.clube.abreviacao }}</p> -->
                </div>

                <div
                  v-if="jogador.reserva"
                  class="column is-full centralize icone-substituicao"
                >
                  <b-icon
                    pack="fas"
                    icon="arrow-alt-circle-up"
                    type="is-success"
                  >
                  </b-icon>
                </div>
              </div>
            </div>

            <div
              class="column"
              :class="{ 'is-4': $mq !== 'sm', 'is-6': $mq == 'sm' }"
            >
              <div class="columns is-multiline is-mobile is-gapless">
                <div
                  class="column is-full"
                  :style="{ 'line-height': $mq == 'sm' ? '0.8' : '1.2' }"
                >
                  <span class="nome_lg">
                    {{ jogador.apelido ? jogador.apelido : "-" }}
                  </span>
                </div>
                <div
                  class="column is-full"
                  :style="{ 'line-height': $mq == 'sm' ? '0.8' : '1.2' }"
                >
                  <span class="pos-lg">{{ atletaPosicao(jogador) }}</span>
                </div>
                <div
                  v-if="$mq == 'sm'"
                  class="column is-full"
                  style="font-size: 1rem !important;"
                >
                  <div
                    v-if="!isGame"
                    class="columns is-multiline is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div class="column is-full">
                      <div
                        class="columns is-mobile is-gapless"
                        style="margin: 0 !important;"
                      >
                        <div
                          class="column is-narrow"
                          v-for="scout of jogador.scout"
                          v-show="scout.fl_positivo == 1 && scout['ponto'] > 0"
                          :key="scout.cd_scout"
                        >
                          <!-- v-show="scout.fl_positivo == 1 && scout[apuracaoId == 3 ?'ponto':'media'] > 0" -->
                          <p class="has-text-success scout">
                            <!-- {{apuracaoId == 3 ? scout['ponto'].toFixed(0) : scout['media'].toFixed(2) }} {{scout.cd_scout}} -->
                            {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                          </p>
                        </div>
                        <div
                          class="column is-narrow"
                          v-for="scout of jogador.scout"
                          v-show="scout.fl_positivo == 0 && scout['ponto'] > 0"
                          :key="scout.cd_scout"
                        >
                          <!-- v-show="scout.fl_positivo == 0 && scout[apuracaoId == 3 ?'ponto':'media'] > 0" -->
                          <p class="has-text-danger scout">
                            <!-- {{apuracaoId == 3 ? scout['ponto'].toFixed(0) : scout['media'].toFixed(2) }} {{scout.cd_scout}} -->
                            {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="columns is-multiline is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div class="column is-full">
                      <div
                        class="columns is-mobile is-gapless"
                        style="margin: 0 !important;"
                      >
                        <div
                          class="column is-narrow"
                          v-for="scout of jogador.scout"
                          v-show="scout['ponto'] > 0"
                          :key="scout.cd_scout"
                        >
                          <p class="has-text-primary scout">
                            {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                          </p>
                        </div>
                        <div
                          class="column is-narrow"
                          v-for="scout of jogador.scoutDesempate"
                          v-show="scout['ponto'] > 0"
                          :key="scout.cd_scout"
                        >
                          <p class="has-text-white scout">
                            {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="$mq !== 'sm'" class="column is-4">
              <div
                v-if="!isGame"
                class="columns is-multiline is-mobile is-gapless"
                style="margin: 0 !important;"
              >
                <div class="column is-full">
                  <div
                    class="columns is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div
                      class="column is-narrow"
                      v-for="scout of jogador.scout"
                      v-show="scout.fl_positivo == 1 && scout['ponto'] > 0"
                      :key="scout.cd_scout"
                    >
                      <!-- v-show="scout.fl_positivo == 1 && scout[apuracaoId == 3 ?'ponto':'media'] > 0" -->
                      <p
                        class="is-size-7 has-text-success"
                        :style="{ 'margin-right': '5px' }"
                      >
                        <!-- {{apuracaoId == 3 ? scout['ponto'].toFixed(0) : scout['media'].toFixed(2) }} {{scout.cd_scout}} -->
                        {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="column is-full">
                  <div
                    class="columns is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div
                      class="column is-narrow"
                      v-for="scout of jogador.scout"
                      v-show="scout.fl_positivo == 0 && scout['ponto'] > 0"
                      :key="scout.cd_scout"
                    >
                      <!-- v-show="scout.fl_positivo == 0 && scout[apuracaoId == 3 ?'ponto':'media'] > 0" -->
                      <p
                        class="is-size-7 has-text-danger"
                        :style="{ 'margin-right': '5px' }"
                      >
                        <!-- {{apuracaoId == 3 ? scout['ponto'].toFixed(0) : scout['media'].toFixed(2) }} {{scout.cd_scout}} -->
                        {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="columns is-multiline is-mobile is-gapless"
                style="margin: 0 !important;"
              >
                <div class="column is-full">
                  <div
                    class="columns is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div
                      class="column is-narrow"
                      v-for="scout of jogador.scout"
                      v-show="scout['ponto'] > 0"
                      :key="scout.cd_scout"
                    >
                      <p
                        class="is-size-7 has-text-primary"
                        :style="{ 'margin-right': '5px' }"
                      >
                        {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="column is-full">
                  <div
                    class="columns is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div
                      class="column is-narrow"
                      v-for="scout of jogador.scoutDesempate"
                      v-show="scout['ponto'] > 0"
                      :key="scout.cd_scout"
                    >
                      <p
                        class="is-size-7 has-text-white"
                        :style="{ 'margin-right': '5px' }"
                      >
                        {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column">
              <div
                class="has-text-right"
                v-if="
                  jogador.variacao_num && (apuracaoId == 2 || apuracaoId == 4)
                "
              >
                <p
                  class="pont-lg"
                  :style="{
                    color: isGame
                      ? 'white'
                      : jogador.variacao_num > 0
                      ? '#36c947'
                      : '#ef3737',
                  }"
                >
                  C$ {{ jogador.variacao_num.toFixed(2) }}
                </p>
              </div>
              <div
                class="has-text-right"
                v-else-if="
                  jogador.pontos_num && !(apuracaoId == 2 || apuracaoId == 4)
                "
              >
                <p
                  class="pont-lg"
                  :style="{
                    color: isGame
                      ? 'white'
                      : jogador.pontos_num > 0
                      ? '#36c947'
                      : '#ef3737',
                  }"
                >
                  {{
                    apuracaoId == 3
                      ? jogador.pontos_num.toFixed(0)
                      : jogador.pontos_num.toFixed(2).replace(".", ",")
                  }}
                  <small v-if="isGame" class="is-size-7 subtitle"
                    ><br />{{ apuracaoId == 3 ? "scouts" : "pontos" }}</small
                  >
                  <b-tag
                    v-if="
                      sala.capitao == 1 && time.capitao_id == jogador.atleta_id
                    "
                    rounded
                    :type="getInfoAboutCap(jogador).type"
                    :size="$mq == 'sm' ? 'is-small' : ''"
                    :style="
                      $mq == 'sm'
                        ? 'font-size: 0.7rem !important; height: 1.5em !important;'
                        : ''
                    "
                    >2x <b>{{ jogador.pontos_num / 2 }}</b>
                  </b-tag>
                </p>
              </div>
              <div v-else>
                <p class="pont-lg">-</p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="time.reservas.length > 0" class="column is-full centralize">
          <span class="title">BANCO DE RESERVA</span>
        </div>
        <div
          class="column is-full has-background-bluedark2"
          :style="'margin: 0.25em 0 !important; padding: 0.5em !important;'"
          v-for="jogador of time.reservas"
          :key="jogador.atleta_id"
        >
          <div
            class="columns is-gapless is-mobile"
            :class="{ 'nao-jogou': !jogador.jogou }"
          >
            <div
              class="column foto-jogador-lg is-vcentered"
              :class="{ 'is-1': $mq !== 'sm', 'is-2': $mq == 'sm' }"
            >
              <img
                v-if="jogador.foto != null"
                :src="jogador.foto"
                class="img-jogador-lg"
              />
              <i v-else class="fas fa-user-shield"></i>
            </div>

            <div class="column is-1">
              <div class="columns is-multiline is-gapless">
                <div
                  class="column is-full centralize"
                  v-if="
                    sala.capitao == 1 && time.capitao_id == jogador.atleta_id
                  "
                >
                  <i
                    v-tooltip.top="{
                      content: 'Capitão',
                      classes: 'home-tooltip',
                    }"
                    class="fas fa-copyright"
                    :style="{
                      'font-size':
                        $mq == 'sm' ? '15px !important' : '20px !important',
                    }"
                    style="padding: 3px; color: rgb(243, 247, 41)"
                  ></i>
                </div>
                <div class="column is-full centralize">
                  <img
                    v-if="jogador.clube.escudo != null"
                    :src="jogador.clube.escudo"
                    class="img-escudo-lg"
                  />
                  <i v-else class="fas fa-shield-alt"></i>
                  <!-- <p class="abreviacao-lg">{{ jogador.clube.abreviacao }}</p> -->
                </div>

                <div
                  v-if="jogador.reserva"
                  class="column is-full centralize icone-substituicao"
                >
                  <b-icon
                    pack="fas"
                    icon="arrow-alt-circle-down"
                    type="is-danger"
                  >
                  </b-icon>
                </div>
              </div>
            </div>

            <div
              class="column"
              :class="{ 'is-4': $mq !== 'sm', 'is-6': $mq == 'sm' }"
            >
              <div class="columns is-multiline is-mobile is-gapless">
                <div
                  class="column is-full"
                  :style="{ 'line-height': $mq == 'sm' ? '0.8' : '1.2' }"
                >
                  <span class="nome_lg">
                    {{ jogador.apelido ? jogador.apelido : "-" }}
                  </span>
                </div>
                <div
                  class="column is-full"
                  :style="{ 'line-height': $mq == 'sm' ? '0.8' : '1.2' }"
                >
                  <span class="pos-lg">{{ atletaPosicao(jogador) }}</span>
                </div>
                <div
                  v-if="$mq == 'sm'"
                  class="column is-full"
                  style="font-size: 1rem !important;"
                >
                  <div
                    v-if="!isGame"
                    class="columns is-multiline is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div class="column is-full">
                      <div
                        class="columns is-mobile is-gapless"
                        style="margin: 0 !important;"
                      >
                        <div
                          class="column is-narrow"
                          v-for="scout of jogador.scout"
                          v-show="scout.fl_positivo == 1 && scout['ponto'] > 0"
                          :key="scout.cd_scout"
                        >
                          <!-- v-show="scout.fl_positivo == 1 && scout[apuracaoId == 3 ?'ponto':'media'] > 0" -->
                          <p class="has-text-success scout">
                            <!-- {{apuracaoId == 3 ? scout['ponto'].toFixed(0) : scout['media'].toFixed(2) }} {{scout.cd_scout}} -->
                            {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                          </p>
                        </div>
                        <div
                          class="column is-narrow"
                          v-for="scout of jogador.scout"
                          v-show="scout.fl_positivo == 0 && scout['ponto'] > 0"
                          :key="scout.cd_scout"
                        >
                          <!-- v-show="scout.fl_positivo == 0 && scout[apuracaoId == 3 ?'ponto':'media'] > 0" -->
                          <p class="has-text-danger scout">
                            <!-- {{apuracaoId == 3 ? scout['ponto'].toFixed(0) : scout['media'].toFixed(2) }} {{scout.cd_scout}} -->
                            {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="columns is-multiline is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div class="column is-full">
                      <div
                        class="columns is-mobile is-gapless"
                        style="margin: 0 !important;"
                      >
                        <div
                          class="column is-narrow"
                          v-for="scout of jogador.scout"
                          v-show="scout['ponto'] > 0"
                          :key="scout.cd_scout"
                        >
                          <p class="has-text-primary scout">
                            {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                          </p>
                        </div>
                        <div
                          class="column is-narrow"
                          v-for="scout of jogador.scoutDesempate"
                          v-show="scout['ponto'] > 0"
                          :key="scout.cd_scout"
                        >
                          <p class="has-text-white scout">
                            {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="$mq !== 'sm'" class="column is-4">
              <div
                v-if="!isGame"
                class="columns is-multiline is-mobile is-gapless"
                style="margin: 0 !important;"
              >
                <div class="column is-full">
                  <div
                    class="columns is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div
                      class="column is-narrow"
                      v-for="scout of jogador.scout"
                      v-show="scout.fl_positivo == 1 && scout['ponto'] > 0"
                      :key="scout.cd_scout"
                    >
                      <!-- v-show="scout.fl_positivo == 1 && scout[apuracaoId == 3 ?'ponto':'media'] > 0" -->
                      <p
                        class="is-size-7 has-text-success"
                        :style="{ 'margin-right': '5px' }"
                      >
                        <!-- {{apuracaoId == 3 ? scout['ponto'].toFixed(0) : scout['media'].toFixed(2) }} {{scout.cd_scout}} -->
                        {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="column is-full">
                  <div
                    class="columns is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div
                      class="column is-narrow"
                      v-for="scout of jogador.scout"
                      v-show="scout.fl_positivo == 0 && scout['ponto'] > 0"
                      :key="scout.cd_scout"
                    >
                      <!-- v-show="scout.fl_positivo == 0 && scout[apuracaoId == 3 ?'ponto':'media'] > 0" -->
                      <p
                        class="is-size-7 has-text-danger"
                        :style="{ 'margin-right': '5px' }"
                      >
                        <!-- {{apuracaoId == 3 ? scout['ponto'].toFixed(0) : scout['media'].toFixed(2) }} {{scout.cd_scout}} -->
                        {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="columns is-multiline is-mobile is-gapless"
                style="margin: 0 !important;"
              >
                <div class="column is-full">
                  <div
                    class="columns is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div
                      class="column is-narrow"
                      v-for="scout of jogador.scout"
                      v-show="scout['ponto'] > 0"
                      :key="scout.cd_scout"
                    >
                      <p
                        class="is-size-7 has-text-primary"
                        :style="{ 'margin-right': '5px' }"
                      >
                        {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="column is-full">
                  <div
                    class="columns is-mobile is-gapless"
                    style="margin: 0 !important;"
                  >
                    <div
                      class="column is-narrow"
                      v-for="scout of jogador.scoutDesempate"
                      v-show="scout['ponto'] > 0"
                      :key="scout.cd_scout"
                    >
                      <p
                        class="is-size-7 has-text-white"
                        :style="{ 'margin-right': '5px' }"
                      >
                        {{ scout["ponto"].toFixed(0) }} {{ scout.cd_scout }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column">
              <div
                class="has-text-right"
                v-if="
                  jogador.variacao_num && (apuracaoId == 2 || apuracaoId == 4)
                "
              >
                <p
                  class="pont-lg"
                  :style="{
                    color: isGame
                      ? 'white'
                      : jogador.variacao_num > 0
                      ? '#36c947'
                      : '#ef3737',
                  }"
                >
                  C$ {{ jogador.variacao_num.toFixed(2) }}
                </p>
              </div>
              <div
                class="has-text-right"
                v-else-if="
                  jogador.pontos_num && !(apuracaoId == 2 || apuracaoId == 4)
                "
              >
                <p
                  class="pont-lg"
                  :style="{
                    color: isGame
                      ? 'white'
                      : jogador.pontos_num > 0
                      ? '#36c947'
                      : '#ef3737',
                  }"
                >
                  {{
                    apuracaoId == 3
                      ? jogador.pontos_num.toFixed(0)
                      : jogador.pontos_num.toFixed(2).replace(".", ",")
                  }}
                  <small v-if="isGame" class="is-size-7">{{
                    apuracaoId == 3 ? "scouts" : "pontos"
                  }}</small>
                  <b-tag
                    v-if="
                      sala.capitao == 1 && time.capitao_id == jogador.atleta_id
                    "
                    rounded
                    :type="getInfoAboutCap(jogador).type"
                    :size="$mq == 'sm' ? 'is-small' : ''"
                    :style="
                      $mq == 'sm'
                        ? 'font-size: 0.7rem !important; height: 1.5em !important;'
                        : ''
                    "
                    >2x <b>{{ jogador.pontos_num / 2 }}</b>
                  </b-tag>
                </p>
              </div>
              <div v-else>
                <p class="pont-lg">-</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="bg-hasnt-team has-background-bluedark2">
        <div class="content has-text-grey has-text-centered">
          <p><b-icon icon="emoticon-sad" size="is-large"> </b-icon></p>
          <p>
            O Mercado ainda está aberto e as escalações dos times estão
            indisponíveis!
          </p>
          <p>
            Aguarde o fechamento do mercado para espiar os times dos outros
            parças!
          </p>
        </div>
      </div>
    </div>
    <div v-else class="bg-hasnt-team has-background-bluedark2">
      <div class="content has-text-grey has-text-centered">
        <p><b-icon icon="emoticon-sad" size="is-large"> </b-icon></p>
        <p>
          Somente será possível visualizar a escalação das rodadas anteriores a
          rodada atual!
        </p>
        <p>Rodada Atual: {{ mercado.rodada_atual }}</p>
        <p>
          {{
            rodadaInicio !== rodadaFim
              ? "Rodadas da Liga: de " + rodadaInicio + " até " + rodadaFim
              : "Rodada da Liga: " + rodadaInicio
          }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TeamsDetails",
  props: {
    sala: {
      type: Object,
      required: true,
      default: () => {},
    },
    timeId: {
      type: Number,
      required: true,
      default: () => 0,
    },
    rodadaInicio: {
      type: Number,
      required: true,
      default: () => 0,
    },
    rodadaFim: {
      type: Number,
      required: true,
      default: () => 0,
    },
    isParcial: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  data() {
    return {
      time: {},
      hasntTeam: false,
      render: false,
      renderFilter: false,
      showFilters: false,
      rodadas: [],
      selectRodada: 0,
      totalRodada: 0,
      desempateRodada: 0,
    };
  },
  async created() {
    await this.getMercado();
    let loader = this.$loading.show();

    this.renderFilter = false;
    if (
      this.mercado.rodada_atual >= this.rodadaInicio &&
      this.mercado.rodada_atual <= this.rodadaFim
    ) {
      if (this.mercado.status_mercado == 1 && this.mercado.rodada_atual > 1) {
        this.selectRodada = this.mercado.rodada_atual - 1;
      } else {
        this.selectRodada = this.mercado.rodada_atual;
      }
    } else if (this.mercado.rodada_atual < this.rodadaInicio) {
      this.selectRodada = this.rodadaInicio;
    } else if (this.mercado.rodada_atual > this.rodadaFim) {
      this.selectRodada = this.rodadaFim;
    }
    for (let index = this.rodadaInicio; index <= this.rodadaFim; index++) {
      if (index <= this.mercado.rodada_atual) {
        this.rodadas.push(index);
      }
    }
    this.renderFilter = true;

    if (this.rodadas.indexOf(this.selectRodada) == -1) {
      this.selectRodada = 0;
    }

    this.loadAgain(this.selectRodada);

    loader.hide();
  },
  computed: {
    ...mapGetters(["mercado"]),
    salaLiga() {
      return this.sala.liga ? this.sala.liga : this.sala.game;
    },
    idLiga() {
      return this.sala.liga ? this.sala.liga.id_liga : this.sala.game.id_game;
    },
    isGame() {
      return this.sala.game;
    },
    apuracaoId() {
      return this.salaLiga.id_tipo_apuracao;
    },
  },
  methods: {
    ...mapActions(["getTeamDetails", "getGameTeamDetails", "getMercado"]),
    atletaPosicao(jogador) {
      return jogador.posicao
        ? jogador.posicao
        : jogador.posicao_nm
        ? jogador.posicao_nm
        : "-";
    },
    getInfoAboutCap(jogador) {
      return {
        type: jogador.pontos_num > 0 ? "is-success" : "is-danger",
      };
    },
    async loadAgain(rodada) {
      let loader = this.$loading.show();

      this.selectRodada = rodada;
      this.totalRodada = 0;
      this.desempateRodada = 0;
      this.render = false;

      let params = {};
      let time = null;

      if (this.isGame) {
        params = {
          gameId: this.idLiga,
          salaId: this.sala.id,
          timeId: this.timeId,
          rodada: this.selectRodada,
          isParcial: this.isParcial,
        };
        time = await this.getGameTeamDetails(params);
      } else {
        params = {
          timeId: this.timeId,
          rodada: this.selectRodada,
          apuracao: this.apuracaoId,
        };
        time = await this.getTeamDetails(params);
      }

      if (!time || time.status == "error") {
        // this.$toasted.show('Não conseguimos buscar a escalação, tente outra rodada!', {
        //   position: 'top-center',
        //   duration: 3500,
        //   type: 'error',
        //   className: 'toasted-class'
        // })
      } else {
        if (time.atletas.length == 0) {
          // this.$toasted.show('Escalação não encontrada, tente outra rodada!', {
          //   position: 'top-center',
          //   duration: 3000,
          //   type: 'error',
          //   className: 'toasted-class'
          // })

          params.rodada = this.selectRodada;

          time = await this.getTeamDetails(params);
        }
        this.hasntTeam = false;

        time.atletas.map((a) => {
          a.pontos_num =
            this.sala.capitao == 1 && time.capitao_id == a.atleta_id
              ? a.pontos_num * 2
              : a.pontos_num;
          this.totalRodada +=
            this.apuracaoId == 2 || this.apuracaoId == 4
              ? a.variacao_num
              : a.pontos_num
              ? a.pontos_num
              : 0;
          if (a.pontos_des) {
            this.desempateRodada += a.pontos_des;
          }
        });
      }

      this.time = time;
      this.render = true;
      loader.hide();
    },
  },
};
</script>

<style scoped>
@media (min-width: 320px) and (max-width: 1024px) {
  .block-content-lg {
    height: auto;
    padding: 8px;
  }
  .pont-lg {
    font-size: 1em !important;
    line-height: 1.3 !important;
  }
  .foto-jogador-lg i {
    font-size: 3em !important;
  }
  .pos-lg {
    font-size: 0.6em !important;
  }
  .nome_lg {
    font-size: 0.6em !important;
  }
  .img-jogador-lg {
    /* width: 30px !important; */
    height: 35px !important;
  }
  .abreviacao-lg {
    font-size: 0.6em !important;
  }
  .img-escudo-lg {
    height: 15px !important;
  }
  .is-cap-object {
    border-width: 15px !important;
  }
  .icone-substituicao {
    transform: scale(0.6) translate(-120%, 30%) !important;
  }
  .scout {
    font-size: 0.6em !important;
  }
  .tag-total-rodada {
    padding: 1.3em 1em !important;
  }
}

.pos-back-arrow {
  position: absolute;
  top: 1em;
  left: 1em;
}
.block-content-lg {
  height: auto;
  margin: 2.5px 0;
  background-color: #4d4d4d;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  padding: 10px;
  position: relative;
}
.title-23 {
  color: rgb(10, 10, 10);
  font-weight: bold;
  font-size: 1.5em;
}
.pont-lg {
  text-align: right;
  font-weight: bold;
  font-size: 1.3em;
  color: rgb(255, 255, 255);
  line-height: 1;
}
.foto-jogador-lg i {
  font-size: 3.2em;
  color: rgb(255, 255, 255);
}
.pos-lg {
  font-size: 0.8em;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}
.abreviacao-lg {
  font-size: 0.8em;
  margin-top: 3px;
  color: rgb(209, 209, 209);
  font-weight: bolder;
  text-transform: uppercase;
}
.nome_lg {
  font-size: 0.8em;
  color: rgb(255, 255, 255);
  font-weight: bolder;
  text-transform: uppercase;
}
.img-jogador-lg {
  /* width: 50px; */
  height: 50px;
  /* background-color: white;
  border-radius: 50%;
  border: 2px solid rgb(185, 185, 185); */
}
.img-escudo-lg {
  height: 25px;
}
.is-cap {
  border: 2px solid rgb(226, 155, 0);
}
.is-cap-object {
  width: 0px;
  height: 0px;
  /* position: absolute; */
  top: 0;
  left: 0;
  line-height: 0;
  border-style: solid;
  border-width: 22px;
  /* margin: 1.3em 0 1.8em; */
  border-color: rgb(226, 155, 0) transparent transparent rgb(226, 155, 0);
}
.bg-hasnt-team {
  background-color: #4d4d4d;
  border-radius: 7px;
}

#TeamsDetails .atl-legend {
  width: 100%;
  padding: 5px 10px;
  font-size: 0.9em;
}

#TeamsDetails .rodadas {
  width: -webkit-fill-available;
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 15px;
}
#TeamsDetails .rodadas-mobile {
  width: -webkit-fill-available;
  font-size: 14px;
  font-weight: bold;
  padding: 0px;
}
#TeamsDetails .rodada {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  width: 30px;
  color: #00fffd;
  text-align: center;
  border: 1px solid #00fffd;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
}
#TeamsDetails .rodada-mobile {
  display: inline-block;
  margin: 5px;
  padding: 3px;
  width: 25px;
  color: #00fffd;
  text-align: center;
  border: 1px solid #00fffd;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
}
#TeamsDetails .rodada-total {
  display: inline-block;
}
#TeamsDetails .rodada-title {
  font-size: 1rem;
}
#TeamsDetails .rodada-selected {
  color: #000;
  border: 1px solid rgba(0, 255, 204, 1);
  box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  background-color: #00fffd;
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  vertical-align: ;
}

.nao-jogou {
  opacity: 0.5;
}
.icone-substituicao {
  width: fit-content;
  transform: scale(0.8) translate(-80%, 25%);
  border-radius: 100%;
  background-color: #fff;
}
.scout {
  font-size: 0.9em;
  margin-right: 3px;
}
.tag-total-rodada {
  text-align: center;
  border: 1px solid white;
  line-height: 0.7;
  padding: 1.2em 1em;
}
</style>
