var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.renderBanks)?_c('section',{attrs:{"id":"Banks"}},[_c('div',{staticClass:"columns is-multiline is-mobile is-centered is-vcentered bank-content has-background-bluedark"},[(_vm.banks.length == 0 || _vm.banks.indexOf('001') !== -1)?_c('div',{staticClass:"column is-centered is-vcentered bank-logo",class:("is-" + _vm.nColumns + " " + (!_vm.selectedBank.id
            ? ''
            : _vm.selectedBank.id == '001'
            ? 'selectedBank'
            : 'notSelectedBank')),on:{"click":function($event){return _vm.selectBank('001', 'Banco do Brasil')}}},[_c('img',{staticClass:"bankLogo",attrs:{"src":require("@/assets/bancos/001.png"),"alt":"Banco do Brasil"}})]):_vm._e(),(_vm.banks.length == 0 || _vm.banks.indexOf('033') !== -1)?_c('div',{staticClass:"column is-centered is-vcentered bank-logo",class:("is-" + _vm.nColumns + " " + (!_vm.selectedBank.id
            ? ''
            : _vm.selectedBank.id == '033'
            ? 'selectedBank'
            : 'notSelectedBank')),on:{"click":function($event){return _vm.selectBank('033', 'Banco Santander')}}},[_c('img',{staticClass:"bankLogo",attrs:{"src":require("@/assets/bancos/033.png"),"alt":"Banco Santander"}})]):_vm._e(),(_vm.banks.length == 0 || _vm.banks.indexOf('077') !== -1)?_c('div',{staticClass:"column is-centered is-vcentered bank-logo",class:("is-" + _vm.nColumns + " " + (!_vm.selectedBank.id
            ? ''
            : _vm.selectedBank.id == '077'
            ? 'selectedBank'
            : 'notSelectedBank')),on:{"click":function($event){return _vm.selectBank('077', 'Banco Inter')}}},[_c('img',{staticClass:"bankLogo",attrs:{"src":require("@/assets/bancos/077.png"),"alt":"Banco Inter"}})]):_vm._e(),(_vm.banks.length == 0 || _vm.banks.indexOf('104') !== -1)?_c('div',{staticClass:"column is-centered is-vcentered bank-logo",class:("is-" + _vm.nColumns + " " + (!_vm.selectedBank.id
            ? ''
            : _vm.selectedBank.id == '104'
            ? 'selectedBank'
            : 'notSelectedBank')),on:{"click":function($event){return _vm.selectBank('104', 'Banco CEF')}}},[_c('img',{staticClass:"bankLogo",attrs:{"src":require("@/assets/bancos/104.png"),"alt":"Banco CEF"}})]):_vm._e(),(_vm.banks.length == 0 || _vm.banks.indexOf('212') !== -1)?_c('div',{staticClass:"column is-centered is-vcentered bank-logo",class:("is-" + _vm.nColumns + " " + (!_vm.selectedBank.id
            ? ''
            : _vm.selectedBank.id == '212'
            ? 'selectedBank'
            : 'notSelectedBank')),on:{"click":function($event){return _vm.selectBank('212', 'Banco Original')}}},[_c('img',{staticClass:"bankLogo",attrs:{"src":require("@/assets/bancos/212.png"),"alt":"Banco Original"}})]):_vm._e(),(_vm.banks.length == 0 || _vm.banks.indexOf('218') !== -1)?_c('div',{staticClass:"column is-centered is-vcentered bank-logo",class:("is-" + _vm.nColumns + " " + (!_vm.selectedBank.id
            ? ''
            : _vm.selectedBank.id == '218'
            ? 'selectedBank'
            : 'notSelectedBank')),on:{"click":function($event){return _vm.selectBank('218', 'Banco BS2')}}},[_c('img',{staticClass:"bankLogo",attrs:{"src":require("@/assets/bancos/218.png"),"alt":"Banco BS2"}})]):_vm._e(),(_vm.banks.length == 0 || _vm.banks.indexOf('237') !== -1)?_c('div',{staticClass:"column is-centered is-vcentered bank-logo",class:("is-" + _vm.nColumns + " " + (!_vm.selectedBank.id
            ? ''
            : _vm.selectedBank.id == '237'
            ? 'selectedBank'
            : 'notSelectedBank')),on:{"click":function($event){return _vm.selectBank('237', 'Banco Bradesco')}}},[_c('img',{staticClass:"bankLogo",attrs:{"src":require("@/assets/bancos/237.png"),"alt":"Banco Bradesco"}})]):_vm._e(),(_vm.banks.length == 0 || _vm.banks.indexOf('341') !== -1)?_c('div',{staticClass:"column is-centered is-vcentered bank-logo",class:("is-" + _vm.nColumns + " " + (!_vm.selectedBank.id
            ? ''
            : _vm.selectedBank.id == '341'
            ? 'selectedBank'
            : 'notSelectedBank')),on:{"click":function($event){return _vm.selectBank('341', 'Banco Itaú')}}},[_c('img',{staticClass:"bankLogo",attrs:{"src":require("@/assets/bancos/341.png"),"alt":"Banco Itaú"}})]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }