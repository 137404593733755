<template>
  <b-dropdown
    class="wl-dropdown"
    :mobile-modal="true"
    :position="`is-${dorpPosition}`"
  >
    <b-tooltip
      slot="trigger"
      class="wl-saldo-trigger"
      label="Saldo da Carteira"
      :position="`is-${tipPosition}`"
      type="is-pigeon"
    >
      <p>R$ {{ saldoTotalFormatted }}</p>
      <b-icon
        v-if="$mq === 'sm' ? !hideIconMobile && !hideIcon : !hideIcon"
        icon="chevron-down"
        pack="fas"
        :style="{ fontSize: '7px', opacity: 0.7, lineHeight: '8px' }"
      ></b-icon>
    </b-tooltip>

    <div class="wl-container">
      <div class="wl-content">
        <h3 class="wl-title">Carteira</h3>
        <div class="columns is-multiline is-gapless is-mobile">
          <div class="column is-full">
            <div class="wl-item">
              <label><b>Saldo Total</b></label> <span>R$ {{ saldoTotal }}</span>
            </div>
          </div>
          <div class="column is-full">
            <div class="wl-item">
              <label>Saldo para Saque</label> <span>R$ {{ saldo }}</span>
            </div>
          </div>
          <div class="column is-full">
            <div class="wl-item wl-sub-item">
              <label>Saldo Depósito</label> <span>R$ {{ saldoDeposito }}</span>
            </div>
          </div>
          <div class="column is-full">
            <div class="wl-item wl-sub-item">
              <label>Saldo Premiação</label> <span>R$ {{ saldoPremio }}</span>
            </div>
          </div>
          <div class="column is-full">
            <div class="wl-item">
              <label>Bônus para jogo</label> <span>R$ {{ saldoBonus }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Wallet",
  props: {
    dorpPosition: {
      type: String,
      default: "bottom-right",
    },
    tipPosition: {
      type: String,
      default: "right",
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideIconMobile: {
      type: Boolean,
      default: true,
    },
    manuallyLoad: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["userId", "wallet"]),
    saldo() {
      return this.wallet && "saldo_wallet" in this.wallet
        ? this.wallet.saldo_wallet.toFixed(2)
        : "-";
    },
    saldoDeposito() {
      return this.wallet && "saldo_deposito" in this.wallet
        ? this.wallet.saldo_deposito.toFixed(2)
        : "-";
    },
    saldoPremio() {
      return this.wallet && "saldo_premio" in this.wallet
        ? this.wallet.saldo_premio.toFixed(2)
        : "-";
    },
    saldoBonus() {
      return this.wallet && "saldo_bonus" in this.wallet
        ? this.wallet.saldo_bonus.toFixed(2)
        : "-";
    },
    saldoTotal() {
      if (
        this.wallet &&
        "saldo_wallet" in this.wallet &&
        "saldo_bonus" in this.wallet
      ) {
        const sum = this.wallet.saldo_bonus + this.wallet.saldo_wallet;
        return sum; // .toLocaleString();
      } else return 0;
    },
    saldoTotalFormatted() {
      if (this.saldoTotal >= 1000000)
        return `${this.intlFormat(this.saldoTotal / 1000000)}M`;
      if (this.saldoTotal >= 1000)
        return `${this.intlFormat(this.saldoTotal / 1000)} Mil`;
      return this.intlFormat(this.saldoTotal).toLocaleString();
    },
  },
  async created() {
    if (!this.manuallyLoad) {
      await this.getWallet(this.userId);
    }
  },
  methods: {
    ...mapActions(["getWallet"]),
    intlFormat(num) {
      return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    },
  },
};
</script>
