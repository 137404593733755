import { mapGetters } from "vuex"


export default {
  data() {
    return {
      Partidas: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['mercado'])
  },
  methods: {
    async getPartidas (id) {
      let a = []
      try {
        this.loading = true
        a = (await this.$http.get(url + '/jogos/partidas/' + id)).data.data  

      } catch (err) {
        console.log(err)
        this.loading = false
      }
      this.loading = false
      return a
    },
    async getAtualRodada () {
      try {
        this.Partidas = await this.getPartidas(this.mercado.rodada_atual)
      } catch (e) {
        console.log(e)
      }
    }
  }
}