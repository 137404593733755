import qs from "qs";
import LigasService from "@/apps/Ligas/services/api";
import UserService from "@/apps/Ligas/services/User";

import LayoutLeagues from "../layout/LayoutLeagues.vue";
import HeaderLeagues from "../components/HeaderLeagues.vue";
import NofityAway from "@/apps/Ligas/components/NotifyAway.vue";
import ShoppingCart from "@/apps/Ligas/components/ShoppingCart.vue";

import InfluencerPageMixin from "./influencerPage";

import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [UserService, InfluencerPageMixin],
  components: {
    LayoutLeagues,
    HeaderLeagues,
    NofityAway,
    ShoppingCart,
  },
  data() {
    return {
      isLoadingLeagues: true,
      isLoading: true,
      userLeagues: [],
      influencers: [],
      filterFields: {},
      filters: {
        modalidade: ["liga", "game"],
        status: ["pre_venda", "aberta"],
        tipo: [],
        apuracao: [],
        capitao: [],
        valor: [0, 1000],
        limit: 10,
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["mercado", "userId", "isPublic"]),
  },
  methods: {
    ...mapActions([
      "getMercado",
      "getLigasTimesCartola",
      "getWallet",
      "setPublic",
    ]),
    getLeaguesByUser() {
      const filters = this.makeFiltersToQuery();

      const queryParams = qs.stringify({
        ...filters,
      });
      return LigasService.getLeaguesByUser(this.userId, queryParams);
    },
    loadUserLeagues() {
      const promises = [
        LigasService.getUserLeaguesRegistrations(this.userId),
        LigasService.getUserGamesRegistrations(this.userId),
      ];
      this.userLeagues = [];

      Promise.all(promises).then(([ligaResponse, gameResponse]) => {
        if (ligaResponse.data.data)
          this.userLeagues.push(...ligaResponse.data.data);
        if (gameResponse.data.data)
          this.userLeagues.push(...gameResponse.data.data);
      });
    },
    loadFiltersFields() {
      return LigasService.getFiltersFields();
    },
    loadInfluencers() {
      const filters = this.makeFiltersToQuery();
      const queryParams = qs.stringify({ ...filters });

      return LigasService.getInfluencersWithLeagues(); // nao utiliza o filtro ativo na página
    },
    getLeaguesFormatted(leaguesRooms) {
      return leaguesRooms
        .map((room) => {
          const modality = room.liga ? "liga" : "game";
          const params = this.makeParamsRoomByModality(room, modality);
          const influencer = this.influencers.find(
            (f) => f.id === room.id_influencer
          );

          return {
            ...room,
            ...params,
            influencer,
          };
        })
        .filter((l) => l.influencer);
    },
    makeParamsRoomByModality(room, modality) {
      const { leagueType, apuracao } = this.fetchRoomObjectsParams(
        room,
        modality
      );

      const params = {};
      params[modality] = {
        ...room[modality],
        tipo: leagueType.label,
        apuracao: apuracao.label,
      };

      if (modality === "game") {
        const apuracaoTiebreaker = this.filterFields.apuracao.find(
          (a) => a.id === room[modality].id_tipo_apuracao_desempate
        );
        params[modality].apuraca_desempate = apuracaoTiebreaker.label;
      }

      return params;
    },
    makeFiltersToQuery() {
      const filters = {};

      Object.entries(this.filters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          filters[key] = value.join(",");
        } else if (key === "influencer") {
          if (value !== "") filters[key] = value;
        } else {
          filters[key] = value;
        }
      });

      return filters;
    },
    fetchRoomObjectsParams(room, modality) {
      const leagueType = this.filterFields.tipo.find(
        (t) => t.id === room[modality].id_tipo
      );
      const apuracao = this.filterFields.apuracao.find(
        (a) => a.id === room[modality].id_tipo_apuracao
      );

      return { leagueType, apuracao };
    },
    setInfluencers(influencers) {
      this.influencers = [
        {
          id: "todos",
          nome_midia: "Todos",
          url_foto: null,
        },
        ...influencers,
      ];
    },
    setFilterFields(filterFields) {
      this.filterFields = filterFields;
    },
  },
};
