<template>
  <div class="leagues-row">
    <div class="leagues-row-header">
      <h2>{{ category.descricao }}</h2>
      <b-button
        v-if="!cantLoadMore"
        type="is-text"
        size="is-small"
        @click="$emit('load-more')"
        >Ver todas as ligas</b-button
      >
    </div>
    <div class="leagues-row-cards">
      <Flicking :plugins="plugins" :options="{ align: 'prev', circular: true }">
        <div
          v-for="room of leagues"
          :key="`room-${category.slug}-${room.id}`"
          class="league-card-content"
        >
          <SalaCard
            :sala="room"
            :reserved-salas="userLeagues"
            :is-user-leagues="isUserLeagues"
            @refresh="load()"
            @login="setLogin"
            @register="setLogin"
          ></SalaCard>
        </div>
        <div v-if="!cantLoadMore" class="league-card-content">
          <div class="card-more-leagues" @click="$emit('load-more')">
            <b-icon pack="fas" icon="external-link-alt"></b-icon>
            <span class="is-size-6">Ver todas as ligas</span>
          </div>
        </div>

        <span
          slot="viewport"
          class="flicking-arrow-prev is-circle"
          :disabled="$mq !== 'sm' && disableArrows"
        ></span>
        <span
          slot="viewport"
          class="flicking-arrow-next is-circle"
          :disabled="$mq !== 'sm' && disableArrows"
        ></span>
        <div slot="viewport" class="flicking-pagination"></div>
      </Flicking>
    </div>
  </div>
</template>

<script>
import SalaCard from "../SalaCard.vue";
import { Arrow, Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";
import "@egjs/flicking-plugins/dist/pagination.css";

export default {
  name: "CategoryList",
  components: {
    SalaCard,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    leagues: {
      type: Array,
      required: true,
    },
    userLeagues: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      plugins: [new Arrow(), new Pagination({ type: "bullet" })],
    };
  },
  computed: {
    perPage() {
      return this.$mq === "sm" ? 1 : 4;
    },
    disableArrows() {
      return this.leagues.length < 3 ? true : false;
    },
    isUserLeagues() {
      return this.category.slug === "minhas-ligas";
    },
    cantLoadMore() {
      return this.category.slug === "para-voce";
    },
  },
  methods: {
    setLogin() {
      this.$emit("login");
    },
  },
};
</script>

<style lang="scss">
.leagues-row {
  position: relative;
  margin-bottom: 1rem;

  .leagues-row-header {
    display: flex;
    align-items: center;

    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-right: 0.5em;
    }
  }

  .leagues-row-cards {
    padding-top: 0.75em;

    .league-card-content {
      padding: 0 0.25em 1.7em 0.25em;

      .card-more-leagues {
        height: 100%;
        border-radius: 4px;
        padding: 1.5em;
        background-color: #474c68;
        border: 1px solid #3c3f55;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .icon {
          margin-bottom: 1em;
        }

        &:hover {
          transition: all 0.2s ease-in-out;
          background-color: rgb(37, 41, 58);
        }
      }
    }

    .flicking-arrow-prev,
    .flicking-arrow-next {
      width: 40px;
      height: 40px;

      &::before {
        left: 12px;
        width: 15px;
        height: 4px;
      }
      &::after {
        top: calc(50% - 2px);
        left: 13px;
        width: 15px;
        height: 4px;
      }

      &[disabled="disabled"] {
        display: none !important;
      }

      @media (max-width: 768px) {
        & {
          top: 92%;
        }
      }
    }

    .flicking-arrow-prev.is-circle,
    .flicking-arrow-next.is-circle {
      background-color: #7285a5;

      &:hover {
        transition: all 0.2s ease-in-out;
        background-color: #61718c;
      }
    }

    .flicking-pagination-bullet {
      background-color: #34374b;
    }
    .flicking-pagination-bullet-active {
      background-color: #00f0f8;
    }

    .flicking-arrow-disabled {
      display: none;
      background-color: #9babc7 !important;
    }
  }
}
</style>
