<template>
  <b-dropdown
    class="menu-dropdown"
    ref="dropdown"
    position="is-bottom-left"
  >
    <div slot="trigger">
      <b-button type="is-secondary" icon-left="th-large" icon-pack="fas">Menu</b-button>
    </div>

    <div class="menu-container">
      <b-menu v-if="renderMenu" class="is-custom-mobile">
          <b-menu-list v-if="limitAccess" label="Usuário">
            <b-menu-item
              v-if="limitAccess"
              icon-pack="fas"
              icon="cubes"
              tag="router-link"
              :to="`/renewSignature/${userId}?action=upgrade`"
              label="Melhorar Plano"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list label="Menu">
            <restrict-access
              v-for="(item, i) of menuItens"
              :key="'menu-item-' + i"
              :slug="item.p"
              appName="painel"
            >
              <b-menu-item
                :icon="item.icon"
                icon-pack="fas"
                expanded
                tag="router-link"
                :to="item.url"
                :label="item.name"
              >
              </b-menu-item>
            </restrict-access>
          </b-menu-list>
        </b-menu>
    </div>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { Menu } from "@/shared/Nav";
import RestrictAccess from "@/components/restrictAccess/restrictAccess";

export default {
  name: "MenuHeader",
  components: {
    "restrict-access": RestrictAccess,
  },
  props: {
    appName: {
      type: String,
      default: () => "ligas",
    },
  },
  data() {
    return {
      isExpanded: [],
      menuItens: [],
      limitAccess: false,
    };
  },
  computed: {
    ...mapGetters(['userId', 'userPermission', 'renderMenu']),
    navlist() {
      return Menu(this.appName)
    },
  },
  watch: {
    userPermission: function (newValue, oldValue) {
      if (newValue.length > 0) {
        this.load();
      }
    }
  },
  created() {
    if (this.userPermission.length > 0) {
      this.load();
    }
  },
  methods: {
    load() {
      this.limitAccess = this.$accessControl.checkLimitAccess();
      this.menuItens = this.navlist;
    }
  }
}
</script>

<style>

</style>