<template>
  <div>
    <div v-if="!loading">
      <div v-for="category of categoriesUpdated" :key="category.id">
        <CategoryList
          v-if="category.leagues.length > 0"
          :category="category"
          :leagues="category.leagues"
          :user-leagues="reservedLeagues"
          @login="$emit('login')"
          @load-more="handleLoadMore(category)"
        ></CategoryList>
      </div>
    </div>
    <b-loading :active.sync="loading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
import CategoryList from "./Categorias/CategoryList.vue";

export default {
  name: "CategoriesManager",
  components: {
    CategoryList,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    userLeagues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reservedLeagues: [...this.userLeagues],
      categoriesUpdated: [...this.categories],
    };
  },
  watch: {
    userLeagues(newValue) {
      this.reservedLeagues = [...newValue];
    },
    categories(newValue) {
      this.categoriesUpdated = [...newValue];
    },
  },
  methods: {
    handleLoadMore(category) {
      this.$emit("open-category", { ...category });
    },
  },
};
</script>
