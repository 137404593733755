<template>
  <div id="TeamsEnroll">
    <div class="te-boxes-teams" :mobile-small="showBonusQuestion">
      <div v-if="!onlyList && showList">
        <span v-if="availableTeams.length > 0" class="te-title-enrolled"
          >Times disponíveis</span
        >
        <div v-if="showTeams" class="columns is-multiline is-gapless">
          <div
            v-for="team of availableTeams"
            :key="team.id_escala"
            class="column is-half"
          >
            <div class="te-box">
              <b-checkbox 
                :ref="`team_${team.id_cartola}`" 
                v-model="listToEnroll" 
                :native-value="team" 
                @input="disableCheckBox(team)"
                :disabled="team.disable"
              >
                <div class="te-checkbox">
                  <img
                    :src="team.url_escudo_svg"
                    :alt="team.nm_time_cartola"
                    class="img-team"
                  />
                  <span>{{ team.nm_time_cartola }}</span>
                </div>
              </b-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="columns is-multiline is-gapless">

        <div
          v-for="team of listToEnroll"
          :key="`or-${team.id_escala}`"
          class="column is-full"
        >
          <div class="te-box">
            <div class="te-checkbox">
              <img
                :src="team.url_escudo_svg"
                :alt="team.nm_time_cartola"
                class="img-team"
              />
              <span>{{ team.nm_time_cartola }}</span>
            </div>
            <!-- <b-button
            type="is-danger"
            icon-left="trash-alt"
            icon-pack="fas"
            size="is-small"
            @click="removeTeam(team)"
          ></b-button> -->
          </div>
        </div>
      </div>

      <div v-if="!onlyList && teamsEnrolled.length > 0">
        
        <div v-if="isGame" class="columns is-multiline is-gapless">
          
          <b-tabs v-if="listEnrolledTeams.length" type="is-toggle" size="is-small" vertical>
            
            <b-tab-item v-for="(team, i) of listEnrolledTeams" :key="`team_${team.id}`" :label="team.nome">

              <div 
                v-if="team.qtd_atletas > 0" 
                class="columns is-mobile is-multiline is-centered"
              >
                <div class="column is-full is-centered is-vcentered has-text-centered">
                  <b-tag 
                    :type="statusTeam(team, 'titular', 'cor')" 
                    style="margin-left: 10px;"
                    size="is-small"
                    expanded
                  >Escalação Titular {{ statusTeam(team, 'titular', 'descricao') }}
                  </b-tag>
                </div>
                <div v-for="(atleta) of team.atletas" :key="'atleta-' + atleta.id" class="column is-narrow is-centered is-vcentered has-text-centered">
                  <!-- <div :style="{ width: $mq == 'sm' ? '57px' : '70px'}"> -->
                  <div :style="{ width: 'min-content'}">
                    <div :class="'foto-atleta has-background-' +
                              statusAtleta(atleta.status_icone).type">
                      <img v-if="atleta.foto && atleta.foto.indexOf('emptystate') == -1" :src="atleta.foto" :alt="atleta.apelido" style="cursor: pointer;">
                      <b-icon v-else icon="user" pack="fas" size="is-medium" type="is-grey"></b-icon>
                      <!-- <div v-else class="has-text-grey centralize" style="position: relative; top: 10px;">
                        <b-icon icon="user-slash" pack="fas" ></b-icon>
                      </div> -->
                    </div>
                    <div class="escudo-clube">
                      <img :src="atleta.clube_escudo" :alt="atleta.clube">
                    </div>
                    <div class="info-atleta">
                      <p class="info-jogador">{{ atleta.apelido }}</p>
                      <div class="info-position">
                        {{ atleta.posicao | uppercase }}
                        <!-- <b-icon :icon-pack="formatterStatus(atleta.status_icone).pack" size="is-small" :icon="formatterStatus(atleta.status_icone).icon" :type="formatterStatus(atleta.status_icone).type" style="margin-right: 5px;"></b-icon> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div 
                v-if="team.qtd_atletas_reserva > 0"
                class="columns is-mobile is-multiline is-gapless"
              >
                <div class="column is-full">
                  <!-- <span class="title">Banco de Reserva</span>
                  <b-tag 
                    :type="(team.atletas_reserva.length < team.qtd_atletas_reserva) ? 'is-warning' : 'is-success'" 
                    size="is-small" 
                    style="margin-left: 10px;"
                    expanded
                  >Escalação {{(team.atletas_reserva.length < team.qtd_atletas_reserva) ? 'Pendente' : 'Concluída'}}
                  </b-tag> -->
                  <b-tag 
                    :type="statusTeam(team, 'reserva', 'cor')" 
                    style="margin-left: 10px;"
                    size="is-medium"
                    expanded
                  >Escalação Reserva {{ statusTeam(team, 'reserva', 'descricao') }}
                  </b-tag>
                </div>
                <div v-for="(atleta) of team.atletas_reserva" :key="'atleta-' + atleta.id" class="column is-narrow">
                  <div :style="{ width: $mq == 'sm' ? '57px' : '70px'}">
                    <div class='foto-atleta'>
                      <img v-if="atleta.foto && atleta.foto.indexOf('emptystate') == -1" :src="atleta.foto" :alt="atleta.apelido" style="cursor: pointer;">
                      <b-icon v-else icon="user" pack="fas" size="is-medium" type="is-grey"></b-icon>
                      <!-- <div v-else class="has-text-grey centralize" style="position: relative; top: 10px;">
                        <b-icon icon="user-slash" pack="fas" ></b-icon>
                      </div> -->
                    </div>
                    <div class="escudo-clube">
                      <img :src="atleta.clube_escudo" :alt="atleta.clube">
                    </div>
                    <div class="info-atleta">
                      <p class="info-jogador">{{ atleta.apelido }}</p>
                      <div class="info-position">
                        {{ atleta.posicao | uppercase }}
                        <b-icon :icon-pack="formatterStatus(atleta.status_icone).pack" size="is-small" :icon="formatterStatus(atleta.status_icone).icon" :type="formatterStatus(atleta.status_icone).type" style="margin-right: 5px;"></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

<!-- 
              <b-tabs type="is-boxed" size="is-small" >

                <b-tab-item v-if="team.qtd_atletas > 0" label="Escalação Titular">
                  <div class="columns is-mobile is-multiline is-gapless">
                    <div class="column is-full">
                      <b-tag 
                        :type="(team.atletas.length < team.qtd_atletas) ? 'is-warning' : 'is-success'" 
                        size="is-small" 
                        expanded
                      >Escalação {{ (team.atletas.length < team.qtd_atletas) ? 'Pendente' : 'Concluída' }}
                      </b-tag>
                    </div>
                    <div v-for="(atleta) of team.atletas" :key="'atleta-' + atleta.id" class="column is-narrow">
                      <div :style="{ width: $mq == 'sm' ? '57px' : '70px'}">
                        <div class='foto-atleta'>
                          <img v-if="atleta.foto && atleta.foto.indexOf('emptystate') == -1" :src="atleta.foto" :alt="atleta.apelido" style="cursor: pointer;">
                          <b-icon v-else icon="user" pack="fas" size="is-medium" type="is-grey"></b-icon>
                        </div>
                        <div class="escudo-clube">
                          <img :src="atleta.clube_escudo" :alt="atleta.clube">
                        </div>
                        <div class="info-atleta">
                          <p class="info-jogador">{{ atleta.apelido }}</p>
                          <div class="info-position">
                            {{ atleta.posicao | uppercase }}
                            <b-icon :icon-pack="formatterStatus(atleta.status_icone).pack" size="is-small" :icon="formatterStatus(atleta.status_icone).icon" :type="formatterStatus(atleta.status_icone).type" style="margin-right: 5px;"></b-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>

                <b-tab-item v-if="team.qtd_atletas_reserva > 0" label="Banco de Reserva">
                  <div class="columns is-mobile is-multiline is-gapless">
                    <div class="column is-full">
                      <b-tag 
                        :type="(team.atletas_reserva.length < team.qtd_atletas_reserva) ? 'is-warning' : 'is-success'" 
                        size="is-small" 
                        expanded
                      >Escalação {{(team.atletas_reserva.length < team.qtd_atletas_reserva) ? 'Pendente' : 'Concluída'}}
                      </b-tag>
                    </div>
                    <div v-for="(atleta) of team.atletas_reserva" :key="'atleta-' + atleta.id" class="column is-narrow">
                      <div :style="{ width: $mq == 'sm' ? '57px' : '70px'}">
                        <div class='foto-atleta'>
                          <img v-if="atleta.foto && atleta.foto.indexOf('emptystate') == -1" :src="atleta.foto" :alt="atleta.apelido" style="cursor: pointer;">
                          <b-icon v-else icon="user" pack="fas" size="is-medium" type="is-grey"></b-icon>
                        </div>
                        <div class="escudo-clube">
                          <img :src="atleta.clube_escudo" :alt="atleta.clube">
                        </div>
                        <div class="info-atleta">
                          <p class="info-jogador">{{ atleta.apelido }}</p>
                          <div class="info-position">
                            {{ atleta.posicao | uppercase }}
                            <b-icon :icon-pack="formatterStatus(atleta.status_icone).pack" size="is-small" :icon="formatterStatus(atleta.status_icone).icon" :type="formatterStatus(atleta.status_icone).type" style="margin-right: 5px;"></b-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>

              </b-tabs>
 -->

            </b-tab-item>
          </b-tabs>

        </div>
        <div v-else class="columns is-multiline is-gapless">

          <span class="te-title-enrolled">Times Inscritos</span>

          <div
            v-for="team of listEnrolledTeams"
            :key="`or-${team.id_escala}`"
            class="column is-half"
          >
            <div class="te-box" enrolled>
              <div class="te-checkbox">
                <img
                  :src="team.url_escudo_svg"
                  :alt="team.nm_time_cartola"
                  class="img-team"
                />
                <span>{{ team.nm_time_cartola }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SalasService from "@/apps/Ligas/services/Salas";

export default {
  name: "TeamsEnroll",
  mixins: [SalasService],
  props: {
    sala: {
      type: Object,
      default: () => {},
    },
    cart: {
      type: Array,
      default: () => [],
    },
    onlyList: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    reservedEnrols: {
      type: Number,
      default: () => 0,
    }
  },
  data() {
    return {
      retry: 0,
      teamsEnrolled: [],
      listToEnroll: [],
      showTeams: false,
      teamName: ''
    };
  },
  watch: {
    listToEnroll: function(n, o) {
      let aux = [...n];
      this.$emit("change", aux);
    },
  },
  computed: {
    ...mapGetters(["userInfo", "getLigasTimesUser", "getGamesTimesUser", "wallet"]),
    isCart(){
      return this.cart ? this.cart.length > 0 : false
    },
    isGame(){
      return this.sala ? this.sala.game : null
    },
    availableTeams() {
      if (this.isGame || this.onlyList) {
        return [];
      }
      let teamsEnrolledIds = this.teamsEnrolled.map((e) => e.id);
      let available = this.getLigasTimesUser.filter(
        (team) => !teamsEnrolledIds.includes(team.id_cartola)
      );

      this.setKeysEnrolled({ key: "availableTeams", val: available });
      return available;
    },
    listEnrolledTeams() {
      if (this.teamsEnrolled.length === 0) return [];

      let teamsEnrolledIds = this.teamsEnrolled.map((e) => e.id);

      if (this.isGame){
        return this.getGamesTimesUser.filter((team) =>
          teamsEnrolledIds.includes(team.id)
        );
      } else {
        return this.getLigasTimesUser.filter((team) =>
          teamsEnrolledIds.includes(team.id_cartola)
        );
      }
    },
    showList() {
      let maxQtdTimePssa = 0
      if (this.sala){
        maxQtdTimePssa = this.sala.max_qtd_time_pssa
      } else if (this.cart){
        this.cart.map(sala => {
          maxQtdTimePssa = (maxQtdTimePssa == 0 && sala.max_qtd_time_pssa > 0) 
          ? sala.max_qtd_time_pssa
          : ((sala.max_qtd_time_pssa < maxQtdTimePssa && sala.max_qtd_time_pssa > 0)
            ? sala.max_qtd_time_pssa 
            : maxQtdTimePssa)
        })
      }
      return (
        maxQtdTimePssa == 0 ||
        maxQtdTimePssa > (this.teamsEnrolled.length - this.reservedEnrols)
      )
    },
    showBonusQuestion() {
      return this.wallet.saldo_bonus > 0 && this.onlyList;
    },
  },
  async created() {
    if (!this.onlyList) await this.loadTeams();
    else {
      this.listToEnroll = [...this.list];
    }
    this.showTeams = true
  },
  methods: {
    ...mapActions(["getUser", "setKeysEnrolled", "setGameCoach"]),
    async loadTeams() {
      try {
        this.loadingTeamsEnroll = true;

        let teamsEnrolled = []
        if (this.sala){
          if (this.isGame){
            teamsEnrolled = await this.getGameTeamsEnrollBySala(this.sala.game.id_game, this.sala.id);
          } else {
            teamsEnrolled = await this.getTeamsEnrollBySala(this.sala.id);
          }
          this.teamsEnrolled = teamsEnrolled.data.data;
        } else if (this.cart){
          this.cart.map(sala => async function (){
            if (sala.game){
              teamsEnrolled = await this.getGameTeamsEnrollBySala(sala.game.id_game, sala.id);
            } else {
              teamsEnrolled = await this.getTeamsEnrollBySala(sala.id);
            }
            this.teamsEnrolled = [...this.teamsEnrolled, ...teamsEnrolled.data.data];
          })
        }
        this.setKeysEnrolled({ key: "teamsEnrolled", val: this.teamsEnrolled });

        this.retry = 0;
      } catch (err) {
        if (err.status !== 404) {
          this.$toasted.error("Erro ao buscar times inscritos", {
            duration: 3000,
            position: "top-center",
          });

          // refaz a chamada caso de algum erro, refaz por ate 2 vezes
          if (this.retry < 2) {
            this.retry += 1;
            this.loadTeams();
          }
        }
      }
      this.loadingTeamsEnroll = false;
    },
    async disableCheckBox(team) {

      let maxQtdTimePssa = 0
      if (this.sala){
        maxQtdTimePssa = this.sala.max_qtd_time_pssa
      } else if (this.cart){
        this.cart.map(sala => {
          maxQtdTimePssa = (maxQtdTimePssa == 0 && sala.max_qtd_time_pssa > 0) 
          ? sala.max_qtd_time_pssa
          : ((sala.max_qtd_time_pssa < maxQtdTimePssa && sala.max_qtd_time_pssa > 0)
            ? sala.max_qtd_time_pssa 
            : maxQtdTimePssa)
        })
      }

      if (
        maxQtdTimePssa > 0 &&
        maxQtdTimePssa < (this.teamsEnrolled.length - this.reservedEnrols + this.listToEnroll.length)
      ){
      
        this.showTeams = false
        await this.removeTeam(team)
        this.showTeams = true

        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: "Número máximo de times por usuário atingido! Caso deseje trocar, desmarque um dos times e selecione o desejado.",
          type: "is-warning",
          hasIcon: true,
        });

      } else {
        this.availableTeams.map(team => {
          team.disable = false
        })
      }
    },
    async removeTeam(team) {
      this.listToEnroll = this.listToEnroll.filter(
        (el) => el.id_cartola !== team.id_cartola
      );
      if (this.listToEnroll.length === 0) {
        this.$emit("reset");
      }
    },
    formatterStatus(status) {
      let s = status.toString()
      s = s.split(' ')
      
      let type
      if (s[1].split('-')[1] == 'check')
        type = 'is-success'
      else if (s[1].split('-')[1] == 'question')
        type = 'is-warning'
      else
        type = 'is-danger'
      
      return  {
        pack: s[0],
        icon: s[1].split('-')[1],
        type: type
      }
    },
    statusTeam(team, type, resp){

      let status = ""

      if (type == 'titular'){
        status = (resp == 'cor') 
          ? ((team.atletas.length < team.qtd_atletas) ? 'is-warning' : 'is-success')
          : ((team.atletas.length < team.qtd_atletas) ? 'Pendente' : 'Concluída');
      } else {
        status = (resp == 'cor') 
          ? ((team.atletas_reserva.length < team.qtd_atletas_reserva) ? 'is-warning' : 'is-success')
          : ((team.atletas_reserva.length < team.qtd_atletas_reserva) ? 'Pendente' : 'Concluída');
      }

      return status
    },
    statusAtleta(status) {
      if (!status) return "";

      let s = status.toString();
      s = s.split(" ");

      let type;
      if (s[1].split("-")[1] == "check") type = "success";
      else if (s[1].split("-")[1] == "question") type = "warning";
      else type = "danger";

      return {
        pack: s[0],
        icon: s[1].split("-")[1],
        type: type,
      };
    },    
  },
};
</script>

<style lang="scss" scoped>
// @media (max-width: 768px) {
//   .te-boxes-teams {
//     max-height: 330px !important;
//   }
//   .te-boxes-teams[mobile-small] {
//     max-height: 200px !important;
//   }
// }

.te-boxes-teams {
  // max-height: 246px;
  // overflow-y: auto;

  .te-box {
    border-radius: 4px;
    background-color: #1d1f30;
    color: white;
    padding: 0.5em 0.25em 0.5em 0.75em;
    margin: 0.25em;
    display: flex;
    justify-content: space-between;

    .te-checkbox {
      display: flex;
      align-items: center;
    }

    .img-team {
      margin-right: 0.25em;
      width: 25px !important;
    }
  }

  .te-box[enrolled] {
    background-color: #2e324b;
    border-left: 3px solid rgb(4, 158, 125);
  }
  .te-title-enrolled {
    font-size: 0.85em;
    font-weight: 600;
    margin-bottom: 0.25em;
  }
}

#TeamsEnroll {
  .foto-atleta {
    height: 45px !important;
    width: 45px !important;
  }
  .escudo-clube {
    transform: translate(-10%, 100%) !important;
    img {
      transform: translate(-45%, -205%) !important;
    }
  }
  .info-atleta {
    left: none !important;
    float: none !important;
    .info-jogador {
      font-size: 0.6rem !important;
    }
    .info-position {
      font-size: 0.5rem !important;
    }
  }
}

</style>
