<template>
  <div class="lg-pos-notify">
    <b-notification
      :active="isAway"
      :indefinite="true"
      container="body"
      position="is-top"
      type="is-pigeon"
      aria-close-label="Fechar Ajuda"
      @close="closeNotifyAway()"
    >
      <div class="lg-notify-content">
        <p>
          Está com algum problema ou dúvida?<br />Podemos te ajudar. Fale com
          nosso suporte.
        </p>
        <b-button
          @click="handlerAjuda()"
          icon-right="whatsapp"
          icon-pack="fab"
          class="lg-bt-notification"
          >Pedir Ajuda
        </b-button>
      </div>
    </b-notification>
  </div>
</template>

<script>
import LeaguesMixin from "../mixins/notifyAway";

export default {
  name: "NotifyAway",
  mixins: [LeaguesMixin],
};
</script>
