<template>

  <div id="mini-game" class="game-modal" ref="modalEscalacao">

    <b-modal
      :active.sync="showBonusMsg"
      :can-cancel="[false, false, false]"
      :width="1100"
    >
      <ModalMessageVue 
        buttonConfirmText="Confirmar"
        title="ATENÇÃO !"
        :closeOnSubmit="false"
        hasBody
        @cancel="() => {
          saldo_bonus = null
          showBonusMsg = false
        }"
        @submit="() => {
          if (saldo_bonus == null){
            showBonusValid = true
          } else {
            showBonusMsg = false
            saveGame()
          }
        }"
      >
        <div
          class="rg-box-info"
          bonus
        >
          <p title>Fala, Cartoleiro!</p>
          <p subtitle>
            Sua carteira virtual possui bônus para inscrição em ligas.<br/>
            Deseja utilizar seu bônus nessa inscrição?
          </p>
          <div style="margin-top: 10px;">
            <b-radio
              type="is-primary"
              ref="saldo_bonus_sim"
              name="saldo_bonus"
              v-model="saldo_bonus"
              :native-value="wallet.saldo_bonus"
              >Sim</b-radio
            >
            <b-radio
              type="is-primary"
              ref="saldo_bonus_nao"
              name="saldo_bonus"
              v-model="saldo_bonus"
              :native-value="0"
              >Não</b-radio
            >
          </div>
          <div v-if="showBonusValid && saldo_bonus == null" class="has-text-danger">
            Favor selecionar uma das opções antes de confirmar.
          </div>
        </div>
      </ModalMessageVue>
    </b-modal>

    <b-modal
      :active.sync="showAtleta.show"
      :can-cancel="[false, false, false]"
    >
      <ModalMessageVue 
        title="DETALHES DO ATLETA"
        buttonConfirmText="Vender"
        buttonConfirmType="is-danger"
        hasBody
        @cancel="showAtleta.show = false"
        @submit="sell(showAtleta.posicao, showAtleta.atleta)"
      >
        <div v-if="showAtleta.atleta" class="columns centralize" style="padding: 10px !important;">
          <div class="column is-narrow centralize">
            <div class="img-player centralize">
              <img :src="showAtleta.atleta.foto" class="img-atleta" />
              <div class="img-time">
                <img :src="showAtleta.atleta.clube_escudo" width="40"/>
                <p class="img-subtitle">{{ showAtleta.atleta.clube }}</p>
              </div>
            </div>
            <div class="info-player centralize has-background-bluedark">
              <div class="centralize-row is-vcentered">
                <h4 class="h4-title" style="padding-right: 10px;">{{ showAtleta.atleta.apelido }} - {{ showAtleta.atleta.posicao }}</h4>
              </div>
              <div class="centralize-row is-vcentered">
                <span class="title is-size-4" style="margin: 0px !important;">C$ {{ showAtleta.atleta.preco }}</span>
              </div>
            </div>
          </div>
        </div>
      </ModalMessageVue>
    </b-modal>

    <div class="game-close" @click="cancelGame()">
      <b-icon icon="times-circle" pack="fas"></b-icon>
    </div>
    <div class="game-modal-header">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="game-modal-influencer">
            <img
              class="game-img-game"
              src="@/assets/joystick.svg"
              alt="MNIGAME"
            />
            <div class="game-inf-content">
              <span class="game-modal-title">
                {{ sala.titulo }}
              </span>
              <small class="game-modal-subtitle">
                {{
                  `${sala.game.tipo} / ${sala.game.apuracao} / ${
                    sala.capitao ? "COM" : "SEM"
                  } capitão`
                }}
              </small>

              <small class="game-modal-subtitle has-text-primary">
                <Timer 
                  v-if="startTime && endTime"
                  :clock="false"
                  :starttime="startTime" 
                  :endtime="endTime" 
                  v-on:expired="endEscalacao = true"
                  :trans="{  
                    days: 'dia(s)',
                    hours: 'h',
                    minutes: 'm',
                    seconds: 's',
                    running: 'Inscrições / Manutenção encerram em ',
                    expired: 'Não será mais possível realizar sua inscrição ou alteração no minigame.',
                    status: {
                      expired: 'Inscrição / Manutenção encerrada!'
                    }
                  }"
                  :style="$mq == 'sm' ? 'width: 150px; transform: scale(0.85,0.85);' : ''"
                ></Timer>
              </small>

              <div class="columns is-mobile">
                <div class="column">
                  <InfosMobile 
                    v-if="$mq == 'sm'"
                    :game="game"
                    :sala="sala"
                  />
                </div>
                <div class="column is-narrow">
                  <b-button 
                    v-if="$mq == 'sm'"
                    type="is-danger" 
                    size="is-small"
                    @click="cancelGame()" 
                    outlined 
                    expanded
                  >Voltar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$mq !== 'sm'" class="level-left">
          <div class="columns is-multiline">
            <div class="column is-full has-text-centered">
              <p class="minigame-label-2 has-text-primary">JOGUE A CAMISA E MONTE SUA PANELA!</p>
              <p v-if="kpis" class="minigame-label-3">Escolha 
                <span class="has-text-primary">{{ infoGame("qtd_atletas") }} </span>
                <span>atleta(s) entre </span>
                <span class="has-text-primary">{{ infoGame("posicoes") }}</span>
              </p>
              <p v-if="kpis" class="minigame-label-3">
                <small>O time que tiver a 
                  <span class="has-text-primary">{{infoGame("rank")}} {{infoGame("apuracao")}}</span>
                   de 
                  <span class="has-text-primary">{{infoGame("scouts")}}</span>
                  , ganha a disputa!
                </small>
              </p>
              <p v-if="kpis" class="minigame-label-3">
                <small class="has-text-danger">O desempate será a {{infoGame("rank_desempate")}} {{infoGame("apuracao_desempate")}} de {{infoGame("scouts_desempate")}}</small>
              </p>
            </div>
          </div>
        </div>

        <div v-if="$mq !== 'sm'" class="level-right">
          <div class="game-modal-info">
            <div class="columns is-multiline is-mobile">

              <div class="column is-narrow">
                <h5>Rodada</h5>
                <p>{{ sala.rodada_inicio + ((sala.rodada_fim !== sala.rodada_inicio) ? ' - ' + sala.rodada_fim : '') }}</p>
              </div>

              <div class="column is-narrow">
                <h5>Valor por Time</h5>
                <p>
                  {{
                    sala.valor > 0
                      ? `R$ ${sala.valor.toFixed(2).replace(".", ",")}`
                      : "Gratuito"
                  }}
                </p>
              </div>

              <div class="column is-narrow">
                <h5>Meu Saldo</h5>
                <Wallet dorpPosition="bottom-left" tipPosition="bottom"></Wallet>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div v-if="$mq == 'sm'" class="columns is-mobile">

        <div v-if="pageAction == 'create'" class="column" :style="{padding: '6px 12px'}">
          <b-tooltip label="Escale os atletas para seu novo time" type="is-info" position="is-bottom" animated multilined>
            <b-icon icon="question-circle" pack="fas" type="is-info"></b-icon>
            <h1>  Novo Time</h1>
          </b-tooltip>
        </div>

        <div v-if="pageAction == 'update'" class="column" :style="{padding: '6px 12px'}">
          <b-field label="Times" label-position="on-border">
            <b-select v-model="teamSelected" size="is-small" @input="selectTeam()" expanded>
              <option
                v-for="team of teamUser"
                :key="team.id"
                :value="team">
                {{ team.nome }}
                <small v-if="team.atletas.length < team.qtd_atletas">
                  (pendente)
                </small>
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-narrow" :style="{padding: '6px 12px'}">
          <b-button 
            type="is-primary" 
            size="is-small" 
            :label="(activeTabMobile == 0) ? 'Atletas' : 'Campo'"
            icon-pack="fas"
            :icon-left="(activeTabMobile == 0) ? 'user-shield' : 'futbol'"
            @click="activateMobileTab()" 
            outlined
          />
        </div>
      </div>

        <div v-if="$mq == 'sm'" class="columns is-mobile">
          <div class="column" :style="{padding: '6px 12px'}">
            <b-button 
              size="is-small" 
              v-if="!endEscalacao && teamSelected && teamSelected.atletas && teamSelected.atletas.length == teamSelected.qtd_atletas" 
              type="is-success" 
              @click="saveGame()" 
              expanded
            >Confirmar Escalação
            </b-button>
            <b-tag 
              v-if="!endEscalacao && (teamSelected && teamSelected.atletas && teamSelected.atletas.length !== teamSelected.qtd_atletas)" 
              type="is-warning" 
              size="is-small"
              style="width: 100%;"
            >Escalação Pendente
            </b-tag>
            <b-tag 
              v-if="endEscalacao" 
              type="is-danger" 
              size="is-small"
              style="width: 100%;"
            >Escalação Encerrada
            </b-tag>
          </div>
          
          <div class="column is-narrow" :style="{padding: '6px 12px'}">
            <div class="game-passos">Passo 2 de 2</div>
          </div>
        </div>

    </div>
    
    <div v-if="$mq !== 'sm'" class="game-modal-content">
      <div class="columns is-multiline">
        <!-- Campo para seleção dos jogadores -->
        <div class="column is-5 has-background-bluedark2">

          <div class="columns is-multiline is-gapless">

            <div class="column is-full has-text-centered">
              <p class="minigame-label-2 has-text-primary">SCOUTS ANALISADOS</p>
              <p v-if="kpis" class="minigame-label-3">
                Desafio: <span class="has-text-primary" v-if="teamSelected.scouts">{{ teamSelected.scouts.join(', ') }}</span><span class="has-text-primary" v-else>-</span>
                Desempate: <span class="has-text-primary" v-if="teamSelected.scouts_desempate">{{ teamSelected.scouts_desempate.join(', ') }}</span><span class="has-text-primary" v-else>-</span>
              </p>
            </div>

            <div class="column is-full">
              <Campo 
                v-if="kpis" 
                :loading="loadingField" 
                @del="sell($event.posicao, $event.atleta, true)" 
                @add="add($event)" 
                :kpis="kpis" 
                kpisPosition="posicao" 
                class="align-campo" 
                :flat="true" 
                rotation="vertical"
              ></Campo>
              <EmptyField v-else></EmptyField>
            </div>  
          </div>
        </div>

        <div class="column is-7 has-background-bluedark2">
          
          <div class="columns is-multiline is-mobile is-gapless">

            <div class="column is-narrow" style="padding: 0.2em !important;">
              <FilterAtletas
                ref="filterAtletas"
                :sala="sala"
                @result="(filter) => handlerFilter(filter)"
              ></FilterAtletas>
            </div>

            <div class="column centralize" style="padding: 0.2em !important;">
              <div v-if="pageAction == 'create'">
                <b-tooltip label="Escale os atletas para seu novo time" type="is-info" position="is-bottom" animated multilined>
                  <b-icon icon="question-circle" pack="fas" type="is-info"></b-icon>
                  <h1>  Novo Time</h1>
                </b-tooltip>
              </div>
              <div>
                <b-field v-if="pageAction == 'update'" label="Times" label-position="on-border">
                  <b-select v-model="teamSelected" @input="selectTeam()">
                    <option
                      v-for="team of teamUser"
                      :key="team.id"
                      :value="team">
                      {{ team.nome }}
                      <small v-if="team.atletas.length < team.qtd_atletas">
                        (pendente)
                      </small>
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>

            <div class="column is-narrow has-text-right centralize" style="padding: 0.2em !important;">
              <b-button v-if="!endEscalacao && teamSelected && teamSelected.atletas && teamSelected.atletas.length == teamSelected.qtd_atletas" type="is-success" @click="saveGame()" >Confirmar Escalação</b-button>
              <b-tag v-if="!endEscalacao && (teamSelected && teamSelected.atletas && teamSelected.atletas.length !== teamSelected.qtd_atletas)" type="is-warning" size="is-medium">Escalação Pendente</b-tag>
              <b-tag v-if="endEscalacao" type="is-danger" size="is-medium">Escalação Encerrada</b-tag>
            </div>

            <div class="column is-narrow has-text-right centralize" style="padding: 0.2em !important;">
              <b-button type="is-danger" @click="cancelGame()" outlined >Voltar</b-button>
            </div>

            <!-- Tabela  -->
            <div class="column is-full has-background-bluedark2">
                <!-- v-if="renderTable"  -->
              <Atletas 
                v-if="teamSelected && teamSelected.scouts" 
                :team="teamSelected"
                :atletas="atletas"
                :loading="loadingTable || loadingButtons"
                :banco-reserva="bancoReserva"
                @page="page => changePage(page)"
                @sort="sort => sortPressed(sort)"
                @limit="limit => changeLimit(limit)"
                @buy="val => buy(val.posicao, val.atleta)"
                @sell="val => sell(val.posicao, val.atleta)"
              />
            </div>
            <!-- Final Tabela -->
          </div>
        </div>

      </div>
    </div>
    <div v-else class="game-modal-content">
      <div class="columns is-multiline is-mobile is-gapless">
        <div v-show="activeTabMobile == 0" class="column is-full" style="margin: 1rem 0;">
          <Campo 
            v-if="kpis" 
            :loading="loadingField" 
            @del="sell($event.posicao, $event.atleta, true)" 
            @add="add($event)" 
            :kpis="kpis" 
            kpisPosition="posicao" 
            class="align-campo" 
            :flat="true" 
            rotation="vertical"
          ></Campo>
          <EmptyField v-else></EmptyField>
        </div>
        <div v-show="activeTabMobile == 1" class="column is-full">
          <div class="columns is-multiline is-mobile is-gapless">
            <div class="column is-full" style='position: sticky; top: -10px; z-index: 9999'>
              <FilterAtletas
                ref="filterAtletas"
                :sala="sala"
                @result="(filter) => handlerFilter(filter)"
              ></FilterAtletas>
            </div>
            <div class="column is-full">
              <Atletas 
                v-if="teamSelected && teamSelected.scouts" 
                :team="teamSelected"
                :atletas="atletas"
                :loading="loadingTable || loadingButtons"
                @page="page => changePage(page)"
                @sort="sort => sortPressed(sort)"
                @limit="limit => changeLimit(limit)"
                @buy="val => buy(val.posicao, val.atleta)"
                @sell="val => sell(val.posicao, val.atleta)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AtletasMixin from "@/modules/requests/Atletas";
import Campo from "@/components/helpers/field/Campo";
import EmptyField from '@/components/helpers/field/EmptyCampo';
import Timer from '@/components/helpers/DownTimer'
import ModalMessageVue from "@/components/modals/ModalMessage.vue";
import Wallet from "@/apps/Ligas/components/Wallet";
import FilterAtletas from "./Filter";
import Atletas from "./Atletas";
import InfosMobile from "./InfosMobile";

export default {
  name: 'EscalarTime',
  mixins: [AtletasMixin],
  components: {
    Wallet,
    FilterAtletas,
    Atletas,
    InfosMobile,
    Timer,
    Campo,
    EmptyField,
    ModalMessageVue
  },
  props: {
    sala: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingComponent: null,
      gameId: '',
      salaId: '',
      pageAction: '',
      startTime: null,
      endTime: null,
      endEscalacao: false,
      editTeamId: "",
      teamUser: [],
      teamSelected: {},
      kpis: [],
      game: {},
      renderTable: false,
      atletas: [],
      loadingTable: false,
      loadingButtons: false,
      loadingField: false,
      page: 0,
      disableInfiniteScroll: false,
      bancoReserva: false,
      showBonusMsg: false,
      showBonusValid: false,
      showAtleta: {
        show: false,
        atleta: null,
        reserva: null
      },
      saldo_bonus: null,
      activeTabMobile: 0
    }
  },
  computed: {
    ...mapGetters([
      'userId', 
      'userInfo', 
      "wallet",
      'posicoes', 
      'scouts',
      'configAtletas', 
    ]),
    saldoCartoletas(){

      let compras = 0
      
      this.teamSelected.atletas.map(a => {
        compras += a.preco
      })

      return (this.teamSelected.qtd_cartoleta - compras)
    },
    saldoCartoletasReserva(){

      let compras = 0
      
      this.teamSelected.atletas_reserva.map(a => {
        compras += a.preco
      })

      return (this.teamSelected.qtd_cartoleta_reserva - compras)
    },
    gamePositions() {
      return this.posicoes.filter(el => (this.game.posicoes) ? this.game.posicoes.split(',').indexOf(el.sigla) !== -1 : false)
    },
    posicoesScouts() {
      
      let scouts = this.scouts.map(s => s.cd_scout)
      scouts.sort()

      return scouts
    },
  },
  async created() {
    this.pageAction = this.action ? this.action : 'create'
    await this.loadPage()
  },
  methods: {
    ...mapActions([
      "getWallet", 
      'getPosicoes', 
      'getScouts',
      'setConfigAtletas', 
      'getGamesTimes', 
      'getGameRoomById', 
      'addAtletaInGame'
    ]),
    infoGame(campo){

      let info = ""

      switch(campo){
        case "qtd_atletas":
          info = this.sala.game.qtd_atletas
        break;
        case "posicoes":
          info = (this.posicoes.length-1) == this.sala.game.posicoes.split(',').length ? 'TODAS AS POSIÇÕES' : this.gamePositions.map(p => p.nome).join(', ');
          info = info.replace(/,/g, ", ");
        break;
        case "rank":
          info = this.sala.game.rank.name.toUpperCase()
        break;
        case "rank_desempate":
          info = this.sala.game.rank_desempate.name.toUpperCase()
        break;
        case "scouts":
          info = this.posicoesScouts.length == this.sala.game.scouts.split(',').length ? 'TODOS OS SCOUTS' : this.sala.game.scouts.toUpperCase().replace(/,([^,]*)$/, ' e $1')
          info = info.replace(/,/g, ", ")
        break;
        case "scouts_desempate":
          info = this.posicoesScouts.length == this.sala.game.scouts_desempate.split(',').length ? 'TODOS OS SCOUTS' : this.sala.game.scouts_desempate.toUpperCase().replace(/,([^,]*)$/, ' e $1')
          info = info.replace(/,/g, ", ")
        break;
        case "scouts_todos":
          info = this.posicoesScouts.length == this.sala.game.scouts.split(',').length
        break;
        case "apuracao":
          info = this.sala.game.id_apuracao == 1 ? ' pontuação nos scouts ' : ' soma '
        break;
        case "apuracao_desempate":
          info = this.sala.game.id_apuracao_desempate == 1 ? ' pontuação nos scouts ' : ' soma '
        break;
      }

      return info
    },
    async handlerFilter(filter) {
      
      if (!this.loadingComponent){
        this.loadingComponent = this.$buefy.loading.open({
          container: this.$refs.modalEscalacao,
        });
      }
      
      let updateFilter = false

      if (!filter.status || filter.status.length == 0){
        let newStatus = [
          {id: 2, label: "Dúvida"},
          {id: 7, label: "Provável"}
        ]
        filter.status = newStatus
        updateFilter = true
      }
      
      if (!filter.position || filter.position.length == 0){
        let newPos = this.gamePositions.map(p => p.sigla.toUpperCase());
        filter.position = newPos
        updateFilter = true
      }

      if (updateFilter){
        await this.handlerFilter(filter)
      }
      
      await this.setAtletasFilter(filter);
      
      let atletas = await this.getAtletas();
      if (atletas){
        atletas = this.updateBuySell(atletas)
        this.atletas = JSON.parse(JSON.stringify(atletas))
      }
      
      if (this.loadingComponent){
        this.loadingComponent.close();
        this.loadingComponent = null
      }
    },
    async changePage(page){
      if (!this.loadingComponent){
        this.loadingComponent = this.$buefy.loading.open({
          container: this.$refs.modalEscalacao,
        });
      }
      await this.setAtletasPage(page);
      let atletas = await this.getAtletas();
      if (atletas){
        atletas = this.updateBuySell(atletas)
        this.atletas = JSON.parse(JSON.stringify(atletas))
      }
      if (this.loadingComponent){
        this.loadingComponent.close();
        this.loadingComponent = null
      }
    },
    async sortPressed(sort) {

      if (!this.loadingComponent){
        this.loadingComponent = this.$buefy.loading.open({
          container: this.$refs.modalEscalacao,
        });
      }
      await this.orderAtletas(sort.field, sort.order);
      let atletas = await this.getAtletas();
      if (atletas){
        atletas = this.updateBuySell(atletas)
        this.atletas = JSON.parse(JSON.stringify(atletas))
      }
      if (this.loadingComponent){
        this.loadingComponent.close();
        this.loadingComponent = null
      }
    },    
    async changeLimit(limit){
      if (!this.loadingComponent){
        this.loadingComponent = this.$buefy.loading.open({
          container: this.$refs.modalEscalacao,
        });
      }
      await this.setAtletasLimit(limit);
      let atletas = await this.getAtletas();
      if (atletas){
        atletas = this.updateBuySell(atletas)
        this.atletas = JSON.parse(JSON.stringify(atletas))
      }
      if (this.loadingComponent){
        this.loadingComponent.close();
        this.loadingComponent = null
      }
    },
    async loadPage() {

      if (!this.loadingComponent){
        this.loadingComponent = this.$buefy.loading.open({
          container: this.$refs.modalEscalacao,
        });
      }

      let load = (this.sala && (this.sala.game))

      if (this.sala){

        this.gameId = this.sala.game.id_game
        this.salaId = this.sala.id
        this.editTeamId = ("teamId" in this.$route.query) ? this.$route.query.teamId : ""
        this.game = this.sala.game ? this.sala.game : null

        load = (this.game)

        if (load){

          this.startTime = new Date()
          this.endTime = new Date(this.game.encerramento_inscricao)
          this.startTime = this.startTime.toString()
          this.endTime = this.endTime.toString()

          await this.getPosicoes()
          await this.getScouts()

          this.teamUser = await this.getGamesTimes({
            userId: this.userId, 
            gameId: this.gameId,
            salaId: this.salaId
          });

          load = (this.teamUser)

          if (load){

            if (
              this.pageAction == 'create' &&
              this.sala.max_qtd_time_pssa && 
              this.sala.max_qtd_time_pssa > 0 && 
              this.teamUser.length >= this.sala.max_qtd_time_pssa
            ){
              this.$buefy.dialog.alert({
                title: "ATENÇÃO!",
                message: `Limite de times por usuário atingido! Você já possui ${this.teamUser.length} time(s) nesse minigame, portanto não será permitida uma nova inscrição.`,
                type: "is-warning",
                hasIcon: true
              });
              this.pageAction = 'update'
            }

            load = (this.pageAction == 'create' || (this.pageAction == 'update' && this.teamUser && this.teamUser.length > 0))
          }
        }
      }
      
      if (load){

        if (this.pageAction == 'update'){
          if (this.editTeamId !== "") {
            this.teamUser.map(t => {
              if (t.id == this.editTeamId){
                this.teamSelected = t
              }
            })
          } else {
            this.teamSelected = this.teamUser[0]
          }
        } else {
          this.teamSelected = {
            atletas: [],
            atletas_reserva: [],
            id_game: this.gameId,
            id_pssa: this.userId,
            id_sala: this.salaId,
            nome: this.userInfo.coach,
            qtd_atletas: this.game.qtd_atletas,
            qtd_cartoleta: this.game.qtd_cartoletas,
            qtd_atletas_reserva: this.game.qtd_atletas_reserva,
            qtd_cartoleta_reserva: this.game.qtd_cartoletas_reserva,
            scouts: (this.game.scouts) ? this.game.scouts.split(',') : [],
            scouts_desempate: (this.game.scouts_desempate) ? this.game.scouts_desempate.split(',') : []
          }
        }

        await this.selectTeam()

      } else {
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: "Problema ao carregar as informações para escalação do time! Clique F5 e tente novamente. Caso o problema persista, favor entrar em contato com o suporte.",
          type: "is-danger",
          hasIcon: true
        });
      }
    },
    async selectTeam(){

      if (this.$mq == 'sm'){
        this.activeTabMobile = 0
      }

      this.loadingTable = true
      this.loadingField = true
      this.loadingButtons = true
      this.kpis = []

      this.teamSelected.scouts_todos = this.infoGame("scouts_todos")  

      let gamePosicoes = this.game.posicoes.split(',')
      gamePosicoes.map(gP => {
        let posicao = (('zag,le,ld').indexOf(gP) !== -1 ? 'zag-le-ld' : (('mei,vol').indexOf(gP) !== -1 ? 'mei-vol' : gP))
        let exist = false
        for (const kpi of this.kpis) {
          exist = (kpi.pos == posicao)
          if (exist) break
        }
        if (!exist){
          this.kpis.push({pos: posicao, atletas: []})
        }
      })
      if (this.teamSelected.qtd_atletas_reserva > 0){
        this.kpis.push({pos: 'reserva', atletas: []})
      }

      this.teamSelected.atletas.map(atleta => {
        let atletaPos = (('zag,le,ld').indexOf(atleta.posicao) !== -1 ? 'zag-le-ld' : (('mei,vol').indexOf(atleta.posicao) !== -1 ? 'mei-vol' : atleta.posicao))
        for (const kpi of this.kpis) {
          if (kpi.pos == atletaPos){
            atleta['posicao_campo'] = atletaPos
            kpi.atletas.push(JSON.parse(JSON.stringify(atleta)))
          }
        }
      })

      if (this.teamSelected.atletas.length < this.teamSelected.qtd_atletas){
        this.kpis.map((kpi, iK) => {
          if (kpi.pos !== 'reserva'){
            let atletas = []
            let newAtletas = (kpi.pos == 'gol' || kpi.pos == 'tec') ? (1 - kpi.atletas.length) : (((this.teamSelected.qtd_atletas > 5) ? 5 : this.teamSelected.qtd_atletas) - kpi.atletas.length)
            for (let index = 0; index < newAtletas; index++) {
                atletas.push({})
            }
            atletas.map(a => this.kpis[iK].atletas.push(a))
          }
        })
      }

      if (this.teamSelected.qtd_atletas_reserva > 0){
      
        this.teamSelected.atletas_reserva.map(atleta => {
          for (const kpi of this.kpis) {
            if (kpi.pos == 'reserva'){
              atleta['posicao_campo'] = 'reserva'
              kpi.atletas.push(JSON.parse(JSON.stringify(atleta)))
            }
          }
        })

        if (this.teamSelected.atletas_reserva.length < this.teamSelected.qtd_atletas_reserva){
          this.kpis.map((kpi, iK) => {
            if (kpi.pos == 'reserva'){
              let atletas = []
              let newAtletas = ((this.teamSelected.qtd_atletas_reserva > 5) ? 5 : this.teamSelected.qtd_atletas_reserva) - kpi.atletas.length
              for (let index = 0; index < newAtletas; index++) {
                  atletas.push({})
              }
              atletas.map(a => this.kpis[iK].atletas.push(a))
            }
          })
        }
      }

      this.loadingField = false

      await this.setAtletasPage(1);
      
      let newPos = this.gamePositions.map(p => p.sigla.toUpperCase());
      await this.handlerFilter({
        status: [{id: 7, label: "Provável"}],
        position: newPos
      })
      // await this.handlerFilter(this.$refs.filterAtletas.filter)
    },
    async buy(posicao, atleta) {

      let saldo = (posicao == 'reserva') ? this.saldoCartoletasReserva : this.saldoCartoletas
      saldo = Number((saldo - atleta.preco).toFixed(2))

      if (saldo < 0){

        let msg = (posicao == 'reserva') ? ' Reserva ' : ''

        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: `Saldo de Cartoletas ${msg} insuficiente para compra.`,
          type: "is-warning",
          hasIcon: true
        });

      } else {

        this.renderTable = false
        this.loadingTable = true

        if (posicao == 'reserva'){
          atleta.buy_reserva = false
          atleta.sell_reserva = true
        } else {
          atleta.buy = false
          atleta.sell = true
        }

        let selectAtleta = {
          apelido: atleta.apelido,
          clube: atleta.time_codigo,
          clube_id: atleta.time_id,
          clube_escudo: atleta.time_escudo,
          clube_nome: atleta.time_nome,
          foto: atleta.foto,
          id: atleta.id,
          posicao: atleta.posicao_codigo,
          posicao_campo: atleta.posicao_campo,
          status: atleta.status,
          status_color: atleta.statusColor,
          status_icone: atleta.statusIcone,
          preco: (atleta.preco_atual) ? atleta.preco_atual : ((atleta.preco) ? atleta.preco : 0)
        }

        for (const kpi of this.kpis) {
          if (kpi.pos == ((posicao == 'reserva') ? 'reserva' : selectAtleta.posicao_campo)){
            for (const key in kpi.atletas) {
              if (kpi.atletas.hasOwnProperty(key)) {
                if (!kpi.atletas[key].id){
                  kpi.atletas.splice(key,1,JSON.parse(JSON.stringify(selectAtleta)))
                  break
                }
              }
            }
          }
        }

        if (posicao == 'reserva'){
          this.teamSelected.atletas_reserva.push(selectAtleta)
        } else {
          this.teamSelected.atletas.push(selectAtleta)
        }

        if (posicao !== 'reserva'){
          if (this.teamSelected.atletas.length == this.teamSelected.qtd_atletas){
            let kpiAtletas = []
            this.kpis.map((kpi,iK) => {
              if (kpi.pos !== 'reserva'){
                kpi.atletas = kpi.atletas.filter(kpiAtleta => (kpiAtleta.id))
              }
            })
          }
        }

        let atletas = this.updateBuySell()
        this.atletas = [...atletas]
        this.loadingTable = false
        this.renderTable = true

        if (this.$mq == 'sm'){
          this.activeTabMobile = 0
        }
      }
    },
    async sell(posicao, atleta, search) {

      if (search){
          this.showAtleta.show = true
          this.showAtleta.atleta = atleta
          this.showAtleta.posicao = posicao
          this.showAtleta.reserva = (posicao == 'reserva')
      } else {

        this.showAtleta.show = false
        this.renderTable = false
        this.loadingTable = true

        let iSel = false

        if (posicao == 'reserva'){
          this.teamSelected.atletas_reserva.map((a,i) => {
            if (a.id == atleta.id){
              iSel = i
            }
          })
          if (iSel !== false){
            this.teamSelected.atletas_reserva.splice(iSel,1)
          }
        } else {
          this.teamSelected.atletas.map((a,i) => {
            if (a.id == atleta.id){
              iSel = i
            }
          })
          if (iSel !== false){
            this.teamSelected.atletas.splice(iSel,1)
          }
        }

        if (posicao == 'reserva'){

          this.kpis.map((kpi,iK) => {
            if (kpi.pos == 'reserva'){
              kpi.atletas.map((kpiAtleta,iA) => {
                if (kpiAtleta.id == atleta.id){
                  this.kpis[iK].atletas.splice(iA,1)
                }
              })
              let atletas = []
              let newAtletas = ((this.teamSelected.qtd_atletas_reserva > 5) ? 5 : this.teamSelected.qtd_atletas_reserva) - kpi.atletas.length
              for (let index = 0; index < newAtletas; index++) {
                  atletas.push({})
              }
              atletas.map(a => this.kpis[iK].atletas.push(a))
            }
          })
        
        } else {

          this.kpis.map((kpi,iK) => {
            if (kpi.pos == atleta.posicao_campo){
              kpi.atletas.map((kpiAtleta,iA) => {
                if (kpiAtleta.id == atleta.id){
                  this.kpis[iK].atletas.splice(iA,1)
                }
              })
            }
            if (kpi.pos == 'reserva'){
              let atletas = []
              let newAtletas = ((this.teamSelected.qtd_atletas_reserva > 5) ? 5 : this.teamSelected.qtd_atletas_reserva) - kpi.atletas.length
              for (let index = 0; index < newAtletas; index++) {
                  atletas.push({})
              }
              atletas.map(a => this.kpis[iK].atletas.push(a))
            } else {
              let atletas = []
              let newAtletas = (kpi.pos == 'gol' || kpi.pos == 'tec') ? (1 - kpi.atletas.length) : (((this.teamSelected.qtd_atletas > 5) ? 5 : this.teamSelected.qtd_atletas) - kpi.atletas.length)
              for (let index = 0; index < newAtletas; index++) {
                  atletas.push({})
              }
              atletas.map(a => this.kpis[iK].atletas.push(a))
            }
          })
        }

        let atletas = this.updateBuySell()
        this.atletas = [...atletas]
        this.loadingTable = false
        this.renderTable = true

        if (this.$mq == 'sm'){
          this.activeTabMobile = 0
        }
      }
    },
    updateBuySell(atletas) {

      this.loadingTable = true
      this.loadingButtons = true

      atletas = atletas ? atletas : this.atletas

      for (const atleta of atletas) {

        let atletaPos = (('zag,le,ld').indexOf(atleta.posicao_codigo) !== -1 ? 'zag-le-ld' : (('mei,vol').indexOf(atleta.posicao_codigo) !== -1 ? 'mei-vol' : atleta.posicao_codigo))
        atleta['posicao_campo'] = atletaPos

        let escalado = (this.teamSelected.atletas.filter(a => a.id == atleta.id).length == 1)
        let escalacao_pendente = (this.teamSelected.atletas.length < this.teamSelected.qtd_atletas)
        let escalacao_disponivel = (this.teamSelected.atletas.filter(a => a.posicao == atleta.posicao_codigo && a.clube == atleta.time_codigo).length == 0)
        let escalacao_goleiro = (this.teamSelected.atletas.filter(a => a.posicao == atleta.posicao_codigo && atleta.posicao_codigo == 'gol').length == 0)
        let escalacao_posicao = this.teamSelected.atletas.filter(a => a.posicao == atleta.posicao_codigo).length
        let escalacao_saldo = (atleta.preco <= this.saldoCartoletas)

        let reserva = (this.teamSelected.atletas_reserva.filter(a => a.id == atleta.id).length == 1)
        let reserva_pendente = (this.teamSelected.atletas_reserva.length < this.teamSelected.qtd_atletas_reserva)
        let reserva_disponivel = (this.teamSelected.atletas_reserva.filter(a => a.posicao == atleta.posicao_codigo && a.clube == atleta.time_codigo).length == 0)
        let reserva_posicao = this.teamSelected.atletas_reserva.filter(a => a.posicao == atleta.posicao_codigo).length
        let reserva_saldo = (atleta.preco <= this.saldoCartoletasReserva)

        if (!this.bancoReserva){

          atleta['buy'] = (
            !escalado && 
            !reserva && 
            escalacao_pendente && 
            escalacao_disponivel && 
            escalacao_goleiro && 
            escalacao_saldo
          )
          atleta['sell'] = (escalado)

          atleta['unavailable'] = ''
          if (!atleta['buy'] && !atleta['sell']){
            atleta['unavailable'] = 
            (reserva) ? 'Atleta no banco de reserva' : 
            ((!escalacao_goleiro) ? 'Goleiro já escalado' : 
            ((!escalacao_saldo) ? 'Preço maior do que o saldo disponível de cartoletas' : 
            ((!escalacao_pendente) ? 'Limite de Atletas atingido!' : 
            'Atleta da mesma posição e clube já escalado!')))
          }
        }
        
        if (this.bancoReserva){

          atleta['buy_reserva'] = (
            !reserva && 
            !escalado && 
            reserva_pendente && 
            reserva_disponivel && 
            reserva_saldo && 
            reserva_posicao < escalacao_posicao)
          atleta['sell_reserva'] = (reserva)

          atleta['unavailable_reserva'] = ''
          if (!atleta['buy_reserva'] && !atleta['sell_reserva']){
            atleta['unavailable_reserva'] = 
            (escalado) ? 'Atleta escalado' : 
            ((!reserva_saldo) ? 'Preço maior do que o saldo disponível de cartoletas para reservas' : 
            ((!reserva_pendente) ? 'Limite de Reservas atingido!' : 
            ((!reserva_disponivel) ? 'Banco de reserva já possui atleta da mesma posição e clube!' : 
            'Reserva indisponível para atletas da mesma posicao')))
          }
        }
      }

      this.loadingButtons = false
      this.loadingTable = false

      return atletas
    },
    async add(posicoes) {
      
      if (this.$mq == 'sm'){
        this.activeTabMobile = 1
      }
      
      if (posicoes !== "reserva"){
        this.bancoReserva = false
        let newPos = posicoes.split('-').filter(p => this.game.posicoes.indexOf(p) !== -1).map(p => p.toUpperCase())
        await this.handlerFilter({
          status: [{id: 7, label: "Provável"}],
          position: newPos
        })
      } else {
        this.bancoReserva = true
        let newPos = this.gamePositions.map(p => p.sigla.toUpperCase());
        await this.handlerFilter({
          status: [{id: 7, label: "Provável"}],
          position: newPos
        })
        // this.atletas = this.atletas.filter(a => a.buy_reserva || a.sell_reserva)
      }
    },
    async activateMobileTab(){
      if (this.activeTabMobile == 0){
        let newPos = this.gamePositions.map(p => p.sigla.toUpperCase());
        await this.handlerFilter({
          status: [{id: 7, label: "Provável"}],
          position: newPos
        })
        this.activeTabMobile = 1
      } else {
        this.activeTabMobile = 0
      }
    },
    isEquivalentObj(a, b) {

      var aObj = JSON.stringify(a)
      var bObj = JSON.stringify(b)

      return (aObj === bObj)
    },
    async validBonus() {

      let valid = true

      if (this.pageAction == 'create' && this.saldo_bonus == null && this.sala.valor > 0){
        
        await this.getWallet(this.userId);
        if ((this.wallet ? this.wallet.saldo_bonus : 0) > 0){

          valid = false
          this.showBonusMsg = true
        }
      }

      return valid
    },
    async saveGame() {

      if (await this.validBonus()){

        if (!this.loadingComponent){
          this.loadingComponent = this.$buefy.loading.open({
            container: this.$refs.modalEscalacao,
          });
        }
        this.isLoading = true;

        let params = JSON.parse(JSON.stringify(this.teamSelected))

        let response = await this.addAtletaInGame({
          team: params,
          saldo_bonus: this.saldo_bonus
        });

        if (!response) {
          this.notifyError();
        } else if (response.error){
          this.notifyError(response.error);
        } else {

          this.$buefy.notification.open({
            duration: 5000,
            message: `Escalação realizada com sucesso!`,
            position: "is-bottom-right",
            type: "is-success",
            hasIcon: true,
          });

          await this.getWallet(this.userId);

          if (this.pageAction == "create"){

            this.$buefy.dialog.confirm({
              title: 'ATENÇÃO!',
              message: "Deseja inscrever outro time nesse minigame?",
              confirmText: 'Sim',
              cancelText: 'Não',
              type: 'is-success',
              hasIcon: true,
              onConfirm: async () => {
                await this.loadPage()
              },
              onCancel: async () => {
                this.$router.push({ path: `/ligas` })
                window.location.reload()
              }
            })

          } else if (this.teamUser.length > 1 || (this.pageAction == "create" && this.teamUser.length >= 1)){

            this.$buefy.dialog.confirm({
              title: 'ATENÇÃO!',
              message: "Você possui outros times nesse minigame. Deseja continuar escalando ou retornar para as Ligas?",
              confirmText: 'Retornar',
              cancelText: 'Continuar',
              type: 'is-info',
              hasIcon: true,
              onConfirm: async () => {
                this.$router.push({ path: `/ligas` })
                window.location.reload()
              },
              onCancel: async () => {
                if (this.pageAction !== "update"){
                  this.pageAction = 'update'
                  await this.loadPage()
                }
              }
            })
          } else {
            this.$router.push({ path: `/ligas` })
            window.location.reload()
          }
        }

        this.isLoading = false;
        if (this.loadingComponent){
          this.loadingComponent.close();
          this.loadingComponent = null
        }
      }      
    },
    async cancelGame() {

      let msgConfirm = "Tem certeza de que deseja cancelar a escalação de seu(s) time(s) e retornar para as ligas?"
      msgConfirm += "<p>OBS.: VOCÊ PODERÁ ALTERAR SEU TIME A QUALQUER MOMENTO ATÉ O ENCERRAMENTO DAS INSCRIÇÕES!</p>" 

      this.$buefy.dialog.confirm({
        title: 'ATENÇÃO!',
        message: msgConfirm,
        confirmText: 'Voltar',
        cancelText: 'Cancelar',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {

          try {
            
            this.$parent.close();
            this.$emit("cancel");

          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$toasted.error("Ocorreu um problema ao tentar cancelar o Mini Game!", {
              duration: 3000,
              position: "top-center"
            });
          }
        }
      })
    },
    notifyError(msgError) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMessageVue,
        hasModalCard: true,
        trapFocus: true,
        props: {
          message: msgError ? 
          `<p>${msgError}</p>` : 
          `<p>Ocorreu um problema na escalação do time no MiniGame.</p><p>Tente novamente ou entre em contato com o suporte.</p>`,
          buttonConfirmText: "Falar com o suporte",
          title: "Escalação não realizada!",
          hasBody: false,
        },
        events: {
          submit: () => {
            this.sendMessageToZap({
              text: `Olá! Eu sou ${this.userInfo.nome} (${
                this.userInfo.email
              }). Tive problemas na escalação do time no MiniGame ${
                this.sala.game.tipo
              } do ${this.sala.influencer.nome_midia} ${
                this.sala.game.apuracao
              } ${this.sala.capitao ? "SEM CAPITÃO" : "COM CAPITÃO"} (ID ${
                this.sala.id
              })`,
            });
          },
        },
      });
    },
  }
}
</script>

<style scoped>


</style>
