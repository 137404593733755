import moment from "moment";
import WhatsAppNotify from "@/_mixins/whatsapp";

export default {
  mixins: [WhatsAppNotify],
  data() {
    return {
      clickedTimes: 0,
      lastClickMoment: null,
      lastClick: null,
      isAway: false,
      interval: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    lastClick: function(n, o) {
      if (this.interval) clearInterval(this.interval);
      let _this = this;
      var b = moment(n);

      this.interval = setInterval(function() {
        let a = moment();
        let diff = a.diff(b, "minute");

        if (diff <= 1) _this.isAway = false;
        else {
          _this.isAway = true;
        }
      }, 1000);
    },
    isAway: function(n, o) {
      if (n) {
        clearInterval(this.interval);
      }
    },
  },
  methods: {
    handlerEveryClick() {
      this.clickedTimes += 1;
      this.lastClickMoment = moment().format("YYYY-MM-DD HH:mm:ss");
      this.lastClick = new Date();
    },
    closeNotifyAway() {
      clearInterval(this.interval);
      this.isAway = false;
    },
    handlerAjuda() {
      this.closeNotifyAway();
      this.sendMessageToZap({ text: "" });
    },
  },
};
