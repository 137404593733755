<template>
  <section id="module-login" :fluid="fluid">
    <div class="container-login">
      <div class="columns is-multiline is-gapless">
        <div v-if="showLogin" class="column is-full">
          <auth
            ref="auth"
            :action="action"
            :influencer="influencer"
            :cupom="cupom"
            :has-close="hasClose"
            :is-influencer-page="isInfluencerPage"
            :display-default-logo="displayDefaultLogo"
            :has-logo="hasLogo"
            :url-logo="urlLogo"
            :logo-href="logoHref"
            :width-logo="widthLogo"
            @success="handlerLogin"
            @showRegister="showRegisterVodal('register')"
            @close="$emit('close')"
          ></auth>
        </div>

        <div v-else-if="showRegister" class="column is-full">
          <register
            ref="register"
            :is-influencer-page="isInfluencerPage"
            :display-default-logo="displayDefaultLogo"
            :has-logo="hasLogo"
            :url-logo="urlLogo"
            :logo-href="logoHref"
            :width-logo="widthLogo"
            @success="handlerRegister"
            @showLogin="showRegisterVodal('login')"
          ></register>
        </div>

        <div v-if="showSignature" class="column is-full">
          <signature
            ref="signature"
            :cadUser="cadUser"
            :influencer="influencer"
            :cupom="cupom"
            @showLogin="showRegisterVodal('login')"
            @showRegister="showRegisterVodal('register')"
          ></signature>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Auth from "../components/Authentication";
import Register from "../components/Registration";
import Sign from "../components/Signature";

export default {
  name: "ModuleAuth",
  components: {
    auth: Auth,
    register: Register,
    signature: Sign,
  },
  props: {
    fluid: {
      type: Boolean,
      default: () => false,
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    displayDefaultLogo: {
      type: Boolean,
      default: true,
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
    isInfluencerPage: {
      type: Boolean,
      default: false,
    },
    urlLogo: {
      type: String,
      default: "",
    },
    logoHref: {
      type: String,
      default: "",
    },
    widthLogo: {
      type: Number,
      default: 140,
    },
    autoSignature: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      action: "",
      influencer: "",
      cupom: "",
      showLogin: true,
      showRegister: false,
      showSignature: false,
      showformError: false,
      cadUser: {},
    };
  },
  mounted() {
    if (this.$route.query.f) {
      this.$buefy.dialog.alert({
        title: "Alteração de Senha",
        message:
          "Você receberá um <b>email</b> com as instruções para alterar sua senha!",
        type: "is-success",
        hasIcon: true,
      });
    }

    if (this.$route.query.action) {
      this.action = this.$route.query.action;
      this.showLogin = this.action.indexOf("login") !== -1 ? true : false;
      this.showRegister = this.action.indexOf("cad") !== -1 ? true : false;
    }
    if (this.$route.query.cupom) {
      this.cupom = this.$route.query.cupom;
    }
    if (this.$route.query.influencer) {
      this.influencer = this.$route.query.influencer;
    }
  },
  methods: {
    showRegisterVodal: async function(form) {
      this.showLogin = false;
      this.showRegister = false;
      this.showSignature = false;

      switch (form) {
        case "login":
          this.showLogin = true;
          break;

        case "register":
          this.showRegister = true;
          break;

        case "signature":
          this.cadUser.plano = "";
          this.cadUser.planoDescricao = "";
          this.cadUser.planoValor = 0;
          this.cadUser.cupom = "";
          this.cadUser.referencia = "";

          this.showSignature = true;
          break;

        default:
          this.showLogin = true;
          break;
      }

      return true;
    },
    handlerLogin() {
      this.$emit("login");
      this.$emit("close");
    },
    handlerRegister(user) {
      this.cadUser = user;
      this.$emit("register");

      // modo de assinatura automatica, no caso para um plano publico (gratuito) em ligas
      if (!this.autoSignature) this.showRegisterVodal("signature");
      else this.$emit("close");
    },
  },
};
</script>
