<template>
  <div class="rg-modal" ref="modalEnroll">
    <div class="rg-close" @click="handlerCancel()">
      <b-icon icon="times-circle" pack="fas"></b-icon>
    </div>
    <div class="rg-modal-header">
      <div class="level">
        <div class="level-left">
          <div v-if="!isGame" class="rg-modal-influencer">
            <img
              class="rg-foto-influencer"
              :src="sala.influencer.url_foto"
              :alt="sala.influencer.nome_midia"
            />
            <div class="rg-inf-content">
              <span class="rg-modal-title">{{
                sala.influencer.nome_midia
              }}</span>
              <small class="rg-modal-subtitle">
                {{
                  `${salaLiga.tipo} / ${salaLiga.apuracao} / ${
                    sala.capitao ? "COM" : "SEM"
                  } capitão`
                }}
              </small>
            </div>
          </div>
          <div v-else class="rg-modal-influencer" :game="isGame">
            <img
              class="rg-img-game"
              src="@/assets/joystick.svg"
              alt="MNIGAME"
            />
            <div v-if="$mq=='sm'" class="rg-inf-content">
              <span class="rg-modal-title">{{ sala.titulo ? sala.titulo : 'MINIGAME' }}</span>
              <small class="rg-modal-subtitle">
                Monte sua panela com {{infoGame("qtd_atletas")}} atletas entre {{infoGame("posicoes")}} 
              </small>
              <InfosMobile 
                :game="sala.game"
                :sala="sala"
              />
            </div>
            <div v-else class="rg-inf-content">
              <span class="rg-modal-title">{{ sala.titulo ? sala.titulo : 'MINIGAME' }}</span>
              <small class="rg-modal-subtitle">
                Monte sua panela com {{infoGame("qtd_atletas")}} atletas entre {{infoGame("posicoes")}} 
              </small>
              <small class="rg-modal-subtitle">
                O time que tiver a {{infoGame("rank")}} {{infoGame("apuracao")}} de {{infoGame("scouts")}}, ganha a disputa!
              </small>
              <small class="rg-modal-subtitle has-text-danger">
                O desempate será a {{infoGame("rank_desempate")}} {{infoGame("apuracao_desempate")}} de {{infoGame("scouts_desempate")}}
              </small>
            </div>
          </div>
        </div>
        <div v-if="!isGame" class="level-right">
          <h2 v-if="sala.titulo">{{ sala.titulo }}</h2>
        </div>
      </div>
    </div>
    <div class="rg-modal-info">
      <div class="columns is-multiline is-variable is-1 is-mobile">
        <div class="column">
          <h5>Meu Saldo</h5>
          <Wallet></Wallet>
        </div>
        <div class="column">
          <h5>Valor</h5>
          <p>
            {{
              sala.valor > 0
                ? `R$ ${sala.valor.toFixed(2).replace(".", ",")}`
                : "Gratuito"
            }}
          </p>
        </div>
        <div class="column">
          <h5>Rodada Início</h5>
          <p>{{ sala.rodada_inicio }}</p>
        </div>
        <div class="column">
          <h5>Rodada Final</h5>
          <p>{{ sala.rodada_fim }}</p>
        </div>
      </div>
    </div>
    <div class="rg-modal-content" :game="isGame">
      <div class="rg-passos">Passo {{ currentPage }} de 2</div>
      <div v-show="currentPage === 1">
        
        <div class="level" :style="{ marginBottom: '0.5em' }">
          <div class="level-left">
            <h3>
              <span :completed="firstStepCompleted">1</span>
              <template v-if="isGame">
                Definição do treinador <br v-if="$mq == 'sm'">
                <template v-if="showReserva">
                  / Reserva de Inscrições
                </template>
              </template>
              <template v-else>
                <template v-if="showReserva">
                  Reserva de Inscrições ou <br v-if="$mq == 'sm'">
                </template>
                Escolha dos Times
              </template>
            </h3>
          </div>
        </div>

        <div v-if="isGame" class="columns is-multiline is-vcentered">

          <div v-if="coach.valid" class="column is-full has-text-centered">
            <p><span class="title is-size-5">Olá {{ coach.name }}!!!</span></p>
            <p><span class="subtitle">Agora é hora de você montar sua panela e entrar em campo.</span></p>
            <p><span class="subtitle">Na próxima etapa fique atento as <span class="has-text-primary">Informações e Regras</span> do minigame para escolher o time certo pra disputa!</span></p>
            <p>
              <span class="subtitle">clique em <span class="has-text-success">Confirmar</span> para fazer uma nova inscrição</span>
              <span class="subtitle" v-if="showReserva"> ou em <span class="has-text-warning">Escalar Reserva</span> para substituir uma reserva com seu time,</span>
              <span class="subtitle"> e escolha seus jogadores!</span>
            </p>
          </div>
          <div class="column is-full has-text-centered">
            <div class="columns is-multiline is-vcentered">
              <div class="column is-vcentered">
                <span v-if="coach.valid" class="subtitle">Caso deseje alterar seu nome de treinador, defina e valide um novo nome. Este processo irá alterar o nome de todos os seus times já inscritos até o momento em todas as ligas.</span>
                <span v-else class="subtitle">Escolha seu nome de treinador, pois será a identificação de seus times em todos os minigames.</span>
              </div>
              <div class="column is-vcentered">
                <b-field label="Treinador" label-position="on-border">
                  <b-input ref="coach" v-model="coach.name" maxlength="40" pattern="[A-Za-zÀ-ú\s]{1,40}" required expanded validation-message="40 Caracteres permitidos: A-Z, a-z, À-ú, espaços"></b-input>
                  <p class="control">
                    <b-button type="is-success" @click="validCoach()">Validar</b-button>
                  </p>
                </b-field>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showReserva" class="columns is-multiline">
          <div class="column is-full">
            <div 
              class="rg-box-info"
              reserva
            >
              <p title>
                <span v-if="reservedEnrols">
                  Você tem {{reservedEnrols}} reserva(s). Inscreva seus times 
                  {{ (sala.limite_reserva && sala.limite_reserva !== '0000-00-00 00:00:00') ? `até ${formatDate(sala.limite_reserva)}` : '' }}
                </span>
                <span v-else>
                  {{ (sala.limite_reserva && sala.limite_reserva !== '0000-00-00 00:00:00') ? `Faça sua reserva até ${formatDate(sala.limite_reserva)}` : `Faça sua reserva` }}
                </span>
              </p>

              <div class="columns is-multiline" style="margin-top: 10px;">
                <div class="column is-narrow">
                  <b-checkbox 
                    v-model="reservation.check" 
                    type="is-primary"
                    @input="reservation.number = 0; teamsToEnroll = []"
                  > {{ reservedEnrols ? 'Fazer nova reserva' : 'Reservar inscrição' }}
                  </b-checkbox>
                </div>
                <div 
                  v-if="reservation.check"
                  class="column"
                >
                  <p title>
                    Qtd.Inscrições
                  </p>
                  <p substitle>
                    Informe a quantidade de incrições que deseja reservar
                  </p>

                  <div style="margin-top: 10px;">
                    <b-input type="number" v-model="reservation.number" step="1" :nim="1" :max="qtdMaxReserva" required></b-input>
                    <span v-if="reservation.check && showMessage.reservas.show" class="te-message-valid">
                      {{ showMessage.reservas.msg }}
                    </span>
                  </div>
                </div>
              </div>

              <p substitle v-if="reservedEnrols">
                Você já possui reservas pendentes de inscrição de times. Se os times não forem inscritos até a data limite, sua reserva será cancelada e seu dinheiro devolvido.
              </p>
              <p substitle v-else>
                Você pode garantir sua vaga agora e inscrever seus times depois! Se você não inscrever seus times até a data limite, sua reserva será cancelada e seu dinheiro devolvido.
              </p>

            </div>
          </div>
        </div>

        <template v-if="!reservation.check">
          <div
            v-if="showStatus"
            class="rg-box-info"
            :style="{ marginBottom: '0.5em' }"
          >
            <p v-html="getEnrollStatus.message"></p>
          </div>
          <span v-if="showMessage.times.show" class="te-message-valid">
            {{ showMessage.times.msg }}
          </span>

          <TeamsEnroll
            ref="teams"
            :reservedEnrols="reservedEnrols"
            :sala="sala"
            @change="handlerTeams"
          ></TeamsEnroll>
        </template>
      </div>
      <!-- @full="handlerStatus('full')"
          @empty="handlerStatus('empty')"
          @max-reached="handlerStatus('max')" -->
      <div v-if="currentPage === 2">
        <div class="level" :style="{ marginBottom: '0.5em' }">
          <div class="level-left">
            <h3>
              <span :completed="secondStepCompleted">2</span>
              Confirmação
            </h3>
          </div>
        </div>
        <div class="columns is-multiline">

          <div class="column is-full"
            v-if="(wallet ? wallet.saldo_bonus : 0) > 0 && valorPagar > 0 && currentPage === 2"
          >
            <div
              class="rg-box-info"
              bonus
            >
              <p title>Fala, Cartoleiro!</p>
              <p subtitle>
                Sua carteira virtual possui bônus para inscrição em ligas. Deseja
                utilizar seu bônus nessa inscrição?
              </p>
              <div style="margin-top: 10px;">
                <b-radio
                  type="is-primary"
                  ref="saldo_bonus_sim"
                  name="saldo_bonus"
                  v-model="saldo_bonus"
                  :native-value="wallet.saldo_bonus"
                  >Sim</b-radio
                >
                <b-radio
                  type="is-primary"
                  ref="saldo_bonus_nao"
                  name="saldo_bonus"
                  v-model="saldo_bonus"
                  :native-value="0"
                  >Não</b-radio
                >
              </div>
            </div>
          </div>

          <div v-if="reservation.check || (!reservation.check && !isGame)" class="column">
            <div v-if="reservation.check" class="rg-confirm-content">
              <div>
                <label>Reservas</label>
                <p>{{ reservation.number }} <small>{{ (reservation.number > 1) ? 'inscrições' : 'inscrição' }}</small></p>
              </div>
              <div>
                <label>Valor a Pagar</label>
                <p>
                  R$ {{ valorPagar.toLocaleString() }}
                </p>
              </div>
            </div>
            <div v-if="!reservation.check && !isGame" class="rg-confirm-content">
              <div>
                <label>Time(s)</label>
                <p>{{ teamsToEnroll.length }}</p>
              </div>
              <div>
                <label>Valor Total</label>
                <p>
                  R$ {{ (sala.valor * teamsToEnroll.length).toLocaleString() }}
                </p>
              </div>
              <div v-if="reservedEnrols">
                <label>Saldo Reserva</label>
                <p>
                  R$ {{ (sala.valor * reservedEnrols).toLocaleString() }}
                </p>
              </div>
              <div v-if="reservedEnrols">
                <label>Valor a Pagar</label>
                <p>
                  R$ {{ valorPagar.toLocaleString() }}
                </p>
              </div>
            </div>
          </div>
<!-- 
          <div v-if="!reservation.check && isGame" class="column is-full has-text-centered">
            <p><span class="is-size-3">Olá {{ coach.name }}!!!</span></p>
            <p><span class="is-size-5">Agora é a hora de você escalar a sua seleção.</span></p>
            <p><span class="is-size-5">clique em "Confirmar" e escolha os jogadores!</span></p>
          </div>
 -->
          <div class="column" v-if="!reservation.check && !isGame">

            <div class="rg-list-teams">
              <TeamsEnroll
                onlyList
                :reservedEnrols="reservedEnrols"
                :sala="sala"
                :list="teamsToEnroll"
                @change="handlerTeams"
                @reset="resetTeams"
              ></TeamsEnroll>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="rg-modal-footer">
      <div class="rg-footer-content">
        <b-button
          v-if="currentPage === 1"
          type="is-pigeon"
          @click="handlerCancel()"
          :size="$mq == 'sm' ? 'is-small' : ''"
        >
          Cancelar
        </b-button>
        <b-button 
          v-if="currentPage === 1 && showAddTeam && !reservation.check" 
          type="is-success" 
          @click="addTeam()"
          :size="$mq == 'sm' ? 'is-small' : ''"
        >
          Adicionar Time(s)
        </b-button>
        <b-button 
          v-if="currentPage > 1" 
          type="is-pigeon" 
          @click="back()"
          :size="$mq == 'sm' ? 'is-small' : ''"
        >
          Voltar
        </b-button>
        <b-button
          v-if="currentPage < 2 && (!showStatus || reservedEnrols > 0 || reservation.check) && !(isGame && !reservation.check && coach.valid)"
          type="is-primary"
          @click="forward()"
          :size="$mq == 'sm' ? 'is-small' : ''"
          :disabled="!firstStepCompleted"
        >
          Avançar
        </b-button>
        <b-button 
          v-if="isGame && reservedEnrols > 0" 
          type="is-warning" 
          @click="escalarGame('update')"
          :size="$mq == 'sm' ? 'is-small' : ''"
        >
          Escalar Reserva
        </b-button>
        <b-button 
          v-if="currentPage === 2 || (isGame && !showStatus && !reservation.check && coach.valid)" 
          type="is-success" 
          @click="submit()"
          :size="$mq == 'sm' ? 'is-small' : ''"
        >
          Confirmar
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/browser";
import { mapActions, mapGetters } from "vuex";
import TeamsEnroll from "./TeamsEnroll";
import WhatsMixin from "@/_mixins/whatsapp";
import Wallet from "@/apps/Ligas/components/Wallet";
import ModalMessageVue from "@/components/modals/ModalMessage.vue";
import ModalAdicionarTime from "@/apps/Ligas/components/Times/ModalAdicionarTime";
import ModalEscalarTime from "./Games/EscalarTime"
import InfosMobile from "./Games/InfosMobile";
import Moment from 'moment'

export default {
  name: "Enroll",
  components: {
    TeamsEnroll,
    Wallet,
    ModalAdicionarTime,
    ModalEscalarTime,
    InfosMobile,
  },
  mixins: [WhatsMixin],
  props: {
    sala: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadProgress: false,
      currentPage: 1,
      teamsToEnroll: [],
      showMessage: {
        reservas: {show: false, msg: ""},
        times: {show: false, msg: ""},
        },
      saldo_bonus: null,
      reservation: {check: false, number: 0},
      reservedEnrols: 0,
      coach: {
        valid: false,
        name: ''
      }
    };
  },
  computed: {
    ...mapGetters([
      "wallet",
      "userId",
      "userInfo",
      "getAvailableTeams",
      "getTeamsEnrolled",
      "getLigasTimesUser",
      "influencer",
      "posicoes",
      "scouts"
    ]),
    isGame(){
      return this.sala.game
    },
    salaLiga(){
      return this.sala.liga ? this.sala.liga : this.sala.game
    },
    getEnrollStatus(){
      if (
        this.sala.max_qtd_time_pssa > 0 &&
        this.sala.max_qtd_time_pssa === this.getTeamsEnrolled.length
      ) {
        let reservas = this.getTeamsEnrolled.filter(el => el.id < 0)
        if (reservas.length > 0){
          return {
            message: `Limite máximo de ${this.sala.max_qtd_time_pssa} inscrições por usuário atingido! Porém, você ainda possui ${reservas.length} reserva(s). Garanta já sua vaga selecionando seus times!`,
            type: "is-pigeon",
          };
        } else {
          return {
            message: `Limite máximo de ${this.sala.max_qtd_time_pssa} inscrições por usuário atingido!`,
            type: "is-pigeon",
          };
        }
      } else if (!this.isGame){
        if (this.getAvailableTeams.length === 0) {
          if (this.getTeamsEnrolled.length === 0){
            if (this.sala.reserva){
              return {
                message: `Você não possui times cadastrados no site. Faça uma reserva ou adicione seus times para participar das ligas.`,
                type: "is-pigeon",
              };
            } else {
              return {
                message: `Você não possui times cadastrados no site. Adicione seus times para participar das ligas.`,
                type: "is-pigeon",
              };
            }
          } else {
            let times = this.getTeamsEnrolled.filter(el => el.id > 0)
            if (times.length > 0){
              return {
                message: `Seus Times ja estão cadastrados nessa liga. Caso deseje adicionar mais times, clique no botão abaixo "Adicionar Time(s)"`,
                type: "is-pigeon",
              };
            } else {
              return {
                message: `Adicione os seus times até a data limite e substitua nas suas reservas. Se os times não forem inscritos até a data limite, sua reserva será cancelada e seu dinheiro devolvido.`,
                type: "is-pigeon",
              };
            }
          }
        } else if (this.getAvailableTeams.length > 0) {
          return;
        }
      }
      return;
    },
    isFirstStep() {
      return this.currentPage === 1;
    },
    isLastStep() {
      return this.currentPage === 2;
    },
    firstStepCompleted() {
      return (this.reservation.check ? 
        this.reservation.number : 
        (this.isGame ? 
          (this.coach.valid ? 1 : 0) : 
          this.teamsToEnroll.length)) > 0;
    },
    secondStepCompleted() {
      return this.saldo_bonus !== null;
    },
    showStatus() {
      return this.getEnrollStatus ? true : false;
    },
    showAddTeam() {
      if (!this.isGame){
        if (
          this.sala.max_qtd_time_pssa > 0 &&
          this.getTeamsEnrolled.length >= this.sala.max_qtd_time_pssa
        )
          return false
        if (this.getLigasTimesUser.length === 0) return true;
        if (this.getAvailableTeams.length === 0) return true;
      }
      return false;
    },
    showReserva() {
      return (
        this.sala.reserva &&
        (this.sala.max_qtd_time_pssa ? this.getTeamsEnrolled.length < this.sala.max_qtd_time_pssa : true)
      )
    },
    qtdMaxReserva() {
      let qtd = this.sala.max_qtd_time_pssa ? 
        (this.sala.max_qtd_time_pssa - this.getTeamsEnrolled.length) : 
        ((this.getTeamsEnrolled.length > 0 || this.getAvailableTeams.length > 0) && !this.isGame ? this.getAvailableTeams.length : 999)
      return qtd
    },
    valorPagar() {
      return this.reservation.check ? 
        (this.sala.valor * this.reservation.number) : (
          (this.reservedEnrols >= this.teamsToEnroll.length) ? 0 : 
          (this.sala.valor * (this.teamsToEnroll.length - this.reservedEnrols))
        )
    },
    routeToCheckout() {
      if (Object.keys(this.influencer).length === 0) {
        return '/ligas/caixa';
      }
      return `/ligas/${this.$route.params.influencer}/caixa`;
    },
    posicoesScouts() {

      let scouts = this.scouts.map(s => s.cd_scout)
      scouts.sort()

      // let scouts = []
      // // let filPosition = this.posicoes.filter(
      // //   p => this.form.posicoes.indexOf(p.sigla) !== -1
      // // );
      
      // this.posicoes.map(p => {
      //   p.scouts.map(s => {
      //     let pScouts = s.scouts.split(',')
      //     for (const scout of pScouts) {
      //       if (scouts.indexOf(scout) == -1){
      //         scouts.push(scout)
      //       }
      //     }
      //   }) 
      // });

      // scouts.sort()

      return scouts
    },

  },
  async created(){

    await this.getPosicoes()
    await this.getScouts()

    this.coach.valid = (this.userInfo.coach && this.userInfo.coach !== '')
    this.coach.name = (this.coach.valid ? this.userInfo.coach : '')

    let myLigas = []
    
    if (this.isGame){
      myLigas = await this.getMyGames({
        userId: this.userId, 
        params: {
          'id_sala': this.sala.id,
          'flag_reserva': 1
        }
      })
    } else {
      myLigas = await this.getMyLigas({
        userId: this.userId, 
        params: {
          'id_sala': this.sala.id,
          'flag_reserva': 1
        }
      })
    }

    if (myLigas){
      this.reservedEnrols = myLigas[0].reservas
    }
  },
  methods: {
    ...mapActions(["getUser", "getWallet", "getMyLigas", "getMyGames", "addTimeInRoom", "setGameCoach", "addTimeInGame", "getPosicoes", "getScouts"]),
    formatDate(data){
      let newDate = ""
      if (data && data !== '0000-00-00 00:00:00'){
        Moment.locale('pt-br')
        newDate = Moment(new Date(data))
        newDate = newDate.format('ddd, DD/MMM, HH:mm:ss')
      }
      return newDate
    },
    infoGame(campo){

      let info = ""

      switch(campo){
        case "qtd_atletas":
          info = this.sala.game.qtd_atletas
        break;
        case "posicoes":
          // info = this.sala.game.posicoes.toUpperCase().replace(/,/g, ", ")
          info = (this.posicoes.length-1) == this.sala.game.posicoes.split(',').length ? 'TODAS AS POSIÇÕES' : this.sala.game.posicoes.toUpperCase().replace(/,([^,]*)$/, ' e $1');
          info = info.replace(/,/g, ", ");
        break;
        case "rank":
          info = this.sala.game.rank.name.toUpperCase()
        break;
        case "rank_desempate":
          info = this.sala.game.rank_desempate.name.toUpperCase()
        break;
        case "scouts":
          info = this.posicoesScouts.length == this.sala.game.scouts.split(',').length ? 'TODOS OS SCOUTS' : this.sala.game.scouts.toUpperCase().replace(/,([^,]*)$/, ' e $1')
          info = info.replace(/,/g, ", ")
        break;
        case "scouts_desempate":
          info = this.posicoesScouts.length == this.sala.game.scouts_desempate.split(',').length ? 'TODOS OS SCOUTS' : this.sala.game.scouts_desempate.toUpperCase().replace(/,([^,]*)$/, ' e $1')
          info = info.replace(/,/g, ", ")
        break;
        case "apuracao":
          info = this.sala.game.id_apuracao == 1 ? ' pontuação nos scouts ' : ' soma '
        break;
        case "apuracao_desempate":
          info = this.sala.game.id_apuracao_desempate == 1 ? ' pontuação nos scouts ' : ' soma '
        break;
      }

      return info
    },
    handlerCancel() {
      this.$parent.close();
    },
    handlerTeams(teams) {
      this.teamsToEnroll = teams;
    },
    resetTeams() {
      this.back();
    },
    redirectToCheckout() {
      this.$router.push({ path: this.routeToCheckout });
    },
    back() {
      this.currentPage -= 1;
    },
    forward() {
      if (
        (
          this.reservation.check ? 
          (this.reservation.number > 0 && this.reservation.number <= this.qtdMaxReserva) : 
          (this.isGame ? 1 : this.teamsToEnroll.length) > 0
        )
      ) {
        this.currentPage += 1;
        this.showMessage = {
          reservas: {show: false, msg: ''},
          times: {show: false, msg: ''}
        }
      } else {
        if (this.reservation.check){

          let msg = ''

          if (this.sala.max_qtd_time_pssa > 0 && this.getTeamsEnrolled.length == 0){
            msg = `Número máximo de reservas por usuário atingido! Sala com limite de ${this.qtdMaxReserva} reserva(s) por usuário.`
          } else {
            msg = `Você só tem mais ${this.qtdMaxReserva} reservas ou inscrições disponíveis`
          }

          this.showMessage = {
            reservas: {show: true, msg},
            times: {show: false, msg: ''}
          }
        } else if (!this.isGame){
          this.showMessage = {
            reservas: {show: false, msg: ''},
            times: {show: true, msg: "Obrigatório escolher no mínimo 1 (um) time para continuar"}
          }
        }
      }
    },
    async submit() {
      const validation = await this.validationSubmit();
      if (!validation) return;

      let loader = this.$loading.show();
      this.loadProgress = true;

      try {

        let checkResponse = (this.reservation.check || !this.isGame)
        let responseEnroll = null

        if (this.isGame){

          if (this.reservation.check){

            responseEnroll = await this.addTimeInGame({
              gameId: this.sala.game.id_game,
              salaId: this.sala.id,
              time: this.coach.name,
              reservation: this.reservation,
              userId: this.userId,
              saldo_bonus: this.saldo_bonus,
            })

          } else {
            this.escalarGame('create')
            this.loadProgress = false;
            loader.hide();
          }

        } else {

          const teamsToEnrollIds = this.teamsToEnroll.map((e) => e.id_cartola);

          responseEnroll = await this.addTimeInRoom({
            ligaId: this.sala.liga.id_liga,
            salaId: this.sala.id,
            timeId: teamsToEnrollIds,
            reservation: this.reservation,
            userId: this.userId,
            saldo_bonus: this.saldo_bonus,
          });
        }

        if (checkResponse){

          if (!responseEnroll) {
            this.notifyError();
          }
          if (responseEnroll.error) {
            this.notifyError(responseEnroll.error);
          }
          if (responseEnroll.result) {
            let success = 0,
              error = 0,
              msgError = "";
            responseEnroll.result.map((res) => {
              if (res.cod === 1) success += 1;
              if (res.cod === 0) {
                error += 1;
                if (msgError.indexOf(res.msg) == -1){
                  msgError += (msgError !== "" ? "; " : "") + res.msg;
                }
              }
            });

            if (success > 0) {
              let msgSuccess = this.reservation.check ? 'reserva' : 'time'
              this.$buefy.notification.open({
                duration: 5000,
                message: `Inscrição de ${success} ${msgSuccess}(s) realizada com sucesso!`,
                position: "is-bottom-right",
                type: "is-success",
                hasIcon: true,
              });
              await this.getWallet(this.userId);
            }
            if (error > 0) {
              this.notifyError(msgError);
            }

            this.$emit("done");
          }

          this.loadProgress = false;
          this.$parent.close();
          loader.hide();
        }

      } catch (err) {
        console.log(err)
        this.$toasted.error(
          "Houve um problema ao realizar sua inscrição, tente mais tarde!",
          {
            duration: 3000,
            position: "top-center",
          }
        );

        // Analytics de erro em ligas
        Sentry.captureException(err);

        this.loadProgress = false;
        this.$parent.close();
        loader.hide();
      }
    },
    async validationSubmit() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.$refs.modalEnroll,
      });

      try {
        if (this.reservation.check){
          if (this.reservation.number === 0) {
            this.$toasted.error("Quantidade de inscrições para reserva não informada!", {
              duration: 3000,
              position: "top-center",
            });
            loadingComponent.close();
            return false;
          }
        } else if (!this.isGame){
          if (this.teamsToEnroll.length === 0) {
            this.$toasted.error("Nenhum time selecionado!", {
              duration: 3000,
              position: "top-center",
            });
            loadingComponent.close();
            return false;
          }
        }
        // validando o ultimo valor da carteira
        await this.getWallet(this.userId);

        if (this.valorPagar == 0) {
          this.saldo_bonus = 0
        } else if (this.wallet.saldo_bonus > 0 && this.saldo_bonus === null) {
          this.$toasted.error(
            "Responda se deseja ou não utilizar seu bônus nessa inscrição!",
            {
              duration: 5000,
              position: "top-center",
            }
          );
          loadingComponent.close();
          return false;
        }

        const saldo = this.wallet.saldo_wallet;
        const saldo_bonus = this.wallet.saldo_bonus;

        const newSaldo = (saldo + saldo_bonus) - this.valorPagar

        if (newSaldo < 0) {
          this.$buefy.modal.open({
            parent: this,
            component: ModalMessageVue,
            hasModalCard: true,
            trapFocus: true,
            props: {
              message: `<p>Fala, Cartoleiro!</p><p>Sua carteira virtual não possui saldo suficiente para efetuar a inscrição nesta liga.</p><p>Deseja incluir créditos para possibilitar sua inscrição?</p>`,
              buttonConfirmText: "Sim",
              title: "Saldo Insuficiente",
              hasBody: false,
            },
            events: {
              submit: () => {
                this.$parent.close();
                this.redirectToCheckout();
              },
            },
          });
          loadingComponent.close();
          return false;
        }
        loadingComponent.close();
        return true;
      } catch (err) {
        this.$toasted.error("Não foi possível realizar sua inscrição", {
          position: "top-center",
          duration: 4000,
        });
        loadingComponent.close();
        return false;
      }
    },
    addTeam() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAdicionarTime,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    escalarGame(action) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalEscalarTime,
        hasModalCard: true,
        trapFocus: true,
        fullScreen: true,
        canCancel: false,
        props: {
          sala: this.sala,
          action: action
        },
        events: {
          submit: () => {
            this.$parent.close();
            this.$emit("done");
          },
          cancel: () => {
            this.$parent.close();
            this.$emit("cancel");
          },
        },
      });
    },
    notifyError(msgError) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMessageVue,
        hasModalCard: true,
        trapFocus: true,
        props: {
          message: msgError ? 
          `<p>${msgError}</p>` : 
          `<p>Ocorreu um problema na inscrição dos times na Liga.</p><p>Tente novamente ou entre em contato com o suporte.</p>`,
          buttonConfirmText: "Falar com o suporte",
          title: "Inscrição não realizada!",
          hasBody: false,
        },
        events: {
          submit: () => {
            this.sendMessageToZap({
              text: `Olá! Eu sou ${this.userInfo.nome} (${
                this.userInfo.email
              }). Tive problemas na inscrição da liga ${
                this.salaLiga.tipo
              } do ${this.sala.influencer.nome_midia} ${
                this.salaLiga.apuracao
              } ${this.sala.capitao ? "SEM CAPITÃO" : "COM CAPITÃO"} (ID ${
                this.sala.id
              })`,
            });
          },
        },
      });
    },
    async validCoach() {

      if (this.coach.name == ''){

          this.$buefy.dialog.alert({
            title: "Atenção!",
            message: "informe seu nome de Treinador para jogar!",
            type: "is-warning",
            hasIcon: true
          });

      } else {
      
        let response = await this.setGameCoach({
          userId: this.userId,
          coach: this.coach.name
        })

        if (!response) {

          this.$toasted.error("Ocorreu um problema na validação do Treinador!", {
            duration: 5000,
            position: "top-center"
          });

        } else if (response.length > 0){
          
          let msgErro = response.join('<br>')
          
          this.$toasted.error("Treinador não validado!<br><br>"+msgErro, {
            duration: 5000,
            position: "top-center"
          });

        } else {

          this.$buefy.dialog.confirm({
            title: "Confirmação!",
            message: "<p>Nome do treinador disponível!</p><p>Deseja continuar com a inscrição ou redefinir o nome?<p>",
            type: "is-success",
            hasIcon: true,
            trapFocus: true,
            confirmText: "Continuar",
            cancelText: "Redefinir",
            onConfirm: (value) => {
              this.getUser(this.userId)
              this.coach.valid = true
              this.$emit("valid");
            },
            onCancel: (value) => {
              this.coach.valid = false
            }
          });
        }
      }
    },
  },
};
</script>

<style></style>
