<template>
  <section v-if="renderBanks" id="Banks">
    <div
      class="columns is-multiline is-mobile is-centered is-vcentered bank-content has-background-bluedark"
    >
      <!-- :class="'is-'+nColumns"  -->
      <div
        v-if="banks.length == 0 || banks.indexOf('001') !== -1"
        class="column is-centered is-vcentered bank-logo"
        :class="
          `is-${nColumns} ${
            !selectedBank.id
              ? ''
              : selectedBank.id == '001'
              ? 'selectedBank'
              : 'notSelectedBank'
          }`
        "
        @click="selectBank('001', 'Banco do Brasil')"
      >
        <img
          class="bankLogo"
          src="@/assets/bancos/001.png"
          alt="Banco do Brasil"
        />
      </div>
      <div
        v-if="banks.length == 0 || banks.indexOf('033') !== -1"
        class="column is-centered is-vcentered bank-logo"
        :class="
          `is-${nColumns} ${
            !selectedBank.id
              ? ''
              : selectedBank.id == '033'
              ? 'selectedBank'
              : 'notSelectedBank'
          }`
        "
        @click="selectBank('033', 'Banco Santander')"
      >
        <img
          class="bankLogo"
          src="@/assets/bancos/033.png"
          alt="Banco Santander"
        />
      </div>
      <div
        v-if="banks.length == 0 || banks.indexOf('077') !== -1"
        class="column is-centered is-vcentered bank-logo"
        :class="
          `is-${nColumns} ${
            !selectedBank.id
              ? ''
              : selectedBank.id == '077'
              ? 'selectedBank'
              : 'notSelectedBank'
          }`
        "
        @click="selectBank('077', 'Banco Inter')"
      >
        <img class="bankLogo" src="@/assets/bancos/077.png" alt="Banco Inter" />
      </div>
      <div
        v-if="banks.length == 0 || banks.indexOf('104') !== -1"
        class="column is-centered is-vcentered bank-logo"
        :class="
          `is-${nColumns} ${
            !selectedBank.id
              ? ''
              : selectedBank.id == '104'
              ? 'selectedBank'
              : 'notSelectedBank'
          }`
        "
        @click="selectBank('104', 'Banco CEF')"
      >
        <img class="bankLogo" src="@/assets/bancos/104.png" alt="Banco CEF" />
      </div>
      <div
        v-if="banks.length == 0 || banks.indexOf('212') !== -1"
        class="column is-centered is-vcentered bank-logo"
        :class="
          `is-${nColumns} ${
            !selectedBank.id
              ? ''
              : selectedBank.id == '212'
              ? 'selectedBank'
              : 'notSelectedBank'
          }`
        "
        @click="selectBank('212', 'Banco Original')"
      >
        <img
          class="bankLogo"
          src="@/assets/bancos/212.png"
          alt="Banco Original"
        />
      </div>
      <div
        v-if="banks.length == 0 || banks.indexOf('218') !== -1"
        class="column is-centered is-vcentered bank-logo"
        :class="
          `is-${nColumns} ${
            !selectedBank.id
              ? ''
              : selectedBank.id == '218'
              ? 'selectedBank'
              : 'notSelectedBank'
          }`
        "
        @click="selectBank('218', 'Banco BS2')"
      >
        <img class="bankLogo" src="@/assets/bancos/218.png" alt="Banco BS2" />
      </div>
      <div
        v-if="banks.length == 0 || banks.indexOf('237') !== -1"
        class="column is-centered is-vcentered bank-logo"
        :class="
          `is-${nColumns} ${
            !selectedBank.id
              ? ''
              : selectedBank.id == '237'
              ? 'selectedBank'
              : 'notSelectedBank'
          }`
        "
        @click="selectBank('237', 'Banco Bradesco')"
      >
        <img
          class="bankLogo"
          src="@/assets/bancos/237.png"
          alt="Banco Bradesco"
        />
      </div>
      <div
        v-if="banks.length == 0 || banks.indexOf('341') !== -1"
        class="column is-centered is-vcentered bank-logo"
        :class="
          `is-${nColumns} ${
            !selectedBank.id
              ? ''
              : selectedBank.id == '341'
              ? 'selectedBank'
              : 'notSelectedBank'
          }`
        "
        @click="selectBank('341', 'Banco Itaú')"
      >
        <img class="bankLogo" src="@/assets/bancos/341.png" alt="Banco Itaú" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Bank",
  props: {
    banks: {
      type: Array,
      default: () => [],
    },
    default: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      nColumns: "3",
      renderBanks: false,
      selectedBank: {},
      options: [
        { id: "001", name: "Banco do Brasil" },
        { id: "033", name: "Banco Santander" },
        { id: "077", name: "Banco Inter" },
        { id: "104", name: "Banco CEF" },
        { id: "212", name: "Banco Original" },
        { id: "218", name: "Banco BS2" },
        { id: "237", name: "Banco Bradesco" },
        { id: "341", name: "Banco Itaú" },
      ],
    };
  },
  created() {
    if (this.$mq == "sm") {
      this.nColumns = "half";
    } else if (this.banks.length == 0 || this.banks.length >= 4) {
      this.nColumns = "3";
    } else {
      this.nColumns = (12 / this.banks.length).toString();
    }
    this.renderBanks = true;
    if (this.default !== "") {
      for (const opt of this.options) {
        if (opt.id == this.default) {
          this.selectBank(opt.id, opt.name);
        }
      }
    }
  },
  methods: {
    async selectBank(id, name) {
      this.selectedBank = { id: id, name: name };
      this.$emit("select", this.selectedBank);
    },
  },
};
</script>

<style scoped>
.bank {
  padding: 1.5em;
}
.bank-content {
  padding: 0.5em;
  border-radius: 10px;
}
.bank-logo {
  height: auto;
  text-align: center;
  vertical-align: middle;
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
}
.bank-logo:hover {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 15px -1px rgba(0, 255, 204, 0.75);
  border: 2px solid rgba(0, 255, 204, 1);
  filter: unset !important;
}
.bankLogo {
  width: auto;
  height: 60px;
  padding: 5px;
  border-radius: 10px;
}

.selectedBank {
  -webkit-box-shadow: 0px 0px 20px -2px rgba(0, 255, 204, 0.75);
  -moz-box-shadow: 0px 0px 20px -2px rgba(0, 255, 204, 0.75);
  box-shadow: 0px 0px 20px -2px rgba(0, 255, 204, 0.75);
  border: 2px solid rgba(0, 255, 204, 1);
}
.notSelectedBank {
  filter: blur(1px);
}
</style>
