<template>
  <b-dropdown 
    v-if="render"
    class="fa-dropdown" 
    ref="fadropdown" 
    position="is-bottom-right"
    :close-on-click="false" 
    :can-close="false"
    :expanded="$mq == 'sm'"
  >
    <div class="atl-fa-content" slot="trigger">
      <b-tooltip
        label="Clique para adicionar Filtros"
        :position="$mq === 'sm' ? 'is-top' : 'is-left'"
        type="is-pigeon"
      >
        <div class="fa-box-filter">
          <b-icon icon="filter" pack="fas"></b-icon>
          <span>Filtros</span>
          <b-tag
            v-if="sumFilters > 0"
            class="fa-tag"
            rounded
            type="is-darkblue"
            >{{ sumFilters }}</b-tag
          >
        </div>
      </b-tooltip>
    </div>

    <div class="fa-container" :style="`max-height: ${heightFilter}; max-width: ${widthFilter};`">
      <div class="fa-header">
        <div v-if="$mq === 'sm'" class="fa-close" @click="close">
          <b-icon icon="times-circle" pack="fas"></b-icon>
        </div>
        <h3 class="fa-title">Filtrar Atletas</h3>
      </div>

      <div class="fa-content">
        <div class="columns is-multiline is-variable is-2">
          <div class="column is-half">
            <div class="fa-filter-field">
              <b-field label="Atleta">
                <b-input
                  placeholder="Nome do Atleta"
                  v-model="filter.player"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-half">
            <div class="fa-filter-field">
              <b-field label="Estados">
                <v-select
                  class="form-select multiple-select"
                  multiple
                  placeholder="Estados"
                  :options="playerStatus"
                  v-model="filter.status"
                ></v-select>
              </b-field>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="fa-filter-field">
              <b-field label="Posições">
                <v-select
                  class="form-select multiple-select"
                  multiple
                  placeholder="Posições"
                  :options="positionsAbrev"
                  v-model="filter.position"
                ></v-select>
              </b-field>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="fa-filter-field">
              <b-field v-if="scoutsOption" label="Scout">
                <b-select placeholder="Scouts" v-model="filter.scout" expanded>
                  <option key="todosScouts" value="">Todos os Scouts</option>
                  <option
                    v-for="scout of scoutsOption"
                    :key="scout.cd_scout"
                    :value="scout.cd_scout"
                  >
                    {{ scout.ds_scout }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="column is-one-third">
            <b-field label="Mando de Jogo">
              <div class="fa-mando-jogo">
                <b-checkbox
                  id="checkbox-button"
                  v-model="filter.mando"
                  native-value="casa"
                  type="is-primary"
                  size="is-medium"
                >
                  <span>Jogadores Mandantes</span>
                </b-checkbox>
                <b-checkbox
                  id="checkbox-button"
                  v-model="filter.mando"
                  native-value="fora"
                  type="is-primary"
                  size="is-medium"
                >
                  <span>Jogadores Visitantes</span>
                </b-checkbox>
              </div>
            </b-field>
          </div>
          <div class="column is-full">
            <b-field label="Times">
              <div class="fa-clubs-content">
                <div>
                  <label class="fa-subtitle">Mandante</label>
                  <div
                    class="columns is-multiline is-mobile mt-1 justify-columns"
                  >
                    <div
                      v-for="club of homeClubs"
                      :key="club.sigla"
                      class="column is-narrow"
                    >
                      <div class="fa-club-box" @click="handlerClubSelect(club)">
                        <img :src="club.escudo" :alt="club.time" width="35" />
                        <b-icon
                          v-if="validClubIsSelect(club.id)"
                          class="fa-icon-check centralize"
                          icon="check-circle"
                          pack="fas"
                          type="is-success"
                        ></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <label class="fa-subtitle">Visitantes</label>
                  <div
                    class="columns is-multiline is-mobile mt-1 justify-columns"
                  >
                    <div
                      v-for="club of awayClubs"
                      :key="club.sigla"
                      class="column is-narrow"
                    >
                      <div class="fa-club-box" @click="handlerClubSelect(club)">
                        <img :src="club.escudo" :alt="club.time" width="35" />
                        <b-icon
                          v-if="validClubIsSelect(club.id)"
                          class="fa-icon-check centralize"
                          icon="check-circle"
                          pack="fas"
                          type="is-success"
                        ></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-field>
          </div>
          <div class="column is-full">
            <div class="fa-align-box">
              <b-field label="Preços C$">
                <b-slider
                  type="is-primary"
                  v-model="filter.price"
                  :min="0"
                  :max="25"
                  :step="1"
                  :custom-formatter="customFormatter"
                >
                  <b-slider-tick :value="0"></b-slider-tick>
                  <b-slider-tick :value="5"></b-slider-tick>
                  <b-slider-tick :value="10"></b-slider-tick>
                  <b-slider-tick :value="15"></b-slider-tick>
                  <b-slider-tick :value="20"></b-slider-tick>
                  <b-slider-tick :value="25"></b-slider-tick>
                </b-slider>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="group-bt-filter">
        <b-button class="fa-bt" type="is-secondary" @click="cleanFilters()"
          >Limpar Filtros</b-button
        ><b-button class="fa-bt" type="is-success" @click="submit()"
          >Filtrar</b-button
        >
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import PartidasMixin from "@/modules/requests/Partidas";
import AtletasMixin from "@/modules/requests/Atletas";

export default {
  name: "Filtros",
  mixins: [PartidasMixin, AtletasMixin],
  components: {
    "v-select": vSelect,
  },
  props: {
    sala: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      render: false,
      sumFilters: 0,
      filter: {
        player: "",
        status: [
          {id: 2, label: "Dúvida"},
          {id: 7, label: "Provável"}
        ],
        position: [],
        mando: [],
        price: [0, 25],
        scout: "",
        clubs: []
      },
      playerStatus: [],
    };
  },
  watch: {
    atletasFilter: function(n, o) {
      this.loadingTable = n
    },
    atletasFilter: {
      handler: function(n, o) {
        if (n.player) {
          this.filter.player = m.player
        } else {
          this.filter.player = ""
        }
        if (n.status) {
          this.filter.status = [...n.status];
        } else {
          this.filter.status = [];
        }
        if (n.position) {
          this.filter.position = [...n.position];
        } else {
          this.filter.position = [];
        }
        if (n.mando) {
          this.filter.mando = [...n.mando];
        } else {
          this.filter.mando = [];
        }
        if (n.price) {
          this.filter.price = [...n.price];
        } else {
          this.filter.price = [];
        }
        if (n.scout) {
          this.filter.scout = n.scout;
        } else {
          this.filter.scout = "";
        }
        if (n.clubs) {
          this.filter.clubs = [...n.clubs];
        } else {
          this.filter.clubs = [];
        }
      },
    },
  },
  computed: {
    ...mapGetters(["scouts", "posicoes"]),
    heightFilter() {
      const screenHeight = this.$mq == 'sm' ? window.screen.height : (window.screen.height - 170);
      const screenAvailable = screenHeight - (this.$mq == 'sm' ? 0 : 170);
      return `${screenAvailable}px`;
    },
    widthFilter() {
      const screenWidth = this.$mq == 'sm' ? window.screen.width : (window.screen.width - 100);
      const screenAvailable = screenWidth - (this.$mq == 'sm' ? 0 : 100);
      return `${screenAvailable}px`;
    },
    homeClubs() {
      return this.Partidas.map((match) => match.clubes.casa);
    },
    awayClubs() {
      return this.Partidas.map((match) => match.clubes.fora);
    },
    positions() {
      return this.sala ? this.sala.game.posicoes.toUpperCase().split(',') : [];
    },
    positionsAbrev() {
      return this.positions.filter(el => this.filter.position.indexOf(el) == -1);
    },
    scoutsOption() {
      return this.scouts ? this.scouts : null
    }
  },
  async created() {

    this.render = false

    await this.getMercado();
    await this.getScouts();
    await this.getPosicoes();

    Promise.all([this.getPlayerStatus(), this.getAtualRodada()]).then(
      (response) => {
        let status = response[0]
        if (status){
          this.playerStatus = status.filter(s => s.id == 2 || s.id == 7);
        }
      }
    );

    this.render = true
  },
  methods: {
    ...mapActions(["getMercado", "getScouts", "getPosicoes"]),
    submit() {
      this.$emit("result", { ...this.filter });
      this.$refs.fadropdown.toggle();
    },
    close() {
      this.$refs.fadropdown.toggle();
    },
    cleanFilters() {
      this.filter = {
        player: "",
        status: [],
        position: [],
        mando: [],
        price: [0, 25],
        scout: "",
        clubs: []
      };
    },
    customFormatter(value) {
      if (value == 25) {
        return `C$ ${value}+`;
      }
      return `C$ ${value}`;
    },
    handlerClubSelect(club) {
      const indexClubSelected = this.filter.clubs.findIndex(
        (idClub) => idClub === club.id
      );

      if (indexClubSelected !== -1) {
        this.filter.clubs.splice(indexClubSelected, 1);
      } else {
        this.filter.clubs.push(club.id);
      }

      this.filter.clubs = [...this.filter.clubs];
    },
    validClubIsSelect(idClub) {
      return this.filter.clubs.find((c) => c === idClub);
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-columns {
  justify-content: space-between;
}
@media (min-width: 758px) {
  .fa-filter-field {
    min-width: 220px;
  }
  .fa-align-box {
    margin-top: 1em;
  }
}

@media (max-width: 658px) {
  .justify-columns {
    justify-content: center !important;
  }
}
</style>
