<template>
  <div class="lgp-navbar">
    <div
      v-if="!isInfluencerPage && limitedAccess"
      class="banner-message-account"
    >
      <div class="lgp-align">
        <div class="container is-fluid">
          <p>
            Sua Assinatura possui um acesso limitado, sem permissão para algumas
            telas, ou expirando antes do final da temporada.
            <router-link :to="'/renewSignature/' + userId + '?action=upgrade'">
              <b class="has-text-primary">Clique Aqui</b>
            </router-link>
            para atualizar a Assinatura!
          </p>
        </div>
      </div>
    </div>
    <div class="lgp-align">
      <div class="container is-fluid">
        <div class="level">
          <div class="level-left">
            <slot name="brand" />
            <div v-if="!isLoading" class="lgp-navbar-item">
              <SocialMedias :list="socialMediasList"></SocialMedias>
              <Notification
                v-if="!isPublic && 'nome' in userInfo && $mq === 'sm'"
              ></Notification>
            </div>
            <div
              ref="user"
              v-if="!isPublic && 'nome' in userInfo && $mq === 'sm'"
              class="lgp-navbar-item"
              id="group-user-dropdown"
            >
              <b-dropdown
                ref="dropdown"
                v-model="dropDown"
                position="is-bottom-left"
                @active-change="(active) => (dropDown = active)"
              >
                <div slot="trigger" class="menu-burger">
                  <b-button
                    type="is-bluedark2"
                    icon-right="bars"
                    icon-pack="fas"
                  >
                  </b-button>
                </div>

                <div class="dropdown-user">
                  <ul>
                    <li @click="gotoMeuDados()">
                      <button type="button" class="reset-bt">
                        <i class="fas fa-user-edit"></i> Meus Dados
                      </button>
                    </li>
                    <li v-on:click="signOutLogin()">
                      <button type="button" class="reset-bt">
                        <i class="fas fa-sign-out-alt"></i> Sair
                      </button>
                    </li>
                  </ul>
                </div>
              </b-dropdown>
            </div>
          </div>
          <div class="level-right">
            <div style="display: flex; align-items: center">
              <div
                v-if="!isPublic && !isInfluencerPage && !isLoading"
                class="lgp-navbar-item"
              >
                <MenuHeader appName="ligas"></MenuHeader>
              </div>
              <div v-if="!isPublic" class="lgp-navbar-item">
                <div class="lgp-navbar-item-button">
                  <Times></Times>
                </div>
              </div>
              <div v-if="!isPublic" class="lgp-navbar-item">
                <div class="lgp-navbar-item-button">
                  <Wallet manually-load></Wallet>
                </div>
              </div>
              <div v-if="!isPublic" class="lgp-navbar-item">
                <b-button
                  v-if="
                    [
                      'Ligas',
                      'Ligas Influenciadores',
                      'Categoria',
                      'Categoria Influenciadores',
                    ].includes($route.name)
                  "
                  tag="router-link"
                  type="is-primary"
                  :to="routerCaixa"
                  ><b>DEPOSITAR</b></b-button
                >
                <b-button
                  v-if="validateCurrentRouteName"
                  tag="router-link"
                  type="is-primary"
                  :to="homeRoute"
                  ><b>VOLTAR</b></b-button
                >
              </div>
            </div>
            <div v-if="isPublic" :class="{ 'lgp-navbar-item': $mq === 'sm' }">
              <b-button type="is-primary" @click="login"
                ><b>ENTRAR</b></b-button
              >
            </div>
            <div
              v-if="!isPublic && 'nome' in userInfo && $mq !== 'sm'"
              class="lgp-navbar-item"
            >
              <Notification></Notification>
            </div>
            <div
              ref="user"
              v-if="!isPublic && 'nome' in userInfo && $mq !== 'sm'"
              class="lgp-navbar-item"
              id="group-user-dropdown"
            >
              <b-dropdown
                ref="dropdown"
                v-model="dropDown"
                position="is-bottom-left"
                @active-change="(active) => (dropDown = active)"
              >
                <div slot="trigger" class="hd-group-user">
                  <label class="user-name"
                    >Olá, {{ userInfo.nome | formatterName }}</label
                  >

                  <button type="button" class="reset-bt bt-dropDown">
                    <i
                      class="fas fa-angle-down icon-user"
                      :class="{ 'rt-180': dropDown }"
                    ></i>
                  </button>
                </div>

                <div class="dropdown-user">
                  <ul>
                    <li @click="gotoMeuDados()">
                      <button type="button" class="reset-bt">
                        <i class="fas fa-user-edit"></i> Meus Dados
                      </button>
                    </li>
                    <li v-on:click="signOutLogin()">
                      <button type="button" class="reset-bt">
                        <i class="fas fa-sign-out-alt"></i> Sair
                      </button>
                    </li>
                  </ul>
                </div>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SocialMedias from "@/components/generic/SocialMedias";
import UserService from "@/apps/Ligas/services/User";
import Wallet from "../components/Wallet";
import Times from "../components/Times";
import ModuloLogin from "@/apps/Auth/pages/Module";
import ModalMeusDados from "@/apps/Auth/modals/MeusDados";
import MenuHeader from "./MenuHeader";
import Notification from "@/components/header/components/Notification";

export default {
  name: "NavbarLeague",
  mixins: [UserService],
  components: {
    SocialMedias,
    Wallet,
    Times,
    MenuHeader,
    Notification,
  },
  props: {
    socialMediasList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
    },
    routerCaixa: {
      type: String,
      default: "/ligas/caixa",
    },
    isInfluencerPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropDown: false,
      propsParams: {},
    };
  },
  computed: {
    ...mapGetters(["userInfo", "isPublic", "userId", "influencer"]),
    isMobile() {
      return this.$mq === "sm";
    },
    homeRoute() {
      return this.isInfluencerPage
        ? `/ligas/${this.$route.params.influencer}`
        : "/ligas";
    },
    limitedAccess() {
      return this.$accessControl.checkLimitAccess();
    },
    validateCurrentRouteName() {
      return ["Caixa", "Caixa Influenciadores", "MiniGame"].includes(
        this.$route.name
      );
    },
  },
  async created() {
    if (this.isInfluencerPage) {
      this.propsParams = {
        displayDefaultLogo: false,
        urlLogo: this.influencer.url_logo,
        hasLogo: true,
        logoHref: this.$route.path,
        isInfluencerPage: this.isInfluencerPage,
      };
    }
    this.$emit("done");
  },
  methods: {
    ...mapActions([
      "signOut",
      "setPublic",
      "getLigasTimesCartola",
      "getWallet",
    ]),
    showDropDown() {
      this.dropDown = this.dropDown == false ? true : false;
    },
    gotoMeuDados() {
      this.showDropDown();

      this.$buefy.modal.open({
        parent: this,
        component: ModalMeusDados,
        hasModalCard: false,
        fullScreen: this.isMobile,
        canCancel: false,
        props: {
          fluid: this.isMobile,
          ...this.propsParams,
        },
        events: {
          error: () => {},
          close: () => {},
        },
      });
    },
    async signOutLogin() {
      await this.signOut();
      this.$emit("signout");
    },
    async handlerLogin() {
      this.getLigasTimesCartola(this.userId);
      this.getWallet(this.userId);
      this.setPublic(false);
    },
    login() {
      this.$buefy.modal.open({
        parent: this,
        component: ModuloLogin,
        hasModalCard: false,
        fullScreen: this.isMobile,
        canCancel: false,
        props: {
          fluid: this.isMobile,
          hasClose: true,
          autoSignature: true,
          ...this.propsParams,
        },
        events: {
          login: async () => {
            await this.handlerLogin();
          },
          register: async () => {
            await this.handlerLogin();
          },
          close: () => {},
        },
      });
    },
  },
};
</script>
