<template>
  <div>
    <div
      class="modal-card"
      :style="
        $mq == 'sm'
          ? 'width: auto; min-height: 90vh; max-height: 90vh;'
          : 'width: 500px; max-height: 85vh; min-height: 75vh;'
      "
    >
      <header class="modal-card-head">
        <p class="modal-card-title">Informações</p>
      </header>

      <section class="modal-card-body">
        <b-tabs type="is-boxed" class="b-tabs-modal" size="is-small">
          <b-tab-item label="Descrição">
            <div>
              <p v-if="!salaLiga.descricao">-</p>
              <p v-else v-html="salaLiga.descricao"></p>
            </div>
          </b-tab-item>

          <b-tab-item label="Premiação Atual">
            <div
              class="is-size-6 has-text-primary"
              style="margin-bottom: 10px;"
            >
              <p>Premiação baseada em {{ qtd_atual }} times</p>
            </div>

            <div
              v-if="
                legendaAtual.percent ||
                  legendaAtual.fixo ||
                  legendaAtual.bonus ||
                  legendaAtual.produto
              "
              class="content-award"
            >
              <div
                class="columns is-multiline is-gapless is-mobile"
                style="margin: 0;"
              >
                <div
                  class="column is-full"
                  v-for="(legenda, item) of legendaAtual"
                  :key="item"
                >
                  <div
                    class="columns is-multiline is-gapless is-mobile"
                    style="margin: 0;"
                  >
                    <div
                      class="column is-narrow"
                      style="padding-right: 15px !important;"
                    >
                      <b-icon
                        v-if="item == 'percent'"
                        pack="fas"
                        icon="trophy"
                        type="is-warning"
                      ></b-icon>
                      <b-icon
                        v-if="item == 'fixo'"
                        pack="fas"
                        icon="coins"
                        type="is-success"
                      ></b-icon>
                      <b-icon
                        v-if="item == 'bonus'"
                        pack="fas"
                        icon="plus-circle"
                        type="is-info"
                      ></b-icon>
                      <b-icon
                        v-if="item == 'produto'"
                        pack="fab"
                        icon="product-hunt"
                        type="is-purple"
                      ></b-icon>
                    </div>
                    <div class="column">
                      <small v-if="item == 'percent'"
                        >Prêmio baseado no número de inscritos</small
                      >
                      <small v-if="item == 'fixo'"
                        >Prêmio fixo bancado pelo influenciador</small
                      >
                      <small v-if="item == 'bonus'"
                        >Prêmio em Bônus de jogo (valor não pode ser
                        sacado)</small
                      >
                      <small v-if="item == 'produto'">Prêmio em produto</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-for="op of info" :key="'sub-' + op.id_sub_sala">
              <div v-if="op.flag_ativo" class="content-award">
                <label class="content-title"
                  ><b>{{ getFormatterTitle(op) }}</b></label
                >
                <div
                  v-for="rank of op.ranking"
                  :key="'info-details-' + rank.rank"
                  class="columns is-multiline is-gapless is-mobile"
                  style="margin: 5px;"
                >
                  <div class="column is-full">
                    <div
                      class="columns is-multiline is-gapless is-mobile"
                      style="margin: 0;"
                    >
                      <div
                        class="column is-narrow content-rank"
                        style="padding-right: 10px !important;"
                      >
                        {{ rank.rank }}º
                      </div>
                      <div class="column content-premio">
                        <span
                          v-if="op.unit_premiacao * (rank.percent / 100) > 0"
                        >
                          <b-icon
                            pack="fas"
                            icon="trophy"
                            type="is-warning"
                            size="is-small"
                          ></b-icon>
                          R$
                          {{
                            (op.unit_premiacao * (rank.percent / 100)).toFixed(
                              2
                            )
                          }}
                        </span>
                        <span
                          style="margin: 0 2px"
                          v-if="
                            rank.fixo &&
                              op.unit_premiacao * (rank.percent / 100) > 0
                          "
                        >
                          <b>+</b>
                        </span>
                        <span v-if="rank.fixo">
                          <b-icon
                            pack="fas"
                            icon="coins"
                            type="is-success"
                            size="is-small"
                          ></b-icon>
                          R$ {{ rank.fixo.toFixed(2) }}
                        </span>
                        <span
                          style="margin: 0 2px"
                          v-if="
                            rank.bonus &&
                              (rank.fixo ||
                                op.unit_premiacao * (rank.percent / 100) > 0)
                          "
                        >
                          <b>+</b>
                        </span>
                        <span v-if="rank.bonus">
                          <b-icon
                            pack="fas"
                            icon="plus-circle"
                            type="is-info"
                            size="is-small"
                          ></b-icon>
                          R$ {{ rank.bonus.toFixed(2) }}
                        </span>
                        <span
                          style="margin: 0 2px"
                          v-if="
                            rank.produto &&
                              (rank.fixo ||
                                rank.bonus ||
                                op.unit_premiacao * (rank.percent / 100) > 0)
                          "
                        >
                          <b>+</b>
                        </span>
                        <span v-if="rank.produto" style="margin-bottom: 0">
                          <b-icon
                            pack="fab"
                            icon="product-hunt"
                            type="is-purple"
                            size="is-small"
                          ></b-icon>
                          {{ rank.produto }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item
            v-if="sala.inscricao && qtd_atual < qtd_prev"
            label="Premiação Estimada"
          >
            <div
              class="is-size-6 has-text-primary"
              style="margin-bottom: 10px;"
            >
              <p>Premiação baseada em {{ qtd_prev }} times</p>
            </div>

            <div
              v-if="
                legendaEstimada.percent ||
                  legendaEstimada.fixo ||
                  legendaEstimada.bonus ||
                  legendaEstimada.produto
              "
              class="content-award"
            >
              <div
                class="columns is-multiline is-gapless is-mobile"
                style="margin: 0;"
              >
                <div
                  class="column is-full"
                  v-for="(legenda, item) of legendaEstimada"
                  :key="item"
                >
                  <div
                    class="columns is-multiline is-gapless is-mobile"
                    style="margin: 0;"
                  >
                    <div
                      class="column is-narrow"
                      style="padding-right: 15px !important;"
                    >
                      <b-icon
                        v-if="item == 'percent'"
                        pack="fas"
                        icon="trophy"
                        type="is-warning"
                      ></b-icon>
                      <b-icon
                        v-if="item == 'fixo'"
                        pack="fas"
                        icon="coins"
                        type="is-success"
                      ></b-icon>
                      <b-icon
                        v-if="item == 'bonus'"
                        pack="fas"
                        icon="plus-circle"
                        type="is-info"
                      ></b-icon>
                      <b-icon
                        v-if="item == 'produto'"
                        pack="fab"
                        icon="product-hunt"
                        type="is-purple"
                      ></b-icon>
                    </div>
                    <div class="column">
                      <small v-if="item == 'percent'"
                        >Prêmio baseado no número de inscritos</small
                      >
                      <small v-if="item == 'fixo'"
                        >Prêmio fixo bancado pelo influenciador</small
                      >
                      <small v-if="item == 'bonus'"
                        >Prêmio em Bônus de jogo (valor não pode ser
                        sacado)</small
                      >
                      <small v-if="item == 'produto'">Prêmio em produto</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-for="op of info_prev" :key="'sub-' + op.id_sub_sala">
              <!--
                <div v-if="op.flag_ativo.data[0] > 0" class="content-award">
              -->
              <div class="content-award">
                <label class="content-title"
                  ><b>{{ getFormatterTitle(op) }}</b></label
                >
                <div
                  v-for="rank of op.ranking"
                  :key="'info-details-' + rank.rank"
                  class="columns is-multiline is-gapless is-mobile"
                  style="margin: 5px;"
                >
                  <div class="column is-full">
                    <div
                      class="columns is-multiline is-gapless is-mobile"
                      style="margin: 0;"
                    >
                      <div
                        class="column is-narrow content-rank"
                        style="padding-right: 10px !important;"
                      >
                        {{ rank.rank }}º
                      </div>
                      <div class="column content-premio">
                        <span
                          v-if="
                            op.unit_premiacao_prev * (rank.percent / 100) > 0
                          "
                        >
                          <b-icon
                            pack="fas"
                            icon="trophy"
                            type="is-warning"
                            size="is-small"
                          ></b-icon>
                          R$
                          {{
                            (
                              op.unit_premiacao_prev *
                              (rank.percent / 100)
                            ).toFixed(2)
                          }}
                        </span>
                        <span
                          style="margin: 0 2px"
                          v-if="
                            rank.fixo &&
                              op.unit_premiacao_prev * (rank.percent / 100) > 0
                          "
                        >
                          <b>+</b>
                        </span>
                        <span v-if="rank.fixo">
                          <b-icon
                            pack="fas"
                            icon="coins"
                            type="is-success"
                            size="is-small"
                          ></b-icon>
                          R$ {{ rank.fixo.toFixed(2) }}
                        </span>
                        <span
                          style="margin: 0 2px"
                          v-if="
                            rank.bonus &&
                              (rank.fixo ||
                                op.unit_premiacao_prev * (rank.percent / 100) >
                                  0)
                          "
                        >
                          <b>+</b>
                        </span>
                        <span v-if="rank.bonus">
                          <b-icon
                            pack="fas"
                            icon="plus-circle"
                            type="is-info"
                            size="is-small"
                          ></b-icon>
                          R$ {{ rank.bonus.toFixed(2) }}
                        </span>
                        <span
                          style="margin: 0 2px"
                          v-if="
                            rank.produto &&
                              (rank.fixo ||
                                rank.bonus ||
                                op.unit_premiacao_prev * (rank.percent / 100) >
                                  0)
                          "
                        >
                          <b>+</b>
                        </span>
                        <span v-if="rank.produto" style="margin-bottom: 0">
                          <b-icon
                            pack="fab"
                            icon="product-hunt"
                            type="is-purple"
                            size="is-small"
                          ></b-icon>
                          {{ rank.produto }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </section>

      <footer class="modal-card-foot">
        <b-button type="is-danger" @click="$parent.close()" outlined
          >Cancelar</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InfoDetails",
  props: {
    sala: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      info: [],
      info_prev: [],
      qtd_atual: 0,
      qtd_prev: 0,
      legendaAtual: {},
      legendaEstimada: {},
    };
  },
  computed: {
    ...mapGetters(["isPublic"]),
    salaLiga() {
      return this.sala.liga ? this.sala.liga : this.sala.game
    },
    idLiga() {
      return this.sala.liga ? this.sala.liga.id_liga : this.sala.game.id_game
    },
    isGame() {
      return this.sala.game
    },
  },
  async created() {
    let loader = this.$loading.show();

    if (!this.isPublic) {
      if (this.isGame){
        this.info = await this.getGameSubSalas({
          gameId: this.idLiga,
          salaId: this.sala.id,
        });
      } else {
        this.info = await this.getSubSalas({
          ligaId: this.idLiga,
          salaId: this.sala.id,
        });
      }
    } else {
      if (this.isGame){
        this.info = await this.getInfluencerGameSubSalas({
          gameId: this.idLiga,
          salaId: this.sala.id,
        });
      } else {
        this.info = await this.getInfluencerSubSalas({
          ligaId: this.idLiga,
          salaId: this.sala.id,
        });
      }
    }

    let maiorLimiar = 0;
    this.info.map((i) => {
      maiorLimiar =
        i.min_qtd_time > maiorLimiar ? i.min_qtd_time : maiorLimiar;
    });
    this.info_prev = this.info.filter((i) => i.min_qtd_time == maiorLimiar);

    if (this.info_prev.length > 0) {

      this.qtd_prev = this.sala.times_inscritos_previsto;

      this.info_prev.map((i) => {
        i.ranking.map((rank) => {
          if (i.unit_premiacao_prev * (rank.percent / 100) > 0) {
            this.legendaEstimada.percent = true;
          }
          if (rank.fixo) {
            this.legendaEstimada.fixo = true;
          }
          if (rank.bonus) {
            this.legendaEstimada.bonus = true;
          }
          if (rank.produto) {
            this.legendaEstimada.produto = true;
          }
        });
      });
    }

    this.info = this.info
      .map((e) => {
        e.flag_ativo =
          (typeof e.flag_ativo == 'object' && "data" in e.flag_ativo) ? 
            e.flag_ativo.data[0] : e.flag_ativo;
        return e;
      })
      .filter((i) => i.flag_ativo);

    if (this.info.length > 0) {
      this.qtd_atual =
        this.info[0].min_qtd_time > this.sala.times_inscritos
          ? this.info[0].min_qtd_time
          : this.sala.times_inscritos;

      this.info.map((i) => {
        if (i.flag_ativo) {
          i.ranking.map((rank) => {
            if (i.unit_premiacao * (rank.percent / 100) > 0) {
              this.legendaAtual.percent = true;
            }
            if (rank.fixo) {
              this.legendaAtual.fixo = true;
            }
            if (rank.bonus) {
              this.legendaAtual.bonus = true;
            }
            if (rank.produto) {
              this.legendaAtual.produto = true;
            }
          });
        }
      });
    }

    loader.hide();
  },
  methods: {
    ...mapActions(["getSubSalas", "getGameSubSalas", "getInfluencerSubSalas", "getInfluencerGameSubSalas"]),
    getFormatterTitle(op) {
      let title = op.descricao_tipo_sub_sala;

      if (op.id_tipo_sub_sala == 1) {
        if (op.rodada_inicio == op.rodada_fim) title += ` ${op.rodada_inicio}`;
        else title += ` ${op.rodada_inicio} até ${op.rodada_fim}`;
      } else if (op.id_tipo_sub_sala == 3) {
        if (op.rodada_fim <= 19) title = `1º ${op.descricao_tipo_sub_sala}`;
        else if (op.rodada_inicio > 19)
          title = `2º ${op.descricao_tipo_sub_sala}`;
      }

      return title;
    },
  },
};
</script>

<style scoped>
.content-award {
  width: 100%;
  padding: 0.5em;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.123);
  margin-bottom: 0.5em;
  border: 1px solid #1d1d1d;
}
.content-title {
  font-size: 1.1rem;
}
.content-rank {
  font-size: 1rem;
}
.content-premio {
  font-size: 0.8rem;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .content-title {
    font-size: 1rem;
  }
  .content-rank {
    font-size: 0.9rem;
  }
  .content-premio {
    font-size: 0.65rem;
  }
}
</style>
