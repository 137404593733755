<template>
  <section id="Signature" class="centralize">
    <div class="lg-container" :class="{ 'is-modal': isModal }">
      <div class="level centralize">
        <h1 class="title">Assinar</h1>
        <h2 class="subtitle" style="text-align: center">
          Selecione um plano de assinatura para ter o acesso ao conteúdo do
          nosso site de acordo com os períodos abaixo
        </h2>
        <b-field v-if="!isModal">
          <b-button
            size="is-small"
            type="is-light"
            outlined
            v-on:click="$emit('showLogin')"
            >Voltar ao Login</b-button
          >
          <b-button
            size="is-small"
            type="is-light"
            outlined
            v-on:click="$emit('showRegister')"
            >Voltar ao Cadastro</b-button
          >
        </b-field>
      </div>
      <div class="content">
        <payment
          product="Planos"
          :hasCupom="true"
          :cpf="cadUser.cpf"
          :email="cadUser.email"
          :defaultInfluencer="influencer"
          :defaultCupom="cupom"
        ></payment>
      </div>
    </div>
  </section>
</template>

<script>
import Payment from "@/components/payment/Payment";

export default {
  name: "Signature",
  components: {
    payment: Payment,
  },
  props: {
    isModal: {
      type: Boolean,
      default: () => false,
    },
    cadUser: {
      type: Object,
      default: () => {},
    },
    influencer: {
      type: String,
      default: () => "",
    },
    cupom: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style scoped>
.lg-container {
  position: relative;
  width: 80vw;
  padding: 5px 15px;
  background-color: #3c3c3c;
  border-radius: 7px;
}
.is-modal {
  width: 100%;
}
@media (min-width: 320px) and (max-width: 1024px) {
  .lg-container {
    width: 95vw;
    background-color: #2e2e2e !important;
  }
}
</style>
