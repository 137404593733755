<template>
  <div class="lg-container">
    <div v-if="hasClose" class="rg-close" @click="$emit('close')">
      <b-icon icon="times-circle" pack="fas"></b-icon>
    </div>

    <div class="level" style="margin-top: 1em">
      <div
        v-if="hasTitle"
        :class="{ 'level-left': $mq !== 'sm', 'level-right': $mq === 'sm' }"
      >
        <div class="title-group">
          <h1>{{ title }}</h1>
          <p>{{ subtitle }}</p>
        </div>
      </div>
      <div :class="{ 'level-right': $mq !== 'sm', 'level-left': $mq === 'sm' }">
        <router-link v-if="showLogoPersonalized" :to="routeLogoPersonalized"
          ><img :src="urlLogo" style="height: 50px"
        /></router-link>
        <router-link v-else-if="displayDefaultLogo" to="/"
          ><img
            src="@/assets/imagens/logotipo_green_10.png"
            alt="Escala 10"
            width="170"
        /></router-link>
      </div>
    </div>

    <div class="lg-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "WizardLayout",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    displayDefaultLogo: {
      type: Boolean,
      default: true,
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
    urlLogo: {
      type: String,
      default: "",
    },
    logoHref: {
      type: String,
      default: "",
    },
    widthLogo: {
      type: Number,
      default: 140,
    },
  },
  computed: {
    hasTitle() {
      return this.title !== "" || this.subtitle !== "";
    },
    showLogoPersonalized() {
      return !this.displayDefaultLogo && this.hasLogo && this.urlLogo !== "";
    },
    routeLogoPersonalized() {
      return this.logoHref !== "" ? this.logoHref : null;
    },
  },
};
</script>
