<template>
  <div class="columns is-multiline">
    <div v-if="isInfluencerPage" class="column is-full">
      <slot name="influencer-banner" />
    </div>
    <div class="column is-full">
      <div
        class="columns is-mobile"
        :class="{ 'columns-adpted-mobile': $mq === 'sm' }"
      >
        <div class="column is-narrow">
          <div class="leagues-header">
            <div class="header-filters">
              <Filters
                ref="filter"
                :filters="filters"
                :filter-fields="filterFields"
                @change="handlerFilter($event)"
              ></Filters>
            </div>
          </div>
        </div>
        <div v-if="isNotForInfluencerPage" class="column is-four-fifths">
          <Influencers
            :influencers="influencers"
            :filters="filters"
            @change="handlerFilter($event)"
          ></Influencers>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filters from "./Filters.vue";
import Influencers from "./Influencers.vue";

export default {
  name: "HeaderLeagues",
  components: {
    Filters,
    Influencers,
  },
  props: {
    defaultFilters: {
      type: Object,
      required: true,
    },
    filterFields: {
      type: Object,
      required: true,
    },
    influencers: {
      type: Array,
      required: true,
    },
    isInfluencerPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viewChosen: this.view,
      filters: { ...this.defaultFilters },
    };
  },
  computed: {
    sizeButton() {
      return this.$mq === "sm" ? "is-small" : "";
    },
    isNotForInfluencerPage() {
      return !this.isInfluencerPage;
    },
  },
  watch: {
    defaultFilters: {
      handler(newValue) {
        this.filters = { ...newValue };
      },
      deep: true,
    },
  },
  methods: {
    handlerFilter(filters) {
      Object.entries(filters).forEach(([key, value]) => {
        this.filters[key] = value;
      });
      this.$emit("change-filters", { ...this.filters });
    },
  },
};
</script>

<style lang="scss">
.columns-adpted-mobile {
  .column {
    padding: 0.2em 0;

    &:first-child {
      padding-left: 1em;
    }
  }
}
.leagues-header {
  display: flex;

  .header-views-options {
    .button:not(.is-independence) {
      background-color: #7285a5;
      border-color: #7285a5;
      color: white;

      &:hover {
        background-color: #697a97;
      }
    }
    .button:hover {
      color: white !important;
    }
  }

  .header-influencers {
    width: 100%;
    padding: 0 1em;
  }

  .header-filters {
    @media screen and (min-width: 768px) {
      .lg-ft-content {
        min-width: 140px;
      }
    }

    .lg-ft-content {
      @media screen and (max-width: 768px) {
        .lg-box-filter {
          width: 50px;
          height: 30px !important;
        }
      }

      .lg-box-filter {
        position: relative;
        border-radius: 4px;
        padding: 0.5em;
        display: flex;
        height: 40px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #7285a5;
        cursor: pointer;

        .icon {
          margin-right: 0.5em;
          font-size: 0.7em;
        }

        &:hover {
          transition: all 0.2s ease;
          background-color: #4c516d;
        }

        .ft-tag {
          margin-left: 0.5em;
        }

        @media screen and (max-width: 768px) {
          .ft-tag {
            position: absolute;
            margin-left: 0px !important;
            bottom: -7px;
            right: -3px;
            border: 1px solid $pigeon;
          }
        }
      }
    }
  }
}
</style>
