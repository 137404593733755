<template>
  <div v-if="renderPacks" class="columns is-multiline">

    <div 
      v-if="legenda.credito || legenda.bonus" 
      class="column is-full has-background-bluedark"
    >
      <div class="columns is-multiline">
        <div v-if="legenda.credito" class="column is-full">
          <div class="columns">
            <div class="column is-narrow">
              <b-icon pack="fas" icon="coins" type="is-success" ></b-icon>
            </div>
            <div class="column">
              <small>Valor de crédito que será inserido no saldo da carteira, podendo ser utilizado para inscrição em ligas, ou sacado a qualquer momento.</small>
            </div>
          </div>
        </div>
        <div v-if="legenda.bonus" class="column is-full">
          <div class="columns">
            <div class="column is-narrow">
              <b-icon pack="fas" icon="plus-circle" type="is-info" ></b-icon>
            </div>
            <div class="column">
              <small>Valor de bônus que será inserido no saldo de crédito para jogo, podendo ser utilizado apenas para inscrição em ligas. Esse valor não pode ser sacado ou utilizado de qualquer outra forma.</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div 
      v-for="(pack, index) of packages" 
      :key="'pack'+index" 
      class="column is-one-third"
    >
      <div class="pack-container has-background-bluedark" :class="{ 'pack-selected' : ((selectedPack) ? (pack.id_package == selectedPack.id_package ? true : false) : false), 'pack-not-selected' : ((!selectedPack.id_package) ? false : (pack.id_package !== selectedPack.id_package ? true : false)), 'pack-blocked' : pack.blocked }">
        <div class="pack-content" @click="selectPack(pack)">
          <div class="pack-level">

            <h2 v-if="pack.original_value == 0">
              <b-field style="padding: 15px">
                <b-input 
                  class="pack-description-sign" 
                  type="number"
                  min="1" 
                  v-model="pack.points" 
                  @input="updatePack(pack)" 
                  required 
                  validation-message="Valor numerico maior do que zero"
                ></b-input>
                <p class="control">
                  <b-button 
                    type="is-success" 
                    icon-pack="fas"
                    icon-left="check"
                    @click="selectPack(pack)" 
                    :disabled="pack.points == 0"
                  ></b-button>
                </p>
              </b-field>
            </h2>

            <h2 v-if="pack.original_value > 0">
              <b-icon pack="fas" icon="coins" type="is-success" size="is-small"></b-icon>
              <small>R$</small> 
              <span class="pack-description-sign">{{ Number(pack.points).toFixed(2).replace('.', ',') }}</span>
            </h2>

            <h2 v-if="pack.valor_bonus">
              <b-icon pack="fas" icon="plus-circle" type="is-info" size="is-small"></b-icon>
              <small>R$</small>
              <span class="pack-description-sign">{{ Number(pack.valor_bonus).toFixed(2).replace('.', ',') }}</span>
            </h2>
          </div>
          <div class="pack-fit-content">
            <p>
              <span>Preço final:</span><br/>
              <span>R$ </span>{{ Number(pack.value).toFixed(2).replace('.', ',') }}
            </p>
            <p>
              <small>{{descPack(pack)}}</small>
            </p>
          </div>
        </div>
      </div> 
    </div>

    <div class="column is-full">
      <b-notification
        type="is-danger"
        has-icon
        :closable="false"
        role="alert"
      >
        <small>O crédito será disponibilizado apenas após a confirmação do pagamento pela operadora. Você receberá um e-mail com a confirmação da operação.</small>
      </b-notification>      
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'Package',
  data() {
    return {
      renderPacks: false,
      packages: [],
      selectedPack: {},
      legenda: {
        credito: false,
        bonus: false
      },
      paymentTx:{
        percent: 0,
        value: 0
      }
    }
  },
  watch: {
    paymentType: function(newTax, oldTax) {
      if (JSON.stringify(newTax) !== JSON.stringify(oldTax)){
        if (newTax.type){
          this.paymentTx = {
            percent: newTax.type.tax_percent,
            value: newTax.type.tax_value
          }
          this.packages.map(pack => {
            this.updatePack(pack)
          })
        }
      }
    },
  },
  computed: {
    ...mapGetters(["paymentType"]),
  },
  async created() {

    let loader = this.$loading.show()

    await this.$http.get(url + '/ligas/packages/')
    .catch(err => {
      console.error('HTTP_ERROR')
      loader.hide()
    })
    .then(response => {
      if (response.data.data) {
        this.packages = response.data.data
        this.packages = this.packages.map((pack,index) => {
          
          if (pack.points > 0){
            this.legenda.credito = true
          }
          
          if (pack.valor_bonus > 0){
            this.legenda.bonus = true
          }
          
          let original_points = pack.points
          let original_value = pack.value

          this.updatePack(pack)

          return {...pack, original_points, original_value}
        })
        this.renderPacks = true
      }
      loader.hide()
    })
  },
  methods: {
    selectPack(packSelect) {

      if (packSelect){

        if (packSelect.blocked == 0){
          
          this.selectedPack = JSON.parse(JSON.stringify(packSelect))
          this.selectedPack.id = this.selectedPack.id_package

          if (this.selectedPack.value > 0){
            this.$emit('select', this.selectedPack)
          }

        }
      }
    },
    updatePack(pack){

      let tx = Number(pack.admtx)
      let value = Number(pack.original_value ? pack.original_value : pack.points)
      
      if (tx > 0){
        tx = (value * (tx/100))
      } else if (this.paymentTx){
        if (this.paymentTx.percent){
          tx = (value * (this.paymentTx.percent/100))
        }
        if (this.paymentTx.value){
          tx += this.paymentTx.value
        }
      }

      pack.tax = tx
      pack.value = value ? (value + pack.tax) : 0
  	},
    descPack(pack){
      
      let desc = ""

      if (pack.tax == 0){
        desc = '(SEM TAXAS)'
      } else {
        let descPoint = Number(pack.points).toFixed(2).replace('.', ',')
        let descTx = Number(pack.tax).toFixed(2).replace('.', ',')
        
        desc = `(R$ ${descPoint} + R$ ${descTx}tx)`
      }
      return desc
    }
  }
}
</script>

<style scoped>

.pack-container {
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
  margin: 5px;
}
.pack-container:hover {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  border: 1px solid rgba(0,255,204,1);
  filter: unset !important;
}
.pack-blocked:hover {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(255, 43, 89, 0.75) !important;
  -moz-box-shadow: 0px 0px 15px -1px rgba(255, 43, 89, 0.75) !important;
  box-shadow: 0px 0px 15px -1px rgba(255, 43, 89, 0.75) !important;
  border: 1px solid rgba(255, 43, 89, 0.75) !important;
  filter: unset !important;
}
.pack-selected {
  -webkit-box-shadow: 0px 0px 20px -2px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 20px -2px rgba(0,255,204,0.75);
  box-shadow: 0px 0px 20px -2px rgba(0,255,204,0.75);
  border: 3px solid rgba(0,255,204,1);
}
.pack-not-selected {
  filter: blur(1.0px);
}
.pack-blocked {
  z-index: 2;
  border-color: #ff3860;
  background-color: #ff38601a;
}

.pack-header {
  width: 100%;
  height: 100%;
  padding: 8px 5px;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
  background: rgba(0,255,204,1);
  background: -moz-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,255,204,1)), color-stop(100%, rgba(0,255,255,1)));
  background: -webkit-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -o-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -ms-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: linear-gradient(to right, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 );
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
  box-shadow: 0px 0px 15px -1px rgba(0,255,204,0.75);
}
.pack-header.points {
  padding: 1px 5px;
}
.pack-header .title {
  font-size: 1.2rem !important;
  color: black !important;
  margin: 0px !important;
}
.pack-header .title-points {
  font-size: 1.0rem !important;
  color: black !important;
  margin: 0px !important;
}

.pack-content {
  /* padding-top: 10px; */
  width: 100%;
  text-align: center;
}
.pack-content small {
  font-size: 0.75rem;
  /* opacity: 0.6; */
}

.pack-level {
  margin: 1rem 0;
  padding: 1rem 0;
}

.pack-fit-content {
  width: 100%;
  padding: 5px 0;
  /* text-transform: uppercase; */
  color: black;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  background: rgba(0,255,204,1);
  background: -moz-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,255,204,1)), color-stop(100%, rgba(0,255,255,1)));
  background: -webkit-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -o-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -ms-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: linear-gradient(to right, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 );
}
.pack-fit-content p {
  line-height: initial;
}
.pack-fit-content span {
  font-size: 0.7rem;
}
.pack-fit-content small {
  font-size: 0.6rem;
}

.pack-description-sign {
  color: white !important;
  font-size: 1.3rem !important;
  /* margin: 5px; */
}

.pay-button-box {
  position: fixed;
  padding: 25px 0;
  background-color: #1a1a1a;
  border-top: 5px solid #9417fa; 
  bottom: 0;
  left: 20.6%;
  width: calc(60% - 1.74rem);
  z-index: 2;
}

</style>