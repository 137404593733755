import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loadingAtletas: false
    }
  },
  computed: {
    ...mapGetters([
      'atletasTable',
      'atletasPage',
      'atletasLimit',
      'atletasTotal',
      'atletasFilter',
      'atletasOrder'
    ])
  },
  methods: {
    ...mapActions([
      'setAtletas',
      'setAtletasPage',
      'setAtletasLimit',
      'setAtletasTotal',
      'setAtletasFilter',
      'setAtletasOrder'
    ]),
    async getPlayerStatus() {
      const response = (await this.$http.get(`${url}/atletas/status`)).data.data;
      return response.map((status) => {
        return {
          id: status.id_status_atleta,
          label: status.ds_status_atleta
        }
      });
    },
    async getAtletas() {
      try {

        this.loadingAtletas = true

        let query = (this.atletasPage ? '?page=' + this.atletasPage  : '')
        query += (this.atletasLimit ? (((query !== '') ? '&' : '?') + 'limit=' + this.atletasLimit) : "")

        let _url = url + '/v2/atletas' + query
        let params = JSON.parse(JSON.stringify(this.atletasFilter));
        let order = JSON.parse(JSON.stringify(this.atletasOrder));

        let res = (await this.$http.post(_url, {params, order})).data.data

        this.setAtletas(res.lista)
        this.setAtletasTotal(res.total)

        this.loadingAtletas = false

        return res.lista

      } catch (err) {
        this.loadingAtletas = true
      }
    },

    async orderAtletas(field, type){
      // let order = {field, type: 'ASC'}
      // if (field == this.atletasOrder.field){
      //   order.type = (this.atletasOrder.type == 'DESC') ? 'ASC' : 'DESC'
      // }
      // this.setAtletasOrder(order)
      this.setAtletasOrder({
        field,
        type
      })
    }
  }
}