<template>
  <b-dropdown class="rp-dropdown" position="is-bottom-left" ref="rpdropdown">
    <b-tooltip
      slot="trigger"
      label="Relatar um problema"
      position="is-left"
      type="is-pigeon"
    >
      <b-icon
        v-if="!game"
        pack="mdi"
        icon="face-agent"
        class="rp-icon"
        danger
      ></b-icon>
      <img
        v-else
        :game="game"
        class="rp-icon"
        src="@/assets/headphones.svg"
        alt="MNIGAME"
      />
    </b-tooltip>

    <div class="rp-container">
      <div class="rp-content">
        <p>Deseja falar com o <b>suporte</b>?</p>
      </div>
      <b-button class="rp-bt" nope @click="handlerAnswer('nope')">Não</b-button>
      <b-button class="rp-bt" yes @click="handlerAnswer('yes')">Sim</b-button>
    </div>
  </b-dropdown>
</template>

<script>
import WhatsAppMixin from "@/_mixins/whatsapp";
import { mapGetters } from "vuex";

export default {
  name: "Report",
  mixins: [WhatsAppMixin],
  props: {
    sala: {
      type: Object,
      default: () => {},
      required: true,
    },
    game: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["userId", "userInfo"]),
  },
  methods: {
    handlerAnswer(answer) {
      if (answer === "nope") {
        this.$refs.rpdropdown.toggle();
      } else if (answer === "yes") {
        const text = `Olá! Eu sou ${this.userInfo.nome} (${
          this.userInfo.email
        }). Tenho dúvidas com relação a liga ${this.sala.liga.tipo} do ${
          this.sala.influencer.nome_midia
        } ${this.sala.liga.apuracao} ${
          this.sala.capitao ? "SEM CAPITÃO" : "COM CAPITÃO"
        } (ID ${this.sala.id})`;
        this.sendMessageToZap({ text });
      }
    },
  },
};
</script>
