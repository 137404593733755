<template>
  <b-dropdown
    class="sh-dropdown"
    :mobile-modal="false"
    position="is-bottom-left"
  >
    <b-tooltip
      slot="trigger"
      label="Compartilhar Liga"
      position="is-left"
      type="is-pigeon"
    >
      <b-icon
        v-if="!game"
        pack="fas"
        icon="share-alt"
        class="sh-icon"
        link
      ></b-icon>
      <img
        v-else
        :game="game"
        class="sh-icon"
        src="@/assets/share_arrow.svg"
        alt="MNIGAME"
      />
    </b-tooltip>

    <div class="sh-container">
      <div class="sh-content">
        <h3 class="sh-title">Compartilhar Com</h3>

        <input type="hidden" :id="id" :value="url" />

        <div
          class="columns is-multiline is-mobile is-vcentered is-variable is-2"
        >
          <div class="column is-narrow">
            <b-button class="sh-icons" type="is-pigeon" @click="copyUrl()">
              <b-icon icon="copy" pack="fas"></b-icon>
            </b-button>
          </div>
          <div
            class="column is-narrow"
            v-for="(midia, index) of socialMidias"
            :key="index"
          >
            <ShareNetwork
              :network="midia.name"
              :url="url"
              :title="title"
              :description="description"
              :quote="quote"
              :hashtags="hashtags"
            >
              <div class="sh-icons">
                <b-icon
                  :pack="midia.pack"
                  :icon="midia.icon"
                  size="is-medium"
                  type="is-white"
                ></b-icon>
              </div>
            </ShareNetwork>
          </div>
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: "ShareLigas",
  props: {
    url: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    quote: {
      type: String,
      default: "",
    },
    hashtags: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    game: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      socialMidias: [
        { name: "facebook", pack: "fab", icon: "facebook-f" },
        { name: "telegram", pack: "fab", icon: "telegram-plane" },
        { name: "twitter", pack: "fab", icon: "twitter" },
        { name: "whatsapp", pack: "fab", icon: "whatsapp" },
      ],
    };
  },
  methods: {
    copyUrl() {
      const copyText = document.querySelector(`#${this.id}`);
      copyText.setAttribute("type", "text");
      copyText.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);
      this.$toasted.show("Link Copiado!", {
        position: "top-center",
        duration: 2000,
        type: "success",
        className: "toasted-class",
      });

      /* unselect the range */
      copyText.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>
