<template>
  <b-dropdown class="tm-dropdown" :mobile-modal="true">
    <b-tooltip
      slot="trigger"
      class="tm-saldo-trigger"
      label="Meus times do Cartola"
      position="is-right"
      type="is-pigeon"
    >
      Meus Times
      <b-tag
        v-if="$mq !== 'sm'"
        size="is-small"
        type="is-primary"
        :style="{ marginLeft: '0.5em' }"
        rounded
        >{{ getLigasTimesUser.length }}</b-tag
      >
      <b-icon
        v-if="$mq === 'sm' ? !hideIconMobile && !hideIcon : !hideIcon"
        icon="chevron-down"
        pack="fas"
        :style="{ fontSize: '7px', opacity: 0.7, lineHeight: '8px' }"
      ></b-icon>
    </b-tooltip>

    <div class="tm-container">
      <div class="tm-content">
        <h3 class="tm-title">
          Times
          <b-tag
            v-if="$mq === 'sm'"
            size="is-small"
            type="is-primary"
            rounded
            >{{ getLigasTimesUser.length }}</b-tag
          >
        </h3>
        <div
          class="columns is-multiline is-gapless is-mobile"
          :style="{ marginTop: '0.5em' }"
        >
          <div
            v-for="time of getLigasTimesUser"
            :key="time.nm_time_cartola"
            class="column is-full"
          >
            <div class="tm-item">
              <div class="tm-align-time">
                <img
                  :src="time.url_escudo_svg"
                  style="height: 25px"
                />
                <label>{{ time.nm_time_cartola }}</label>
              </div>
              <div class="tm-align-bt">
                <b-tooltip type="is-pigeon" label="Ir para Desempenho do Time">
                  <b-button
                    tag="router-link"
                    :to="`/painel/desempenho/${time.id_cartola}`"
                    type="is-pigeon"
                    size="is-small"
                    icon-pack="fas"
                    icon-right="external-link-alt"
                    :style="{ marginRight: '0.5em', height: '30px' }"
                  ></b-button>
                </b-tooltip>
                <b-tooltip type="is-pigeon" label="Excluir Time">
                  <b-button
                    @click="deleteTeam(time)"
                    size="is-small"
                    type="is-danger"
                    icon-right="delete"
                  ></b-button>
                </b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-button
        class="tm-bt-add"
        @click="openAddTeam()"
        expanded
        type="is-success"
      >
        Adicionar Time
      </b-button>
    </div>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalAdicionarTime from "./Times/ModalAdicionarTime";

export default {
  name: "Times",
  components: {
    ModalAdicionarTime,
  },
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideIconMobile: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["userId", "getLigasTimesUser"]),
  },
  async created() {
    await this.getLigasTimesCartola(this.userId);
  },
  methods: {
    ...mapActions(["getLigasTimesCartola", "disableTeam"]),
    openAddTeam() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAdicionarTime,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    deleteTeam(time) {
      this.$buefy.dialog.confirm({
        title: "Excluir Time",
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        message: "Tem certeza que deseja excluir seu time?",
        canCancel: true,
        confirmText: "Sim, desejo excluir",
        cancelText: "Cancelar",
        size: "is-small",
        onConfirm: async () => {
          try {
            let res = await this.disableTeam({
              userId: this.userId,
              teamId: time.id_escala,
            });

            if (res.data) {
              this.getLigasTimesCartola(this.userId);

              this.$toasted.show("Time Excluido Com Sucesso!", {
                position: "top-center",
                duration: 3000,
                type: "success",
                className: "toasted-class",
              });
            }
          } catch (err) {
            if (err.status == 400) {
              this.$toasted.error("Time está inscrito em uma Liga!", {
                position: "top-center",
                duration: 3000,
              });
            } else {
              this.$toasted.error("Erro ao Excluir Time", {
                position: "top-center",
                duration: 3000,
              });
            }
          }
        },
      });
    },
  },
};
</script>
