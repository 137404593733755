<template>
  <LayoutLeagues
    id="ligas-section"
    :is-loading="isLoading"
    v-bind="layoutLeagueProperties"
    @click="handlerEveryClick"
  >
    <HeaderLeagues
      :influencers="influencers"
      :filter-fields="filterFields"
      :default-filters="filters"
      :is-influencer-page="isInfluencerPage"
      @change-filters="handleFiltersChange"
    >
      <BannerInfluencer
        v-if="isInfluencerPage && influencerPage"
        slot="influencer-banner"
        :influencer="influencerPage"
      ></BannerInfluencer>
    </HeaderLeagues>
    <b-loading :active.sync="isLoading"></b-loading>
    <div v-if="!isLoading" class="league-container">
      <b-loading :active.sync="isLoadingLeagues"></b-loading>
      <div v-if="!isLoadingLeagues">
        <CategoriesManager
          v-if="view === 1 && !emptyCategoriesLeagues"
          :user-leagues="userLeagues"
          :loading="isLoadingLeagues"
          :categories="categoriesVerified"
          @login="doLogin"
          @open-category="handleOpenCategory"
        ></CategoriesManager>
        <div v-if="emptyCategoriesLeagues" class="section has-text-centered">
          <div class="mg-not-found">
            <b-icon
              icon="surprise"
              pack="far"
              size="is-large"
              type="is-pigeon"
            ></b-icon>
            <b-icon
              icon="question"
              pack="fas"
              size="is-large"
              type="is-pigeon"
            ></b-icon>
          </div>
          <h4
            class="has-text-pigeon"
            :class="{ 'is-size-4': $mq !== 'sm', 'is-size-6': $mq === 'sm' }"
          >
            Nenhuma Liga foi encontrada
          </h4>
          <p class="has-text-pigeon">Tente novamente com outros filtros!</p>
        </div>
      </div>
    </div>
    <NofityAway ref="notify"></NofityAway>
    <ShoppingCart></ShoppingCart>
  </LayoutLeagues>
</template>

<script>
import CategoriesManager from "../components/CategoriesManager.vue";
import LeaguesManager from "../components/LeaguesManager.vue";
import BannerInfluencer from "@/apps/Ligas/components/BannerInfluencer.vue";

import LeaguesMixin from "@/apps/Ligas/mixins/leagues";
import CategoriesMixin from "@/apps/Ligas/mixins/categories";

export default {
  name: "Leagues",
  mixins: [LeaguesMixin, CategoriesMixin],
  components: {
    CategoriesManager,
    LeaguesManager,
    BannerInfluencer,
  },
  data() {
    return {
      isLoadingLeagues: true,
      isLoading: true,
      influencers: [],
      categories: [],
      leagues: [],
      userLeagues: [],
      filterFields: {},
      leaguesWithoutCategory: [],
      filters: {
        modalidade: ["liga", "game"],
        status: ["pre_venda", "aberta"],
        tipo: [],
        apuracao: [],
        capitao: [],
        valor: [0, 1000],
      },
      view: 1,
    };
  },
  computed: {
    categoriesVerified() {
      return this.categories.map((categorie) => {
        if (this.isInfluencerPage) {
          categorie.leagues = categorie.leagues.filter(
            (room) => room.id_influencer === this.influencerPage.id
          );
        }
        return categorie;
      });
    },
    emptyCategoriesLeagues() {
      const hasCategoriesWithLeague = this.categories.find(
        (category) => category.leagues.length > 0
      );
      return !!!hasCategoriesWithLeague;
    },
  },
  watch: {
    filters() {
      this.loadInfluencers().then((influencersResponse) => {
        this.setInfluencers(influencersResponse.data.data);
      });
    },
  },
  async created() {
    this.isLoading = true;

    this.getMercado();

    let promiseInitialLoad = [
      this.loadCategories(),
      this.loadFiltersFields(),
      this.loadInfluencers(),
    ];

    if (!this.isPublic) {
      promiseInitialLoad.push(this.getUserData());
    }

    if (this.isInfluencerPage) {
      this.handleWhenIsInfluencerPage();
    }

    Promise.all(promiseInitialLoad).then(
      ([categoriesResponse, filterFieldsResponse, influencersResponse]) => {
        this.setCategories(categoriesResponse.data.data);
        this.setFilterFields(filterFieldsResponse.data.data);
        this.setInfluencers(influencersResponse.data.data);

        this.isLoading = false;
        this.loadAllCategories();
      }
    );
  },
  methods: {
    getRoomsByQuery() {
      const paramsQuery = {};
      if (Object.keys(this.$route.query).length > 0) {
        Object.entries(this.$route.query).forEach(([key, value]) => {
          paramsQuery[key] = value.split("-");
        });
      }

      const leaguesByQuery = {};

      this.leaguesWithoutCategory.forEach((room) => {
        if (paramsQuery.ligas?.includes(room.liga.id_liga.toString())) {
          leaguesByQuery[room.id] = room;
        }
      });

      const leagues = Object.entries(leaguesByQuery).map(
        ([key, value]) => value
      );

      const categoryDefaultIndex = this.categories.findIndex(
        (c) => c.id === -1
      );
      if (categoryDefaultIndex != -1) {
        this.categories[categoryDefaultIndex].leagues = leagues;
      } else {
        this.categories.unshift({
          descricao: "Para você",
          id: -2,
          leagues,
          ordem: 0,
          slug: "para-voce",
        });
      }
    },
    handleFiltersChange(filters) {
      this.filters = { ...filters };
      this.loadUserLeagues();
      this.loadAllCategories();
    },
    handleOpenCategory(category) {
      let route = `/ligas/c/${category.slug}`;
      if (this.isInfluencerPage) {
        route = `/ligas/${this.influencerPage.slug}/c/${category.slug}`;
      }
      const routeData = this.$router.resolve(route);
      window.open(routeData.href, "_blank");
    },
    handlerEveryClick() {
      this.$refs.notify.handlerEveryClick();
    },
    doLogin() {
      this.setPublic(false);
      Promise.all([
        this.getUserData(),
        this.getLigasTimesCartola(this.userId),
        this.getWallet(this.userId),
        this.loadAllCategories(),
      ]).then(() => {
        this.loadUserLeagues();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.league-container {
  padding-bottom: 5rem;
}
</style>
