import qs from "qs";
import LigasService from "@/apps/Ligas/services/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      categories: [],
    };
  },
  computed: {
    ...mapGetters(["userId"]),
  },
  methods: {
    loadCategories() {
      return LigasService.getCategories();
    },
    setCategories(categoreis) {
      this.categories = categoreis;
    },
    loadLeaguesByCategory(idCategory) {
      const filters = this.makeFiltersToQuery();
      const params = {
        ...filters,
        categoria: idCategory,
      };

      if (this.userId) {
        params.userId = this.userId;
      }
      const queryParams = qs.stringify(params);
      return LigasService.getLeaguesByCategory(queryParams);
    },
    async loadAllCategories() {
      const promises = [];
      let categories = [...this.categories];

      categories.forEach((category) => {
        promises.push(this.loadLeaguesByCategory(category.id));
      });

      this.isLoadingLeagues = true;
      await Promise.all(promises).then((response) => {
        categories = categories.map((category, index) => {
          const categoryLeagues = response[index].data.data;

          const leagues = this.getLeaguesFormatted(categoryLeagues);
          this.leaguesWithoutCategory.push(...leagues);
          return {
            ...category,
            leagues,
          };
        });
      });
      const leaguesResponse = (await this.getuserLeaguesLikeCategory()).data
        .data;
      const leagues = this.getLeaguesFormatted(leaguesResponse);
      categories.unshift({
        descricao: "Minhas Ligas",
        id: null,
        leagues,
        ordem: null,
        slug: "minhas-ligas",
      });
      this.categories = categories;

      this.getRoomsByQuery();
      this.isLoadingLeagues = false;
    },
    getuserLeaguesLikeCategory() {
      const { status, ...filters } = this.makeFiltersToQuery();
      const newStatus = [
        "aberta",
        "pre_venda",
        "em_andamento",
        "encerrada",
      ].join(",");

      const queryParams = qs.stringify({
        ...filters,
        status: newStatus,
      });
      return LigasService.getLeaguesByUser(this.userId, queryParams);
    },
  },
};
