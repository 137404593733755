<template>
  <div id="mini-game-atletas" class="columns is-gapless is-mobile">
    <!-- Tabela  -->
    <div class="column is-full">
      <b-table
        blueDark2
        v-if="atletas.length"
        ref="table"
        :data="atletas"
        :sticky-header="$mq == 'sm' ? false : true"
        :height="$mq=='sm' ? '100%' : heightTable"
        :loading="loadingTable"
        :default-sort="ordInMobile"
        default-sort-direction="desc"
        :mobile-cards="false"
        narrowed
        detailed
        detail-key="id"
        :show-detail-icon="$mq == 'sm' ? false : showDetailIcon"
        @details-open="(row, a) => getDetails(row)"
        backend-pagination
        :paginated="atletasPaginated"
        pagination-size="is-small"
        :pagination-position="$mq == 'sm' ? 'both' : 'bottom'"
        :per-page="atletasLimit"
        :current-page="atletasPage"
        :total="atletasTotal"
        @page-change="(page) => changePage(page)"
        backend-sorting
        @sort="sortPressed"
      >
        <!-- :height="$mq == 'sm' ? '100%' : '68vh'" -->
        <!-- :height="heightTable" -->
        <!-- :show-header="$mq !== 'sm'" -->
        <!-- :sticky-header="true" -->
        
        <template slot-scope="props" slot="header">
          <div v-if="props.column.label == 'Saldo Cartoletas'">
            <p class="is-size-7 has-text-warning">{{props.column.label}}</p>
            <p class="is-size-6 has-text-warning">C$ {{saldoCartoletas.toFixed(2)}}</p>
          </div>
          <div v-else-if="props.column.label == 'Saldo Cartoletas Reserva'">
            <p class="is-size-7 has-text-warning">{{props.column.label}}</p>
            <p class="is-size-6 has-text-warning">C$ {{saldoCartoletasReserva.toFixed(2)}}</p>
          </div>
          <div v-else-if="props.column.sortable" class="label-table centralize-row" @click="statusSort(props.index)">
            <span v-if="props.column.sortable && showVisibleSort[props.index]">
              <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
            </span>
            <p>{{ props.column.label }}</p>
          </div>
          <div v-else class="label-table centralize-row">
            <div v-if="props.column.label == 'MobileCard'">
              <p v-if="!bancoReserva" class="is-size-6 has-text-warning">
                Saldo Cartoletas C$ {{saldoCartoletas.toFixed(2)}}
              </p>
              <p v-if="bancoReserva" class="is-size-6 has-text-warning">
                Saldo Cartoletas Reserva C$ {{saldoCartoletasReserva.toFixed(2)}}
              </p>
            </div>
            <p v-else>{{ props.column.label }}</p>
          </div>
        </template>

        <template slot-scope="props">

            <b-table-column :visible="$mq !== 'sm'" field="apelido" label="Atleta" sticky sortable>
              <div class="columns is-mobile is-gapless" style="width: 110px;">
                <div class="column is-narrow">
                  <div :class="'foto-atleta has-background-'+formatterStatus(props.row.statusIcone).type"  style="padding: 8px;">
                    <img v-if="props.row.foto && props.row.foto.indexOf('emptystate') == -1" :src="props.row.foto" :alt="props.row.apelido" @click="toggle(props.row)" style="cursor: pointer;">
                    <b-icon v-else icon="user" pack="fas" size="is-medium" type="is-grey"></b-icon>
                    <!-- <div v-else class="has-text-grey centralize" style="position: relative; top: 10px;">
                      <b-icon icon="user-slash" pack="fas" ></b-icon>
                    </div> -->
                  </div>
                  <div class="escudo-clube">
                    <img :src="props.row.time_escudo" :alt="props.row.time_nome">
                  </div>
                </div>
                
                <div class="column is-narrow" @click="toggle(props.row)">
                  <div class="align-scouts-atl">
                    <p class="nome-jogador" style="margin-right: 5px;" v-html="props.row.apelido.replace(' ','<br>')"></p>
                    <span class="legend-position" @click="toggle(props.row)">{{ props.row.posicao_codigo | uppercase }}</span>
                    <!-- <b-icon :icon-pack="formatterStatus(props.row.statusIcone).pack" size="is-small" :icon="formatterStatus(props.row.statusIcone).icon" :type="formatterStatus(props.row.statusIcone).type" style="margin-right: 10px;"></b-icon> -->
                  </div>
                </div>
              </div>
            </b-table-column>

            <b-table-column :visible="$mq !== 'sm'" @click="toggle(props.row)" field="preco" label="Preço" sortable centered >
              <p class="numeric-table has-text-warning is-size-6">C$ {{ props.row.preco.toFixed(2) }}</p>
            </b-table-column>

            <b-table-column :visible="$mq !== 'sm' && !bancoReserva" label="Saldo Cartoletas" centered >
              <b-button v-if="props.row.buy" :loading="loadingTable" type="is-primary" size="is-small" @click="buy(props.row.posicao_codigo, props.row)">Comprar</b-button>
              <b-button v-if="props.row.sell" :loading="loadingTable" type="is-danger" size="is-small" @click="sell(props.row.posicao_codigo, props.row)">Vender</b-button>
              <b-tooltip v-if="props.row.unavailable && props.row.unavailable !== ''" :label="props.row.unavailable" type="is-warning" position="is-right" animated multilined>
                <b-tag v-if="props.row.unavailable && props.row.unavailable !== ''" :loading="loadingTable" type="is-warning" size="is-small">Indisponível</b-tag>
              </b-tooltip>
              <b-button v-if="!props.row.buy && !props.row.sell && !props.row.unavailable" :loading="!props.row.buy && !props.row.sell && !props.row.unavailable" type="is-warning" size="is-small" >Carregando...</b-button>
            </b-table-column>

            <b-table-column :visible="$mq !== 'sm' && bancoReserva" label="Saldo Cartoletas Reserva" centered >
              <b-button v-if="props.row.buy_reserva" :loading="loadingTable" type="is-primary" size="is-small" @click="buy('reserva', props.row)">Comprar</b-button>
              <b-button v-if="props.row.sell_reserva" :loading="loadingTable" type="is-danger" size="is-small" @click="sell('reserva', props.row)">Vender</b-button>
              <b-tooltip v-if="props.row.unavailable_reserva && props.row.unavailable_reserva !== ''" :label="props.row.unavailable_reserva" type="is-warning" position="is-right" animated multilined>
                <b-tag v-if="props.row.unavailable_reserva && props.row.unavailable_reserva !== ''" :loading="loadingTable" type="is-warning" size="is-small">Indisponível</b-tag>
              </b-tooltip>
              <b-button v-if="!props.row.buy_reserva && !props.row.sell_reserva && !props.row.unavailable_reserva" :loading="!props.row.buy_reserva && !props.row.sell_reserva && !props.row.unavailable_reserva" type="is-warning" size="is-small" >Carregando...</b-button>
            </b-table-column>

            <b-table-column :visible="$mq !== 'sm' && !team.scouts_todos" v-for="(gameScout) of team.scouts" :key="gameScout" @click="toggle(props.row)" :field="'media_'+gameScout" :label="gameScout" sortable centered>
              <span class="numeric-table">{{ props.row['media_'+gameScout].toFixed(1) }}</span>
            </b-table-column>

            <b-table-column :visible="$mq !== 'sm' && team.scouts_todos" key="media" @click="toggle(props.row)" field="media" label="Média Scout" sortable centered>
              <span class="numeric-table">{{ props.row['media'].toFixed(1) }}</span>
            </b-table-column>

            <b-table-column :visible="$mq !== 'sm'" @click="toggle(props.row)" field="media" label="Média" sortable centered>
              <p class="numeric-table">{{ props.row.media.toFixed(1) }}</p>
              <small class="subtitle-atl">({{ props.row.jogos }} {{ jogos(props.row.jogos) }})</small>
            </b-table-column>

            <b-table-column :visible="$mq !== 'sm'" @click="toggle(props.row)" label="Confronto" centered >
              <div class="jogador-campo">
                <div class="round-team-atl">
                  <img v-if="props.row.mando == 'casa'" :src="props.row.time_escudo" :alt="props.row.time_nome" width="30">
                  <img v-else :src="props.row.time_vis_escudo" :alt="props.row.time_vis_nome" width="30">
                </div>
                <span style="margin: 0 10px 0 10px">X</span>
                <div class="round-team-atl">
                  <img v-if="props.row.mando == 'casa'" :src="props.row.time_vis_escudo" :alt="props.row.time_vis_nome" width="30">
                  <img v-else :src="props.row.time_escudo" :alt="props.row.time_nome" width="30">
                </div>
              </div>
            </b-table-column>

            <b-table-column :visible="$mq == 'sm'" label="MobileCard" centered>

              <div class="card has-background-bluedark">
                <div class="columns is-mobile is-gapless">
                  <div class="column is-narrow" @click="toggle(props.row)">
                    <div :class="'foto-atleta has-background-'+formatterStatus(props.row.statusIcone).type"  style="padding: 8px;">
                      <img v-if="props.row.foto && props.row.foto.indexOf('emptystate') == -1" :src="props.row.foto" :alt="props.row.apelido" style="cursor: pointer;">
                      <b-icon v-else icon="user" pack="fas" size="is-medium" type="is-grey"></b-icon>
                    </div>
                    <div class="escudo-clube">
                      <img :src="props.row.time_escudo" :alt="props.row.time_nome">
                    </div>
                    <div class="centralize" :style="{width: '100%'}">
                      <div class="title is-size-7">C$ {{ props.row.preco.toFixed(2).replace(".",",") }}</div>
                    </div>
                  </div>
                  
                  <div class="column has-text-left" @click="toggle(props.row)" style="padding: 0.6em !important;">
                    <p>
                      <span class="title is-size-6">{{props.row.apelido}} - {{ props.row.posicao_codigo | uppercase }}</span>
                    </p>
                    <p>
                      <label class="subtitle">Média de Scouts</label>
                    </p>
                    <div class="columns is-multiline is-mobile is-gapless" style="margin: 0 !important;">
                      <div class="column is-narrow"
                        v-for="(scout) of team.scouts" 
                        v-show="props.row['media_'+scout] > 0"
                        :key="scout"
                      >
                        <p
                          v-if="props.row['media_'+scout] > 0"
                          class="is-size-7"
                          :class="props.row.scouts.find(s => s.sigla == scout).analise == '+' ? 'has-text-success' : 'has-text-danger'"
                          :style="{'margin-right': '5px'}"
                        >
                          {{props.row['media_'+scout].toFixed(1)}} {{scout}}
                        </p>
                      </div>
                    </div>
                    <p class="is-size-7">
                      <span>{{ props.row.media.toFixed(1) }} pontos/jogos   {{ props.row.jogos }} jogo(s)</span>
                    </p>
                  </div>
                  <div class="column is-narrow" style="padding: 0.6em 0 !important;">
                    <div @click="toggle(props.row)">
                      <label class="title">Confronto</label>
                      <div class="columns is-mobile is-centered is-gapless">
                        <div class="column is-narrow is-vcentered">
                          <img v-if="props.row.mando == 'casa'" :src="props.row.time_escudo" :alt="props.row.time_nome" width="30">
                          <img v-else :src="props.row.time_vis_escudo" :alt="props.row.time_vis_nome" width="30">
                        </div>
                        <div class="column is-narrow is-vcentered" style="margin: 0 0.5em;">
                          <span>X</span>
                        </div>
                        <div class="column is-narrow is-vcentered">
                          <img v-if="props.row.mando == 'casa'" :src="props.row.time_vis_escudo" :alt="props.row.time_vis_nome" width="30">
                          <img v-else :src="props.row.time_escudo" :alt="props.row.time_nome" width="30">
                        </div>
                      </div>
                    </div>
                    <div v-if="!bancoReserva">
                      <b-button v-if="props.row.buy" :loading="loadingTable" type="is-primary" size="is-small" @click="buy(props.row.posicao_codigo, props.row)" >Comprar</b-button>
                      <b-button v-if="props.row.sell" :loading="loadingTable" type="is-danger" size="is-small" @click="sell(props.row.posicao_codigo, props.row)" >Vender</b-button>
                      <b-tooltip v-if="props.row.unavailable && props.row.unavailable !== ''" :label="props.row.unavailable" size="is-small" type="is-warning" position="is-bottom" animated multilined>
                        <b-button v-if="props.row.unavailable && props.row.unavailable !== ''" :loading="loadingTable" size="is-small" type="is-warning" >Indisponível</b-button>
                      </b-tooltip>
                      <b-button v-if="!props.row.buy && !props.row.sell && !props.row.unavailable" :loading="!props.row.buy && !props.row.sell && !props.row.unavailable" type="is-warning" size="is-small" >Carregando...</b-button>
                    </div>
                    <div v-if="bancoReserva">
                      <b-button v-if="props.row.buy_reserva" :loading="loadingTable" type="is-primary" size="is-small" @click="buy('reserva', props.row)" >Comprar</b-button>
                      <b-button v-if="props.row.sell_reserva" :loading="loadingTable" type="is-danger" size="is-small" @click="sell('reserva', props.row)" >Vender</b-button>
                      <b-tooltip v-if="props.row.unavailable_reserva && props.row.unavailable_reserva !== ''" :label="props.row.unavailable_reserva" type="is-warning" size="is-small" position="is-bottom" animated multilined>
                        <b-tag v-if="props.row.unavailable_reserva && props.row.unavailable_reserva !== ''" :loading="loadingTable" type="is-warning" size="is-small">Indisponível</b-tag>
                      </b-tooltip>
                      <b-button v-if="!props.row.buy_reserva && !props.row.sell_reserva && !props.row.unavailable_reserva" :loading="!props.row.buy_reserva && !props.row.sell_reserva && !props.row.unavailable_reserva" type="is-warning" size="is-small">Carregando...</b-button>
                    </div>
                  </div>
                </div>
              </div>

            </b-table-column>

        </template>

        <template slot="detail" slot-scope="props">
          
          <b-tabs 
            v-if="props.row.details" 
            v-model="props.row.defaultDetail" 
            type="is-boxed" 
            size="is-small" 
            :vertical="$mq !== 'sm'"
            :expanded="$mq == 'sm'"
            @change="props.row.showDetailTab = false; props.row.showDetailTab = true"
          >

            <b-tab-item label="Casa">
              <div class="columns is-gapless">
                <div class="column is-4 has-background-bluedark2 atleta-detail-column">
                  <div class="atleta-detail-column-title">
                    ÚLTIMOS 3 JOGOS
                  </div>
                  <div class="atleta-detail-column-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Conq.</th>
                          <th></th>
                          <th>Ced.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="scout of props.row.details.casa.ult3" :key="'casa3'+scout.sigla">
                          <td>{{ (scout.conquistado ? scout.conquistado : 0).toFixed(1) }}</td>
                          <td>{{ scout.descricao }}</td>
                          <td>{{ (scout.cedido ? scout.cedido : 0).toFixed(1) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="column is-4 has-background-bluedark2 atleta-detail-column">
                  <div class="atleta-detail-column-title">
                    ÚLTIMOS 5 JOGOS
                  </div>
                  <div class="atleta-detail-column-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Conq.</th>
                          <th></th>
                          <th>Ced.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="scout of props.row.details.casa.ult5" :key="'casa5'+scout.sigla">
                          <td>{{ (scout.conquistado ? scout.conquistado : 0).toFixed(1) }}</td>
                          <td>{{ scout.descricao }}</td>
                          <td>{{ (scout.cedido ? scout.cedido : 0).toFixed(1) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="column is-4 has-background-bluedark2 atleta-detail-column">
                  <div class="atleta-detail-column-title">
                    TODOS OS JOGOS
                  </div>
                  <div class="atleta-detail-column-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Conq.</th>
                          <th></th>
                          <th>Ced.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="scout of props.row.details.casa.todos" :key="'casatodos'+scout.sigla">
                          <td>{{ (scout.conquistado ? scout.conquistado : 0).toFixed(1) }}</td>
                          <td>{{ scout.descricao }}</td>
                          <td>{{ (scout.cedido ? scout.cedido : 0).toFixed(1) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="Fora">
              <div class="columns is-gapless">
                <div class="column is-4 has-background-bluedark2 atleta-detail-column">
                  <div class="atleta-detail-column-title">
                    ÚLTIMOS 3 JOGOS
                  </div>
                  <div class="atleta-detail-column-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Conq.</th>
                          <th></th>
                          <th>Ced.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="scout of props.row.details.fora.ult3" :key="'fora3'+scout.sigla">
                          <td>{{ (scout.conquistado ? scout.conquistado : 0).toFixed(1) }}</td>
                          <td>{{ scout.descricao }}</td>
                          <td>{{ (scout.cedido ? scout.cedido : 0).toFixed(1) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="column is-4 has-background-bluedark2 atleta-detail-column">
                  <div class="atleta-detail-column-title">
                    ÚLTIMOS 5 JOGOS
                  </div>
                  <div class="atleta-detail-column-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Conq.</th>
                          <th></th>
                          <th>Ced.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="scout of props.row.details.fora.ult5" :key="'fora5'+scout.sigla">
                          <td>{{ (scout.conquistado ? scout.conquistado : 0).toFixed(1) }}</td>
                          <td>{{ scout.descricao }}</td>
                          <td>{{ (scout.cedido ? scout.cedido : 0).toFixed(1) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="column is-4 has-background-bluedark2 atleta-detail-column">
                  <div class="atleta-detail-column-title">
                    TODOS OS JOGOS
                  </div>
                  <div class="atleta-detail-column-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Conq.</th>
                          <th></th>
                          <th>Ced.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="scout of props.row.details.fora.todos" :key="'foratodos'+scout.sigla">
                          <td>{{ (scout.conquistado ? scout.conquistado : 0).toFixed(1) }}</td>
                          <td>{{ scout.descricao }}</td>
                          <td>{{ (scout.cedido ? scout.cedido : 0).toFixed(1) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="Ambos">
              <div class="columns is-gapless">
                <div class="column is-4 has-background-bluedark2 atleta-detail-column">
                  <div class="atleta-detail-column-title">
                    ÚLTIMOS 3 JOGOS
                  </div>
                  <div class="atleta-detail-column-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Conq.</th>
                          <th></th>
                          <th>Ced.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="scout of props.row.details.ambos.ult3" :key="'ambos3'+scout.sigla">
                          <td>{{ (scout.conquistado ? scout.conquistado : 0).toFixed(1) }}</td>
                          <td>{{ scout.descricao }}</td>
                          <td>{{ (scout.cedido ? scout.cedido : 0).toFixed(1) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="column is-4 has-background-bluedark2 atleta-detail-column">
                  <div class="atleta-detail-column-title">
                    ÚLTIMOS 5 JOGOS
                  </div>
                  <div class="atleta-detail-column-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Conq.</th>
                          <th></th>
                          <th>Ced.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="scout of props.row.details.ambos.ult5" :key="'ambos5'+scout.sigla">
                          <td>{{ (scout.conquistado ? scout.conquistado : 0).toFixed(1) }}</td>
                          <td>{{ scout.descricao }}</td>
                          <td>{{ (scout.cedido ? scout.cedido : 0).toFixed(1) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="column is-4 has-background-bluedark2 atleta-detail-column">
                  <div class="atleta-detail-column-title">
                    TODOS OS JOGOS
                  </div>
                  <div class="atleta-detail-column-content">
                    <table>
                      <thead>
                        <tr>
                          <th>Conq.</th>
                          <th></th>
                          <th>Ced.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="scout of props.row.details.ambos.todos" :key="'ambostodos'+scout.sigla">
                          <td>{{ (scout.conquistado ? scout.conquistado : 0).toFixed(1) }}</td>
                          <td>{{ scout.descricao }}</td>
                          <td>{{ (scout.cedido ? scout.cedido : 0).toFixed(1) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-tab-item>

          </b-tabs>
        </template>

        <template slot="bottom-left">
          <b-field
            label="Atletas/Página"
            label-position="on-border"
            :style="'width: ' + ($mq !== 'sm' ? '150px' : '100%')"
          >
            <b-select v-model="atletasPerPage" size="is-small" expanded>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option :value="atletasTotal">Todos</option>
            </b-select>
          </b-field>
        </template>

        <template slot="empty">
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p>
                        <b-icon
                            icon="emoticon-sad"
                            size="is-large">
                        </b-icon>
                    </p>
                    <p>Sem Registros.</p>
                </div>
            </section>
        </template>

      </b-table>

      <section v-else class="section has-background-bluedark">
          <div class="content has-text-grey has-text-centered">
              <p>
                  <b-icon
                      icon="emoticon-sad"
                      size="is-large">
                  </b-icon>
              </p>
              <p>Sem Registros.</p>
          </div>
      </section>
    </div>
    <!-- Final Tabela -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AtletasMixin from "@/modules/requests/Atletas";

export default {
  name: 'EscalarTimeAtletas',
  mixins: [AtletasMixin],
  props: {
    team: {
      type: Object,
      required: true,
    },
    atletas: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false
    },
    bancoReserva: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingTable: false,
      loadingDetail: false,
      showDetailIcon: false,
      page: 0,
      ordInMobile: 'preco',
      showVisibleSort: [],
      disableInfiniteScroll: false,
      atletasPaginated: true,
      atletasPerPage: 25,
    }
  },
  computed: {
    ...mapGetters([
      'statusAtletas', 
      'scouts', 
      'posicoes', 
      'configAtletas'
    ]),
    heightTable() {
      const screenHeight = window.screen.height - 200;
      const screenAvailable = screenHeight - 200;
      return `${screenAvailable}px`;
    },
    saldoCartoletas(){

      let compras = 0
      
      this.team.atletas.map(a => {
        compras += a.preco
      })

      return (this.team.qtd_cartoleta - compras)
    },
    saldoCartoletasReserva(){

      let compras = 0
      
      this.team.atletas_reserva.map(a => {
        compras += a.preco
      })

      return (this.team.qtd_cartoleta_reserva - compras)
    },
  },
  watch: {
    loading: function(n, o) {
      this.loadingTable = n
    },
    atletasPerPage: async function(n, o) {
      this.atletasPaginated = true;
      this.$emit("limit", n);
    },
  },
  async created() {

    await this.getScouts()
    this.showVisibleSort = [true, true, true, true]
    this.team.scouts.map(s => {
      this.showVisibleSort.push(true)
    })

    this.atletasPerPage = this.atletasLimit
  },    
  methods: {
    ...mapActions([
      'getScouts', 
    ]),
    async changePage(page) {
      this.$emit("page", page);
    },
    async sortPressed(field, order, event) {
      this.$emit("sort", {field, order});
    },    
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    statusSort(index) {
      this.showVisibleSort = [true, true, true, true, true, true, true, true, true]
      this.showVisibleSort[index] = false
    },
    jogos(jogos){
      return parseInt(jogos) == 1 ? 'jogo' : 'jogos'
    },
    formatterStatus(status) {
      let s = status.toString()
      s = s.split(' ')
      
      let type
      if (s[1].split('-')[1] == 'check')
        type = 'success'
      else if (s[1].split('-')[1] == 'question')
        type = 'warning'
      else
        type = 'danger'
      
      return  {
        pack: s[0],
        icon: s[1].split('-')[1],
        type: type
      }
    },
    async getDetails(atleta) {

      if (!atleta['details']){

        this.loadingTable = true
        this.loadingDetail = true
        this.toggle(atleta)

        await this.$http.post(url + `/atletas/gameDetails`,{
          id_atleta: atleta.id,
          posicao: atleta.posicao_codigo,
          cd_clube_adv: atleta.time_vis_codigo,
          scouts: this.team.scouts
        })
        .catch( err => console.error(err))
        .then(response => {

          if (response.data.data) {

            let result = response.data.data

            let scouts = this.scouts.filter(s => this.team.scouts.indexOf(s.cd_scout) !== -1)
            let scoutsSelected = {}
            scouts.map(s => {
              scoutsSelected[s.cd_scout] = {sigla: s.cd_scout, descricao: s.ds_scout, conquistado: 0, cedido: 0}
            })
            scoutsSelected['total'] = {sigla: 'total', descricao: 'Pontuação Média', conquistado: 0, cedido: 0}

            let qtdJogos = {
              ult3: JSON.parse(JSON.stringify(scoutsSelected)),
              ult5: JSON.parse(JSON.stringify(scoutsSelected)),
              todos: JSON.parse(JSON.stringify(scoutsSelected))
            }

            let details = {
              casa: JSON.parse(JSON.stringify(qtdJogos)),
              fora: JSON.parse(JSON.stringify(qtdJogos)),
              ambos: JSON.parse(JSON.stringify(qtdJogos))
            }

            result.conquistado.map(d => {
              if (d.ult_3_casa == 1){
                
                this.team.scouts.map(s => {
                  details.casa.ult3[s].conquistado += d['media_'+s]
                })
                details.casa.ult3['total'].conquistado += d.media_total
              
              } else if (d.ult_5_casa == 1){
                
                this.team.scouts.map(s => {
                  details.casa.ult5[s].conquistado += d['media_'+s]
                })
                details.casa.ult5['total'].conquistado += d.media_total
              
              } else if (d.total_casa == 1){
                
                this.team.scouts.map(s => {
                  details.casa.todos[s].conquistado += d['media_'+s]
                })
                details.casa.todos['total'].conquistado += d.media_total
              
              } else if (d.ult_3_fora == 1){
                
                this.team.scouts.map(s => {
                  details.fora.ult3[s].conquistado += d['media_'+s]
                })
                details.fora.ult3['total'].conquistado += d.media_total
              
              } else if (d.ult_5_fora == 1){
                
                this.team.scouts.map(s => {
                  details.fora.ult5[s].conquistado += d['media_'+s]
                })
                details.fora.ult5['total'].conquistado += d.media_total
              
              } else if (d.total_fora == 1){
                
                this.team.scouts.map(s => {
                  details.fora.todos[s].conquistado += d['media_'+s]
                })
                details.fora.todos['total'].conquistado += d.media_total
              
              } else if (d.ult_3_total == 1){
                
                this.team.scouts.map(s => {
                  details.ambos.ult3[s].conquistado += d['media_'+s]
                })
                details.ambos.ult3['total'].conquistado += d.media_total
              
              } else if (d.ult_5_total == 1){
                
                this.team.scouts.map(s => {
                  details.ambos.ult5[s].conquistado += d['media_'+s]
                })
                details.ambos.ult5['total'].conquistado += d.media_total
              
              } else if (d.total_jogos == 1){
                
                this.team.scouts.map(s => {
                  details.ambos.todos[s].conquistado += d['media_'+s]
                })
                details.ambos.todos['total'].conquistado += d.media_total
              
              }
            })

            result.cedido.map(d => {
              if (d.ult_3_casa == 1){
                
                this.team.scouts.map(s => {
                  details.casa.ult3[s].cedido += d['media_'+s]
                })
                details.casa.ult3['total'].cedido += d.media_total
              
              } else if (d.ult_5_casa == 1){
                
                this.team.scouts.map(s => {
                  details.casa.ult5[s].cedido += d['media_'+s]
                })
                details.casa.ult5['total'].cedido += d.media_total
              
              } else if (d.total_casa == 1){
                
                this.team.scouts.map(s => {
                  details.casa.todos[s].cedido += d['media_'+s]
                })
                details.casa.todos['total'].cedido += d.media_total
              
              } else if (d.ult_3_fora == 1){
                
                this.team.scouts.map(s => {
                  details.fora.ult3[s].cedido += d['media_'+s]
                })
                details.fora.ult3['total'].cedido += d.media_total
              
              } else if (d.ult_5_fora == 1){
                
                this.team.scouts.map(s => {
                  details.fora.ult5[s].cedido += d['media_'+s]
                })
                details.fora.ult5['total'].cedido += d.media_total
              
              } else if (d.total_fora == 1){
                
                this.team.scouts.map(s => {
                  details.fora.todos[s].cedido += d['media_'+s]
                })
                details.fora.todos['total'].cedido += d.media_total
              
              } else if (d.ult_3_total == 1){
                
                this.team.scouts.map(s => {
                  details.ambos.ult3[s].cedido += d['media_'+s]
                })
                details.ambos.ult3['total'].cedido += d.media_total
              
              } else if (d.ult_5_total == 1){
                
                this.team.scouts.map(s => {
                  details.ambos.ult5[s].cedido += d['media_'+s]
                })
                details.ambos.ult5['total'].cedido += d.media_total
              
              } else if (d.total_jogos == 1){
                
                this.team.scouts.map(s => {
                  details.ambos.todos[s].cedido += d['media_'+s]
                })
                details.ambos.todos['total'].cedido += d.media_total
              
              }
            })

            atleta['details'] = JSON.parse(JSON.stringify(details))
          }
        })
      
        this.loadingDetail = false
        this.loadingTable = false
        this.toggle(atleta)
      }

      atleta['defaultDetail'] = (atleta.mando == 'fora') ? 1 : 0 

    },
    buy(posicao, atleta){
      this.$emit("buy", {posicao, atleta});
    },
    sell(posicao, atleta){
      this.$emit("sell", {posicao, atleta});
    },
  },
}
</script>

<style scoped>

@media screen and (max-width:768px) {
  .tabela-atl {
    height: auto !important;
  }
  .nome {
    font-size: 0.9rem !important; 
  }
  .posicao {
    font-size: 0.7rem !important;
  }
  .align-scouts-atl {
    width: 70% !important;
    float: left;
    text-align: left !important;
    /* padding-right: 0.75rem; */
  }
  .align-scouts-jg {
    float: left;
  }
  .atl-info-cel {
    display: block;
  }
  .atl-info-cel label {
    font-size: 0.7rem;
    opacity: 0.6;
  }
  .atl-info-cel p {
    font-size: 0.8rem;
  }
}
.align-atl-clb {
  position: relative;
  width: 60px;
}
.align-scouts-atl {
  margin: 0 0 0 10px;
  /* width: 70%; */
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.align-scouts-jg {
  display: table;
  line-height: 0.70rem
}
.align-scouts-jg p {
  display: inline-flex;
}
.group-opc-atl {
  padding: 10px 5px 5px 5px;
  width: 100%;
}
.group-opc-atl h3 {
  font-weight: bolder;
  margin-left: 10px;
  text-transform: uppercase;
}
.round-pos-atl {
  padding: 5px;
  margin: 5px;
  border-radius: 50%;
}
.round-pos-atl-2 {
  padding: 6px;
  margin: 0 12px 0 0;
  border-radius: 50%;
}
.round-pos-atl-3 {
  height: 15px;
  width: 15px;
  margin: 0 12px 0 0;
  border-radius: 50%;
}
.group-opc-atl span {
  text-transform: uppercase;
  font-size: 0.75rem;
}

.nome-jogador {
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}
.sub-subtitle {
  opacity: 0.6;
  font-size: 0.7rem;
  margin: 0;
  line-height: 0.7rem;
}
@media screen and (min-width: 769px) and (max-width:1390px) {
  .round-team-atl {
    height: 35px !important;
    width: 35px !important;
  } 
  .round-team-atl img {
    width: 32px;
  }
  .round-team-atl-jogador-1 {
    height: 35px !important;
    width: 35px !important;
  }
  .round-team-atl-1 {
    height: 25px !important;
    width: 25px !important;
  }
  .round-team-atl-1 img {
    width: 22px;
  }
  .round-team-atl-jogador-1 img {
    position: relative;
    top: 50%;
    left: 50%;
    padding: 3px;
    transform: translate(-50%, -42%)
  }
  .numeric-table {
    font-size: 0.8rem;
  }
  .span-scout {
    font-size: 0.55rem !important;
  }
  .align-scouts-atl {
    /* width: 48% !important; */
  }
  /*
  .align-scouts-jg {
    max-width: 85%;
  } */
  .nome-jogador {
    font-size: 0.76rem !important;
  }
  .subtitle-atl {
    font-size: 0.65rem !important;
  }
  .tabela-atl {
    height: 85vh !important; 
  }
  .label-table {
    font-size: 0.7rem !important;
  }
  .label-table i {
    font-size: 0.7rem !important;
  }
  .legend-position {
    font-size: 0.55rem !important;
  }
}
.round-team-atl {
  background-color: transparent;
  position: relative;
  border-radius: 50%;
  height: 45px;
  width: 45px;
}
.round-team-atl-jogador {
  background-color: white;
  position: relative;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  overflow: hidden;
  float: left;
}

.round-team-atl img {
  position: relative;
  top: 50%;
  left: 44%;
  padding: 3px;
  transform: translate(-50%, -50%)
}
.round-team-atl-jogador img {
  position: relative;
  top: 50%;
  left: 50%;
  padding: 3px;
  transform: translate(-50%, -42%)
}
.round-team-atl-1 {
  background-color: white;
  position: relative;
  border-radius: 50%;
  border: 1px solid #4d4d4d;
  height: 30px;
  width: 30px;
  left: 49%;
  top: 10px;
  transform: translate(-10%, 66%)
}
.round-team-atl-jogador-1 {
  background-color: white;
  position: relative;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  top: 10px;
  overflow: hidden;
  float: left;
}

.round-team-atl-1 img {
  position: relative;
  left: 50%;
  padding: 3px;
  transform: translate(-50%, -145%)
}
.round-team-atl-jogador-1 img {
  position: relative;
  top: 50%;
  left: 50%;
  padding: 3px;
  transform: translate(-50%, -42%)
}

@media screen and (max-width: 768px) {
  .round-team-atl-1 {
    background-color: white;
    position: relative;
    border-radius: 50%;
    border: 1px solid #4d4d4d; 
    height: 25px;
    width: 25px;
    left: 49%;
    top: 15px;
    transform: translate(-10%, 66%)
  }
  .round-team-atl-jogador-1 {
    background-color: white;
    position: relative;
    border-radius: 50%;
    top: 10px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    float: left;
  }

  .round-team-atl-1 img {
    position: relative;
    left: 53%;
    padding: 3px;
    transform: translate(-50%, -168%)
  }
  .round-team-atl-jogador-1 img {
    position: relative;
    top: 50%;
    left: 50%;
    padding: 3px;
    transform: translate(-50%, -42%)
  }
  .round-jogador-acd {
    border: 1px solid #1f1f1f;
  }
  .round-jogador-acd img {
    position: relative;
    left: 50%;
    transform: translate(-50%, 10%);
  }
}

.jogador-campo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.span-scout {
  margin: 0 2px;
  font-size: 0.60rem;
  display: inline-flex;
}

.tabela-atl {
  width: 100%;
  height: 85vh;
  overflow-y: auto;
  background-color: #4d4d4d;
  border-radius: 7px;
  padding: 5px;
}

.subtitle-atl {
  opacity: 0.4;
  display: block;
}

.round-jogador-acd {
  background-color: white;
  position: relative;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  overflow: hidden;
  z-index: 1;
}

.legenda-nome-acd {
  z-index: 2;
  position: relative;
  /* background-color: #202833; */
  background-color: #333333;
  color: white;
  padding: 5px 20px;
  border-radius: 20px;
  top: -10px;
  text-align: center;
  font-size: 0.9rem;
  /* width: fit-content; */
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.fit-content-acd {
  width: 100%;
  padding: 5px 5px 15px 5px;
  position: relative;
  /* background-color: #202833; */
  background-color: #333333;
  border-radius: 20px;
}
.fit-content-acd h5 {
  width: 100%;
  text-align: center;
  border-radius: 20px;
  border: 2px solid rgb(156, 156, 156);
  color: rgb(156, 156, 156);
  padding: 3px 0;
  margin-bottom: 15px;
}
.fit-content-acd-2 {
  width: 100%;
  padding: 5px;
}
.fit-content-acd-2 h3 {
  font-weight: 600;
  text-align: center;
}

.field-acd {
  text-align: center;
  margin: 5px 0;
}
.field-acd h6 {
  font-size: 0.75rem;
}
.field-acd p {
  opacity: 0.7;
  font-size: 0.75rem;
}

.col-acd {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-inside-fit {
  padding: 5px;
  font-size: 0.75rem;
}
.content-inside-fit div {
  padding: 5px 0;
}

.graph-title {
  width: 100%;
  font-weight: bolder;
  text-align: center;
}
.graph-options {
  width: 100%;
  font-weight: bolder;
  font-size: 1.2rem;
  text-align: center;
  height: min-content;
  margin-bottom: 20px;
  width: auto;
}

.pos-nome-atl {
  font-size: 0.7rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scouts-atl {
  width: 40px;
  display: inline-flex;
}
.legend-position {
  opacity: 0.6;
  margin-right: 2px;
  font-size: 0.7rem;
  width: 25px;
  text-align: center;
}

/*Informações do Mini Game*/
.minigame-label {
  font-size: 1.5em !important;
  font-weight: bolder !important;
}
.minigame-label-1 {
  font-size: 1.2em !important;
  font-weight: bolder !important;
}
.minigame-label-2 {
  font-size: 1.0em !important;
  font-weight: bolder !important;
}
.minigame-label-3 {
  font-size: 0.9em !important;
}
.minigame-photo {
  width: auto;
  height: auto;
  border: 1px solid white;
  border-radius: 10%;
  box-shadow: 6px 6px 5px 5px rgba(0,0,0,.3);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 600px) {
  .minigame-photo {
    width: 100% !important;
    height: 100px !important;
    border-right: none;
  }
  .minigame-bar {
    display: block;
  }
  .minigame-half-content {
    width: 100%;
    height: auto;
    display: inline-block;
  }
  .minigame-align-content {
    width: 100%;
    height: auto;
    display: block;
  }
}

.minigame-content-sm-inf {
  display: inline-flex;
  width: 100%;
  height: 100%;
  padding: 1em;
}
.minigame-align-photo-inf {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  min-width: 70px;
  min-height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid white;
  margin-right: 2em;
}

.minigame-bd-left {
  border-left: 1px solid aqua;
}
.minigame-bd-right {
  border-right: 1px solid aqua;
}

#mini-game-atletas .atleta-detail-column {
  border-radius: 10px;
  margin: 0px 5px;
  padding: 0px !important;
  text-align: center;
}
#mini-game-atletas .atleta-detail-column-title {
  border: 1px solid white;
  border-radius: 10px;
  width: auto;
  text-align: center;
  font-size: 1.0em;
  font-weight: bold;
  padding: 5px;
}
#mini-game-atletas .atleta-detail-column-content {
  width: auto;
  text-align: center;
  font-size: 0.8em;
  padding: 5px;
}
#mini-game-atletas .atleta-detail-column-content table {
  width: 100%;
}
#mini-game-atletas .atleta-detail-column-content th,
#mini-game-atletas .atleta-detail-column-content td {
  padding: 5px;
  text-align: center;
  background-color: transparent !important;
}
#mini-game-atletas .atleta-detail-column-content th {
  font-size: 1.1em;
}
#mini-game-atletas .atleta-detail-column-content p {
  padding: 5px;
}

</style>
