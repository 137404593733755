<template>
  <div id="leagues-manager">
    <div v-if="!loading" class="columns is-multiline">
      <div
        v-for="room of leaguesVerified"
        :key="`room-${room.id}`"
        class="column"
        :class="{
          'is-narrow': !isFluid && $mq !== 'sm',
          'is-one-quarter': isFluid && $mq !== 'sm',
          'is-half': $mq === 'sm',
        }"
      >
        <SalaCard
          :sala="room"
          :enrolled="isEnrolled(room.id)"
          :reserved-salas="reservedLeagues"
          :is-user-leagues="isUserLeagues"
          @refresh="load()"
          @login="setLogin"
          @register="setLogin"
        ></SalaCard>
      </div>
    </div>
    <b-loading :active.sync="loading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
import SalaCard from "./SalaCard.vue";

export default {
  name: "LeaguesManager",
  components: {
    SalaCard,
  },
  props: {
    leagues: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    userLeagues: {
      type: Array,
      default: () => [],
    },
    isFluid: {
      type: Boolean,
      default: true,
    },
    isUserLeagues: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reservedLeagues: [...this.userLeagues],
      leaguesVerified: [...this.leagues],
    };
  },
  watch: {
    leagues(newValue) {
      this.leaguesVerified = [...newValue];
    },
    userLeagues(newValue) {
      this.reservedLeagues = [...newValue];
    },
  },
  methods: {
    setLogin() {
      this.$emit("login");
    },
    isEnrolled(roomId) {
      const isEnroll = this.reservedLeagues.find(
        (reservedRoom) => reservedRoom.id_sala === roomId
      );
      return isEnroll ? true : false;
    },
  },
};
</script>
