<template>
  <div
    class="banner"
    :style="{
      backgroundImage,
    }"
  >
    <div class="lg-avatar">
      <img :src="influencer.url_foto" />
      <h1>{{ influencer.nome_midia }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerInfluencer",
  props: {
    influencer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backgroundImage() {
      return this.influencer.configuracao?.banner
        ? `url(${this.influencer.configuracao.banner})`
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.ajust-image {
  height: 45px !important;
}
.banner {
  height: 230px;
  width: 100%;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  background-image: url("/images/banner-influencer.jpg");

  .lg-avatar {
    position: absolute;
    left: 1.5em;
    bottom: 1.5em;
    display: flex;
    align-items: flex-end;

    img {
      border-radius: 3px;
      width: 90px;
      height: 90px;
      -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    }

    h1 {
      margin-left: 0.7em;
      font-size: 2em !important;
      font-weight: 600;
      color: white;
      text-shadow: rgba(0, 0, 0, 0.75) 0px 0px 9px;
    }
  }

  @media (max-width: 768px) {
    .lg-avatar {
      left: 0.75em !important;
      bottom: 0.75em !important;

      img {
        width: 60px !important;
        height: 60px !important;
      }

      h1 {
        font-size: 18px !important;
      }
    }
  }
}
</style>
