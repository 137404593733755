var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"Register"}},[_c('WizardLayout',{ref:"wizard",attrs:{"title":"Cadastro","subtitle":"Insira suas informações para se cadastrar no Escala10","has-close":"","display-default-logo":_vm.displayDefaultLogo,"has-logo":_vm.hasLogo,"url-logo":_vm.urlLogo,"logo-href":_vm.logoHref,"width-logo":_vm.widthLogo},on:{"close":function($event){return _vm.$emit('showLogin')}}},[_c('ValidationObserver',{staticClass:"form-auth",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"columns is-multiline is-variable is-2"},[_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"text","name":"cad-nome","rules":{
              required: true,
            },"label":"Nome"},model:{value:(_vm.cadUser.nome),callback:function ($$v) {_vm.$set(_vm.cadUser, "nome", $$v)},expression:"cadUser.nome"}})],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"text","name":"cad-nome","rules":{
              required: true,
            },"label":"Sobrenome"},model:{value:(_vm.cadUser.sobrenome),callback:function ($$v) {_vm.$set(_vm.cadUser, "sobrenome", $$v)},expression:"cadUser.sobrenome"}})],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"text","name":"cad-nome","rules":{
              required: true,
              cpf: true,
            }},model:{value:(_vm.cadUser.cpf),callback:function ($$v) {_vm.$set(_vm.cadUser, "cpf", $$v)},expression:"cadUser.cpf"}},[_c('template',{slot:"label"},[_c('p',[_vm._v("CPF")]),_c('span',{staticStyle:{"font-size":"0.7rem"}},[_vm._v("(necessário para transações bancárias em Ligas)")])])],2)],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"text","name":"cad-telefone","icon":"whatsapp"},model:{value:(_vm.cadUser.telefone),callback:function ($$v) {_vm.$set(_vm.cadUser, "telefone", $$v)},expression:"cadUser.telefone"}},[_c('template',{slot:"label"},[_c('p',[_vm._v("Telefone")]),_c('span',{staticStyle:{"font-size":"0.7rem"}},[_vm._v("(DDD + Número)")])])],2)],1),_c('div',{staticClass:"column is-one-third"},[_c('Input',{attrs:{"type":"text","name":"cad-cidade","rules":{
              required: true,
            },"label":"Cidade"},model:{value:(_vm.cadUser.endereco.cidade),callback:function ($$v) {_vm.$set(_vm.cadUser.endereco, "cidade", $$v)},expression:"cadUser.endereco.cidade"}})],1),_c('div',{staticClass:"column is-one-third"},[_c('Select',{attrs:{"name":"cad-cidade","rules":"required","label":"Estado"},model:{value:(_vm.cadUser.endereco.uf),callback:function ($$v) {_vm.$set(_vm.cadUser.endereco, "uf", $$v)},expression:"cadUser.endereco.uf"}},_vm._l((_vm.states),function(estado,i){return _c('option',{key:i,domProps:{"value":estado}},[_vm._v(" "+_vm._s(estado)+" ")])}),0)],1),_c('div',{staticClass:"column is-one-third"},[_c('Input',{attrs:{"type":"text","name":"cad-pais","rules":{
              required: true,
            },"label":"País"},model:{value:(_vm.cadUser.endereco.pais),callback:function ($$v) {_vm.$set(_vm.cadUser.endereco, "pais", $$v)},expression:"cadUser.endereco.pais"}})],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"text","name":"cad-email","rules":{
              required: true,
              email: true,
            },"label":"Email"},model:{value:(_vm.cadUser.email),callback:function ($$v) {_vm.$set(_vm.cadUser, "email", $$v)},expression:"cadUser.email"}})],1),_c('div',{staticClass:"column is-half"},[_c('Input',{attrs:{"type":"password","name":"cad-password","rules":"required","label":"Senha","password-reveal":""},model:{value:(_vm.cadUser.pass),callback:function ($$v) {_vm.$set(_vm.cadUser, "pass", $$v)},expression:"cadUser.pass"}})],1),_c('div',{staticClass:"column is-full"},[_c('b-field',[_c('b-checkbox',{attrs:{"true-value":1,"false-value":0,"type":"is-primary"},model:{value:(_vm.cadUser.termos_uso),callback:function ($$v) {_vm.$set(_vm.cadUser, "termos_uso", $$v)},expression:"cadUser.termos_uso"}},[_vm._v("Concorda com os nossos "),_c('a',{attrs:{"href":"/termos-de-uso","target":"_blank"}},[_vm._v("termos de usos")]),_vm._v(".")])],1),_c('b-field',[_c('b-checkbox',{attrs:{"true-value":1,"false-value":0,"type":"is-primary"},model:{value:(_vm.cadUser.mensagens_externas),callback:function ($$v) {_vm.$set(_vm.cadUser, "mensagens_externas", $$v)},expression:"cadUser.mensagens_externas"}},[_vm._v("Aceita receber informações do Escala10, como novidades, promoções e informações, por meio de seus contatos cadastrados.")])],1)],1)]),_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('b-button',{attrs:{"type":"is-secondary"},on:{"click":function($event){return _vm.$emit('showLogin')}}},[_vm._v("Voltar")])],1),_c('div',{staticClass:"level-right"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return handleSubmit(_vm.validForm)}}},[_vm._v(" Confirmar ")])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }